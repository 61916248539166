import { tr } from 'pmt-modules/i18n'
import React, { Fragment } from 'react'
import isNil from 'lodash/isNil'

import { CheckContainer } from 'pmt-modules/payment'

import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'
import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'

import { getQueryParam } from 'pmt-utils/url'

import { Button } from 'app/components/Button'

import { Check, TableNumber } from './components'
import OpeningHours from './components/OpeningHours'

const styles = theme => ({
  root: {
    display: 'flex',
    flex: 1,
    background: `${theme.palette.primary.main} center center`,
    backgroundSize: 'cover',
  },
  darkLayout: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: 'black',
    opacity: 0.2,
    zIndex: 1,
  },
  overContainer: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    padding: theme.spacing(4),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  logo: {
    '&>img': {
      maxWidth: 115,
      maxHeight: 63,
    },
  },
  content: {
    flex: 1,
  },
  welcome: {
    whiteSpace: 'pre-line',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.pmt.colors.white,
  },
  cantPayFooter: {
    '& button': {
      width: '100%',
    },
  },
})

const View = ({
  canOrder,
  canPay,
  classes,
  frontAppConfig,
  orderAppConfig,
  isFetchingRestaurant,
  restaurant,
  onRedirectToOrder,
  onRedirectToPayment,
}) => {
  const posCheckId = getQueryParam('posCheckId')
  const tableId = getQueryParam('tableId')

  return (
    <div
      className={classes.root}
      style={
        frontAppConfig.theme.background.path && frontAppConfig.theme.background.display
          ? {
              backgroundImage: `url(${frontAppConfig.theme.background.path})`,
            }
          : {}
      }
    >
      <LoadingBlockWrapper show={isFetchingRestaurant || !restaurant} />
      <div className={classes.darkLayout} />

      <div className={classes.overContainer}>
        {restaurant &&
          ((posCheckId || tableId) && (
            <CheckContainer
              forceReload
              restaurantId={restaurant.id}
              posCheckId={posCheckId}
              tableNumber={tableId}
            >
              {({ check, checkError, isFetchingCheck, reloadCheck }) => (
                <Fragment>
                  {(frontAppConfig.theme.logo.path || restaurant.logo) && (
                    <div className={classes.header}>
                      <div className={classes.logo}>
                        <img src={frontAppConfig.theme.logo.path || restaurant.logo} alt="logo" />
                      </div>
                      <TableNumber check={check} canPay={canPay} />
                    </div>
                  )}
                  <div className={classes.content}>
                    <CustomTextsContainer>
                      {({ texts }) => (
                        <TypographyCustom type={307} skipColor className={classes.welcome}>
                          {!isNil(texts) && !isNil(texts.WEB_CUSTOMER__RESTAURANT__WELCOME)
                            ? texts.WEB_CUSTOMER__RESTAURANT__WELCOME.replace(
                                '%{name}',
                                restaurant.name
                              )
                            : tr('web_customer.restaurant.welcome', { name: restaurant.name })}
                        </TypographyCustom>
                      )}
                    </CustomTextsContainer>

                    {/* Show opening hours if there is no bypassOpeningHours */}
                    {!orderAppConfig.bypassOpeningHours && <OpeningHours restaurant={restaurant} />}
                  </div>

                  {!canPay ? (
                    <div className={classes.cantPayFooter}>
                      {restaurant.hasFeatureOrder && (
                        <Button disabled={!canOrder} onClick={onRedirectToOrder}>
                          <TypographyCustom type="187" skipColor>
                            {tr('web_customer.restaurant.order_button')}
                          </TypographyCustom>
                        </Button>
                      )}
                    </div>
                  ) : (
                    <Check
                      check={check}
                      checkError={checkError}
                      isFetchingCheck={isFetchingCheck}
                      reloadCheck={reloadCheck}
                      restaurant={restaurant}
                      canOrder={canOrder}
                      canPay={canPay}
                      onRedirectToOrder={onRedirectToOrder}
                      onRedirectToPayment={onRedirectToPayment}
                    />
                  )}
                </Fragment>
              )}
            </CheckContainer>
          ))}
      </div>
    </div>
  )
}

export default withStyles(styles)(View)
