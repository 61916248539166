import { tr } from 'pmt-modules/i18n'
import React, { Fragment } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import ui from 'pmt-modules/reduxUi'
import get from 'lodash/get'
import set from 'lodash/set'

import { getUserSettings } from 'pmt-modules/appConfig/selectors'
import { withForm, FormType, updateFormOptions } from 'pmt-modules/form'
import { getRegisterError } from 'pmt-modules/registration'

import TextField from 'pmt-ui/TextField'
import Cgu from 'pmt-ui/Cgu'

import { getField, registrationCodeErrors } from './constants'
import { getUserLightCookie } from '../../../auth/utils'


/**
 * Register form fields are retrieved from api consumer settings
 * see ./constants for mapping field type and components cloned here
 * Cgu component is not configurable and thus should always be displayed
 */
class RegisterFormView extends React.PureComponent {
  static Steps = {
    DEFAULT: 'DEFAULT',
    REGISTRATION_CODE: 'REGISTRATION_CODE',
  }

  constructor(props) {
    super(props)

    const formFields = this.props.userSettings.registration.form.fields

    this.props.updateFormOptions(FormType.REGISTER, {
      fields: formFields,
    })
  }

  componentWillReceiveProps(nextProps) {
    const { registerError, updateUI, ui } = nextProps

    if (
      !this.props.registerError &&
      registerError &&
      (registerError.code === registrationCodeErrors.SET_PASSWORD ||
        registerError.code === registrationCodeErrors.INVALID_CODE)
    ) {
      updateUI({
        step: RegisterFormView.Steps.REGISTRATION_CODE,
        initialized: ui.initialized,
      })
    }
  }

  render() {
    const { style, formData, formErrors, onChange, cguLabel, ui } = this.props
    const formFields = this.props.userSettings.registration.form.fields

    if (!ui.initialized) {
      const userLightCookie = getUserLightCookie()
      if (formFields && userLightCookie) {
        formFields.forEach(fieldInfo => {
          const field = getField(fieldInfo.type)
          set(formData, field.key, get(userLightCookie, field.key))
        })
      }
      ui.initialized = true
    }

    // if we have a registration error with these specific code, it means the user already
    // exists has user light, so we replace form with a registration code input
    // in order to complete registration
    return (
      <div style={style}>
        {ui.step === RegisterFormView.Steps.DEFAULT && (
          <Fragment>
            {formFields.map(fieldInfo => {
              const field = getField(fieldInfo.type)

              return React.cloneElement(field.component, {
                key: field.key,
                value: get(formData, field.key),
                required: fieldInfo.required,
                onChange: value => onChange(set(formData, field.key, value)),
                validation: get(formErrors, field.key),
              })
            })}
            <Cgu
              label={cguLabel}
              value={formData.acceptCgu}
              onChange={acceptCgu => onChange({ ...formData, acceptCgu })}
            />
          </Fragment>
        )}
        {ui.step === RegisterFormView.Steps.REGISTRATION_CODE && (
          <TextField
            autoFocus
            label={tr('global.form.user.registrationCode')}
            inputProps={{ maxLength: 5 }}
            className="u-sizeFullWidth u-marginTop20"
            value={formData.registrationCode || ''}
            onChange={e => onChange({ ...formData, registrationCode: e.target.value })}
          />
        )}
      </div>
    )
  }
}

RegisterFormView.defaultProps = {
  cguLabel: null,
}

const mapStateToProps = state => ({
  userSettings: getUserSettings(state),
  registerError: getRegisterError(state),
})

export default compose(
  ui({
    state: {
      step: RegisterFormView.Steps.DEFAULT,
      initialized: false,
    },
  }),
  connect(
    mapStateToProps,
    {
      updateFormOptions,
    }
  ),
  withForm(FormType.REGISTER)
)(RegisterFormView)
