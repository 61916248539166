import External from './constants/External'
import { isLocalhost, isDev, isPreprod, isProd } from '../environment'

export const getOrderPluginUrl = () => {
  if (isLocalhost() || isDev()) {
    return External.ORDER_PLUGIN_DEV
  }
  if (isPreprod()) {
    return External.ORDER_PLUGIN_PREPROD
  }
  if (isProd()) {
    return External.ORDER_PLUGIN_PROD
  }
}