import { createMiddleware } from 'pmt-modules/redux'
import MangoPayErrors from 'pmt-modules/api/errors/MangoPayErrors'

import {
  FetchUserCreditCardsAction,
  PostUserCreditCardRegistrationTypeAction,
  SendUserCreditCardToPspAction,
  postPspDatas,
  PostPspDatasAction,
  sendCreditCardToPsp,
  onSelectUserCard,
} from './actions'
import { replaceIndexesByCreditCardFields } from './utils'
import { PspRegisterUserCardType } from './constants'

const fetchCreditCardMiddleware = createMiddleware(
  FetchUserCreditCardsAction.SUCCESS,
  ({ getState, dispatch, next, action }) => {
    if (action.response.length === 1) {
      dispatch(onSelectUserCard(action.data.userId, action.response[0]))
    }
  }
)

const postPspDatasActionSuccess = createMiddleware(
  PostPspDatasAction.SUCCESS,
  ({ action, dispatch }) => {
    dispatch(onSelectUserCard(action.data.userId, action.response))
  }
)

const sendCreditCardToPspMiddleware = createMiddleware(
  PostUserCreditCardRegistrationTypeAction.SUCCESS,
  ({ dispatch, action }) => {
    if (action.response.type === PspRegisterUserCardType.WEB_SERVICES) {
      const { body, pspRegistrationId } = action.response.map
      const { userId, restaurantId, data, options } = action.data
      const bodyFormatted = replaceIndexesByCreditCardFields(body, data.creditCard)

      dispatch(
        sendCreditCardToPsp(
          userId,
          restaurantId,
          pspRegistrationId,
          data.creditCard,
          {
            url: action.response.url,
            method: action.response.httpMethod,
            body: bodyFormatted,
          },
          options
        )
      )
    }
  }
)

const sendReceivedCreditCardFromPspToApiMiddleware = createMiddleware(
  SendUserCreditCardToPspAction.SUCCESS,
  ({ dispatch, action }) => {
    // The mangopay api returns a 201 but with an errorCode inside..
    // the fake PSP returns a json, and not a string, so the indexOf function does not exist
    if (action.response.indexOf === 'function' && action.response.indexOf('errorCode=') >= 0) {
      // get errorCode int
      const errorCode = action.response.replace('errorCode=', '')

      const apiError = {
        code: errorCode.trim(),
        message: 'MangoPay error',
        localizedMessage: MangoPayErrors.registerCreditCard(errorCode),
        response: {
          ok: false,
          status: 400,
        },
      }

      dispatch({ type: SendUserCreditCardToPspAction.FAILURE, error: apiError })
      return createMiddleware.STOP_PROPAGATION
    }

    //API expects 'data' to be a string
    const data = typeof action.response === 'string'? action.response : JSON.stringify(action.response)

    dispatch(
      postPspDatas(
        action.data.userId,
        action.data.restaurantId,
        action.data.pspRegistrationId,
        action.data.creditCard,
        data, 
        action.data.options
      )
    )
  }
)

export const creditCardMiddlewares = [
  fetchCreditCardMiddleware,
  postPspDatasActionSuccess,
  sendCreditCardToPspMiddleware,
  sendReceivedCreditCardFromPspToApiMiddleware,
]
