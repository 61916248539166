import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { TopUpChooseAmountView } from 'pmt-modules/topUp'
import { UserAccountRemainingView } from 'pmt-modules/userAccount'

import ErrorBlock from 'pmt-ui/ErrorBlock'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import Price from 'pmt-ui/Price'
import { TypographyCustom } from 'pmt-ui/Typography'
import UserType from 'pmt-modules/user/constants/UserType'

import PaymentMethods from 'app/components/payment/PaymentMethods'
import Section from 'app/components/Section'

/**
 * @mockup N/A
 */
const View = ({
  isFetching,
  isSubmitDisabled,
  onSubmit,
  onSubmitNewCard,
  topUp,
  topUpError,
  userAccount,
  userId,
  user,
}) => (
  <div
    className="u-overflowAuto u-sizeFullWidth u-paddingBottom50 u-flex1" // huge padding on prupose for iOS < 10
  >
    <LoadingBlockWrapper show={isFetching} />

    <ErrorBlock mode={ErrorBlock.Mode.SNACKBAR} error={topUpError} />

    <UserAccountRemainingView userId={userId} dislayLinkToTopUp={false} />

    <div className="u-marginBottom30">
      <Section
        title={tr('web_customer.top_up.selectable_amount', {
          fr: 'Montant du rechargement',
          context: '',
          desc: 'List of amounts available for top up',
        })}
      >
        <TopUpChooseAmountView userId={userId} userAccount={userAccount} />
      </Section>
    </div>

    <Section
      title={tr('web_customer.top_up.payment_method', {
        fr: 'Moyens de paiement',
        context: '',
        desc: 'Payment methods used for top up',
      })}
    >
      {user && (
        <PaymentMethods
          isFetching={isFetching}
          isSubmitDisabled={isSubmitDisabled}
          onSubmit={onSubmit}
          onSubmitNewCard={onSubmitNewCard}
          submitLabel={
            <TypographyCustom type="207" skipColor className="u-flex">
              {tr('web_customer.top_up.action')}
              &nbsp;
              {topUp && topUp.amount > 0 && <Price value={topUp.amountPriceFormatted} />}
            </TypographyCustom>
          }
          user={user}
          canRegisterCardWithName={user && user.type === UserType.NORMAL }
        />
      )}
    </Section>
  </div>
)

export default View
