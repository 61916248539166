import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { compose } from 'redux'
import isNil from 'lodash/isNil'

import LabelDivider from 'pmt-ui/LabelDivider'
import ErrorBlock from 'pmt-ui/ErrorBlock'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'
import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'

import { Button, ButtonLink } from '../../../components/Button'

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
  },
  allowIncognito: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
})

const AuthenticationChoiceForm = ({
  classes,
  isFetchingAuth,
  isFetchingRegister, // useful if registering as incognito
  authError,
  onRedirectToRegister,
  onRedirectToLogin,
  onRedirectToIncognito,
  allowIncognito,
}) => (
  <div className={classes.root}>
    <LoadingBlockWrapper show={isFetchingAuth || isFetchingRegister} />

    <ErrorBlock mode={ErrorBlock.Mode.SNACKBAR} error={authError} />

    <div className="u-marginTop50 u-sizeFullWidth">
      <Button
        className="u-sizeFull"
        onClick={e => {
          e.preventDefault()
          onRedirectToRegister()
        }}
      >
        <TypographyCustom type="167" skipColor>
          {tr('web_customer.login.signin')}
        </TypographyCustom>
      </Button>
      {allowIncognito && (
        <div className={classes.allowIncognito}>

          <CustomTextsContainer>
            {({ texts }) => (
                <ButtonLink
                    component="a"
                    onClick={e => {
                      e.stopPropagation()
                      onRedirectToIncognito()
                    }}
                    label={!isNil(texts) && !isNil(texts.ORDER__INCOGNITO__LINK)
                        ? texts.ORDER__INCOGNITO__LINK
                        : tr('web_customer.login.continue_as_incognito.from_NORMAL.link')}
                />
            )}
          </CustomTextsContainer>

        </div>
      )}
    </div>

    <LabelDivider className="u-marginTop30 u-marginBottom30" label={tr('global.divider.or')} />

    <Button
      className="u-marginTop20 u-sizeFullWidth"
      variant="outlined"
      onClick={e => {
        e.preventDefault()
        onRedirectToLogin()
      }}
    >
      <TypographyCustom type="167" skipColor>
        {tr('web_customer.login.connect')}
      </TypographyCustom>
    </Button>
  </div>
)

export default compose(withStyles(styles))(AuthenticationChoiceForm)
