import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Egg = props => (
  <SvgIcon {...props}>
    <path d="M 9 2 C 6.898438 2 5.175781 3.484375 3.96875 5.40625 C 2.761719 7.328125 2 9.773438 2 12.1875 C 2 15.234375 3.339844 17.570313 5.21875 18.78125 C 6.5 20.628906 8.75 22 11.8125 22 C 14.226563 22 16.671875 21.238281 18.59375 20.03125 C 20.515625 18.824219 22 17.101563 22 15 C 22 12.898438 20.515625 11.175781 18.59375 9.96875 C 17.652344 9.375 16.566406 8.902344 15.4375 8.5625 C 14.941406 6.929688 14.175781 5.40625 13.15625 4.21875 C 12.058594 2.9375 10.636719 2 9 2 Z M 9 4 C 9.863281 4 10.804688 4.503906 11.65625 5.5 C 12.238281 6.179688 12.726563 7.078125 13.125 8.0625 C 12.691406 8.015625 12.25 8 11.8125 8 C 7.519531 8 4.808594 10.683594 4.15625 13.625 C 4.074219 13.179688 4 12.707031 4 12.1875 C 4 10.199219 4.664063 8.046875 5.65625 6.46875 C 6.648438 4.890625 7.898438 4 9 4 Z M 11.8125 10 C 13.800781 10 15.953125 10.664063 17.53125 11.65625 C 19.109375 12.648438 20 13.898438 20 15 C 20 16.101563 19.109375 17.351563 17.53125 18.34375 C 15.953125 19.335938 13.800781 20 11.8125 20 C 7.773438 20 6 17.371094 6 15 C 6 12.628906 7.773438 10 11.8125 10 Z " />
  </SvgIcon>
)
Egg = pure(Egg)
Egg.displayName = 'Egg'
Egg.muiName = 'SvgIcon'

export default Egg
