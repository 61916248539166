import React from 'react'
import PropTypes from 'prop-types'

import { generateUUID } from 'pmt-utils/uuid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

class MenuView extends React.Component {
  state = {
    anchorEl: null,
    id: generateUUID(),
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { anchorEl } = this.state
    const { children, button } = this.props

    return (
      <React.Fragment>
        {React.cloneElement(button, {
          'aria-owns': anchorEl ? this.state.id : null,
          'aria-haspopup': 'true',
          onClick: this.handleClick,
        })}
        <Menu
          id={this.state.id}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {children({
            onClose: this.handleClose,
          })}
        </Menu>
      </React.Fragment>
    )
  }
}

MenuView.propTypes = {
  button: PropTypes.node.isRequired,
}

MenuView.MenuItem = MenuItem

export default MenuView
