import React from 'react'
import { connect } from 'react-redux'

import LoadingPage from 'pmt-ui/LoadingPage'
import Page from '../../components/Page'
import View from './View'

import { withRestaurantsGroupMine } from 'pmt-modules/restaurantsGroup/components'

import { withAuthState } from 'pmt-modules/userMe'

import {
  redirectToLoginPageForAuthApp,
  fetchAuthorizeApp,
  appDataIsFetching,
  getAppData,
  getAppDataError,
  postAuthorizeApp,
} from 'pmt-modules/authApp'

/**
 * @specs N/A
 * See modules/authApp for documentation
 */
@withRestaurantsGroupMine({
  withAuthorization: false,
})
@withAuthState
class AuthAppPage extends React.PureComponent {
  constructor(props) {
    super(props)

    // if the user is not logged in, we first logged in him
    if (this.shouldRedirectToLogin(props)) {
      props.redirectToLoginPageForAuthApp()
    } else {
      this.loadApp(props, true)
    }
  }

  componentWillReceiveProps(nextProps) {
    this.loadApp(nextProps)
  }

  shouldRedirectToLogin(props) {
    return !props.haveAuthCredentials
  }

  loadApp(nextProps, force = false) {
    // we loader the user, we can load the app
    if (nextProps.me !== this.props.me || force) {
      // get the app info
      const { client_id, state, redirect_uri, response_type = 'code' } = this.props.location.query
      this.props.fetchAuthorizeApp({
        client_id,
        state,
        redirect_uri,
        response_type,
      })
    }
  }

  handleAuthorize = (isAuthorized: boolean = false) => {
    const { client_id, state, redirect_uri, response_type = 'code' } = this.props.location.query

    //
    // Even if the user did not allowed the app, we have to call the API to get the
    // right error to return.
    //
    this.props.postAuthorizeApp({
      isAuthorized,
      redirect_uri,
      state,
      client_id,
      response_type,
    })
  }

  render() {
    const { appData, appDataIsFetching, appDataError, isFetchingRestaurantsGroup } = this.props

    if (isFetchingRestaurantsGroup || this.shouldRedirectToLogin(this.props)) {
      return <LoadingPage show />
    }

    return (
      <Page title={"Autoriser l'application"}>
        <View
          appData={appData}
          appDataError={appDataError}
          appDataIsFetching={appDataIsFetching}
          onAuthorize={this.handleAuthorize}
        />
      </Page>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    appDataError: getAppDataError(state),
    appDataIsFetching: appDataIsFetching(state),
    appData: getAppData(state),
  }
}

export default connect(mapStateToProps, {
  redirectToLoginPageForAuthApp,
  fetchAuthorizeApp,
  postAuthorizeApp,
})(AuthAppPage)
