import { tr } from 'pmt-modules/i18n'
import React  from 'react'
import isNil from 'lodash/isNil'
import { compose } from 'redux'

import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'
import { LoginFormView } from 'pmt-modules/auth/forms/login'

import LabelDivider from 'pmt-ui/LabelDivider'
import ErrorBlock from 'pmt-ui/ErrorBlock'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'
import { FormType, withForm } from 'pmt-modules/form'

import { Button, ButtonLink } from '../../../components/Button'

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
  },
  subheader: {
    color: theme.pmt.colors.grey500,
  },
  allowIncognito: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
})

const LoginForm = ({
  classes,
  isFetchingAuth,
  isFetchingRegister, // useful if registering as incognito
  formIsValid,
  authError,
  onSubmitLogin,
  onRedirectToRegister,
  onRedirectToIncognito,
  formData,
  allowIncognito,
}) => {

  return (
    <div className={classes.root}>
      <form
        onSubmit={event => {
          event.preventDefault()
          onSubmitLogin(formData)
        }}
      >
        <CustomTextsContainer>
          {({ texts }) =>
            !isNil(texts) &&
            !isNil(texts.LOGIN__SUBHEADER) && (
              <TypographyCustom type="144" className={classes.subheader}>
                {texts.LOGIN__SUBHEADER}
              </TypographyCustom>
            )
          }
        </CustomTextsContainer>

        <LoadingBlockWrapper show={isFetchingAuth || isFetchingRegister} />

        <ErrorBlock mode={ErrorBlock.Mode.SNACKBAR} error={authError} />

        <LoginFormView />

        <Button type="submit" className="u-marginTop20 u-sizeFull" disabled={!formIsValid}>
          <TypographyCustom type="167" skipColor>
            {tr('web_customer.login.connect')}
          </TypographyCustom>
        </Button>

        <LabelDivider className="u-marginTop30 u-marginBottom30" label={tr('global.divider.or')} />

        <div className="u-marginTop10 u-sizeFullWidth">
          <Button
            className="u-sizeFull"
            variant="outlined"
            onClick={e => {
              e.preventDefault()
              onRedirectToRegister()
            }}
          >
            <TypographyCustom type="167" skipColor>
              {tr('web_customer.login.signin')}
            </TypographyCustom>
          </Button>
          {allowIncognito && (
            <React.Fragment>

              <div className={classes.allowIncognito}>
                <CustomTextsContainer>
                  {({ texts }) => (
                      <ButtonLink
                          component="a"
                          onClick={(e) => {
                            e.stopPropagation()
                            onRedirectToIncognito()
                          }}
                          label={
                            !isNil(texts) && !isNil(texts.ORDER__INCOGNITO__LINK)
                                ? texts.ORDER__INCOGNITO__LINK
                                : tr('web_customer.login.continue_as_incognito.from_NORMAL.link')
                          }
                      />
                  )}
                </CustomTextsContainer>
              </div>
            </React.Fragment>
          )}
        </div>
      </form>
    </div>
  )
}

export default compose(
  withStyles(styles),
  withForm(FormType.LOGIN)
)(LoginForm)
