import React from 'react'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'
import withCheck from 'pmt-modules/payment/components/withCheck'
import { withAppConfig } from 'pmt-modules/appConfig'
import { withRestaurant } from 'pmt-modules/restaurant/components'
import { getAuthenticatedUser } from 'pmt-modules/authenticatedUser/selectors'
import {
  isFetchingPaymentPdfLink,
  fetchPaymentPdfLink,
  getDataPaymentPdfLink,
  resetPaymentPdfLink,
  getErrorPaymentPdfLink,
} from 'pmt-modules/payment/pdf'

import { getRoute, redirectTo } from 'pmt-modules/routing'
import { getPostPayment, getPaymentMade } from 'pmt-modules/payment'
import UserType from 'pmt-modules/user/constants/UserType'

import Page from '../../../components/Page'
import View from './View'

/**
 * https://app.zeplin.io/project/5b616247d86fcf41520410cc/screen/5bc07139c1de410a38c7ed1e
 * https://app.zeplin.io/project/5b616247d86fcf41520410cc/screen/5bc0713a29495f5fbbe1e771
 * https://paymytable.atlassian.net/browse/PP-900
 */
@withAppConfig
@withCheck
@withRestaurant
class ConfirmPage extends React.Component {
  constructor(props) {
    super(props)

    this.props.resetPaymentPdfLink()
    if (
      props.authenticatedUser &&
      props.authenticatedUser.type === UserType.INCOGNITO &&
      props.postPayment?.id
    ) {
      this.props.fetchPaymentPdfLink(props.postPayment.id)
    }
  }

  componentWillReceiveProps = nextProps => {
    if (
      nextProps.authenticatedUser &&
      nextProps.authenticatedUser.type === UserType.INCOGNITO &&
      nextProps.postPayment?.id &&
      (this.props.postPayment?.id !== nextProps.postPayment?.id ||
        isNil(this.props.paymentPdfLink)) &&
      !this.props.isFetchingPaymentPdfLink &&
      !nextProps.isFetchingPaymentPdfLink &&
      !nextProps.errorPaymentPdfLink
    ) {
      this.props.fetchPaymentPdfLink(nextProps.postPayment.id)
    }
  }

  handleBackToCheck = () => {
    this.props.redirectTo(getRoute('PAYMENT_CHECK_VIEW'), {
      restaurantId: this.props.restaurantId,
      checkId: this.props.checkId,
    })
  }

  handleBackToHome = () => {
    this.props.redirectTo(getRoute('RESTAURANT'), {
      restaurantId: this.props.restaurantId,
    })
  }

  render() {
    const {
      frontAppConfig,
      check,
      isFetchingCheck,
      restaurant,
      isFetchingRestaurant,
      authenticatedUser,
      paymentPdfLink,
      errorPaymentPdfLink,
      paymentMade,
    } = this.props

    return (
      <Page showHeader={false}>
        <View
          frontAppConfig={frontAppConfig}
          isFetching={isFetchingRestaurant || isFetchingCheck}
          restaurant={restaurant}
          check={check}
          paymentMade={paymentMade}
          handleBackToCheck={this.handleBackToCheck}
          handleBackToHome={this.handleBackToHome}
          isIncognito={authenticatedUser && authenticatedUser.type === UserType.INCOGNITO}
          paymentPdfLink={paymentPdfLink}
          errorPaymentPdfLink={errorPaymentPdfLink}
        />
      </Page>
    )
  }
}

ConfirmPage.propTypes = {}

const mapStateToProps = (state, props) => ({
  authenticatedUser: getAuthenticatedUser(state),
  isFetchingPaymentPdfLink: isFetchingPaymentPdfLink(state),
  errorPaymentPdfLink: getErrorPaymentPdfLink(state),
  paymentPdfLink: getDataPaymentPdfLink(state)?.signedUrl,
  postPayment: getPostPayment(state),
  paymentMade: getPaymentMade(state),
})

export default connect(
  mapStateToProps,
  {
    redirectTo,
    fetchPaymentPdfLink,
    resetPaymentPdfLink,
  }
)(ConfirmPage)
