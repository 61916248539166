import React from 'react'

import { UserAccountRemainingView } from 'pmt-modules/userAccount'

import IconButton from 'pmt-ui/IconButton'
import { withStyles } from 'pmt-ui/styles'
import Refresh from 'pmt-ui/svg-icons/navigation/refresh'

import HistoryView from './components/HistoryView'

const styles = theme => ({
  refresh: {
    position: 'absolute',
    top: 2,
    right: 2,
    color: theme.palette.primary.main,
  },
})

/**
 * @mockup https://drive.google.com/open?id=0B_LROLHK6BeTSHBaRjNrdFNuSDA
 *
 */
const UserHistoryView = ({ classes, userId, restaurantsGroup, onRefresh }) => (
  <div className="u-flexColumnTop u-flexGrow1 u-sizeFullHeight">
    <IconButton onClick={onRefresh} className={classes.refresh}>
      <Refresh />
    </IconButton>
    <UserAccountRemainingView userId={userId} dislayLinkToTopUp />

    <div className="u-flexColumnTop u-flexGrow1 u-overflowHidden">
      {restaurantsGroup && <HistoryView userId={userId} restaurantsGroup={restaurantsGroup} />}
    </div>
  </div>
)

export default withStyles(styles)(UserHistoryView)
