import React from 'react'

import Button from 'pmt-ui/Button'

const Header = ({ appData }) => (
  <div className="u-marginTop30">
    {/* TODO: add restaurantsGroup logo */}
    <h3>{appData.restaurantsGroup.name}</h3>
  </div>
)

const Authorize = ({ appData, onAuthorize }) => (
  <div className="u-marginTop10">
    <h3>{appData.restaurantsGroup.name} souhaite utiliser votre compte.</h3>

    <div className="u-marginTop30 u-flexCenter u-justifyContentCenter">
      <Button
        raised
        className="u-marginRight20"
        onClick={() => onAuthorize(false)}
        label={'Refuser'}
      />
      <Button raised onClick={() => onAuthorize(true)} primary>
        Accepter
      </Button>
    </div>
  </div>
)

/**
 * @mockup N/A
 */
const View = ({ appData, appDataIsFetching, appDataError, onAuthorize }) => {
  if (appDataIsFetching || appData === null) {
    return null
  }

  return (
    <div>
      <Header appData={appData} />

      <Authorize appData={appData} onAuthorize={onAuthorize} />
    </div>
  )
}

export default View
