import { tr } from 'pmt-modules/i18n'
import invariant from 'invariant'
import { createListFormatter, createFormatter } from 'pmt-utils/format'
import { formatDate } from 'pmt-utils/date'
import { formatPriceWithCurrency } from 'pmt-utils/currency'
import { UserTransactionType } from './constants'

const formatTypeLabel = transaction => {
  transaction.isDebitType = transaction.type === UserTransactionType.DEBIT
  transaction.isCreditType = transaction.type === UserTransactionType.CREDIT

  switch (transaction.type) {
    case UserTransactionType.CREDIT:
      transaction.typeLabel = tr('global.transaction.type_label.CREDIT')
      break
    case UserTransactionType.DEBIT:
      transaction.typeLabel = tr('global.transaction.type_label.DEBIT')
      break
    default:
      invariant(false, 'transaction type not handled')
  }
  return transaction
}

const formatTransactionData = transaction => {
  transaction.formattedSimpleCreationDate = formatDate(transaction.date, 'DD/MM/YY')
  transaction.formattedSimpleCreationDateHour = formatDate(transaction.date, 'HH:mm')

  transaction.amountPriceFormatted = formatPriceWithCurrency(transaction.amount)

  if (transaction.isCreditType) {
    transaction.amountPriceFormatted = `+${transaction.amountPriceFormatted}`
  }

  return transaction
}

export const formatTransaction = createFormatter(formatTypeLabel, formatTransactionData)

export const formatTransactionsList = createListFormatter(formatTransaction)
