import React from 'react'

import TipBar from './TipBar'
import TipSlider from './TipSlider'

import { TipsValueType } from 'pmt-modules/payment/constants'

const Tips = ({ tips, onChoseTip, tipsSettings, amountWithoutTips, roundingValue }) => {
  if (!tipsSettings) {
    return null
  }
  if (tipsSettings.slider) {
    return (
      <TipSlider
        tips={tips}
        onChoseTip={onChoseTip}
        amountWithoutTips={amountWithoutTips}
        defaultSelectedPercentage={tipsSettings.tipsSliderSettings?.defaultSelectedPercentage ?? 5}
        roundValue={roundingValue || 0.01}
      />
    )
  } else {
    return (
      <TipBar
        tips={tips}
        onChoseTip={onChoseTip}
        amountWithoutTips={amountWithoutTips}
        tipsOptions={tipsSettings.tipsFixedValuesSettings?.possibleValues}
        isPercentage={tipsSettings.tipsFixedValuesSettings?.type === TipsValueType.PERCENTAGE}
        roundValue={roundingValue || 0.01}
      />
    )
  }
}

export default Tips
