import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  topUpSelectAmount,
  topUpUnselectAmount,
  topUpClickOtherAmount,
  topUpInit,
} from '../../makeTopUp/actions'

import {
  makeGetTopUpForAccount,
  makeGetTopUpErrorForAccount,
  makeIsPostingTopUp,
  makeGetOtherAmountClicked,
} from '../../makeTopUp/selectors'

/**
 * Container that fetch the credit cards for the given `userId` and pass it to the children.
 * The `children` must be a `Function as child component`.
 *
 * Requirements:
 * - userId, can be 'me'
 * - userAccount
 */
class TopUpContainer extends React.Component {
  constructor(props) {
    super(props)

    if (props.userAccount !== null && props.topUp === null) {
      props.topUpInit(props.userAccount.id)
    }
  }

  componentWillReceiveProps(nextProps) {
    const props = this.props

    if (
      nextProps.userAccount !== null && // we need a userAccount.id to init the top up
      (nextProps.topUp === null || // the top up has not been init
      props.userAccount === null || // we previously didn't have a userAccount, we have one now, so we can init the top up
        props.userAccount.id !== nextProps.userAccount.id) // the user account has changed
    ) {
      nextProps.topUpInit(nextProps.userAccount.id)
    }
  }

  render() {
    const {
      children,
      topUpSelectAmount,
      topUpUnselectAmount,
      topUpClickOtherAmount,
      userAccount,
      topUp,
      topUpError,
      isPostingTopUp,
      otherAmountClicked,
      ...otherProps
    } = this.props

    return children({
      onSelectAmount: amount => topUpSelectAmount(userAccount.id, amount),
      onUnselectAmount: amount => topUpUnselectAmount(userAccount.id, amount),
      onClickOtherAmount: amount => topUpClickOtherAmount(userAccount.id),
      topUpIsInit: topUp !== null,
      topUp,
      userAccount,
      topUpError,
      isPostingTopUp,
      otherAmountClicked,
      ...otherProps,
    })
  }
}

TopUpContainer.propTypes = {
  //
  // props to pass to the container
  //
  userId: PropTypes.string.isRequired,

  userAccount: PropTypes.object.isRequired,

  // children MUST be a function
  children: PropTypes.func.isRequired,

  //
  // props retrieve on the store (cf mapStateToProps)
  //

  topUp: PropTypes.object.isRequired,

  topUpError: PropTypes.object,

  otherAmountClicked: PropTypes.bool,
}

const makeMapStateToProps = () => {
  const getTopUpForAccount = makeGetTopUpForAccount()
  const getTopUpErrorForAccount = makeGetTopUpErrorForAccount()
  const isPostingTopUp = makeIsPostingTopUp()
  const getOtherAmountClicked = makeGetOtherAmountClicked()

  const mapStateToProps = (state, props) => {
    return {
      topUp: getTopUpForAccount(state, props),
      topUpError: getTopUpErrorForAccount(state, props),
      isPostingTopUp: isPostingTopUp(state, props),
      otherAmountClicked: getOtherAmountClicked(state, props),
    }
  }
  return mapStateToProps
}

export default connect(
  makeMapStateToProps,
  {
    topUpSelectAmount,
    topUpUnselectAmount,
    topUpClickOtherAmount,
    topUpInit,
  }
)(TopUpContainer)
