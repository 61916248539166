import { tr } from 'pmt-modules/i18n'
import React from 'react'
import isEmpty from 'lodash/isEmpty'

import Typography from 'pmt-ui/Typography'
import ErrorBlock from 'pmt-ui/ErrorBlock'
import Button from 'pmt-ui/Button'

const LinkToPopUp = ({ show, onRedirectToTopUp }) =>
  !show ? null : (
    <Button
      color="primary"
      variant="contained"
      style={{
        width: '100%',
      }}
      onClick={onRedirectToTopUp}
    >
      {tr('global.user_account.top_up', {
        fr: 'Recharger',
        context: '',
        desc: 'Button to top up displayed on user account',
      })}
    </Button>
  )

const View = ({
  isFetchingUserAccounts,
  userAccount,
  dislayLinkToTopUp,
  onRedirectToTopUp,
  balanceLabel,
  restaurantsGroup,
}) => (
  <div className="u-textAlignCenter u-marginTop15 u-marginBottom15 u-paddingLeft20 u-paddingRight20 u-flex0">
    {!isFetchingUserAccounts && isEmpty(userAccount) ? (
      <ErrorBlock
        mode={ErrorBlock.Mode.SNACKBAR}
        error={{
          message: tr('global.user_account.error', {
            restaurantsGroupName: restaurantsGroup && restaurantsGroup.name,
          }),
        }}
      />
    ) : (
      <React.Fragment>
        <Typography variant="body2" className="u-fontSize18 u-uiTextMedium">
          {balanceLabel}
        </Typography>

        <Typography variant="body2" element="div" className="u-fontSize32 u-marginBottom15">
          {userAccount && userAccount.amountFormatted}
        </Typography>
      </React.Fragment>
    )}

    <LinkToPopUp show={dislayLinkToTopUp} onRedirectToTopUp={onRedirectToTopUp} />
  </div>
)

export default View
