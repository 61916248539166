import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'

const getRegisterData = state => state.entities.registration

export const getRegisterError = createSelector(
  [ getRegisterData ],
  (authData) => {
    const error = authData.get('error')
    if (isNil(error)) {
      return null
    }
    return error.toJS()
  }
)

export const isFetchingRegister = createSelector(
  [ getRegisterData ],
  (authData) => {
    return authData.get('isFetching')
  }
)
