import React from 'react'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'

import { getAsyncPayment } from 'pmt-modules/asyncPayment'
import { createUserLightBasic } from 'pmt-modules/api/utils'
import { withAppConfig } from 'pmt-modules/appConfig'
import { getPostPayment } from 'pmt-modules/payment'
import withCheck from 'pmt-modules/payment/components/withCheck'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import { redirectTo, getRoute } from 'pmt-modules/routing'

import { getQueryParam } from 'pmt-utils/url'

import Page from '../../../components/Page'
import View from './View'


@withAppConfig
@withCheck
@withRestaurant
class PaymentVerificationPage extends React.Component {
  constructor(props) {
    super(props)

    const { restaurantId, checkId } = props
    const options = { restaurantId, checkId }

    const userLight = getQueryParam('userLight')
    if (userLight) {
      options.authorization = createUserLightBasic(decodeURIComponent(userLight))
    }

    if (!isNil(props.postPayment) && !isNil(props.postPayment.id)) {
      props.getAsyncPayment(props.postPayment.id, options)
    } else {
      props.redirectTo(getRoute('PAYMENT_PAYMENT'), options)
    }
  }

  render() {
    const { restaurant } = this.props

    return (
      <Page title={restaurant ? restaurant.name : ''} disableBack>
        <View />
      </Page>
    )
  }
}

const mapStateToProps = state => ({
  postPayment: getPostPayment(state),
})

export default connect(mapStateToProps, {
  redirectTo,
  getAsyncPayment,
})(PaymentVerificationPage)
