import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import isNil from 'lodash/isNil'
import find from 'lodash/find'
import startsWith from 'lodash/startsWith'
import { countryCodes } from '../country/countryCodes'

// https://github.com/catamphetamine/libphonenumber-js
import {
  parsePhoneNumberFromString,
  ParseError,
  getCountryCallingCode,
  AsYouType,
} from 'libphonenumber-js'

// TODO: unit tests
// - null & empty phone numbers

/**
 * Tools to manipulate phone numbers.
 * Use https://github.com/seegno/google-libphonenumber, abstract of
 * https://github.com/googlei18n/libphonenumber
 */
const MIN_LENGHT_FOR_NUMBER = 3

export const formatPhoneNumberAsYouType = (number = null, iso = 'FR') => {
  if (number === null) {
    return null
  }

  return new AsYouType(iso).input(number)
}

export const parse = number => {
  try {
    return parsePhoneNumberFromString(number)
  } catch (error) {
    if (error instanceof ParseError) {
      // Not a phone number, non-existent country, etc.
      console.log(error.message)
    } else {
      // throw error
    }
  }
  return null
}

export const formatPhoneNumber = (number = null) => {
  if (number === null) {
    return null
  }

  if (isNull(number) || number.length <= MIN_LENGHT_FOR_NUMBER) {
    return number
  }

  const phoneNumber = parse(number)
  if (phoneNumber) {
    return phoneNumber.format('INTERNATIONAL')
  }
  return number
}

export const isValidPhoneNumber = number => {
  if (isNull(number) || number.length <= MIN_LENGHT_FOR_NUMBER) {
    return false
  }

  const phoneNumber = parse(number)
  if (phoneNumber) {
    return phoneNumber.isValid()
  }

  return false
}

/**
 *
 * @param number
 * @param countryIso
 * @returns {*}
 */
export const parseWithIso = (number, countryIso) => {
  const countryCode = getCountryCallingCode(countryIso)
  let parsedPhone = number

  if (!isEmpty(number)) {
    try {
      parsedPhone = formatPhoneNumber(number)
    } catch (e) {
      // console.log(e)
    }
  }

  return {
    country_code: countryCode,
    national_number: parsedPhone,
  }
}

export const getCountryIndicatorFromNumber = number => {
  if (!isEmpty(number)) {
    try {
      const phoneNumber = parse(number)
      return phoneNumber.countryCallingCode
    } catch (e) {
      console.log(e)
    }
  }
  return null
}

export const getCountryIsoForNumber = number => {
  if (!isEmpty(number)) {
    try {
      const phoneNumber = parse(number)
      return phoneNumber.country
    } catch (e) {
      // console.log(e)
    }
  }

  return null
}

export const removeIndicatorOnPhoneNumber = phoneNumber => {
  if (!isEmpty(phoneNumber)) {
    const haveIndicator = phoneNumber.indexOf('+') !== -1

    if (haveIndicator !== -1) {
      const firstSpaceIndex = phoneNumber.indexOf(' ')
      return phoneNumber.substring(firstSpaceIndex + 1)
    }
  }

  return phoneNumber
}

/**
 * Inspired by https://github.com/PayMyTable/pmt_AppEngine/blob/develop/ws/src/main/java/com/paymytable/utils/PhoneUtils.java
 */
export const cleanForApi = phoneNumber => {
  if (isNil(phoneNumber)) {
    return ''
  }
  let cleanPhoneNumber = formatPhoneNumber(phoneNumber)

  // Note: we use lodash/startsWith instead of cleanPhoneNumber.startsWith since IE does not handle
  // it
  // see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/startsWith

  if (startsWith(cleanPhoneNumber, '+330')) {
    cleanPhoneNumber = cleanPhoneNumber.replace('+330', '+33')
  }

  if (startsWith(cleanPhoneNumber, '06')) {
    cleanPhoneNumber = cleanPhoneNumber.replace('06', '+336')
  }

  if (startsWith(cleanPhoneNumber, '07')) {
    cleanPhoneNumber = cleanPhoneNumber.replace('07', '+337')
  }

  // remove all spaces
  cleanPhoneNumber = cleanPhoneNumber.replace(new RegExp(' ', 'g'), '')
  // remove all hyphen
  cleanPhoneNumber = cleanPhoneNumber.replace(new RegExp('-', 'g'), '')

  return isValidPhoneNumber(cleanPhoneNumber) ? cleanPhoneNumber : null
}

export const getCountryCodes = () => countryCodes

export const getCountryDialCodeFromNumber = number => {
  const country = find(getCountryCodes(), c => number.indexOf(c.dial_code) === 0)
  return !isNil(country) ? country.dial_code : null
}

export const getCountryCodeFromNumber = number => {
  const country = find(getCountryCodes(), c => number.indexOf(c.dial_code) === 0)
  return !isNil(country) ? country.code : null
}

export const getCountryDialCodeFromCountryCode = countryCode => {
  const country = find(getCountryCodes(), c => countryCode.indexOf(c.code) === 0)
  return !isNil(country) ? country.dial_code : null
}
