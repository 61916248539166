import { tr } from 'pmt-modules/i18n'
import React, { Fragment } from 'react'
import compose from 'recompose/compose'
import ui from 'pmt-modules/reduxUi'
import classNames from 'classnames'

import IconButton from 'pmt-ui/IconButton'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'
import ArrowDown from 'pmt-ui/svg-icons/hardware/keyboard-arrow-down'
import CloseIcon from 'pmt-ui/svg-icons/navigation/close'

const styles = theme => ({
  root: {
    color: theme.pmt.colors.white,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '20px',
  },
  close: {
    width: 'auto',
    height: 'auto',
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  hoursModal: {
    position: 'absolute',
    zIndex: theme.zIndex.modal,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: theme.pmt.colors.white,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    animation: 'hide .5s forwards',
  },
  hoursModalOpened: {
    display: 'block !important',
    animation: 'show .5s forwards',
  },
  days: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  day: {
    marginBottom: theme.spacing(2),
  },
  dayLabel: {
    display: 'inline-block',
    width: 32,
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
  },
  '@keyframes show': {
    '0%': { transform: 'scale(1, 1)', opacity: 0 },
    '100%': { opacity: 1 },
  },
  '@keyframes hide': {
    '0%': { opacity: 1 },
    '99%': { transform: 'scale(1, 1)', opacity: 0 },
    '100%': { transform: 'scale(0, 0)', opacity: 0 },
  },
})

const OpeningHours = ({ classes, restaurant, ui, updateUI }) => (
  <div className={classes.root}>
    {restaurant.orderSettings.openingHoursList.filter(day => day.isCurrentDay).map(day => (
      <div key={`open-${day.dayKeyName}`} onClick={() => updateUI({ toggleHours: true })}>
        {restaurant.orderSettings.isCurrentlyOpen ? (
          <TypographyCustom type="167" skipColor className={classes.label}>
            {tr('web_customer.restaurant.opening_hours.open', {
              opening: day.openingHours[0].formattedOpening,
              closing: day.openingHours[0].formattedClosing,
            })}
            {day.openingHours[1] &&
              ` ${tr('web_customer.restaurant.opening_hours.open_and', {
                opening: day.openingHours[1].formattedOpening,
                closing: day.openingHours[1].formattedClosing,
              })}`}
            <ArrowDown />
          </TypographyCustom>
        ) : (
          <TypographyCustom type="167" skipColor>
            {tr('web_customer.restaurant.opening_hours.closed')}
          </TypographyCustom>
        )}
      </div>
    ))}

    <div
      className={classNames(classes.hoursModal, {
        [classes.hoursModalOpened]: ui.toggleHours,
      })}
      style={!ui.toggleHours ? { display: 'none' } : {}}
    >
      <TypographyCustom type="207" align="center">
        {tr('web_customer.restaurant.opening_hours.modal.title')}
      </TypographyCustom>
      <IconButton className={classes.close} onClick={() => updateUI({ toggleHours: false })}>
        <CloseIcon />
      </IconButton>
      <ul className={classes.days}>
        {restaurant.orderSettings.openingHoursList.map(day => (
          <li key={`list-${day.dayKeyName}`} className={classes.day}>
            <TypographyCustom className={classes.dayLabel} type="144" skipColor>
              {day.dayNameShort}
            </TypographyCustom>
            <TypographyCustom component="span" type="144">
              {day.openingHours[0] ? (
                <Fragment>
                  {`${day.openingHours[0].formattedOpening} - ${day.openingHours[0].formattedClosing}`}
                  {day.openingHours[1] &&
                    ` ${tr('web_customer.restaurant.opening_hours.and')} ${
                      day.openingHours[1].formattedOpening
                    } - ${day.openingHours[1].formattedClosing}`}
                </Fragment>
              ) : (
                tr('web_customer.restaurant.opening_hours.closed')
              )}
            </TypographyCustom>
          </li>
        ))}
      </ul>
    </div>
  </div>
)

export default compose(
  ui({
    state: {
      toggleHours: null,
    },
  }),
  withStyles(styles)
)(OpeningHours)
