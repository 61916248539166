import isEmpty from 'lodash/isEmpty'
import { createMiddleware } from '../../redux'

import { redirectToExternal, replaceWith, formatQueryParams, getRoute } from '../../routing'

import { GetAppDataAction } from './actions'

import { postAuthorizeApp } from '../authorizeApp/actions'

const getAppDataSuccessMiddleware = createMiddleware(
  GetAppDataAction.SUCCESS,
  ({ getState, dispatch, next, action }) => {
    /* TODO : atm skip authorize part, later maybe a param in restaurants group settings ? */

    const { redirect_uri, state, client_id, response_type } = action.data.props

    return dispatch(
      postAuthorizeApp({
        isAuthorized: true,
        redirect_uri,
        state,
        client_id,
        response_type,
      })
    )
  }
)

const getAppDataErrorMiddleware = createMiddleware(
  GetAppDataAction.FAILURE,
  ({ getState, dispatch, next, action }) => {
    //
    // Can be:
    //  `access_denied` | User chose not to grant your app permissions | Fail gracefully - let the user know you cannot continue, and be respectful of their choice to decline to use your app
    // `unsupported_response_type` | Invalid `response_type` parameter in initial Authorization | Ensure that the `response_type` parameter is one of the allowed values
    // `invalid_scope` | Invalid `scope` parameter in initial Authorization | Ensure that the `scope` parameter is a space-separated list of valid scopes
    // `invalid_request` | There was an issue with the request sent to `/authorize`
    // ...
    const error = action.error.body.error
    const state = action.data.props.state
    const redirect_uri = action.data.props.redirect_uri

    // `invalid_grant` | "The access token provided has expired." / ""

    // the current access_token is invalid, we can't retrieve the app data.
    // redirect to the login page
    if (error === 'invalid_grant') {
      // we return a next action here, since we don't want other middlewares to handle the 401
      return next(
        replaceWith(
          getRoute('LOGIN'),
          {},
          {
            redirectTo: window.location.toString(),
          }
        )
      )
    } else {
      if (isEmpty(redirect_uri)) {
        // TODO: log, use another way to display the error?
        alert('Error: no redirect_uri provided')
      } else {
        const uri = formatQueryParams(redirect_uri, { error, state })
        dispatch(redirectToExternal(uri))
      }
    }
  }
)

export const getAppMiddlewares = [getAppDataSuccessMiddleware, getAppDataErrorMiddleware]
