import React from 'react'

import { tr } from 'pmt-modules/i18n'

import { withStyles } from 'pmt-ui/styles'
import Grid from 'pmt-ui/Grid'
import { LoadingBlock } from 'pmt-ui/LoadingBlock'
import Price from 'pmt-ui/Price'
import { TypographyCustom } from 'pmt-ui/Typography'

import { formatPriceWithCurrency } from 'pmt-utils/currency'
import { formatDate, formatHour } from 'pmt-utils/date'

import { Button } from '../../../components/Button'
import ErrorBlock from 'pmt-ui/ErrorBlock'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'

const styles = theme => ({
  root: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
  },
  content: {
    flex: 1,
    paddingBottom: theme.spacing(3),
  },
  logo: {
    maxWidth: 113,
    maxHeight: 65,
  },
  amount: {
    color: theme.palette.primary.main,
  },
  paymentDone: {
    marginTop: theme.spacing(2),
    color: theme.pmt.colors.grey91,
  },
  leftToPayContainer: {
    marginTop: theme.spacing(18),
  },
  hr: {
    width: '100%',
    height: 0,
    marginTop: theme.spacing(2),
    border: 'none',
    borderTop: `1px solid ${theme.pmt.colors.grey91}`,
  },
  backLink: {
    marginTop: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  buttonBack: {
    width: '100%',
  },
  downloadBtn: {
    marginTop: theme.spacing(3),
    textAlign: 'center',
  },
})

const View = ({
  frontAppConfig,
  restaurant,
  check,
  paymentMade,
  isFetching,
  handleBackToCheck,
  handleBackToHome,
  classes,
  isIncognito,
  paymentPdfLink,
  errorPaymentPdfLink,
}) => {
  const leftToPay = check?.outstandingBalance - paymentMade.amount
  // doing the format here so the currency is good even if we refresh the page
  const totalWithTipFormatted = formatPriceWithCurrency(paymentMade.amount + paymentMade.tips)

  return (
    <div className={classes.root}>
      {isFetching ? (
        <LoadingBlock show />
      ) : (
        restaurant &&
        paymentMade &&
        check && (
          <React.Fragment>
            <div className={classes.content}>
              {frontAppConfig.theme.logo.display &&
                (frontAppConfig.theme.logo.path || restaurant.logo) && (
                  <img
                    src={frontAppConfig.theme.logo.path || restaurant.logo}
                    className={classes.logo}
                    alt="logo"
                  />
                )}
              <TypographyCustom type="207" className="u-marginTop40">
                {tr('web_customer.payment.confirm.thank_you')}
              </TypographyCustom>
              <TypographyCustom
                type="204"
                className="u-marginTop10"
                dangerouslySetInnerHTML={{
                  __html: tr('web_customer.payment.confirm.payment_confirmed', {
                    spanBegin: `<span class="${classes.amount}">`,
                    amountFormatted: totalWithTipFormatted,
                    spanEnd: '</span>',
                  }),
                }}
              />
              <TypographyCustom type="124" className={classes.paymentDone}>
                {tr('web_customer.payment.confirm.payment_date', {
                  day: formatDate(null, 'DD/MM/YY'),
                  hour: formatHour(null, ':'),
                })}
              </TypographyCustom>

              {isIncognito ? (
                <React.Fragment>
                  {paymentPdfLink && (
                    <div className={classes.downloadBtn}>
                      <Button icon={<PictureAsPdfIcon />} href={paymentPdfLink} target="_blank">
                        {tr('web_customer.payment.confirm.payment_receipt.download')}
                      </Button>
                    </div>
                  )}
                  <ErrorBlock error={errorPaymentPdfLink} />
                </React.Fragment>
              ) : (
                <TypographyCustom type="124" className={classes.paymentDone}>
                  {tr('web_customer.payment.confirm.payment_receipt')}
                </TypographyCustom>
              )}

              {leftToPay > 0 ? (
                <React.Fragment>
                  <Grid container className={classes.leftToPayContainer}>
                    <Grid item xs={5}>
                      <TypographyCustom type="164">
                        {tr('web_customer.payment.confirm.left_to_pay')}
                      </TypographyCustom>
                    </Grid>
                    <Grid item xs={7}>
                      <TypographyCustom type="247">
                        <Price
                          value={formatPriceWithCurrency(leftToPay)}
                          className="u-floatRight"
                        />
                      </TypographyCustom>
                    </Grid>
                  </Grid>
                  <hr className={classes.hr} />
                  <TypographyCustom
                    type="144"
                    align="center"
                    onClick={handleBackToCheck}
                    className={classes.backLink}
                  >
                    {tr('web_customer.payment.confirm.see_bill')}
                  </TypographyCustom>
                </React.Fragment>
              ) : (
                <Grid container className={classes.leftToPayContainer}>
                  <Grid item xs={9}>
                    <TypographyCustom type="164">
                      {tr('web_customer.payment.confirm.nothing_to_pay')}
                    </TypographyCustom>
                  </Grid>
                </Grid>
              )}
            </div>
            <Button
              variant="outlined"
              size="large"
              className={classes.buttonBack}
              onClick={handleBackToHome}
            >
              <TypographyCustom type="164" skipColor>
                {tr('web_customer.payment.confirm.back_home')}
              </TypographyCustom>
            </Button>
          </React.Fragment>
        )
      )}
    </div>
  )
}

export default withStyles(styles)(View)
