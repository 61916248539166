import { LOCATION_CHANGE } from 'pmt-modules/routing'

import Immutable from 'immutable'

import { ForgotPasswordAction } from './actions'

export * from './actions'
export * from './selectors'
export * from './middlewares'

const DEFAULT_FORGOT_PASSWORD = Immutable.fromJS({
  isFetching: false,
  data: null,
  error: null,
})

export const forgotPasswordReducer = (state = DEFAULT_FORGOT_PASSWORD, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return state.merge({
        error: null,
      })

    case ForgotPasswordAction.REQUEST:
      return state.merge({
        isFetching: true,
        data: null,
        error: null,
      })

    case ForgotPasswordAction.SUCCESS:
      return state.merge({
        isFetching: false,
        data: {
          ...action.response,
          username: action.data.username,
        },
        error: null,
      })

    case ForgotPasswordAction.FAILURE:
      return state.merge({
        isFetching: false,
        data: null,
        error: action.error,
      })

    case ForgotPasswordAction.RESET_DATA:
      return state.merge({
        data: null,
      })

    default:
      return state
  }
}
