import React from 'react'
import classNames from 'classnames'

import { tr } from 'pmt-modules/i18n'
import { UserAccountsContainer } from 'pmt-modules/userAccount'
import LoadingContainer from 'pmt-ui/LoadingContainer'
import { HistoryType, HistoryTypeContainer } from 'pmt-modules/userHistory'

import { RoutingContainer } from 'pmt-modules/routing'
import Divider from 'pmt-ui/Divider'
import { withStyles } from 'pmt-ui/styles'
import { TableRow, TableCell } from 'pmt-ui/Table'
import Typography from 'pmt-ui/Typography'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'

import HistoryList from '../components/HistoryList'

const styles = theme => ({
  root: {
    padding: `0 ${theme.spacing(2)}px`,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  title: {
    textAlign: 'center',
    fontSize: 14,
    textTransform: 'uppercase',
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    margin: `${theme.spacing(1)}px 0`,
  },
  historyListContainer: {
    // height: 300,
    overflow: 'scroll',
  },
  cell: {
    padding: `${theme.spacing(2)}px 0!important`,
  },
  date: {
    color: theme.pmt.colors.grey91,
    fontSize: 12,
  },
  typeLabel: {
    fontSize: 16,
  },
  price: {
    fontSize: 20,
  },
  price_DEBIT: {},
  price_CREDIT: {
    color: theme.pmt.colors.green4ca,
  },
})

const TransactionsHistoryContent = ({
  isFetchingHistory,
  list,
  pagination,
  onLoadMore,
  classes,
}) => (
  <RoutingContainer>
    {({ getRoute, redirectTo }) => (
      <div className={classes.historyListContainer}>
        <HistoryList
          key={'history-transactions-list'}
          list={list}
          pagination={pagination}
          onLoadMore={onLoadMore}
          emptyList={
            !isFetchingHistory &&
            tr('web_customer.history.transactions.no_transaction', {
              fr: 'Aucune transaction',
              context: '',
              desc: 'Label when no transactions has been returned on transactions history',
            })
          }
          rowRenderer={transaction => (
            <TableRow
              key={`history-transactions-${transaction.checkPosId}`}
              onClick={() =>
                redirectTo(getRoute('USER_HISTORY_TRANSACTION'), {
                  checkPosId: transaction.checkPosId,
                })
              }
            >
              <TableCell className={classes.cell} width={100}>
                <Typography component="span" className={classes.date}>
                  {tr('global.date_at', {
                    date: transaction.formattedSimpleCreationDate,
                    hour: transaction.formattedSimpleCreationDateHour,
                  })}
                </Typography>
              </TableCell>
              <TableCell className={classes.cell} width={100}>
                <Typography component="span" className={classes.typeLabel}>
                  {transaction.typeLabel}
                </Typography>
              </TableCell>
              <TableCell className={classes.cell} width={80} align="right">
                <Typography
                  component="span"
                  className={classNames(classes.price, {
                    [classes.price_DEBIT]: transaction.isDebitType,
                    [classes.price_CREDIT]: transaction.isCreditType,
                  })}
                >
                  {transaction.amountPriceFormatted}
                </Typography>
              </TableCell>
            </TableRow>
          )}
        />
      </div>
    )}
  </RoutingContainer>
)

const HistoryTransactionsModeView = ({ className, classes, userId }) => (
  <LoadingContainer
    withWrapper
    container={<UserAccountsContainer userId={userId} />}
    show={({ isFetchingUserAccounts, userAccount }) => isFetchingUserAccounts || !userAccount}
  >
    {({ userAccount }) =>
      userAccount && (
        <HistoryTypeContainer
          userId={userId}
          userAccountId={userAccount.id}
          historyType={HistoryType.TRANSACTIONS}
        >
          {props => (
            <div className={classNames(className, classes.root, 'u-flexColumnTop u-flexGrow1')}>
              <h3 className={classes.title}>{tr('web_customer.history.transactions.title')}</h3>

              <Divider className={classes.divider} />

              <LoadingBlockWrapper show={props.isFetchingHistory} />
              <TransactionsHistoryContent classes={classes} {...props} />
            </div>
          )}
        </HistoryTypeContainer>
      )
    }
  </LoadingContainer>
)

export default withStyles(styles)(HistoryTransactionsModeView)
