import React from 'react'
import PropTypes from 'prop-types'
import QRCode from 'qrcode.react'

class QrCode extends React.Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    size: PropTypes.number,
    backgroundgColor: PropTypes.string,
    foregroundColor: PropTypes.string,
    level: PropTypes.string,
  }

  static defaultProps = {
    size: 128,
    backgroundgColor: '#FFFFFF',
    foregroundColor: '#000000',
    level: 'L',
  }

  render() {
    const { value, size, backgroundgColor, foregroundColor, level } = this.props

    return (
      <QRCode
        value={value}
        size={size}
        bgColor={backgroundgColor}
        fgColor={foregroundColor}
        level={level}
      />
    )
  }
}

export default QrCode
