import React from 'react'
import { compose } from 'redux'
import isNil from 'lodash/isNil'
import { tr } from 'pmt-modules/i18n'

import { AuthMode } from 'pmt-modules/auth/constants'
import { FormType, withForm } from 'pmt-modules/form'
import LabelDivider from 'pmt-ui/LabelDivider'
import ErrorBlock from 'pmt-ui/ErrorBlock'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'
import { CguLabel } from 'pmt-ui/Cgu'
import { IncognitoFormView } from 'pmt-modules/registration/forms/incognito'

import { Button, ButtonLink } from '../../../components/Button'

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
  },
  link: {
    display: 'inline',
    fontSize: 14,
    lineHeight: '14px',
    marginTop: -2,
  },
  caption: {
    color: theme.pmt.status.inactive,
  },
  captionTitle: {
    marginBottom: theme.spacing(1),
  },
  backLink: {
    textAlign: 'center',
  },
})

const IncognitoForm = ({
  classes,
  isFetchingAuth,
  isFetchingRegister, // useful if registering as incognito
  authError,
  onRedirectToRegister,
  onRedirectToLogin,
  onRedirectToUserLight,
  handleKeepIncognito,
  restaurantsGroup,
  formIsValid,
  formData,
  authMode,
  customTexts,
}) => {
  let btnLabel = ''
  let captionTitle = ''
  let captionText = ''
  // default labels
  if (authMode === AuthMode.NORMAL) {
    btnLabel = tr('web_customer.login.continue_as_incognito.from_NORMAL.btnLabel')
  } else if (authMode === AuthMode.LIGHT) {
    btnLabel = tr('web_customer.login.continue_as_incognito.from_LIGHT.btnLabel')
    captionTitle = tr('web_customer.login.continue_as_incognito.from_LIGHT.captionTitle')
    captionText = tr('web_customer.login.continue_as_incognito.from_LIGHT.captionText')
  } else {
    btnLabel = tr('web_customer.login.continue_as_incognito.from_INCOGNITO_ONLY.btnLabel')
    captionTitle = tr('web_customer.login.continue_as_incognito.from_INCOGNITO_ONLY.captionTitle')
    captionText = tr('web_customer.login.continue_as_incognito.from_INCOGNITO_ONLY.captionText')
  }
  // override with custom texts
  if (!isNil(customTexts)) {
    if (!isNil(customTexts.ORDER__INCOGNITO__SUBMIT_BUTTON)) {
      btnLabel = customTexts.ORDER__INCOGNITO__SUBMIT_BUTTON
    }
    if (!isNil(customTexts.ORDER__INCOGNITO__CAPTION_TITLE)) {
      captionTitle = customTexts.ORDER__INCOGNITO__CAPTION_TITLE
    }
    if (!isNil(customTexts.ORDER__INCOGNITO__CAPTION_TEXT)) {
      captionText = customTexts.ORDER__INCOGNITO__CAPTION_TEXT
    }
  }

  const withCaption = captionTitle || captionText

  return (
    <div className={classes.root}>
      <LoadingBlockWrapper show={isFetchingAuth || isFetchingRegister} />

      {withCaption && (
        <div className={classes.caption}>
          {captionTitle && (
            <TypographyCustom type="167" className={classes.captionTitle} skipColor>
              {captionTitle}
            </TypographyCustom>
          )}
          {captionText && (
            <TypographyCustom type="144" skipColor>
              {captionText}
            </TypographyCustom>
          )}
        </div>
      )}

      <ErrorBlock mode={ErrorBlock.Mode.SNACKBAR} error={authError} />

      <div className="u-sizeFullWidth">
        <IncognitoFormView
          cguLabel={<CguLabel restaurantsGroup={restaurantsGroup} linkClass={classes.link} />}
        />

        <Button
          disabled={!formIsValid}
          className="u-sizeFull u-marginTop30 u-marginBottom30"
          onClick={e => {
            e.stopPropagation()
            handleKeepIncognito(formData)
          }}
          label={btnLabel}
        >
          <TypographyCustom type="167" skipColor>
            {btnLabel}
          </TypographyCustom>
        </Button>
      </div>

      {authMode === AuthMode.NORMAL && (
        <>
          {/* we display the buttons to go back to LOGIN/REGISTER  */}

          <LabelDivider
            className="u-marginTop30 u-marginBottom30"
            label={tr('global.divider.or')}
          />

          <Button
            className="u-marginTop10 u-sizeFullWidth"
            variant="outlined"
            onClick={e => {
              e.preventDefault()
              onRedirectToLogin()
            }}
          >
            <TypographyCustom type="167" skipColor>
              {tr('web_customer.login.connect')}
            </TypographyCustom>
          </Button>

          <LabelDivider
            className="u-marginTop30 u-marginBottom30"
            label={tr('global.divider.or')}
          />

          <div className="u-marginTop10 u-sizeFullWidth">
            <Button
              className="u-sizeFull"
              variant="outlined"
              onClick={e => {
                e.preventDefault()
                onRedirectToRegister()
              }}
            >
              <TypographyCustom type="167" skipColor>
                {tr('web_customer.login.signin')}
              </TypographyCustom>
            </Button>
          </div>
        </>
      )}

      {authMode === AuthMode.LIGHT && (
        <>
          {/* we display a tiny link to go back to USER_LIGHT view */}
          <div className={classes.backLink}>
            <ButtonLink
              component="a"
              onClick={e => {
                e.stopPropagation()
                onRedirectToUserLight()
              }}
              label={tr('web_customer.login.back_to_user_light')}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default compose(
  withStyles(styles),
  withForm(FormType.INCOGNITO)
)(IncognitoForm)
