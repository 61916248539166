import React from 'react'

import RestaurantsGroupContainer from './RestaurantsGroupContainer'

/**
 * Add the RestaurantsGroupContainer as HOC for the given component
 * Requirements
 * - restaurantsGroupId in props or routeParams
 */
const withRestaurantsGroupMine = (options = {}) => RestaurantsGroupWrappedComponent => {
  return props => (
    <RestaurantsGroupContainer
      RestaurantsGroupWrappedComponent={RestaurantsGroupWrappedComponent}
      restaurantsGroupId={'mine'}
      {...props}
      {...options}
    />
  )
}

export default withRestaurantsGroupMine
