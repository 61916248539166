import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'

import { fetchUserUserAccounts } from 'pmt-modules/userAccount'
import UserAccountsContainer from 'pmt-modules/userAccount/components/UserAccountsContainer'
import { withRestaurantsGroupMine } from 'pmt-modules/restaurantsGroup/components'

import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'

import Page from '../../../components/Page'

import View from './View'

@withRestaurantsGroupMine()
class UserHistoryPage extends React.PureComponent {
  loadUserAccount = () => {
    this.props.fetchUserUserAccounts(this.props.userId)
  }

  render() {
    const { isFetchingRestaurantsGroup, restaurantsGroup, userId } = this.props

    return (
      <Page title={tr('web_customer.history.title')}>
        <UserAccountsContainer userId={userId}>
          {({ isFetchingUserAccounts }) => (
            <React.Fragment>
              <LoadingBlockWrapper
                show={isFetchingRestaurantsGroup || !restaurantsGroup || isFetchingUserAccounts}
              />
              <View
                userId={userId}
                restaurantsGroup={restaurantsGroup}
                onRefresh={this.loadUserAccount}
              />
            </React.Fragment>
          )}
        </UserAccountsContainer>
      </Page>
    )
  }
}

UserHistoryPage.defaultProps = {
  userId: 'me',
}

const mapStateToProps = () => ({})

export default connect(
  mapStateToProps,
  {
    fetchUserUserAccounts,
  }
)(UserHistoryPage)
