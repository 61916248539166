import { createSelector } from 'reselect'
import isNil from 'lodash/isNil'

import { createSimpleSelector } from '../redux'
import { formatApiConsumers, formatApiConsumer } from './format'

const getApiConsumerListSelectors = createSimpleSelector(state => state.entities.apiConsumerList)

export const isFetchingApiConsumerList = getApiConsumerListSelectors.isFetching
export const getApiConsumerListData = getApiConsumerListSelectors.getData
export const getErrorsApiConsumerList = getApiConsumerListSelectors.getError
export const getApiConsumerList = createSelector([getApiConsumerListData], apiConsumerList => {
  if (isNil(apiConsumerList)) {
    return null
  }
  apiConsumerList.apiConsumers = formatApiConsumers(apiConsumerList.apiConsumers)
  return apiConsumerList
})

export const getApiConsumerListWithDefault = createSelector(
  [getApiConsumerList],
  apiConsumerList => {
    if (isNil(apiConsumerList)) {
      return null
    }

    apiConsumerList.apiConsumers = formatApiConsumers(apiConsumerList.apiConsumers)

    // add default apiConsumers for retro-compatibility
    apiConsumerList.apiConsumers.push({
      id: 0,
      name: 'Autre',
    })

    return apiConsumerList
  }
)

//
//
//

const getApiConsumerSelectors = createSimpleSelector(
  state => state.entities.apiConsumer,
  formatApiConsumer
)

export const isFetchingApiConsumer = getApiConsumerSelectors.isFetching
export const getApiConsumer = getApiConsumerSelectors.getData
export const getApiConsumerError = getApiConsumerSelectors.getError
