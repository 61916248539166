import { LOCATION_CHANGE } from 'pmt-modules/routing'

import Immutable from 'immutable'

import { removeAccessToken } from './utils'

import {
  AuthUserAction,
  CLEAN_AUTH_DATA,
  LOGOUT_ACTION,
  SAVE_RAW_ACCESS_TOKEN_ACTION,
} from './actions'

import { RefreshAction, RefreshTokenFlowAction } from './refreshToken/actions'

export * from './utils'
export * from './constants'
export * from './actions'
export * from './selectors'
export * from './middlewares'

/**
 * Only actions results are store in state
 * Auth data is stored in current oauth clientId cookie
 */
const getDefaultState = () =>
  Immutable.fromJS({
    isFetching: false,
    error: null,

    // for Refresh.
    refreshSucceded: false,
  })

export const authReducer = (state = getDefaultState(), action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return state.merge({
        error: null,
      })

    case AuthUserAction.REQUEST:
      return state.merge({
        isFetching: true,
        error: null,
        isRefresh: false,
      })

    case AuthUserAction.SUCCESS:
      return state.merge({
        isFetching: false,
        refreshSucceded: true,
        error: null,
      })

    case AuthUserAction.FAILURE:
      return state.merge({
        isFetching: false,
        error: action.error,
      })

    //
    // refresh
    //

    case RefreshAction.REQUEST:
      return state.merge({
        isFetching: true,
        refreshSucceded: null,
        error: null,

        // isRefreshing is set to true while we take the new token, and while we re-run the pending
        // actions
        // so we set it to false only in case of failure, since after the RefreshAction.SUCCESS
        // will be followed by the re-run of the pending actions.
        isRefreshing: true,
      })

    case RefreshAction.FAILURE:
      return state.merge({
        isFetching: false,
        error: action.error,
        refreshSucceded: false,
        isRefreshing: false,
      })

    case RefreshAction.SUCCESS:
      return state.merge({
        isFetching: false,
        refreshSucceded: true,
        error: null,
      })

    case RefreshTokenFlowAction.DONE:
      return state.merge({
        isRefreshing: false,
      })

    case LOGOUT_ACTION:
      return state.merge({
        isFetching: false,
        refreshSucceded: false,
        error: null,
      })

    /**
     * Custom action to save raw access token passed as url param
     */
    case SAVE_RAW_ACCESS_TOKEN_ACTION:
      return state.merge({
        isFetching: false,
        refreshSucceded: false,
        error: null,
      })

    /**
     * Clean auth store
     * remove auth data from storage and return default state
     */
    case CLEAN_AUTH_DATA:
      removeAccessToken()
      return getDefaultState()

    default:
      return state
  }
}
