import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { compose } from 'redux'
import isNil from 'lodash/isNil'

import { withForm, FormType } from 'pmt-modules/form'
import { Email, Password } from 'pmt-modules/user/forms/elements/index'
import { Link } from 'pmt-modules/routing'

import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  forgottenPassword: {
    color: theme.palette.secondary.main,
  },
})

const LoginFormView = ({ classes, formData, formErrors, forgottenPasswordLink, onChange }) => (
  <React.Fragment>
    <Email
      value={formData.username}
      onChange={username => onChange({ ...formData, username })}
      validation={formErrors.username}
      required
    />

    <Password
      value={formData.password}
      onChange={password => onChange({ ...formData, password })}
      validation={formErrors.password}
      required
      checkSecurity
    />

    <div className="u-textAlignRight u-foreground u-marginTop10">
      {!isNil(forgottenPasswordLink) ? (
        <a
          className={classes.forgottenPassword}
          href={forgottenPasswordLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {tr('global.forgot_password_link', {
            fr: 'Mot de passe oublié ?',
            context: '',
            desc: 'Link for forgotten password',
          })}
        </a>
      ) : (
        <Link to={Link.getRoute('FORGOT_PASSWORD')} className={classes.forgottenPassword}>
          {tr('global.forgot_password_link', {
            fr: 'Mot de passe oublié ?',
            context: '',
            desc: 'Link for forgotten password',
          })}
        </Link>
      )}
    </div>
  </React.Fragment>
)

export default compose(
  withStyles(styles),
  withForm(FormType.LOGIN)
)(LoginFormView)
