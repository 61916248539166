import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Nut = props => (
  <SvgIcon {...props}>
    <path d="M21.991,10.62c-0.122-2.068-1.515-3.813-2.441-4.758C20.239,5.169,21,4.154,21,2h-2c0,1.386-0.364,1.941-0.864,2.446 c-0.944-0.927-2.678-2.311-4.738-2.437c-1.507-0.085-2.884,0.481-4.105,1.703C9.111,3.894,9.01,4.137,9.003,4.39L9,4.387 C8.304,4.883,7.638,5.45,7.021,6.073C4.353,8.767,3,11.77,3,15c0,1.182,0.415,2.104,0.878,2.919 c-0.065,0.312-0.535,1.151-0.877,1.702l1.374,1.38c0.541-0.333,1.388-0.805,1.701-0.875C7.037,20.681,7.844,21,9,21 c3.129,0,6.174-1.405,8.808-4.063c0.268-0.271,1.098-1.14,1.578-1.956c0,0,0.002,0,0.002,0C19.454,14.993,19.52,15,19.586,15 c0.262,0,0.518-0.104,0.707-0.293C21.508,13.493,22.08,12.117,21.991,10.62z M16.386,15.529C14.137,17.8,11.583,19,9,19 c-0.756,0-1.234-0.2-1.998-0.649c-0.165-0.117-0.488-0.307-1.131-0.216c0.091-0.656-0.103-0.993-0.234-1.169 C5.243,16.277,5,15.702,5,15c0-2.685,1.158-5.214,3.441-7.52c0.387-0.39,0.801-0.744,1.224-1.078c0.555,1.194,1.553,2.86,3.315,4.62 c1.693,1.691,3.299,2.679,4.482,3.246C17.169,14.681,16.746,15.166,16.386,15.529z M19.267,12.86 c-0.889-0.316-2.798-1.18-4.873-3.252c-2.074-2.073-2.938-3.981-3.254-4.87c0.676-0.536,1.379-0.768,2.131-0.733 c2.151,0.126,4.036,2.483,4.054,2.505c0.049,0.063,0.105,0.12,0.168,0.17c0.024,0.019,2.375,1.927,2.501,4.059 C20.039,11.49,19.801,12.188,19.267,12.86z" />
  </SvgIcon>
)
Nut = pure(Nut)
Nut.displayName = 'Nut'
Nut.muiName = 'SvgIcon'

export default Nut
