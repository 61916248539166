import React from 'react'

import {
  BadgeId,
  Email,
  FirstName,
  LastName,
  MarketingOptim,
  PhoneNumber,
} from 'pmt-modules/user/forms/elements/index'

export const fieldsType = {
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
  BADGE_ID: 'BADGE_ID',
  MARKETING_ALLOW_CONTACT: 'MARKETING_ALLOW_CONTACT',
}

/**
 * map fields retrieved from api consumer setting to formData key
 * and component to display in form view
 */
export const getFieldsMap = () => ({
  FIRST_NAME: {
    key: 'firstName',
    component: <FirstName />,
  },
  LAST_NAME: {
    key: 'lastName',
    component: <LastName />,
  },
  EMAIL: {
    key: 'email',
    component: <Email />,
  },
  PHONE: {
    key: 'phone',
    component: <PhoneNumber />,
  },
  BADGE_ID: {
    key: 'badgeId',
    component: <BadgeId />,
  },
  MARKETING_ALLOW_CONTACT: {
    key: 'marketing.allowContact',
    component: <MarketingOptim />,
  },
})

export const getField = field => getFieldsMap()[field] || null
