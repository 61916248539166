import { tr } from 'pmt-modules/i18n'
import moment from 'moment'

import {
  isBetweenInclude,
  isGreaterThanInclude,
  isCardSupported,
  isExpirationDateMonthInFuture,
  isCardNameValid,
  isCardNumberValid,
  isCardSecurityCodeValid,
} from 'pmt-modules/form/validation'

// import { notEmpty } from 'pmt-modules/form/validation'
import createForm from 'pmt-modules/form/createForm'
import FormType from 'pmt-modules/form/FormType'

import { Currencies } from 'pmt-utils/currency'

// import { CreditCardType } from '../../constants'

// in DEV environment, prefill form with the following data
const devData = {
  // type: CreditCardType[0],
  number: '4970105181854329',
  expirationDateMonth: 12,
  expirationDateYear: 2024,
  cvv: 123,
  // name: 'Perso',
}

const getDefaultData = () => {
  const currentDate = moment().add(1, 'year')

  return {
    // type: false,
    number: null,
    // expirationDateMonth: null,
    // expirationDateYear: null,
    expirationDateMonth: currentDate.month() + 1, // 1 for January
    expirationDateYear: currentDate.year(),
    cvv: null,
    currency: Currencies.EUR,
    name: tr('global.credit_card.form.default_name'),
    // Note: when user light, it should be changed to false using the defaultData prop
    saveForLater: true,
  }
}

const validationRules = {
  // type: [[notEmpty, 'global.credit_card.form.validation.type_mandatory']],
  number: [
    [isGreaterThanInclude(0), 'global.credit_card.form.validation.number_mandatory'],
    [isCardSupported, 'global.credit_card.form.validation.unsupported_card'],
    [isCardNumberValid, 'global.credit_card.form.validation.invalid_card_number'],
  ],
  expirationDateMonth: [
    [isBetweenInclude(1, 12), 'global.credit_card.form.validation.expiration_date_invalid'],
    [isExpirationDateMonthInFuture(), 'global.credit_card.form.validation.expiration_date_invalid'],
  ],
  expirationDateYear: [
    [
      isGreaterThanInclude(moment().year()),
      'global.credit_card.form.validation.expiration_date_invalid',
    ],
  ],
  cvv: [[isCardSecurityCodeValid, 'global.credit_card.form.validation.security_code_invalid']],
  name: [[isCardNameValid(), 'global.credit_card.form.validation.name_mandatory']],
}

export default createForm(FormType.CREDIT_CARD, validationRules, getDefaultData, devData)
