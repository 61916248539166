import createAction from '../redux/createAction'

import { createApiCallAction, createApiEnumAction } from '../api/utils'

import UserAccountApi from '../api/calls/UserAccountApi'

export const UserAccountAction = {
  RESET: 'USER_ACCOUNT::RESET',
}

export const resetUserAccount = () => createAction(UserAccountAction.RESET)

export const FetchUserUserAccountsAction = createApiEnumAction('USER::USER_ACCOUNTS::GET')

export const fetchUserUserAccounts = userId =>
  createApiCallAction(FetchUserUserAccountsAction, UserAccountApi.getUserAccounts(userId), {
    userId,
  })

//
//
//

export const FetchUserUserAccountAction = createApiEnumAction('USER::USER_ACCOUNT::GET')

export const fetchUserUserAccount = (userId, accountId, refreshToken = false) =>
  createApiCallAction(
    FetchUserUserAccountAction,
    UserAccountApi.getUserAccount(userId, accountId, refreshToken),
    {
      userId,
      accountId,
      refreshToken,
    }
  )

//
//
//

export const UpdateUserAccountAction = createApiEnumAction('USER::USER_ACCOUNT::PUT')

export const updateUserAccount = (userId, userAccount) =>
  createApiCallAction(UpdateUserAccountAction, UserAccountApi.putUserAccount(userId, userAccount), {
    userId,
    userAccount,
    accountId: userAccount.id,
  })
