import ApiEndpoints from '../config/ApiEndpoints'
import UserErrors from '../errors/UserErrors'

// const loadCompagnies = (rGroupId, props, options, json, uids) => {
//   // remove duplicates
//   uids = uniqBy(uids, function (id) {
//     return id;
//   })

//   // get difference between our uids list and the already loaded restaurants (props.restaurantIds)
//   // to keep only the ids of the restaurants to load
//   uids = differenceWith(uids, props.restaurantIds, isEqual)

//   // there is no restaurant to load, we set default data and call success
//   if (isEmpty(rids)) {
//     json.restaurants = []
//     json.restaurantIds = []
//     options.success(json)
//     return null
//   }

//   // we load the restaurants
//   const getRestaurants = RestaurantApi.getRestaurants(json, uids)
//   return getRestaurants
// }

const transformUserFormDataToBody = user => ({
  ...user,
  birthdate: user.birthdate ? user.birthdate.valueOf() : null,
})

const UserApi = {
  getUserMe: () => {
    return {
      endpoint: ApiEndpoints.USER_ME,
      type: 'GET',
    }
  },

  getRecoverPassword: email => ({
    type: 'GET',
    query: {
      email,
    },
    headers: {
      authorization: null,
    },
    endpoint: ApiEndpoints.USER_FORGOT_PASSWORD,
    errorHandler: UserErrors.FORGOT_PASSWORD,
  }),

  /**
   *  Get users list depending on rGroupId
   *  props = {
   *    - userIds: A list that describe the restaurants that we already loaded. We don't want
   *    to load theme again.
   *  }
   */
  getUserList: (rGroupId, nextCursor, limit) => ({
    type: 'GET',
    params: {
      rGroupId,
    },
    query: {
      loadAccounts: true,
      cursor: nextCursor,
      limit,
    },
    endpoint: ApiEndpoints.RGROUP_USERS,
    // then: (options, json) => {
    // let uids = json.data.map((user) => (user.id))
    // return loadCompagnies(rGroupId, props, options, json, uids)
    // }
  }),

  /**
   *  Get user's orders list depending on rGroupId / userId
   */
  getOrdersList: (rGroupId, userId, nextCursor, limit) => ({
    type: 'GET',
    params: {
      rGroupId,
    },
    query: {
      userId,
      cursor: nextCursor,
      limit,
    },
    endpoint: ApiEndpoints.HISTORY_USER_ORDERS,
  }),

  getUser: (rGroupId, userId, loadAccounts = true) => ({
    endpoint: ApiEndpoints.RGROUP_USER,
    type: 'GET',
    query: {
      loadAccounts: loadAccounts,
    },
    params: {
      rGroupId,
      userId,
    },
  }),

  getUserWithUnknownRestaurantsGroup: (userId, loadAccounts = true) => ({
    endpoint: ApiEndpoints.USER,
    type: 'GET',
    query: {
      loadAccounts: loadAccounts,
    },
    params: {
      userId,
    },
  }),

  postUser: (rGroupId, user) => ({
    type: 'POST',
    endpoint: ApiEndpoints.USERS,
    // params: {
    // rGroupId,
    // },
    body: transformUserFormDataToBody(user),
  }),

  putUser: user => ({
    type: 'PUT',
    endpoint: ApiEndpoints.RGROUP_USER,
    params: {
      rGroupId: user.restaurantsGroupId,
      userId: user.id,
    },
    body: transformUserFormDataToBody(user),
    errorHandler: UserErrors.EDIT,
  }),

  putProfile: user => ({
    type: 'PUT',
    endpoint: ApiEndpoints.USER,
    params: {
      userId: user.id,
    },
    query: {
      sendPhoneVerificationCode: false,
    },
    body: transformUserFormDataToBody(user),
    errorHandler: UserErrors.EDIT,
  }),
}

export default UserApi
