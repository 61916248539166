import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Soy = props => (
  <SvgIcon {...props}>
    <path d="M 20 2 C 19.210938 3.917969 17.332031 4.667969 16 6 C 14.296875 7.703125 14 10 14 10 C 11.019531 10 8.292969 12.75 8 14.96875 C 7.371094 15.078125 5.394531 15.332031 4 17 C 2.925781 18.285156 2 20.0625 2 20.96875 C 3.3125 22.28125 8.71875 22.40625 11 20.96875 C 11.835938 20.441406 12 20 12 20 C 16 20 19 16.324219 19 13 C 19 13 20.367188 12.601563 21.460938 10.4375 C 22.519531 8.335938 21.8125 3.8125 20 2 Z M 8.007813 19.902344 C 6.78125 20.386719 5.582031 20.140625 5.324219 19.347656 C 5.066406 18.558594 5.847656 17.523438 7.070313 17.039063 C 8.296875 16.554688 9.496094 16.804688 9.753906 17.59375 C 10.011719 18.382813 9.230469 19.417969 8.007813 19.902344 Z M 14.570313 16.429688 C 13.464844 17.257813 12.003906 17.175781 11.304688 16.242188 C 10.605469 15.308594 10.9375 13.882813 12.039063 13.054688 C 13.144531 12.226563 14.609375 12.3125 15.304688 13.246094 C 16.003906 14.175781 15.675781 15.605469 14.570313 16.429688 Z M 19.882813 9.152344 C 19.359375 10.359375 18.335938 11.03125 17.59375 10.65625 C 16.851563 10.28125 16.675781 8.996094 17.195313 7.789063 C 17.71875 6.582031 18.742188 5.90625 19.484375 6.285156 C 20.222656 6.660156 20.402344 7.945313 19.882813 9.152344 Z " />
  </SvgIcon>
)
Soy = pure(Soy)
Soy.displayName = 'Soy'
Soy.muiName = 'SvgIcon'

export default Soy
