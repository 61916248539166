import blue from 'pmt-ui/colors/blue'
import orange from 'pmt-ui/colors/orange'
import red from 'pmt-ui/colors/red'
import grey from 'pmt-ui/colors/grey'
import lightGreen from 'pmt-ui/colors/lightGreen'

import { createMuiTheme, dark, light, getLuminance } from 'pmt-ui/styles'

import { getQueryParam } from 'pmt-utils/url'

const PMTColors = {
  red400: red['A400'],
  orange300: orange[300],
  lightGreen400: lightGreen[400],
  blue800: blue[800],
  grey500: grey[500],
  greyBackground: '#efeff4',
  white: '#fff',
  greyCD: '#cdcdcd',
  grey91: '#919ca7',
  darkGrey: '#262626',
  green4ca: '#4caf50',
}

const getPMTColor = color => PMTColors[color]

const getPMTColors = () => PMTColors

const muiTheme = createMuiTheme({
  palette: {
    type: getQueryParam('theme', window.location.uri) || 'light', // Switching the dark mode on is a single property value change.
    primary: {
      main: '#85a60f',
    },
    secondary: {
      main: '#85a60f',
    },
    contrastThreshold: 0.5,
  },
  pmt: {
    appConfig: {}, // can be overwritten by api consumer theme settings
    colors: getPMTColors(),
    status: {
      info: getPMTColor('blue800'),
      error: getPMTColor('red400'),
      warning: getPMTColor('orange300'),
      inactive: getPMTColor('grey500'),
      valid: getPMTColor('lightGreen400'),
    },
    header: {
      height: 48,
      titleFontSize: 24,
    },
    subHeader: {
      height: 27,

      /**
       * The subHeader has the same background color as the header, but with a different opacity
       */
      opacity: 0.8,
      fontSize: 20,
    },
    bottomButtonBar: {
      height: 50,
    },
    pageContent: {
      paddingLeft: 0, // must be 0
      paddingRight: 0, // must be 0
      paddingTop: 48, // must be 0
      paddingBottom: 0,
    },
    loading: {
      color: grey[400],
    },
    textEllipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    typography: {
      fonts: {
        '117': {
          style: {
            fontSize: 11,
            fontWeight: 700,
            lineHeight: 1,
          },
        },
        '124': {
          style: {
            fontSize: 12,
            fontWeight: 400,
            lineHeight: 1,
          },
        },
        '127': {
          style: {
            fontSize: 12,
            fontWeight: 700,
            lineHeight: 1,
          },
        },
        '144': {
          style: {
            fontSize: 14,
            fontWeight: 400,
            lineHeight: 1,
          },
        },
        '147': {
          style: {
            fontSize: 14,
            fontWeight: 700,
            lineHeight: 1,
          },
        },
        '164': {
          style: {
            fontSize: 16,
            fontWeight: 400,
            lineHeight: 1,
          },
        },
        '167': {
          style: {
            fontSize: 16,
            fontWeight: 700,
            lineHeight: 1,
          },
        },
        '184': {
          style: {
            fontSize: 18,
            fontWeight: 400,
            lineHeight: 1,
          },
        },
        '187': {
          style: {
            fontSize: 18,
            fontWeight: 700,
            lineHeight: 1,
          },
        },
        '204': {
          style: {
            fontSize: 20,
            fontWeight: 400,
            lineHeight: 1,
          },
        },
        '207': {
          style: {
            fontSize: 20,
            fontWeight: 700,
            lineHeight: 1,
          },
        },
        '247': {
          style: {
            fontSize: 24,
            fontWeight: 700,
            lineHeight: 1,
          },
        },
        '267': {
          style: {
            fontSize: 26,
            fontWeight: 700,
            lineHeight: 1,
          },
        },
        '307': {
          style: {
            fontSize: 30,
            fontWeight: 700,
            lineHeight: 1,
          },
        },
      },
    },
  },
  zIndex: {
    loader: 1600,
  },
})

const theme = {
  muiTheme,
}

// https://github.com/callemall/material-ui/issues/8183
// override getContrastText function to have a white color on green buttons
// TODO : check the evolution of the issue above
theme.muiTheme.palette.getContrastText = color => {
  if (getLuminance(color) <= theme.muiTheme.palette.contrastThreshold) {
    return dark.text.primary
  } else {
    return light.text.primary
  }
}

export default theme
