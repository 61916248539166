import React from 'react'

import Slider from '@material-ui/core/Slider'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    // using fixed values and not theme.spacing because used by Order Plugin and Web customer, which don't have the same theme.spacing
    margin: '34px 16px 16px 16px',
  },
}))

/**
 * To avoid imprecision such as x.000000000001 numbers after a calculation
 *
 * @return the rounded value after the 2nd floating digit (0.009 will be rounded to 0.01)
 */
const exact = x => {
  return Math.round(x * 100.0) / 100.0
}

const round = (exactValue, roundingValue) => {
  return exact(Math.round(exactValue / roundingValue) * roundingValue)
}

const TipSlider = ({
  tips,
  onChoseTip,
  amountWithoutTips,
  defaultSelectedPercentage,
  roundValue,
}) => {
  const classes = useStyles()
  const [initialized, setInitialized] = React.useState(false)

  React.useEffect(
    () => {
      if (!initialized) {
        if (tips > 0) {
          // already some tips selected => we reselect them
          onChoseTip(tips)
        } else {
          const defaultTipsValue = round(
            ((defaultSelectedPercentage || 0) * amountWithoutTips) / 100,
            roundValue
          )
          onChoseTip(defaultTipsValue)
        }
        setInitialized(true)
      }
    },
    [amountWithoutTips, initialized, defaultSelectedPercentage, roundValue, onChoseTip, tips]
  )

  // 20 % max
  const maxValue = round(0.2 * amountWithoutTips, roundValue)
  const tipValueAsPercentage = Number(((tips * 100) / amountWithoutTips).toFixed())

  return (
    <div className={classes.root}>
      <Slider
        min={0}
        max={maxValue}
        value={tips}
        onChange={(event, newValue) => {
          onChoseTip(newValue)
        }}
        marks={true}
        valueLabelDisplay="on"
        valueLabelFormat={value => `${tipValueAsPercentage} %`}
        step={roundValue}
      />
    </div>
  )
}

export default TipSlider
