import React from 'react'

import Button from 'pmt-ui/Button'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  root: {
    minHeight: 48,
    '&:disabled': {
      backgroundColor: theme.pmt.colors.greyCD,
    },
  },
  label: {
    textTransform: 'initial',
    lineHeight: 1,
  },
  sizeSmall: {
    minHeight: 36,
  },
  sizeLarge: {
    minHeight: 55,
  },
  outlined: {
    borderWidth: 2,

    '&:hover, &:focus': {
      borderWidth: 2,
    },
  },
})

// ButtonCustom - used for main action for web customer
// created to format style applied in one file
// and not everywhere a primary button has to be set
const ButtonCustom = ({ children, classes, color, variant, ...otherProps }) => (
  <Button variant={variant} color={color} classes={classes} {...otherProps}>
    {children}
  </Button>
)

ButtonCustom.defaultProps = {
  color: 'primary',
  variant: 'contained',
}

export default withStyles(styles)(ButtonCustom)
