import { tr } from 'pmt-modules/i18n'
import React from 'react'

import IconButton from 'pmt-ui/IconButton'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import Refresh from 'pmt-ui/svg-icons/navigation/refresh'
import { TypographyCustom } from 'pmt-ui/Typography'
import { UserAccountRemainingView } from 'pmt-modules/userAccount'
import { withStyles } from 'pmt-ui/styles'

import QrCodeView from './components/QrCodeView'

const styles = theme => ({
  refresh: {
    position: 'absolute',
    top: 2,
    right: 2,
    color: theme.palette.primary.main,
  },
})

/**
 * @mockup N/A
 */
const View = ({ classes, isFetchingUserAccount, onLoadUserAccount, userId, userAccount }) => (
  <div className="u-flexColumn u-flex1">
    <IconButton onClick={onLoadUserAccount} className={classes.refresh}>
      <Refresh />
    </IconButton>
    <UserAccountRemainingView userId={userId} />
    <hr className="separation borderPrimary" />
    <div className="u-flexGrow1 u-flexColumnTop">
      {isFetchingUserAccount ? (
        <LoadingBlockWrapper show />
      ) : (
        <div className="u-textAlignCenter u-flexGrow1 u-flexColumnTop">
          <TypographyCustom
            type="144"
            align="center"
            className="u-padding20 u-contentSerifItalic u-absolute u-sizeFullWidth"
          >
            {tr('web_customer.qr_code.expiration_warning', {
              fr: 'Attention, ce QR Code expirera à %{tokenExpirationDateAsHour}',
              context: '',
              desc: 'Warning message displayed for qr code expiration',
              tokenExpirationDateAsHour: userAccount.tokenExpirationDateAsHour,
            })}
          </TypographyCustom>

          <div className="u-flexGrow1 u-flexCenter u-justifyContentCenter">
            <QrCodeView value={userAccount.token} size={167} />
          </div>
        </div>
      )}
    </div>
  </div>
)

export default withStyles(styles)(View)
