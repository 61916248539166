import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let NavigationArrowBack = props => (
  <SvgIcon {...props}>
    <path d="M19,11H7.83l4.88-4.88c0.39-0.39,0.39-1.03,0-1.42l0,0c-0.39-0.39-1.02-0.39-1.41,0l-6.59,6.59 c-0.39,0.39-0.39,1.02,0,1.41l6.59,6.59c0.39,0.39,1.02,0.39,1.41,0l0,0c0.39-0.39,0.39-1.02,0-1.41L7.83,13H19c0.55,0,1-0.45,1-1 v0C20,11.45,19.55,11,19,11z" />
  </SvgIcon>
)
NavigationArrowBack = pure(NavigationArrowBack)
NavigationArrowBack.displayName = 'NavigationArrowBack'
NavigationArrowBack.pmtName = 'SvgIcon'

export default NavigationArrowBack
