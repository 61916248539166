import { tr } from 'pmt-modules/i18n'
import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import InfiniteScroll from 'react-infinite-scroller'

import Table, { TableBody } from 'pmt-ui/Table'
import { TypographyCustom } from 'pmt-ui/Typography'

import { Button } from '../../../../components/Button'

const Pagination = ({ pagination, onLoadMore }) =>
  !pagination || isEmpty(pagination.cursors.after) ? null : (
    <div className="u-textAlignCenter u-marginTop20 u-marginBottom20">
      <Button onClick={onLoadMore}>
        <TypographyCustom type="187">{tr('global.load_more')}</TypographyCustom>
      </Button>
    </div>
  )

/**
 * Infinite scroller : https://github.com/CassetteRocks/react-infinite-scroller
 */
const HistoryList = ({
  list = [],
  pagination,
  onLoadMore,
  rowRenderer,
  emptyList,
  infiniteScroll,
}) => (
  <InfiniteScroll
    loadMore={onLoadMore}
    hasMore={!infiniteScroll.disabled && pagination && !isEmpty(pagination.cursors.after)}
    {...infiniteScroll}
  >
    {!isEmpty(list) ? (
      <Table selectable="false">
        <TableBody>{list.map(object => rowRenderer(object))}</TableBody>
      </Table>
    ) : (
      <p className="u-contentSlabItalic u-textAlignCenter u-marginTop20">{emptyList}</p>
    )}
    {infiniteScroll.disabled && <Pagination pagination={pagination} onLoadMore={onLoadMore} />}
  </InfiniteScroll>
)

HistoryList.defaultProps = {
  infiniteScroll: {
    disabled: false, // Disable infinite scroll by default
    initialLoad: false, // Whether the component should load the first set of items.
    pageStart: 0, // The number of the first page to load, With the default of 0, the first page is 1.
    threshold: 50, // The distance in pixels before the end of the items that will trigger a call to loadMore.
    useWindow: false, // Add scroll listeners to the window, or else, the component's parentNode.
  },
}

HistoryList.propTypes = {
  list: PropTypes.array,
  pagination: PropTypes.object,
  rowRenderer: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  infiniteScroll: PropTypes.object,
}

export default HistoryList
