//
// Note: this file should never be import directly. Use a getter via getters.js
//

import find from 'lodash/find'
import merge from 'lodash/merge'
import isNil from 'lodash/isNil'

const Env = {
  COMMON: {
    VERSION: '0.0.1',

    Logger: {
      DISPLAY_REPORT_DIALOG: true,
    },

    SHOULD_USE_APP_CONFIG: true,

    IS_WEB_CUSTOMER: true,

    IS_LOCAL: false,
    IS_DEV: false,
    IS_PREPROD: false,
    IS_PROD: false,
    IS_KIOSK_TOPUP_LOCAL: false,
    IS_KIOSK_TOPUP_DEV: false,
    IS_KIOSK_TOPUP_PREPROD: false,
    IS_KIOSK_TOPUP_PRODUCTION: false,
  },

  LOCAL: {
    ENV: 'DEV',

    HOSTNAME: 'http://localhost:8080',
    BASE_URL: /app.qa[0-9]{1}.paymytable.com/,
    API_URL: 'http://localhost:8080/rest',
    API_CONSUMER: 'NTcwMDQ5NjYwMTkwNzIwMDpEc1pydVZkd1RLcGo=',
    // API_CONSUMER: 'NTczNjQyMDQwNDYyNTQwODpBT3dPRnFzS3JqMXE',

    IS_DEV: true,
    IS_QA: true,
  },

  QA: {
    ENV: 'DEV',

    HOSTNAME: 'app.qa1.paymytable.com',
    BASE_URL: /app.qa[0-9]{1}.paymytable.com/,
    API_URL: 'https://paymytabledev.appspot.com/rest',
    API_CONSUMER: 'NTcwMDQ5NjYwMTkwNzIwMDpEc1pydVZkd1RLcGo=',
    // API_CONSUMER: 'NTczNjQyMDQwNDYyNTQwODpBT3dPRnFzS3JqMXE',

    IS_DEV: true,
    IS_QA: true,
  },

  PREPROD: {
    ENV: 'PREPROD',

    HOSTNAME: 'app.preprod.paymytable.com',
    BASE_URL: /app.preprod.paymytable.com/,
    API_URL: 'https://paymytable-eu-preprod.ew.r.appspot.com/rest',
    // PayGreen:
    API_CONSUMER: 'NDkyNTA3MjQwMzg1NzQwODpBTFIyRHJQRi1HWjI',
    // default:
    // API_CONSUMER: 'NTA5NzcxMDM5MDYwNzg3MjpTaFNJZHVRbS1zZ1Y=',
    Logger: {
      SENTRY_URL: 'https://a5b9ae39ca4c4ed581221426adeb9f37@sentry.io/207747',
    },

    IS_PREPROD: true,
  },

  PRODUCTION: {
    ENV: 'PROD',

    HOSTNAME: 'app.paymytable.com',
    BASE_URL: /app.paymytable.com/,
    API_URL: 'https://paymytable-eu.ew.r.appspot.com/rest',
    // Burge Federation ApiConsumer - this needs to be fixed
    API_CONSUMER: 'NTk1MzQ3MjU2NjM5NDg4MDpBUHVsa1dwLW9VdGF6Zw==',

    IS_PROD: true,

    Logger: {
      SENTRY_URL: 'https://a5b9ae39ca4c4ed581221426adeb9f37@sentry.io/207747',
    },
  },
}

let predicate = null

const hostname = window.location.hostname

if (!isNil(window.globalEnvironmentSettings)) {
  predicate = config => window.globalEnvironmentSettings.env === config.ENV
} else {
  predicate = config => (!isNil(config.BASE_URL) ? hostname.match(config.BASE_URL) : null)
}

let currentEnv = find(Env, predicate) || Env.QA

// for QA, we need to set the right URL (qa1, qa2, qa3) in hostname in order to get the right fr.json/en.json files
if (currentEnv.IS_QA) {
  currentEnv.HOSTNAME = hostname
}

if (process.env.__DEV__) {
  currentEnv = Env.PREPROD
}

console.log('[CONFIGURATION] ', currentEnv.API_URL, currentEnv.HOSTNAME)

export default merge({}, Env.COMMON, currentEnv)
