import Immutable from 'immutable'
import {
  RegisterUserLightAction,
  RegisterUserAction,
  RegisterIncognitoUserAction,
} from '../registration'
import {
  LOGOUT_ACTION,
  getAuthCookie,
  getUserLightCookie,
  getIncognitoCookie,
} from 'pmt-modules/auth'
import { GetAppConfigAction } from 'pmt-modules/appConfig'
import { GetUserMeAction } from 'pmt-modules/userMe'
import UserType from 'pmt-modules/user/constants/UserType'

const getDefaultState = () => {
  if (getAuthCookie() != null) {
    // this will be replaced by real data when fetching userMe
    return Immutable.fromJS({
      id: null,
      email: null,
      lastName: null,
      firstName: null,
      type: null,
    })
  } else if (getUserLightCookie() != null) {
    return Immutable.fromJS({
      id: null,
      email: getUserLightCookie().email,
      lastName: getUserLightCookie().lastName,
      firstName: getUserLightCookie().firstName,
      type: UserType.LIGHT,
    })
  } else if (getIncognitoCookie() != null) {
    return Immutable.fromJS({
      id: getIncognitoCookie(),
      email: null,
      lastName: null,
      firstName: null,
      type: UserType.INCOGNITO,
    })
  }
  return Immutable.fromJS({
    id: null,
    email: null,
    lastName: null,
    firstName: null,
    type: null,
  })
}

export const authenticatedUserReducer = (state = getDefaultState(), action) => {
  switch (action.type) {
    case RegisterUserAction.SUCCESS:
    case RegisterUserLightAction.SUCCESS:
    case RegisterIncognitoUserAction.SUCCESS:
    case GetUserMeAction.SUCCESS:
      return state.merge({
        id: action.response.id,
        email: action.response.email,
        lastName: action.response.lastName,
        firstName: action.response.firstName,
        type: action.response.userType,
      })

    // reload from cookies, as cookies depend from appConfig.restaurantsGroupId
    case GetAppConfigAction.SUCCESS:
      return getDefaultState()

    case LOGOUT_ACTION:
      return state.merge({
        id: null,
        email: null,
        lastName: null,
        firstName: null,
        type: null,
      })

    default:
      return state
  }
}
