import each from 'lodash/each'
import isNil from 'lodash/isNil'
import { sortArray } from 'pmt-utils/array'

import { createFormatter, createListFormatter } from 'pmt-utils/format'
import { formatDate } from 'pmt-utils/date'

const formatExpirationDate = card => {
  card.expirationDateFormatted = formatDate(card.expirationDate, 'MM/YY')

  return card
}

const sortByCreationDate = cardsList => {
  return sortArray(cardsList.filter(Boolean), card => -card.creationDate)
}

export const formatCreditCard = createFormatter(formatExpirationDate)

export const formatCreditCards = createFormatter(
  sortByCreationDate,
  createListFormatter(formatCreditCard)
)

const formatRegistrationTypeFields = registrationType => {
  const cardRegistrationDatasArray = []

  if (!isNil(registrationType)) {
    each(registrationType.map.fields, (value, key) => {
      cardRegistrationDatasArray.push({ key, value })
    })

    registrationType.map.fieldsFormatted = cardRegistrationDatasArray
  }

  return registrationType
}

export const formatRegistrationType = createFormatter(formatRegistrationTypeFields)
