import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { tr } from 'pmt-modules/i18n'
import { ButtonLink } from 'app/components/Button'
import { TypographyCustom } from 'pmt-ui/Typography'


const CguLabel = ({ restaurantsGroup, linkClass }) => (
  <TypographyCustom type="164">
    {tr('global.register.accept_terms_clickable.first')}

    {!isEmpty(restaurantsGroup?.userSettings?.touUrl) ? (
      <ButtonLink
        component="a"
        classes={{
          root: linkClass,
        }}
        onClick={(e) => {
          e.stopPropagation()
          window.open(restaurantsGroup.userSettings.touUrl)
        }}
        label={tr('global.register.accept_terms_clickable.second')}
      />
    ) : (
      <span>
        &nbsp;
        {tr('global.register.accept_terms_clickable.second')}
      </span>
    )}
  </TypographyCustom>
)

export default CguLabel