import { tr } from 'pmt-modules/i18n'
import React from 'react'
import ReactDOMServer from 'react-dom/server'
import isNil from 'lodash/isNil'

import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'

import Grid from 'pmt-ui/Grid'
import Price from 'pmt-ui/Price'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withTheme, ThemeProvider } from 'pmt-ui/styles'

import { formatDate, formatHour } from 'pmt-utils/date'

import { Button } from 'app/components/Button'

const styles = theme => ({
  root: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: 1,
    paddingBottom: theme.spacing(3),
  },
  amount: {
    display: 'inline-flex',
    color: theme.palette.primary.main,
  },
  paymentDone: {
    marginTop: theme.spacing(2),
    color: theme.pmt.colors.grey91,
  },
  buttonBack: {
    width: '80%',
    marginTop: theme.spacing(1),
  },
})

class View extends React.PureComponent {
  constructor(props) {
    super(props)

    if (!props.topUp || props.topUp.amount === 0) {
      props.handleGoToHistory()
    }
  }

  // TODO: display a warning / information message if isUsingEmpotency === true
  render() {
    const { classes, handleGoToHistory, handleGoToTopUp, topUp, theme } = this.props

    return (
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.content}>
          <TypographyCustom type="207" className="u-marginTop40">
            {tr('web_customer.top_up.confirm.thank_you')}
          </TypographyCustom>
          <TypographyCustom
            type="204"
            className="u-marginTop10"
            dangerouslySetInnerHTML={{
              __html: tr('web_customer.top_up.confirm.top_up_confirmed', {
                spanBegin: `<span class="${classes.amount}">`,
                amountFormatted: ReactDOMServer.renderToStaticMarkup(
                  <ThemeProvider theme={theme}>
                    <TypographyCustom type="204" skipColor className={classes.amount}>
                      <Price value={topUp.amountPriceFormatted} />
                    </TypographyCustom>
                  </ThemeProvider>
                ),
                spanEnd: '</span>',
              }),
            }}
          />
          <TypographyCustom type="124" className={classes.paymentDone}>
            {tr('web_customer.top_up.confirm.payment_date', {
              day: formatDate(topUp.creationDate, 'DD/MM/YY'),
              hour: formatHour(topUp.creationDate, ':'),
            })}
          </TypographyCustom>
        </Grid>
        <Grid item className={classes.content}>
          <CustomTextsContainer>
            {({ texts }) =>
              !isNil(texts) &&
              !isNil(texts.TOP_UP_CONFIRM) && (
                <TypographyCustom type="184">{texts.TOP_UP_CONFIRM}</TypographyCustom>
              )
            }
          </CustomTextsContainer>
        </Grid>
        <Grid item container justify="center" alignItems="center">
          <Button variant="outlined" className={classes.buttonBack} onClick={handleGoToTopUp}>
            <TypographyCustom type="164" skipColor>
              {tr('web_customer.top_up.confirm.back_topup')}
            </TypographyCustom>
          </Button>
          <Button className={classes.buttonBack} onClick={handleGoToHistory}>
            <TypographyCustom type="164" skipColor>
              {tr('web_customer.top_up.confirm.back_history')}
            </TypographyCustom>
          </Button>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(withTheme(View))
