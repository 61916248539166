import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Shellfish = props => (
  <SvgIcon {...props}>
    <path d="M 9.3125 2.03125 C 7.824219 2.089844 5.96875 3.0625 6.59375 4.6875 L 9.09375 10.8125 L 5.3125 5.90625 C 3.8125 4.105469 1.101563 7.988281 3 9.1875 L 7.40625 12.8125 L 3.40625 11.1875 C 2.304688 10.789063 2.195313 11.511719 2.09375 11.8125 C 1.695313 13.113281 2.511719 14.414063 3.8125 14.8125 C 5.210938 15.210938 6.898438 15.988281 8.5 17.1875 C 10.101563 18.386719 10.304688 20.09375 11.90625 20.09375 L 12.09375 20.09375 C 13.59375 20.09375 13.898438 18.386719 15.5 17.1875 C 17.101563 15.988281 18.886719 15.3125 20.1875 14.8125 C 21.488281 14.3125 22.304688 13.011719 21.90625 11.8125 C 21.804688 11.511719 21.695313 10.789063 20.59375 11.1875 L 16.59375 12.8125 L 21 9.1875 C 22.898438 7.988281 20.210938 4.105469 18.8125 5.90625 L 15 10.8125 L 17.5 4.6875 C 18.398438 2.085938 13.101563 1.101563 13 3 L 12 8.6875 L 11 3 C 10.960938 2.289063 10.207031 1.996094 9.3125 2.03125 Z M 8 19 C 7.699219 19.199219 7.394531 19.898438 7.09375 20.5 C 6.695313 21.300781 7.199219 22 8 22 L 12 22 C 9.800781 22 9.300781 19 8 19 Z M 12 22 L 16 22 C 16.800781 22 17.304688 21.300781 16.90625 20.5 C 16.605469 19.898438 16.300781 19.199219 16 19 C 14.699219 19 14.199219 22 12 22 Z " />
  </SvgIcon>
)
Shellfish = pure(Shellfish)
Shellfish.displayName = 'Shellfish'
Shellfish.muiName = 'SvgIcon'

export default Shellfish
