import React from 'react'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'

import { withAppConfig, checkAccessSecurityModes } from 'pmt-modules/appConfig'
import { getApiConsumer } from 'pmt-modules/environment'
import { getCurrentLanguageWithoutRegionCode } from 'pmt-modules/i18n/utils'
import { resetPayment, resetPostPayment } from 'pmt-modules/payment'
import { withRestaurant } from 'pmt-modules/restaurant/components'
import { redirectTo, redirectToExternal, getRoute, getOrderPluginUrl } from 'pmt-modules/routing'

import { withTheme } from 'pmt-ui/styles'

import { getQueryParam } from 'pmt-utils/url'

import Page from '../../components/Page'
import View from './View'

/**
 * https://paymytable.atlassian.net/browse/PP-711
 * https://app.zeplin.io/project/5b616247d86fcf41520410cc/screen/5b6162799035c6536167c2f1
 * https://app.zeplin.io/project/5b616247d86fcf41520410cc/screen/5b6162797d6f9ad50928862a
 * https://app.zeplin.io/project/5b616247d86fcf41520410cc/screen/5ba9ffe305c2eba0d2b5305b
 */
@withAppConfig
@withRestaurant
class RestaurantPage extends React.PureComponent {
  constructor(props) {
    super(props)

    if (!this.hasDefinedTableId()) {
      this.props.redirectTo(getRoute('PAYMENT_TABLE_NUMBER'), {
        restaurantId: this.props.restaurantId,
      })
    }

    // clean payment state when returning to home
    this.props.resetPayment()
    this.props.resetPostPayment()
  }

  canOrder = () =>
    this.props.restaurant &&
    this.props.restaurant.status === 'ENABLED' &&
    this.props.restaurant.hasFeatureOrder &&
    (this.props.restaurant.orderSettings.isCurrentlyOpen ||
      this.props.orderAppConfig?.bypassOpeningHours) &&
    this.props.restaurant.orderSettings.onSiteSettings.enabled &&
    this.hasDefinedTableId()

  canPay = () =>
    this.props.restaurant && this.props.restaurant.hasFeaturePayment && this.hasDefinedTableId()

  hasDefinedTableId = () => {
    const posCheckId = getQueryParam('posCheckId')
    const tableId = getQueryParam('tableId')

    // when the app token is invalid, we don't have a securityAppConfig
    return this.props.securityAppConfig && this.props.securityAppConfig.checkAccessSecurity
      ? (this.props.securityAppConfig.checkAccessSecurity.mode ===
          checkAccessSecurityModes.POS_CHECK_ID &&
          posCheckId) ||
          (this.props.securityAppConfig.checkAccessSecurity.mode ===
            checkAccessSecurityModes.TABLE_NUMBER &&
            tableId)
      : tableId
  }

  handleRedirectToPayment = (e, checkId) => {
    e.stopPropagation()

    this.props.redirectTo(getRoute('PAYMENT_CHECK_VIEW'), {
      restaurantId: this.props.restaurant.id,
      checkId,
    })
  }

  handleRedirectToOrder = e => {
    e.stopPropagation()

    let url = getOrderPluginUrl()
    let queryParams = {
      l: getCurrentLanguageWithoutRegionCode(),
    }

    const tableId = getQueryParam('tableId')
    if (tableId) {
      queryParams.tableNumber = tableId
    }

    const posCheckId = getQueryParam('posCheckId')
    if (posCheckId) {
      queryParams.posCheckId = posCheckId
    }

    // add app token to the order plugin url
    const at = getQueryParam('at')
    if (at) {
      queryParams.at = at
    }

    if (!isNil(this.props.frontAppConfig) && !isNil(this.props.frontAppConfig.orderUrl)) {
      url = this.props.frontAppConfig.orderUrl

      if (url.indexOf(':restaurantId') > -1) {
        url = url.replace(':restaurantId', this.props.restaurantId)
      }
    } else {
      // keep these params for retro-compatibility
      const loginMode = getQueryParam('loginMode')

      queryParams = {
        ...queryParams,
        restaurantId: this.props.restaurantId,
        apiConsumer: getApiConsumer(),
        from: 'webapp',
        skipLayout: '[5,1]',
        modes: '[0]',
        isAsap: 'true',
        mainColor: this.props.theme.palette.primary.main.substring(1),
        loginMode: loginMode ? loginMode : 'light',
      }
    }

    this.props.redirectToExternal(url, queryParams)
  }

  render() {
    const { isFetchingRestaurant, restaurant, frontAppConfig, orderAppConfig } = this.props

    return (
      <Page showHeader={false} padding="0">
        <View
          canOrder={this.canOrder()}
          canPay={this.canPay()}
          frontAppConfig={frontAppConfig}
          orderAppConfig={orderAppConfig}
          isFetchingRestaurant={isFetchingRestaurant}
          restaurant={restaurant}
          onRedirectToOrder={this.handleRedirectToOrder}
          onRedirectToPayment={this.handleRedirectToPayment}
        />
      </Page>
    )
  }
}

export default connect(
  () => ({}),
  {
    redirectTo,
    redirectToExternal,
    resetPayment,
    resetPostPayment,
  }
)(withTheme(RestaurantPage))
