export default {
  authentication: {
    mode: 1,
    oauth2: {
      clientId: null,
      clientSecret: null,
    },
  },
  front: {
    theme: {
      logo: {
        path: null,
        display: true,
      },
      background: {
        path: null,
        display: false,
      },
    },
  },
  order: {
    bypassOpeningHours: false,
  },
  security: {
    checkAccessSecurity: {
      mode: 'TABLE_NUMBER',
    },
  },
  user: {
    login: null,
    registration: {
      form: {
        fields: [
          {
            type: 'FIRST_NAME',
            required: true,
          },
          {
            type: 'LAST_NAME',
            required: true,
          },
          {
            type: 'EMAIL',
            required: true,
          },
          {
            type: 'PHONE',
            required: false,
          },
          {
            type: 'PASSWORD',
            required: true,
          },
          {
            type: 'MARKETING_ALLOW_CONTACT',
            required: false,
          },
        ],
      },
    },
  },
}
