import ApiEndpoints from '../config/ApiEndpoints'
import PaymentErrorsList, { PaymentPdfErrorsList } from '../errors/PaymentErrorsList'

const PaymentApi = {
  get: paymentId => ({
    endpoint: ApiEndpoints.GET_PAYMENT,
    type: 'GET',
    params: {
      paymentId,
    },
    errorHandler: PaymentErrorsList,
  }),

  getPaymentPdfLink: paymentId => ({
    endpoint: ApiEndpoints.GET_PAYMENT_PDF_LINK,
    type: 'GET',
    params: {
      paymentId,
    },
    errorHandler: PaymentPdfErrorsList,
  }),

  processIrlPayment: (restaurant, check, payment) => ({
    endpoint: ApiEndpoints.POST_PAYMENT,
    type: 'POST',
    body: {
      ...payment,
      irlPayment: {
        amount: check.outstandingBalance,
        type: 'CB',
        externalId: payment.externalId,
      },
    },
    params: {
      restaurantId: restaurant.id,
      checkId: check.id,
    },
    errorHandler: PaymentErrorsList,
  }),

  processPaymentv2: (restaurantId, paymentMethod, payment, check, { selectedCreditCard }) => ({
    endpoint: ApiEndpoints.POST_PAYMENT,
    type: 'POST',
    body: {
      ...payment,
      method: paymentMethod,
      cardId: selectedCreditCard?.id,
    },
    params: {
      restaurantId,
      checkId: check.id,
    },
    updateBody: (card, body) => ({
      ...body,
      method: paymentMethod,
      cardId: card?.id,
    }),
    errorHandler: PaymentErrorsList,
  }),

  postPaymentEmail: (paymentId, body) => ({
    endpoint: ApiEndpoints.POST_PAYMENT_EMAIL,
    type: 'POST',
    params: {
      paymentId,
    },
    body,
    errorHandler: PaymentErrorsList,
  }),

  getRestaurantHistory: (restaurantId, fromDate, toDate) => ({
    endpoint: ApiEndpoints.PAYMENTS_HISTORY,
    type: 'GET',
    query: {
      restaurantId,
      fromTime: fromDate,
      toTime: toDate,
      limit: 100, // TODO: remove
    },
  }),
}

export default PaymentApi
