import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

import AppSecurityContainer from 'pmt-modules/appSecurity/components/AppSecurityContainer'
import { isRefreshingOauthToken } from 'pmt-modules/auth'
import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'
import { goBack } from 'pmt-modules/routing'

import Logger from 'pmt-utils/logger'
import { CALLBACK_EVENTS, getCallbackUri, getQueryParam } from 'pmt-utils/url'

import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import { withStyles } from 'pmt-ui/styles'
import ArrowBack from 'pmt-ui/svg-icons/navigation/arrow-back'
import { TypographyCustom } from 'pmt-ui/Typography'

import ErrorLayout from '../ErrorLayout'

const styles = theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    position: 'relative',
    padding: theme.spacing(2),
    lineHeight: '20px',
  },
  arrowBack: {
    position: 'absolute',
    fontSize: 20,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
  footer: {
    background: theme.pmt.colors.white,
    zIndex: 2, // to not be under the darkLayout
    padding: `${theme.spacing(1)}px`,
    whiteSpace: 'pre-line', // to display \n as new lines
    '&> a': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  }
})

class Page extends React.PureComponent {
  handleBack = () => {
    const currentLocation = window.location.href
    const { goBack, goBackRoute, onBack } = this.props

    if (onBack) {
      onBack()
    }

    if (isNil(goBackRoute)) {
      goBack()
    } else {
      goBackRoute()
    }

    /**
     * wait until goBack has been made,
     * then check if current location hasn't changed and redirect to callbackUri if defined
     */
    setTimeout(() => {
      if (window.location.href === currentLocation) {
        const callbackUri = getQueryParam('callback_uri')
        if (!isEmpty(callbackUri)) {
          window.location.href = getCallbackUri(callbackUri, CALLBACK_EVENTS.NO_PREVIOUS_PAGE)
        } else {
          Logger.error('[SETUP]', 'callback uri is not defined', { callbackUri })
        }
      }
    }, 500)
  }

  render() {
    const {
      children,
      classes,
      isRefreshingOauthToken,
      showHeader,
      title,
      disableBack = false,
    } = this.props

    return (
      <div className={classes.root}>
        <AppSecurityContainer>
          {({ appSecurityError }) =>
            appSecurityError ? (
              <ErrorLayout error={appSecurityError.localizedMessage} />
            ) : isRefreshingOauthToken ? (
              <LoadingBlockWrapper show />
            ) : (
              <Fragment>
                {showHeader && (
                  <div className={classes.header}>
                    {!disableBack && (
                      <ArrowBack
                        color="primary"
                        className={classes.arrowBack}
                        onClick={this.handleBack}
                      />
                    )}
                    <TypographyCustom type="147" align="center">
                      {title}
                    </TypographyCustom>
                  </div>
                )}
                <div className={classes.content}>{children}</div>
                <CustomTextsContainer>
                  {({ texts }) =>
                    !isNil(texts) &&
                    !isNil(texts.WEB_CUSTOMER__FOOTER) && (
                      <TypographyCustom
                        type="144" 
                        align="center"
                        className={classNames(
                          'custom-css-WEB_CUSTOMER__FOOTER',
                          classes.footer
                        )}
                        html={texts.WEB_CUSTOMER__FOOTER} // we allow html so they can put links
                      />
                    )
                  }
                </CustomTextsContainer>
              </Fragment>
            )
          }
        </AppSecurityContainer>
      </div>
    )
  }
}

Page.defaultProps = {
  showHeader: true,
  title: '',
  goBackRoute: null,
  onBack: null,
}

Page.propTypes = {
  onBack: PropTypes.func,

  goBackRoute: PropTypes.func,
}

const mapStateToProps = (state, ownProps) => ({
  isRefreshingOauthToken: isRefreshingOauthToken(state),
})

export default connect(
  mapStateToProps,
  {
    goBack,
  }
)(withStyles(styles)(Page))
