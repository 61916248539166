import React, { Fragment } from 'react'
import { tr } from 'pmt-modules/i18n'
import compose from 'recompose/compose'
import ui from 'pmt-modules/reduxUi'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { getQueryParam } from 'pmt-utils/url'

import ErrorBlock from 'pmt-ui/ErrorBlock'
import IconButton from 'pmt-ui/IconButton'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import MessageBlock from 'pmt-ui/MessageBlock'
import CloseIcon from 'pmt-ui/svg-icons/navigation/close'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

import { Button } from '../../../components/Button'
import CheckList from '../../../components/payment/CheckList'
import { ButtonLink } from '../../../components/Button'

const ROOT_TOP_POS = 55
const ROOT_PADDING = 16

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    justifyContent: 'flex-end',
    transition: 'all .3s ease-out',
    position: 'absolute',
    top: `${ROOT_TOP_POS}%`,
    bottom: 0,
    left: 0,
    right: 0,
    padding: `${ROOT_PADDING}px ${ROOT_PADDING}px 0`,
  },
  rootExpanded: {
    transition: 'all .3s ease-out',
    top: '0 !important',
    width: '100%',
    background: theme.pmt.colors.white,
  },
  container: {
    position: 'relative',
    background: theme.pmt.colors.white,
    borderRadius: '3px 3px 0 0',
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minHeight: 168,
  },
  containerExpanded: {
    margin: 0,
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
  close: {
    width: 'auto',
    height: 'auto',
    position: 'absolute',
    top: 0,
    right: 0,
    color: theme.palette.primary.main,
  },
  hasError: {
    flex: 1,
  },
  checkErrorMessage: {
    marginTop: theme.spacing(1),
  },
  errorBlock: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  buttonTryAgain: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(6),
  },
  checkContent: {
    flex: 1,
    display: 'flex',
    width: '100%',
    height: 'calc(100% - 48px)',
    flexDirection: 'column',
    position: 'relative',
  },
  checkContentExpanded: {
    overflow: 'hidden',
  },
  checkTitle: {
    height: theme.spacing(6),
    lineHeight: `${theme.spacing(6)}px`,
  },
  entries: {
    flex: 1,
    overflow: 'hidden',
    position: 'relative',
    marginBottom: theme.spacing(2),
  },
  entriesExpanded: {
    overflow: 'scroll',
  },
  entry: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  quantity: {
    marginRight: theme.spacing(2),
  },
  name: {
    ...theme.pmt.textEllipsis,
  },
  mask: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: 1,
    boxShadow: `1px 1px 40px 20px ${theme.pmt.colors.white}`,
    background: theme.pmt.colors.white,
    opacity: 0.9,
  },
  buttons: {
    display: 'flex',
    zIndex: theme.zIndex.snackbar,
    minHeight: theme.spacing(6),
  },
  buttonOrder: {
    width: '100%',
    marginRight: theme.spacing(2),
  },
  buttonPay: {
    width: '100%',
  },
  emptyCheckContainer: {
    paddingBottom: theme.spacing(2),
    '& button': {
      marginTop: theme.spacing(3),
      width: '100%',
    },
  },
  checkList: {
    paddingTop: theme.spacing(1),
  },
})

const OrderUnavailableBecauseClosedMessageBlock = ({ nextOpening, classes }) => {
  return (
    <>
      <MessageBlock text={tr('web_customer.restaurant.currently_closed')} classes={classes} />

      {nextOpening && (
        <MessageBlock
          text={
            nextOpening.isCurrentDay
              ? tr('web_customer.restaurant.closed_today_until', {
                  hour: nextOpening.nextOpeningHour.formattedOpening,
                })
              : tr('web_customer.restaurant.closed_until', {
                  day: nextOpening.dayName,
                  hour: nextOpening.nextOpeningHour.formattedOpening,
                })
          }
          classes={classes}
        />
      )}
    </>
  )
}

class Check extends React.Component {
  constructor(props) {
    super(props)
    this.rootRef = React.createRef()
    this.entriesRef = React.createRef()
  }

  componentDidMount() {
    this.swipedetect(this.rootRef.current, (dist, swipedir) => {
      if (this.entriesRef.current) {
        const pos = {
          bottom: (ROOT_TOP_POS * window.outerHeight) / 100,
          top: 0,
        }

        if (swipedir && !this.props.ui.expandCheck) {
          this.props.updateUI({
            expandCheckTop: swipedir === 'up' ? 0 : pos.bottom,
            expandCheck: swipedir === 'up' ? true : false,
          })
        } else if (!this.props.ui.expandCheck) {
          this.props.updateUI({
            expandCheckTop:
              dist > pos.bottom ? pos.bottom : dist < pos.to ? pos.to : dist - ROOT_PADDING,
            expandCheck:
              dist > pos.bottom ? false : dist < pos.to ? true : this.props.ui.expandCheck,
          })
        }
      }
    })
  }

  swipedetect = (el, callback) => {
    let swipedir = 'none'
    let previousDistY = null

    if (el) {
      el.addEventListener(
        'touchmove',
        e => {
          swipedir = e.changedTouches[0].pageY < previousDistY ? 'up' : 'down'
          previousDistY = e.changedTouches[0].pageY
          callback(e.changedTouches[0].pageY)
        },
        false
      )

      el.addEventListener('touchend', e => {
        callback(e.changedTouches[0].pageY, swipedir)
      })
    }
  }

  render() {
    const {
      classes,
      restaurant,
      canOrder,
      canPay,
      check,
      checkError,
      isFetchingCheck,
      reloadCheck,
      onRedirectToOrder,
      onRedirectToPayment,
      ui,
      updateUI,
    } = this.props
    const posCheckId = getQueryParam('posCheckId')

    const orderUnavailableBecauseClosed =
      this.props.restaurant &&
      this.props.restaurant.status === 'ENABLED' &&
      this.props.restaurant.hasFeatureOrder &&
      this.props.restaurant.orderSettings.onSiteSettings.enabled &&
      !(
        this.props.restaurant.orderSettings.isCurrentlyOpen ||
        this.props.orderAppConfig?.bypassOpeningHours
      )

    return (
      <div ref={this.rootRef}>
        <LoadingBlockWrapper show={isFetchingCheck} />
        {!isFetchingCheck && (
          <div
            className={classNames(classes.root, {
              [classes.rootExpanded]: ui.expandCheck,
            })}
            style={ui.expandCheckTop ? { top: `${ui.expandCheckTop}px` } : {}}
            onClick={() => check && !isEmpty(check.entries) && updateUI({ expandCheck: true })}
          >
            {(isFetchingCheck || checkError) && <div className={classes.hasError} />}
            {check && isEmpty(check.entries) ? (
              <div className={classes.emptyCheckContainer}>
                {restaurant.hasFeatureOrder && (
                  <Button disabled={!canOrder} onClick={onRedirectToOrder}>
                    <TypographyCustom type="187" skipColor>
                      {tr('web_customer.restaurant.order_button')}
                    </TypographyCustom>
                  </Button>
                )}
                {restaurant.hasFeaturePayment && (
                  <Button disabled>
                    <TypographyCustom type="187" skipColor>
                      {tr('web_customer.restaurant.pay_button')}
                    </TypographyCustom>
                  </Button>
                )}
              </div>
            ) : (
              <div
                className={classNames(classes.container, {
                  [classes.containerExpanded]: ui.expandCheck,
                })}
              >
                {ui.expandCheck && (
                  <Fragment>
                    <TypographyCustom type="207" align="center" className={classes.checkTitle}>
                      {tr('web_customer.restaurant.check.title')}
                    </TypographyCustom>
                    <IconButton
                      className={classes.close}
                      onClick={e => {
                        e.stopPropagation()
                        e.preventDefault()
                        this.entriesRef.current && this.entriesRef.current.scrollTo(0, 0)
                        updateUI({ expandCheck: false })
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Fragment>
                )}

                <div
                  className={classNames(classes.checkContent, {
                    [classes.checkContentExpanded]: ui.expandCheck,
                  })}
                >
                  {checkError ? (
                    <Fragment>
                      <TypographyCustom type="124" className={classes.checkErrorMessage}>
                        {orderUnavailableBecauseClosed ? (
                          <OrderUnavailableBecauseClosedMessageBlock
                            nextOpening={restaurant.orderSettings.nextOpening}
                            classes={{ container: classes.errorBlock }}
                          />
                        ) : // 403 = CHECK_NOT_FOUND_FOR_TABLE
                        // meaning we are using a static QR code (with table number), and not a dynamic QR code (with posCheckId)
                        // and that the waiter has not opened the table yet. But it's not a problem : it will be opened by the first order
                        // so we invite users to order

                        checkError.code === '403' && canOrder ? (
                          <MessageBlock
                            classes={{ container: classes.errorBlock }}
                            text={tr('web_customer.restaurant.no_current_check_please_order')}
                          />
                        ) : (
                          <ErrorBlock
                            error={checkError}
                            mode={ErrorBlock.Mode.CUSTOM}
                            customElement={
                              <MessageBlock classes={{ container: classes.errorBlock }} />
                            }
                          />
                        )}
                      </TypographyCustom>
                      <ButtonLink
                        color="primary"
                        className={classes.buttonTryAgain}
                        onClick={
                          orderUnavailableBecauseClosed
                            ? () => {
                                window.location.reload()
                              }
                            : reloadCheck
                        }
                      >
                        <TypographyCustom type="124" skipColor>
                          {orderUnavailableBecauseClosed || (checkError.code === '403' && canOrder)
                            ? tr('web_customer.check.refresh')
                            : tr('web_customer.restaurant.try_again')}
                        </TypographyCustom>
                      </ButtonLink>
                    </Fragment>
                  ) : (
                    check && (
                      <div
                        ref={this.entriesRef}
                        className={classNames(classes.entries, {
                          [classes.entriesExpanded]: ui.expandCheck,
                        })}
                      >
                        <CheckList
                          check={check}
                          type={CheckList.Type.LISTING}
                          classes={{ root: classes.checkList }}
                        />
                      </div>
                    )
                  )}
                  {ui.expandCheck && (
                    <div className={classNames(classes.mask, classes.maskExpanded)} />
                  )}
                </div>
                <div className={classes.buttons}>
                  {restaurant.hasFeatureOrder && (
                    <Button
                      className={classes.buttonOrder}
                      // when a posCheckId has been specified, but the check can't be retrieved,
                      // we don't want to allow user to order
                      disabled={!canOrder || (posCheckId && !check)}
                      onClick={onRedirectToOrder}
                    >
                      <TypographyCustom type="167" skipColor align="center">
                        {check && !isEmpty(check.entries)
                          ? tr('web_customer.restaurant.order_button_with_entries')
                          : tr('web_customer.restaurant.order_button')}
                      </TypographyCustom>
                    </Button>
                  )}
                  {restaurant.hasFeaturePayment && (
                    <Button
                      className={classes.buttonPay}
                      disabled={!canPay || !check}
                      onClick={e => onRedirectToPayment(e, check.id)}
                    >
                      <TypographyCustom type="167" skipColor align="center">
                        {tr('web_customer.restaurant.pay_button_small')}
                      </TypographyCustom>
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default compose(
  ui({
    state: {
      expandCheck: false,
      expandCheckTop: null,
    },
  }),
  withStyles(styles)
)(Check)
