const UserErrors = {
  FORGOT_PASSWORD: {
    default: 'global.forgot_password.unknown_email',
  },
  EDIT: {
    101: 'Vous ne pouvez pas supprimer un email lié à ce compte convive',
    default: "Une erreur est survenue lors de la mise à jour de l'utilisateur.",
  },

  POST_USER: {
    100: 'global.post_user.email_already_used',
    101: 'global.post_user.incomplete_profile',
    103: 'global.post_user.cant_create_bank_account',
    104: 'global.post_user.fid_already_used',
    105: 'global.post_user.unknown_sponsorship',
    106: 'global.post_user.cant_create_bank_wallet',
    107: 'global.post_user.phone_already_used',
    108: 'global.post_user.invalid_badge_id',
    151: 'global.post_user.invalid_registration_code',
    1026: 'global.post_user.user_light_set_password',
    default: 'global.post_user.generic_error',
  },
}

export default UserErrors
