import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Choose = props => (
  <SvgIcon viewBox="0 0 50 30" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-26.000000, -146.000000)">
        <g id="Group-3" transform="translate(10.000000, -262.000000)">
          <g id="Visa-light" transform="translate(16.000000, 408.000000)">
            <rect id="Rectangle" fill="#EFEFF4" x="0" y="0" width="50" height="30" rx="2" />
            <polyline
              id="Fill-1"
              fill="#26337A"
              points="19.4949954 19.7733803 21.0323641 10.236338 23.4897926 10.236338 21.9525645 19.7733803 19.4949954 19.7733803"
            />
            <path
              d="M30.8664355,10.4704225 C30.3805438,10.2790141 29.6163571,10.0702817 28.6643917,10.0702817 C26.2347926,10.0702817 24.5245438,11.364507 24.5103479,13.2177465 C24.4948871,14.5885915 25.7307696,15.3530986 26.6624954,15.8087324 C27.6200829,16.2757746 27.9418088,16.5746479 27.9380138,16.9922535 C27.9314078,17.6309859 27.1736866,17.923662 26.4671267,17.923662 C25.4832558,17.923662 24.9605392,17.7795775 24.1523594,17.4235211 L23.8362558,17.2716901 L23.4926037,19.4064789 C24.0656382,19.6721127 25.1279378,19.9026761 26.2304355,19.9147887 C28.8125346,19.9147887 30.4903157,18.6361972 30.5099931,16.6571831 C30.5188479,15.5701408 29.8640115,14.7461972 28.4458318,14.0659155 C27.587053,13.623662 27.0599793,13.3307042 27.066023,12.8830986 C27.0665853,12.4867606 27.5112949,12.0623944 28.4738018,12.0623944 C29.2776244,12.0494366 29.8595138,12.2350704 30.3129378,12.4288732 L30.533606,12.5378873 L30.8664355,10.4704225"
              id="Fill-2"
              fill="#26337A"
            />
            <path
              d="M34.1386498,16.3921127 C34.3423111,15.8439437 35.1176014,13.726338 35.1176014,13.726338 C35.103265,13.7515493 35.3201382,13.1739437 35.4442465,12.8164789 L35.6100991,13.6390141 C35.6100991,13.6390141 36.0816544,15.9153521 36.1787765,16.3921127 L34.1386498,16.3921127 L34.1386498,16.3921127 Z M37.1703779,10.2460563 L35.2712258,10.2460563 C34.6821682,10.2460563 34.2412535,10.4147887 33.9831982,11.0366197 L30.3337396,19.7767606 L32.9148548,19.7767606 C32.9148548,19.7767606 33.3359516,18.6009859 33.4315276,18.3429577 C33.7130553,18.3429577 36.2210829,18.3478873 36.5790714,18.3478873 C36.6527212,18.6807042 36.8783088,19.7767606 36.8783088,19.7767606 L39.1592028,19.7767606 L37.1703779,10.2460563 L37.1703779,10.2460563 Z"
              id="Fill-3"
              fill="#26337A"
            />
            <path
              d="M17.4333641,10.2443662 L15.0280806,16.746338 L14.770447,15.4246479 C14.3222235,13.901831 12.9269539,12.2512676 11.3662535,11.4242254 L13.5667512,19.7650704 L16.1675438,19.7635211 L20.03753,10.2443662 L17.4333641,10.2443662"
              id="Fill-4"
              fill="#26337A"
            />
            <path
              d="M12.7952558,10.2378873 L8.83236406,10.2378873 L8.79975576,10.435493 C11.8833479,11.225493 13.9236152,13.1323944 14.770447,15.4246479 L13.9092788,11.0425352 C13.7607143,10.438169 13.3289355,10.2590141 12.7952558,10.2378873"
              id="Fill-5"
              fill="#EC982D"
            />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
)
Choose = pure(Choose)
Choose.displayName = 'Choose'
Choose.muiName = 'SvgIcon'

export default Choose
