import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'

import { formatUserAccount, formatUserAccounts } from './format'

import { createSimpleSelector } from '../redux'

const getUserAccounts = state => state.entities.userAccounts
const getUserIdOnProps = (state, props) => props.userId
const getUserAccountIdOnProps = (state, props) => props.userAccountId

/**
 * Required props:
 * - userId
 */
export const makeIsFetchingUserUserAccount = () =>
  createSelector(
    [getUserIdOnProps, getUserAccountIdOnProps, getUserAccounts],
    (userId, userAccountId, userAccountsState) => {
      const userAccountsData = userAccountsState.get(userId, null)
      if (!isNil(userAccountsData)) {
        const userAccounts = userAccountsData.get('list', null)
        if (!isNil(userAccounts)) {
          const userAccount = userAccounts[userAccountId]
          if (!isNil(userAccount)) {
            return userAccount.isFetching
          }
        }
      }

      return false
    }
  )

/**
 * Required props:
 * - userId
 */
export const makeIsFetchingUserUserAccounts = () =>
  createSelector([getUserIdOnProps, getUserAccounts], (userId, userAccountsState) => {
    const userAccountsData = userAccountsState.get(userId)
    if (isNil(userAccountsData)) {
      return false
    }

    return userAccountsData.get('isFetching', false)
  })

/**
 * Required props:
 * - userId
 */
export const makeGetUserUserAccountsData = () =>
  createSelector([getUserIdOnProps, getUserAccounts], (userId, userAccountsState) => {
    const userAccountsData = userAccountsState.get(userId)
    if (isNil(userAccountsData)) {
      return null
    }
    return userAccountsData.toJS()
  })

/**
 * Required props:
 * - userId
 */
export const makeGetUserUserAccounts = () =>
  createSelector([getUserIdOnProps, getUserAccounts], (userId, userAccountsState) => {
    const userAccountsData = userAccountsState.get(userId)
    if (isNil(userAccountsData)) {
      return null
    }

    let userAccounts = userAccountsData.get('list')

    if (isNil(userAccounts)) {
      return null
    }

    userAccounts = Object.keys(userAccounts).map(userAccountId => userAccounts[userAccountId].data)

    return formatUserAccounts(userAccounts)
  })

/**
 * Required props:
 * - userId
 * - userAccountId
 */
export const makeGetUserUserAccount = () =>
  createSelector(
    [getUserIdOnProps, getUserAccountIdOnProps, getUserAccounts],
    (userId, userAccountId, userAccountsState) => {
      const userAccountsData = userAccountsState.get(userId)
      if (isNil(userAccountsData)) {
        return null
      }

      let userAccounts = userAccountsData.get('list')

      if (isNil(userAccounts)) {
        return null
      }

      let userAccount = userAccounts[userAccountId]

      if (isNil(userAccount)) {
        return null
      }

      userAccount = userAccount.data

      return formatUserAccount(userAccount)
    }
  )

/**
 * For convenience, for now, we consider that the user will have only 1 UserAccount
 *
 * Required props:
 * - userId
 */
export const makeGetDefaultUserUserAccount = () =>
  createSelector([getUserIdOnProps, getUserAccounts], (userId, userAccountsState) => {
    const userAccountsData = userAccountsState.get(userId)
    if (isNil(userAccountsData)) {
      return null
    }

    let userAccounts = userAccountsData.get('list')

    if (isNil(userAccounts)) {
      return null
    }

    let userAccount = userAccounts[Object.keys(userAccounts)[0]]

    if (isNil(userAccount)) {
      return null
    }

    userAccount = userAccount.data

    return formatUserAccount(userAccount)
  })

//
//
//

const updateUserAccountSelectors = createSimpleSelector(state => state.entities.updateUserAccount)

export const getUpdateUserAccountError = updateUserAccountSelectors.getError
export const getUpdateUserAccountData = updateUserAccountSelectors.getData
export const isFetchingUpdateUserAccount = updateUserAccountSelectors.isFetching
