import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'

import { TopUpContainer } from 'pmt-modules/topUp'
import { UserAccountsContainer } from 'pmt-modules/userAccount'
import { getRoute, redirectTo } from 'pmt-modules/routing'
import { resetTopUp } from 'pmt-modules/topUp'

import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'

import Page from 'app/components/Page'
import View from './View'

class TopUpConfirmPage extends React.PureComponent {
  handleGoToHistory = userAccountId => {
    this.props.resetTopUp(userAccountId)
    this.props.redirectTo(getRoute('USER_HISTORY'))
  }

  handleGoToTopUp = userAccountId => {
    this.props.resetTopUp(userAccountId)
    this.props.redirectTo(getRoute('USER_TOP_UP'))
  }

  render() {
    const { userId, resetTopUp } = this.props

    return (
      <UserAccountsContainer userId={userId}>
        {({ userAccount, isFetchingUserAccounts }) =>
          isFetchingUserAccounts || !userAccount ? (
            <LoadingBlockWrapper show />
          ) : (
            <TopUpContainer userId={userId} userAccount={userAccount}>
              {({ topUp }) => (
                <Page
                  title={tr('web_customer.top_up.confirm.title')}
                  // we must reset the top up data on back, otherwise the idempotency is the same
                  // and while the user can make a new top-up, it will be taken as the previous one,
                  // but the user is not notified about this.
                  onBack={() => {
                    resetTopUp()
                  }}
                >
                  <View
                    handleGoToHistory={() => this.handleGoToHistory(userAccount.id)}
                    handleGoToTopUp={() => this.handleGoToTopUp(userAccount.id)}
                    topUp={topUp}
                  />
                </Page>
              )}
            </TopUpContainer>
          )
        }
      </UserAccountsContainer>
    )
  }
}

TopUpConfirmPage.defaultProps = {
  userId: 'me',
}

const mapStateToProps = () => {}

const mapDispatchToProps = {
  resetTopUp,
  redirectTo,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopUpConfirmPage)
