import { tr } from 'pmt-modules/i18n'
import React from 'react'
// import PropTypes from 'prop-types'
import isNil from 'lodash/isNil'

import { HistoryType, HistoryTypeContainer } from 'pmt-modules/userHistory'

import { withStyles } from 'pmt-ui/styles'
import { TableRow, TableCell } from 'pmt-ui/Table'
import { TypographyCustom } from 'pmt-ui/Typography'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'

import HistoryList from '../components/HistoryList'

const styles = theme => ({
  row: {
    height: 48,
  },
  cell: {
    padding: '0 5px !important',
  },
})

const TopUpHistoryContent = ({ isFetchingHistory, list, pagination, onLoadMore, classes }) => {
  let finalList = []

  if (!isNil(list)) {
    finalList = list.filter(topUp => topUp.isStatusSucceeded)
  }

  return (
    <HistoryList
      key={'topup-history-list'}
      list={finalList}
      pagination={pagination}
      onLoadMore={onLoadMore}
      emptyList={
        !isFetchingHistory &&
        tr('web_customer.history.top_up.no_transaction', {
          fr: 'Aucune transaction',
          context: '',
          desc: 'Label when no transaction has been returned on top up history',
        })
      }
      rowRenderer={topUp => (
        <TableRow key={`topup-history-${topUp.id}`} className={classes.row}>
          <TableCell className={classes.cell} width={70}>
            <TypographyCustom type="144">{topUp.formattedSimpleCreationDate}</TypographyCustom>
          </TableCell>
          <TableCell className={classes.cell}>
            <TypographyCustom type="144">{topUp.typeLabel}</TypographyCustom>
          </TableCell>
          <TableCell className={classes.cell} width={80}>
            <TypographyCustom type="144" align="right">+{topUp.amountPriceFormatted}</TypographyCustom>
          </TableCell>
        </TableRow>
      )}
    />
  )
}

const TopUpHistory = ({ className, classes, userId }) => (
  <HistoryTypeContainer userId={userId} historyType={HistoryType.TOP_UP}>
    {props => (
      <div className={className}>
        <LoadingBlockWrapper show={props.isFetchingHistory} />
        <TopUpHistoryContent classes={classes} {...props} />
      </div>
    )}
  </HistoryTypeContainer>
)

export default withStyles(styles)(TopUpHistory)
