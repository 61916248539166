import React from 'react'
import { connect } from 'react-redux'

import { getAppSecurityError } from '../../selectors'

class AppSecurityContainer extends React.PureComponent {
  render() {
    const { appSecurityError, children } = this.props

    return children({
      appSecurityError,
    })
  }
}

AppSecurityContainer.propTypes = {}

const mapStateToProps = state => {
  return {
    appSecurityError: getAppSecurityError(state),
  }
}

export default connect(mapStateToProps, {})(AppSecurityContainer)
