import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Milk = props => (
  <SvgIcon {...props}>
    <path d="M 10 2 C 9.476563 2 8.941406 2.183594 8.5625 2.5625 C 8.183594 2.941406 8 3.476563 8 4 L 8 5 C 8 5.398438 8.21875 5.757813 8.4375 6.09375 C 8.394531 6.15625 8.324219 6.183594 8.28125 6.25 C 7.898438 6.832031 7.515625 7.558594 7.1875 8.34375 C 6.53125 9.910156 6 11.605469 6 12.90625 L 6 19 C 6 20.644531 7.355469 22 9 22 L 15 22 C 16.644531 22 18 20.644531 18 19 L 18 12.90625 C 18 11.605469 17.46875 9.910156 16.8125 8.34375 C 16.484375 7.558594 16.101563 6.832031 15.71875 6.25 C 15.675781 6.183594 15.605469 6.15625 15.5625 6.09375 C 15.78125 5.757813 16 5.398438 16 5 L 16 4 C 16 3.476563 15.816406 2.941406 15.4375 2.5625 C 15.058594 2.183594 14.523438 2 14 2 Z M 10 4 L 14 4 L 14 5 L 10.03125 5 C 10.019531 5 10.011719 5 10 5 Z M 10.21875 7 L 13.78125 7 C 13.851563 7.074219 13.949219 7.199219 14.0625 7.375 C 14.335938 7.789063 14.640625 8.421875 14.9375 9.125 C 15.53125 10.535156 16 12.308594 16 12.90625 L 16 19 C 16 19.554688 15.554688 20 15 20 L 9 20 C 8.445313 20 8 19.554688 8 19 L 8 12.90625 C 8 12.308594 8.46875 10.535156 9.0625 9.125 C 9.359375 8.421875 9.664063 7.789063 9.9375 7.375 C 10.050781 7.199219 10.148438 7.074219 10.21875 7 Z " />
  </SvgIcon>
)
Milk = pure(Milk)
Milk.displayName = 'Milk'
Milk.muiName = 'SvgIcon'

export default Milk
