import register from 'pmt-modules/registration/forms/register'
import login from 'pmt-modules/auth/forms/login'
import forgotPassword from 'pmt-modules/forgotPassword/forms/forgotPassword'
import userLight from 'pmt-modules/registration/forms/userLight'
import incognito from 'pmt-modules/registration/forms/incognito'
import creditCard from 'pmt-modules/creditCard/forms/create'

const forms = [register, login, forgotPassword, userLight, incognito, creditCard]

export default forms
