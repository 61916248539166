import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'

import withCheck from 'pmt-modules/payment/components/withCheck'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import { setPaymentTypeEntries, fetchRefreshCheck } from 'pmt-modules/payment'
import { redirectTo, getRoute } from 'pmt-modules/routing'

import Page from '../../../components/Page'

import View from './View'

/**
 * https://paymytable.atlassian.net/browse/PP-898
 * https://app.zeplin.io/project/5b616247d86fcf41520410cc/screen/5b684e7ea260407d83d690b9
 * https://app.zeplin.io/project/5b616247d86fcf41520410cc/screen/5bc4427cdf29ce0aa9aae070
 * https://app.zeplin.io/project/5b616247d86fcf41520410cc/screen/5bc4427c1d99c2190ca1fbd2
 * https://app.zeplin.io/project/5b616247d86fcf41520410cc/screen/5bc4427c13e0040a1a867e44
 */
@withCheck
@withRestaurant
class ChoosePage extends React.Component {
  componentDidMount() {
    this.props.setPaymentTypeEntries()
  }

  handleNext = () => {
    this.props.fetchRefreshCheck(this.props.restaurantId, this.props.checkId, true)
  }

  render() {
    const { check, payment, isFetchingCheck, restaurantId, checkId, redirectTo } = this.props

    return (
      <Page
        title={tr('web_customer.payment.choose.title')}
        goBackRoute={() => redirectTo(getRoute('PAYMENT_CHECK_VIEW'), { restaurantId, checkId })}
      >
        <View
          isFetchingCheck={isFetchingCheck}
          check={check}
          payment={payment}
          onSubmit={this.handleNext}
        />
      </Page>
    )
  }
}

ChoosePage.propTypes = {}

const mapStateToProps = (state, props) => ({})

export default connect(
  mapStateToProps,
  {
    setPaymentTypeEntries,
    fetchRefreshCheck,
    redirectTo,
  }
)(ChoosePage)
