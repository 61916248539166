import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { withStyles } from 'pmt-ui/styles'
import InfoOutline from 'pmt-ui/svg-icons/action/info-outline'
import ErrorOutline from 'pmt-ui/svg-icons/action/error-outline'
import MessageBlockSdk from 'pmt-ui/MessageBlock'
import { TypographyCustom } from 'pmt-ui/Typography'

const styles = theme => ({
  root: {},
  errorRoot: {
    color: theme.pmt.status.error,
  },
  infoRoot: {
    color: theme.pmt.status.info,
  },
  warningRoot: {
    color: theme.pmt.status.warning,
  },
})

const Type = {
  INFO: 'TYPE::INFO',
  ERROR: 'TYPE::ERROR',
  WARNING: 'TYPE::WARNING',
}

/**
 * error will be displayed with the color defined in theme: theme.pmt.status.error
 * be sure to set it properly in your config theme file
 */
const MessageBlock = ({ classes, type, text }) => {
  switch (type) {
    case Type.ERROR:
      return (
        <MessageBlockSdk
          classes={{
            root: clsx(classes.root, classes.errorRoot),
          }}
          icon={<ErrorOutline />}
          text={
            <TypographyCustom skipColor type="164">
              {text}
            </TypographyCustom>
          }
        />
      )
    case Type.WARNING:
      return (
        <MessageBlockSdk
          classes={{
            root: clsx(classes.root, classes.warningRoot),
          }}
          icon={<ErrorOutline />}
          text={
            <TypographyCustom skipColor type="164">
              {text}
            </TypographyCustom>
          }
        />
      )

    case Type.INFO:
    default:
      return (
        <MessageBlockSdk
          classes={{
            root: clsx(classes.root, classes.infoRoot),
          }}
          icon={<InfoOutline />}
          text={
            <TypographyCustom skipColor type="164">
              {text}
            </TypographyCustom>
          }
        />
      )
  }
}

MessageBlock.defaultProps = {
  type: Type.INFO,
}

MessageBlock.propTypes = {
  // type of message block to display
  type: PropTypes.string,

  // text to display
  text: PropTypes.string,
}

const MessageBlockComponent = withStyles(styles)(MessageBlock)

MessageBlockComponent.Type = Type

export default MessageBlockComponent
