import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'

import { withAppConfig } from 'pmt-modules/appConfig'
import {
  AuthMode,
  getAuthCookie,
  getIncognitoCookie,
  getUserLightCookie,
  haveAuthCredentials,
} from 'pmt-modules/auth'
import { getAuthenticatedUser } from 'pmt-modules/authenticatedUser/selectors'
import {
  postUserCreditCardRegistrationType,
  resetUserCreditCardRegistrationTypeError,
} from 'pmt-modules/creditCard'
import { FormType, withForms } from 'pmt-modules/form'
import { PaymentType } from 'pmt-modules/global'
import {
  getPaymentError,
  isFetchingPostPaymentAtTable,
  resetPostPayment,
  selectTips,
} from 'pmt-modules/payment'
import withCheck from 'pmt-modules/payment/components/withCheck'
import { isFetchingRegister, registerAsIncognito } from 'pmt-modules/registration'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import { withRestaurantsGroupMine } from 'pmt-modules/restaurantsGroup/components'
import { getRoute, redirectTo, replaceWith } from 'pmt-modules/routing'
import { withUserMe } from 'pmt-modules/userMe'
import { processPaymentv2 } from 'pmt-modules/webCustomer'
import { LoadingBlock } from 'pmt-ui/LoadingBlock'

import Page from 'app/components/Page'

import View from './View'

/**
 * https://app.zeplin.io/project/5b616247d86fcf41520410cc/screen/5b684e7fd64ef37e2f54bc2d
 * https://app.zeplin.io/project/5b616247d86fcf41520410cc/screen/5bfd651553c3184cb33011cb
 * https://paymytable.atlassian.net/browse/PP-897
 */
@withAppConfig
@withCheck
@withForms([FormType.CREDIT_CARD])
@withRestaurant
@withUserMe
@withRestaurantsGroupMine()
class Payment extends React.Component {
  constructor(props) {
    super(props)

    if (!this.isCorrectlyAuthenticated(props.appConfig.authentication)) {
      // user is not authenticated, or not authenticated with the appropriate mode (normal/userLight/incognito) => make him authenticate
      if (getUserLightCookie()) {
        // user is light, but should be normal => force Registering
        props.replaceWith(getRoute('REGISTER'), null, {
          redirectTo: window.location.toString(),
          restaurantId: props.restaurant?.id,
        })
      } else {
        // user is totally unknown => login
        props.replaceWith(getRoute('LOGIN'), null, {
          redirectTo: window.location.toString(),
          restaurantId: props.restaurant?.id,
        })
      }
    }
  }

  componentWillUnmount() {
    this.props.resetUserCreditCardRegistrationTypeError()
  }

  isCorrectlyAuthenticated = authenticationSettings => {
    // cf. getAuthorizationFromModeAndCookies
    if (authenticationSettings.mode === AuthMode.NORMAL) {
      if (getAuthCookie() != null) {
        return true // already authenticated as normal
      } else if (authenticationSettings.allowIncognito && getIncognitoCookie()) {
        return true // already authenticated as incognito
      }
    } else if (authenticationSettings.mode === AuthMode.LIGHT) {
      if (getAuthCookie() != null) {
        return true // already authenticated as normal
      } else if (getUserLightCookie() != null) {
        return true // already authenticated as userLight
      } else if (authenticationSettings.allowIncognito && getIncognitoCookie()) {
        return true // already authenticated as incognito
      }
    } else if (authenticationSettings.mode === AuthMode.INCOGNITO_ONLY) {
      if (getAuthCookie() != null) {
        // allow authenticated TEST user even on incognito_only apiConsumer
        // so a user can use the login page to authenticate as TEST user, and access test restaurants
        return true // already authenticated as normal (eg. test user)
      } else if (getIncognitoCookie() != null) {
        return true // already authenticated as incognito
      }
    }
    return false
  }

  handlePostPayment = (paymentMethod, { selectedCreditCard }) => {
    const { restaurantId, payment, check } = this.props

    this.props.processPaymentv2(restaurantId, paymentMethod, payment, check, { selectedCreditCard })
  }

  handlePostNewCardPayment = (userId, formData) => {
    const {
      restaurantId,
      payment,
      check,
      postUserCreditCardRegistrationType,
      resetUserCreditCardRegistrationTypeError,
    } = this.props

    resetUserCreditCardRegistrationTypeError()

    postUserCreditCardRegistrationType(
      userId,
      restaurantId,
      { creditCard: formData },
      {
        dispatchPayment: true,
        restaurantId,
        payment,
        check,
      }
    )
  }

  render() {
    const {
      payment,
      paymentError,
      formsAreValid,
      formsErrors,
      formsData,
      isFetchingPostPaymentAtTable,
      resetPostPayment,
      appConfig,
      checkId,
      restaurant,
      restaurantsGroup,
      restaurantId,
      redirectTo,
      selectTips,
      authenticatedUser,
      isFetchingRegister,
    } = this.props

    if (!this.isCorrectlyAuthenticated(appConfig.authentication) && !isFetchingRegister) {
      return null
    }

    return (
      <Page
        title={tr(
          (payment.type === PaymentType.ALL && 'web_customer.payment.all.title') ||
            (payment.type === PaymentType.SHARE && 'web_customer.payment.share.title') ||
            (payment.type === PaymentType.ENTRIES && 'web_customer.payment.choose.title')
        )}
        goBackRoute={() =>
          redirectTo(
            getRoute(
              payment.type === PaymentType.ALL
                ? 'PAYMENT_CHECK_VIEW'
                : payment.type === PaymentType.SHARE
                  ? 'PAYMENT_SHARE'
                  : 'PAYMENT_CHOOSE'
            ),
            { restaurantId, checkId }
          )
        }
      >
        <LoadingBlock show={!restaurant || isFetchingRegister}>
          {() => (
            <View
              appConfig={appConfig}
              isFetching={isFetchingPostPaymentAtTable}
              formsAreValid={formsAreValid}
              formsErrors={formsErrors}
              formsData={formsData}
              paymentError={paymentError}
              payment={payment}
              onPostPayment={this.handlePostPayment}
              onPostNewCardPayment={this.handlePostNewCardPayment}
              resetPostPayment={resetPostPayment}
              user={authenticatedUser}
              restaurant={restaurant}
              restaurantsGroup={restaurantsGroup}
              selectTips={selectTips}
            />
          )}
        </LoadingBlock>
      </Page>
    )
  }
}

const mapStateToProps = state => ({
  haveAuthCredentials: haveAuthCredentials(state),
  isFetchingPostPaymentAtTable: isFetchingPostPaymentAtTable(state),
  paymentError: getPaymentError(state),
  authenticatedUser: getAuthenticatedUser(state),
  isFetchingRegister: isFetchingRegister(state),
})

export default connect(
  mapStateToProps,
  {
    postUserCreditCardRegistrationType,
    processPaymentv2,
    replaceWith,
    redirectTo,
    selectTips,
    resetUserCreditCardRegistrationTypeError,
    resetPostPayment,
    registerAsIncognito,
  }
)(Payment)
