import { tr } from 'pmt-modules/i18n'
import React, { useState } from 'react'
import DialogContainer from 'pmt-ui/DialogContainer'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import LockerIcon from './LockerIcon'
import { getPublicUrl } from 'pmt-modules/environment'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import { getHistory } from 'pmt-modules/routing'

const useStyles = makeStyles(theme => ({
  icons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  text: {
    marginTop: theme.spacing(4),
    fontSize: 18,
    lineHeight: '26px',

    '& p': {
      marginBottom: theme.spacing(1),
    },
  },
  btn: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
}))

function PaymentAsyncRedirectTutorialDialog({ onSubmit, label1, label2 }) {
  const classes = useStyles()

  const [redirecting, setRedirecting] = useState(false)

  return (
    <DialogContainer defaultOpen title={''} disableDefaultOnClose>
      <LoadingBlockWrapper show={redirecting} />
      <div className={classes.icons}>
        <LockerIcon width={66} height={66} />
        <img
          src={`${getPublicUrl()}/img/CreditCardsIcon.png`}
          width={90}
          height={60}
          alt="credit cards"
        />
      </div>
      <div className={classes.text}>
        <p>{label1}</p>
        <p>{label2}</p>
      </div>

      <Button
        disabled={redirecting}
        className={classes.btn}
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => {
          setRedirecting(true)
          // indicate in the browser history that we displayed the dialog once
          // so if the user's use the "back" browser's button to go back here, he will be redirected to the previous page (payment page)
          // (skipping this dialog page)

          // payment page -> asyncDialog -> psp payment page -> (hit "back") -> payment page

          let history = getHistory()
          if (history) {
            let currentPath = history.location.pathname + history.location.search
            // keeping the url, just adding a state
            history.replace(currentPath, { popupAlreadyDisplayed: true })
          }

          onSubmit()
        }}
      >
        {tr('PaymentAsyncRedirectTutorialDialog.confirm')}
      </Button>
    </DialogContainer>
  )
}

export default PaymentAsyncRedirectTutorialDialog
