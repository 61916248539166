import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import classNames from 'classnames'

import isNil from 'lodash/isNil'
import { withStyles } from 'pmt-ui/styles'
import withWidth, { isWidthDown } from 'pmt-ui/utils/withWidth'

const styles = theme => ({
  root: {},
  padding: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  white: {
    background: theme.pmt.colors.white,
  },
  lightgrey: {
    background: theme.pmt.colors.grey100,
  },
  grey: {
    background: theme.pmt.colors.greyBackground,
  },
})

const Type = {
  WHITE: 'WHITE',
  LIGHTGREY: 'LIGHTGREY',
  GREY: 'GREY',
}

const BlockContainer = ({
  children,
  type,
  mobileType,
  className,
  padding,
  mobilePadding,
  disablePadding,
  disableMobilePadding,
  classes,
  width,
  ...otherProps
}) => {
  const isMobile = isWidthDown('sm', width)

  let typeToUse = type
  if (isMobile && mobileType) {
    typeToUse = mobileType
  }
  let classBackground = ''
  switch (typeToUse) {
    case Type.WHITE:
      classBackground = classes.white
      break
    case Type.LIGHTGREY:
      classBackground = classes.lightgrey
      break
    case Type.GREY:
      classBackground = classes.grey
      break
    default:
      classBackground = classes.white
      break
  }

  let style = {}

  if (!isNil(padding) && !disablePadding) {
    style.padding = padding
  }

  if (!isNil(mobilePadding) && isMobile && !disableMobilePadding) {
    style.padding = mobilePadding
  }

  return (
    <div
      className={classNames(classes.root, classBackground, className, {
        // put padding if:
        // - disablePadding is true
        // - disableMobilePadding is false
        // -  disableMobilePadding is true and we are not on mobile
        // - there is no custom padding given
        [classes.padding]:
          !disablePadding && (!isMobile || !disableMobilePadding) && isNil(padding),
      })}
      style={style}
      {...otherProps}
    >
      {children}
    </div>
  )
}

BlockContainer.defaultProps = {
  disablePadding: false,
  disableMobilePadding: false,
}

BlockContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  // BlockContainer.Type
  type: PropTypes.string,
  // BlockContainer.Type
  // define a different type for mobile
  mobileType: PropTypes.string,
  className: PropTypes.string,
  // custom padding for mobile and desktop
  padding: PropTypes.any,
  // custom padding for mobile only
  mobilePadding: PropTypes.any,
  disablePadding: PropTypes.bool,
  disableMobilePadding: PropTypes.bool,
}

const BlockContainerToExport = compose(
  withWidth(),
  withStyles(styles)
)(BlockContainer)

BlockContainerToExport.Type = Type

export default BlockContainerToExport
