import { tr } from 'pmt-modules/i18n'
import React from 'react'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

import { withStyles } from 'pmt-ui/styles'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import Price from 'pmt-ui/Price'
import { TypographyCustom } from 'pmt-ui/Typography'

import CheckList from 'app/components/payment/CheckList'
import { Button } from 'app/components/Button'

const styles = theme => ({
  introduction: {
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
  checkList: {
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: theme.spacing(1),
    overflowY: 'scroll',
    flexGrow: 1,
  },
  footer: {
    padding: theme.spacing(2),
    background: theme.pmt.colors.greyBackground,
    zIndex: 5,
  },
  selectionTotalLabel: {
    float: 'left',
    lineHeight: '18px',
  },
  selectionTotalAmount: {
    float: 'right',
  },
  button: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  totalAmountContainer: {
    color: theme.pmt.colors.white,
  },
  totalAmount: {
    float: 'right',
    marginLeft: theme.spacing(0.5),
  },
})
/**
 * @mockup N/A
 */
const View = ({ isFetchingCheck, payment, check, onSubmit, classes }) => {
  return (
    <React.Fragment>
      <LoadingBlockWrapper show={isFetchingCheck} />
      {!isNil(check) && (
        <React.Fragment>
          <TypographyCustom type="167" className={classes.introduction}>
            {tr('web_customer.payment.choose.introduction')}
          </TypographyCustom>
          <CheckList
            check={check}
            payment={payment}
            type={CheckList.Type.SELECTION}
            classes={{ root: classes.checkList, entry: classes.entry }}
          />
          <div className={classes.footer}>
            <div className="u-overflowHidden">
              <TypographyCustom type="147" className={classes.selectionTotalLabel}>
                {tr('web_customer.payment.choose.total_selection')}
              </TypographyCustom>
              <TypographyCustom type="187" className={classes.selectionTotalAmount}>
                <Price value={payment.amountFormatted} />
              </TypographyCustom>
            </div>
            <Button
              className={classes.button}
              onClick={onSubmit}
              disabled={isEmpty(payment.items) || isFetchingCheck}
            >
              <TypographyCustom
                type="187"
                component="span"
                className={classes.totalAmountContainer}
              >
                {tr('web_customer.payment.choose.submit')}
                <Price value={payment.amountFormatted} className={classes.totalAmount} />
              </TypographyCustom>
            </Button>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default withStyles(styles)(View)
