import React from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'
import { withStyles } from 'pmt-ui/styles'
import ErrorBlock from 'pmt-ui/ErrorBlock'

const styles = theme => ({
  root: {
    display: 'flex',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  icon: {
    display: 'flex',
    marginRight: theme.spacing(1),
  },
  variant_contained: {}, // nothing
  variant_text: {
    background: 'none!important',
    border: 'none!important',
    '& *': {
      color: 'inherit',
    },
  },
})

const MessageBlock = ({ classes, className, icon, variant = 'contained', text, ...otherProps }) => (
  <div
    className={clsx(
      classes.root,
      className,
      clsx({
        [classes.variant_contained]: variant === 'contained',
        [classes.variant_text]: variant === 'text',
      })
    )}
    {...otherProps}
  >
    <div className={classes.container}>
      {icon && <div className={classes.icon}>{icon}</div>}
      {text}
    </div>
  </div>
)

MessageBlock.propTypes = {
  // icon element
  icon: PropTypes.element,

  // text to display, can be a string or an element
  // e.g.: <Typography />
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,

  variant: PropTypes.string,
}

const toExport = withStyles(styles)(MessageBlock)

toExport.Error = ({ error, ...otherProps }) => (
  <ErrorBlock
    error={error}
    mode={ErrorBlock.Mode.CUSTOM}
    customElement={<toExport type={MessageBlock.Type.ERROR} />}
    {...otherProps}
  />
)
export default toExport
