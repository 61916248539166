const ApiConsumerErrors = {
  PATCH: (requestOptions, json, response) => {
    if (json?.extra) {
      return json?.extra?.details?.errors?.map(e => e.message).join(', ')
    } else {
      return json?.message
    }
  },
}

export default ApiConsumerErrors
