import React from 'react'
import isNil from 'lodash/isNil'
import { compose } from 'redux'
import { tr } from 'pmt-modules/i18n'

import { UserLightFormView } from 'pmt-modules/registration/forms/userLight'
import { CguLabel } from 'pmt-ui/Cgu'
import { FormType, withForm } from 'pmt-modules/form'

import ErrorBlock from 'pmt-ui/ErrorBlock'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

import { Button } from '../../../components/Button'

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
  },
  subheader: {
    color: theme.pmt.colors.grey500,
  },
  allowIncognito: {
    paddingTop: theme.spacing(2),
  },
  link: {
    display: 'inline',
    fontSize: 14,
    lineHeight: '14px',
    marginTop: -2,
  },
  captionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.pmt.status.inactive,
  },
  captionTitle: {
    marginBottom: theme.spacing(1),
  },
})

const UserLightForm = ({
  classes,
  isFetchingRegister,
  formIsValid,
  registerError,
  onSubmitLogin,
  onRedirectToIncognito,
  formData,
  allowIncognito,
  restaurantsGroup,
  customTexts: texts,
}) => {
  const captionTitle =
    !isNil(texts) && !isNil(texts.ORDER__USERLIGHT__CAPTION_TITLE)
      ? texts.ORDER__USERLIGHT__CAPTION_TITLE
      : tr('web_customer.login.user_light.caption.title')

  const captionText =
    !isNil(texts) && !isNil(texts.ORDER__USERLIGHT__CAPTION_TEXT)
      ? texts.ORDER__USERLIGHT__CAPTION_TEXT
      : tr('web_customer.login.user_light.caption.text')

  return (
    <div className={classes.root}>
      <form
        onSubmit={event => {
          event.preventDefault()
          onSubmitLogin(formData)
        }}
      >
        {!isNil(texts) &&
          !isNil(texts.LOGIN__SUBHEADER) && (
            <TypographyCustom type="144" className={classes.subheader}>
              {texts.LOGIN__SUBHEADER}
            </TypographyCustom>
          )}

        <div className={classes.captionWrapper}>
          {captionTitle && (
            <TypographyCustom type="167" className={classes.captionTitle} skipColor>
              {captionTitle}
            </TypographyCustom>
          )}

          {captionText && (
            <TypographyCustom type="144" skipColor>
              {captionText}
            </TypographyCustom>
          )}

          {allowIncognito && (
            <div className={classes.allowIncognito}>
              <Button
                size="small" // small height
                className="u-sizeFull" // large width
                onClick={e => {
                  e.stopPropagation()
                  onRedirectToIncognito()
                }}
              >
                <TypographyCustom type="167" skipColor>
                  {!isNil(texts) && !isNil(texts.ORDER__INCOGNITO__LINK)
                    ? texts.ORDER__INCOGNITO__LINK
                    : tr('web_customer.login.continue_as_incognito.from_LIGHT.link')}
                </TypographyCustom>
              </Button>
            </div>
          )}
        </div>

        <LoadingBlockWrapper show={isFetchingRegister} />

        <ErrorBlock mode={ErrorBlock.Mode.SNACKBAR} error={registerError} />

        <UserLightFormView
          cguLabel={<CguLabel restaurantsGroup={restaurantsGroup} linkClass={classes.link} />}
        />

        <Button type="submit" className="u-marginTop20 u-sizeFull" disabled={!formIsValid}>
          <TypographyCustom type="167" skipColor>
            {tr('web_customer.login.user_light')}
          </TypographyCustom>
        </Button>
      </form>
    </div>
  )
}

export default compose(
  withStyles(styles),
  withForm(FormType.USER_LIGHT)
)(UserLightForm)
