import React from 'react'

/**
 * Incognito : no email, no badge id, no password
 */
import { FirstName, LastName, PhoneNumber } from 'pmt-modules/user/forms/elements/index'

export const fieldsType = {
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  PHONE: 'PHONE',
}

/**
 * map fields retrieved from api consumer setting to formData key
 * and component to display in form view
 */
export const getFieldsMap = () => ({
  FIRST_NAME: {
    key: 'firstName',
    component: <FirstName />,
  },
  LAST_NAME: {
    key: 'lastName',
    component: <LastName />,
  },
  PHONE: {
    key: 'phone',
    component: <PhoneNumber />,
  },
})

export const getField = field => getFieldsMap()[field] || null
