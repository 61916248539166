import { createListFormatter, createFormatter } from 'pmt-utils/format'
import { formatDate } from 'pmt-utils/date'

import {
  ApiConsumerStatus,
  getApiConsumerStatusLabel,
  getApiConsumerStatusType,
  getApiConsumerPresetLabel,
  getApiConsumerPresetExplaination,
} from './constants'

const formatData = apiConsumer => {
  if (apiConsumer.creationDate) {
    apiConsumer.creationDateFormatted = formatDate(apiConsumer.creationDate)
  } else {
    apiConsumer.creationDateFormatted = 'N/A'
  }

  apiConsumer.statusLabel = getApiConsumerStatusLabel(apiConsumer.status)
  apiConsumer.statusType = getApiConsumerStatusType(apiConsumer.status)

  apiConsumer.isStatusCreated = apiConsumer.status === ApiConsumerStatus.CREATED
  apiConsumer.isStatusEnabled = apiConsumer.status === ApiConsumerStatus.ENABLED
  apiConsumer.isStatusDisabled = apiConsumer.status === ApiConsumerStatus.DISABLED
  apiConsumer.isStatusArchived = apiConsumer.status === ApiConsumerStatus.ARCHIVED

  apiConsumer.isInactive = apiConsumer.isStatusArchived || apiConsumer.isStatusDisabled

  apiConsumer.presetLabel = getApiConsumerPresetLabel(apiConsumer.preset)
  apiConsumer.presetExplaination = getApiConsumerPresetExplaination(apiConsumer.preset)

  return apiConsumer
}

export const formatApiConsumer = createFormatter(formatData)

export const formatApiConsumers = createListFormatter(formatApiConsumer)
