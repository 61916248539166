import { createAction } from 'pmt-modules/redux'

import UserApi from '../api/calls/UserApi'

import { createApiCallAction, createApiEnumAction } from '../api/utils'

export const ForgotPasswordAction = {
  ...createApiEnumAction('FORGOT_PASSWORD::GET'),
  RESET_DATA: 'RESET_DATA',
}

export const forgotPassword = username =>
  createApiCallAction(ForgotPasswordAction, UserApi.getRecoverPassword(username), {
    username,
  })

export const resetForgotPasswordData = () => createAction(ForgotPasswordAction.RESET_DATA, {})
