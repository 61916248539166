import React from 'react'
import PropTypes from 'prop-types'

import isNil from 'lodash/isNil'

// https://blog.logrocket.com/advanced-react-router-concepts-code-splitting-animated-transitions-scroll-restoration-recursive-17096c0cf9db
class ScrollToTop extends React.Component {
  constructor(props) {
    super(props)
    // scroll to top when arriving on a new page
    window.scrollTo(0, 0)
  }

  componentDidUpdate(prevProps) {
    // Note: we can't compare the location object, since it changes when the anchor change
    if (
      this.props.location.pathname !== prevProps.location.pathname &&
      (isNil(this.props.isInactive) || !this.props.isInactive(prevProps, this.props))
    ) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const { WrappedComponent, ...otherProps } = this.props
    return <WrappedComponent {...otherProps} />
  }
}

ScrollToTop.defaultProps = {
  isInactive: null,
}

ScrollToTop.propTypes = {
  isInactive: PropTypes.func,
}

export default ScrollToTop
