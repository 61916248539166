import React from 'react'

import { PayZenCallbackStatus } from 'pmt-modules/payment'
import { getRoute } from 'pmt-modules/routing'

import LoadingBlock from 'pmt-ui/LoadingBlock'
import { withStyles } from 'pmt-ui/styles'

import { getAbsolutePath } from 'pmt-utils/url'

import BlockContainer from 'app/components/BlockContainer'

const styles = theme => ({
  blockContainer: {
    width: '100%',
    height: '100%',
  },
})

class PaymentCancelPage extends React.Component {
  constructor(props) {
    super(props)
    window.parent.postMessage(
      { status: PayZenCallbackStatus.CANCEL },
      getAbsolutePath(getRoute('ORDER__PAYMENT__CANCEL'))
    )
  }

  shouldComponentUpdate() {
    return false
  }

  render() {
    const { classes } = this.props
    return (
      <BlockContainer className={classes.blockContainer} type={BlockContainer.Type.WHITE}>
        <LoadingBlock show />
      </BlockContainer>
    )
  }
}

export default withStyles(styles)(PaymentCancelPage)
