import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Share = props => (
  <SvgIcon {...props}>
    <path
      d="M18.1236747,11.094071 C18.1245571,11.0419865 18.125,10.989795 18.125,10.9375 C18.125,6.0370094 14.2353826,2.04498762 9.375,1.88028694 L9.375,11.25 L18.1197131,11.25 C18.1214706,11.198133 18.1227924,11.1461555 18.1236747,11.094071 C18.0401091,16.026925 14.0152853,20 9.0625,20 C4.05741945,20 0,15.9425805 0,10.9375 C0,5.93241945 4.05741945,1.875 9.0625,1.875 C14.0675805,1.875 18.125,5.93241945 18.125,10.9375 C18.125,10.9897953 18.1245571,11.0419871 18.1236747,11.0940719 Z"
      id="Combined-Shape"
      opacity="0.5"
    />
    <path
      d="M11.25,0.00528693677 C16.1103826,0.169987625 20,4.1620094 20,9.0625 C20,9.16708994 19.9982282,9.27126607 19.9947131,9.375 L11.25,9.375 L11.25,0.00528693677 Z"
      id="Combined-Shape"
    />
  </SvgIcon>
)
Share = pure(Share)
Share.displayName = 'Share'
Share.muiName = 'SvgIcon'

export default Share
