import { createMiddleware } from '../redux'

import { getRoute, replaceWith } from 'pmt-modules/routing'

import { CreateUserPostUserAction } from './actions'

const userCreatedMiddleware = createMiddleware(
  CreateUserPostUserAction.SUCCESS,
  ({ action, next }) => {
    next(
      replaceWith(
        getRoute('BO_USER_ADD_STEP_ACCOUNT'),
        {
          userId: action.response.id,
        },
        {
          // default company id to use
          // Set on query params when we redirect to the user add page from a page that contains a
          // company.
          companyId: action.data.props.companyId,
        }
      )
    )
  }
)

export const proCreateUserMiddlewares = [userCreatedMiddleware]
