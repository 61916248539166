import { LOCATION_CHANGE } from 'pmt-modules/routing'

import Immutable from 'immutable'

import { RegisterUserAction, RegisterUserLightAction, RegisterIncognitoUserAction } from './actions'

export * from './actions'
export * from './selectors'
export * from './middlewares'

const DEFAULT = Immutable.fromJS({
  user: null,
  isFetching: false,
  error: null,
})

export const registrationReducer = (state = DEFAULT, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return state.merge({
        error: null,
      })

    case RegisterUserAction.REQUEST:
    case RegisterUserLightAction.REQUEST:
    case RegisterIncognitoUserAction.REQUEST:
      return state.merge({
        isFetching: true,
        user: null,
        error: null,
      })

    case RegisterUserAction.SUCCESS:
    case RegisterUserLightAction.SUCCESS:
    case RegisterIncognitoUserAction.SUCCESS:
      return state.merge({
        isFetching: false,
        user: action.response,
        error: null,
      })

    case RegisterUserAction.FAILURE:
    case RegisterUserLightAction.FAILURE:
    case RegisterIncognitoUserAction.FAILURE:
      return state.merge({
        isFetching: false,
        user: null,
        error: action.error,
      })

    default:
      return state
  }
}
