/**
 * constant used to switch between views
 */
export const LoginViews = {
  /**
   * This is the first screen of the login/register feature :
   * the user will have to choose if he wants to login, register, or continue without account creation (= as incognito)
   *
   * handled by AuthenticationChoiceForm
   */
  CHOICE_TO_DO: 'CHOICE_TO_DO',

  /**
   * handled by LoginForm
   */
  LOGIN: 'LOGIN',

  /**
   * handled by RegisterForm
   */
  REGISTER: 'REGISTER',

  /**
   * handled by UserLightForm
   */
  USER_LIGHT: 'USER_LIGHT',

  /**
   * Used when the user chose "continue without account creation"
   * it allows to display the CGU checkbox, and make it mandatory to check it to continue
   *
   * handled by IncognitoForm
   */
  INCOGNITO: 'INCOGNITO',
}
