import { createFormatter } from 'pmt-utils/format'

import { getDietLabel, getAllergenLabel } from '../constants'

const formatDiet = dietaryPreferences => {
  dietaryPreferences.dietLabel = getDietLabel(dietaryPreferences.diet) || null
  return dietaryPreferences
}

const formatAllergens = dietaryPreferences => {
  dietaryPreferences.allergensLabels = (dietaryPreferences.allergens || []).map(getAllergenLabel)
  return dietaryPreferences
}

export const formatDietaryPreferences = createFormatter(formatDiet, formatAllergens)
