import { createFormatter } from 'pmt-utils/format'

const formatAllowContact = (marketing) => {
  marketing.allowContactLabel = marketing.allowContact === true ? 'oui' : 'non'
  return marketing
}

export const formatMarketing = createFormatter(
  formatAllowContact,
)
