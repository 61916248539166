import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import clsx from 'clsx'
import isNull from 'lodash/isNull'
import isEmpty from 'lodash/isEmpty'

import { withForm } from 'pmt-modules/form'
import FormType from 'pmt-modules/form/FormType'
import { isWebCustomer, getPublicUrl } from 'pmt-modules/environment'

import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import TextField from 'pmt-ui/TextField'
import Checkbox from 'pmt-ui/Checkbox'
import { FormControl, FormControlLabel } from 'pmt-ui/Form'
import Tooltip from 'pmt-ui/Tooltip'
import withWidth, { isWidthDown } from 'pmt-ui/utils/withWidth'
import HelpOutline from 'pmt-ui/svg-icons/action/help-outline'

import { getCurrentYear, getExpirationYears } from '../utils'

const styles = theme => ({
  numberContainer: {
    overflow: 'hidden',
  },
  number: {
    float: 'left',
    width: 'calc(100% - 110px)',
    marginTop: theme.spacing(2),
  },
  numberMobile: {
    width: '100%',
  },
  expiration: {
    overflow: 'hidden',
    marginTop: theme.spacing(2),
  },
  expirationDesktop: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
  expirationMonth: {
    float: 'left',
    width: 70,
    marginRight: theme.spacing(1),
    textAlign: 'left',
  },
  monthYearSeparator: {
    float: 'left',
    width: 20,
    textAlign: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  expirationYear: {
    float: 'left',
    width: 80,
    textAlign: 'left',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  cvv: {
    float: 'left',
    width: 160,
  },
  cvvInput: {
    float: 'left',
    width: `calc(100% - ${theme.spacing(4.5)}px)`,
  },
  cvvHelp: {
    float: 'right',
    width: 34,
    height: 34,
    padding: theme.spacing(1),
    marginTop: theme.spacing(1.5),
    color: theme.palette.primary.main,
  },
  popper: {
    opacity: 1,
  },
  tooltip: {
    padding: theme.spacing(1),
    background: theme.pmt.colors.white,
    color: theme.pmt.colors.darkGrey,
    boxShadow: `0 0 8px 0 ${theme.pmt.colors.darkGrey}`,
  },
  cvvImage: {
    float: 'left',
  },
  cvvImageText: {
    float: 'right',
    maxWidth: 133,
    marginLeft: theme.spacing(1),
  },
  checkbox: {
    marginTop: theme.spacing(3),
  },
  name: {
    maxWidth: 290,
    marginTop: theme.spacing(1),
  },
  type: {
    width: '100%',
    maxWidth: 200,
  },
})

const Number = ({ value, validation, onChange, isMobile, classes }) => (
  <div className={classes.numberContainer}>
    <TextField
      fullWidth
      autoComplete="cc-number"
      name="cardnumber"
      label={tr('global.credit_card.form.number', {
        fr: 'Numéro de carte',
        context: '',
        desc: 'Number field of credit card form',
      })}
      type={isMobile ? 'number' : null}
      inputProps={isMobile ? { pattern: '\\d*' } : {}} // digits only, so keyboard on iphone show only digits, and not comas, period, etc
      value={value}
      onChange={event => {
        let value =
          event.target.value && !isNaN(parseInt(event.target.value, 10))
            ? parseInt(event.target.value, 10)
            : ''
        onChange(event, value)
      }}
      helperText={validation.message}
      error={!isNull(validation.message) && !isEmpty(validation.message)}
      className={clsx(classes.number, {
        [classes.numberMobile]: isMobile || isWebCustomer(),
      })}
    />
    {!isMobile &&
      !isWebCustomer() && (
        <div className="u-floatRight u-marginTop30">
          <img
            src={`${getPublicUrl()}/img/ic_VISA.png`}
            width="40"
            className="u-marginRight10"
            alt="VISA"
          />
          <img src={`${getPublicUrl()}/img/ic_MASTERCARD.png`} width="40" alt="MASTERCARD" />
        </div>
      )}
  </div>
)

const Months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']

const ExpirationDateMonth = ({ value, validation, className, onChange, isMobile }) => (
  /* we need to use plain html select in order to have autocompletion working */
  <FormControl
    className={`${className} ${
      !isNull(validation.message) && !isEmpty(validation.message)
        ? 'MuiInput-underline Mui-error'
        : ''
    }`}
  >
    <label for="cc-exp-month" class="MuiInputLabel-shrink">
      {tr('global.credit_card.form.expiration_month', {
        fr: 'Mois',
        context: '',
        desc: 'Expiration month field of credit card form',
      })}
    </label>
    <select
      id="cc-exp-month"
      name="cc-exp-month"
      autocomplete="cc-exp-month"
      value={value.toLocaleString('en-US', {
        minimumIntegerDigits: 2, // integer value transformed to string like "05" so autocomplete with (for example) Dashlane works
        useGrouping: false,
      })}
      onChange={event => {
        onChange(event, parseInt(event.target.value))
      }}
    >
      {Months.map((month, index) => (
        <option key={index} value={month}>
          {month}
        </option>
      ))}
    </select>
  </FormControl>
)

const ExpirationDateYear = ({
  value,
  validation,
  currentYear,
  expirationYears,
  className,
  onChange,
  isMobile,
}) => (
  /* we need to use plain html select in order to have autocompletion working */
  <FormControl
    className={`${className} ${
      !isNull(validation.message) && !isEmpty(validation.message)
        ? 'MuiInput-underline Mui-error'
        : ''
    }`}
  >
    <label for="cc-exp-year" class="MuiInputLabel-shrink">
      {tr('global.credit_card.form.expiration_year', {
        fr: 'Année',
        context: '',
        desc: 'Expiration year field of credit card form',
      })}
    </label>
    <select
      id="cc-exp-year"
      name="cc-exp-year"
      autocomplete="cc-exp-year"
      value={value}
      onChange={event => {
        onChange(event, parseInt(event.target.value))
      }}
    >
      {expirationYears.map((year, index) => (
        <option key={index} value={year}>
          {year}
        </option>
      ))}
    </select>
  </FormControl>
)

/**
 * Code verification value input field
 * this cannot be a NumberField since leading zero is valid for cvv
 * so, we consider its value as a string instead of number
 */
const Cvv = ({ value, validation, className, classes, onChange, isMobile }) => (
  <div className={className}>
    <TextField
      fullWidth
      autoComplete="cc-csc"
      name="cvc"
      className={classes.cvvInput}
      label={tr('global.credit_card.form.security_code', {
        fr: 'Code de sécurité',
        context: '',
        desc: 'Security code field of credit card form',
      })}
      value={value}
      onChange={event => {
        let value =
          event.target.value && !isNaN(parseInt(event.target.value, 10)) ? event.target.value : ''
        let valueTemp = parseInt(value, 10)
        value = value !== '' && !isNaN(valueTemp) && valueTemp > 999 ? '999' : value
        onChange(event, value)
      }}
      step={isMobile ? 1 : null}
      type={isMobile ? 'number' : null}
      inputProps={isMobile ? { pattern: '\\d*' } : {}} // digits only, so keyboard on iphone show only digits, and not comas, period, etc
      helperText={validation.message}
      error={!isNull(validation.message) && !isEmpty(validation.message)}
    />
    <Tooltip
      title={
        <div className="u-overflowHidden">
          <img
            className={classes.cvvImage}
            src={`${getPublicUrl()}/img/ic_card_CVV.png`}
            width="52"
            alt="Card CVV"
          />
          <TypographyCustom type="124" className={classes.cvvImageText}>
            {tr('global.credit_card.form.cvv')}
          </TypographyCustom>
        </div>
      }
      classes={{ popper: classes.popper, tooltip: classes.tooltip }}
      placement="bottom"
    >
      <HelpOutline className={classes.cvvHelp} />
    </Tooltip>
  </div>
)

const Expiration = ({
  creditCard,
  currentYear,
  expirationYears,
  formErrors,
  onChange,
  classes,
  width,
}) => (
  <div
    className={clsx(classes.expiration, {
      [classes.expirationDesktop]: !isWidthDown('md', width),
    })}
  >
    <div
      className={clsx('u-marginBottom10', {
        'u-flexGrow1': !isWidthDown('md', width),
      })}
    >
      <ExpirationDateMonth
        className={classes.expirationMonth}
        value={
          creditCard.expirationDateMonth === null || creditCard.expirationDateMonth === ''
            ? ''
            : creditCard.expirationDateMonth
        }
        onChange={(evt, expirationDateMonth) => {
          onChange({
            ...creditCard,
            expirationDateMonth,
          })
        }}
        validation={creditCard.expirationDateMonth !== null && formErrors.expirationDateMonth}
        isMobile={isWidthDown('sm', width)}
      />
      <span className={classes.monthYearSeparator}>/</span>
      <ExpirationDateYear
        className={classes.expirationYear}
        currentYear={currentYear}
        expirationYears={expirationYears}
        value={
          creditCard.expirationDateYear === null || creditCard.expirationDateYear === ''
            ? ''
            : creditCard.expirationDateYear
        }
        onChange={(evt, expirationDateYear) => {
          onChange({
            ...creditCard,
            expirationDateYear,
          })
        }}
        validation={creditCard.expirationDateYear !== null && formErrors.expirationDateYear}
        isMobile={isWidthDown('sm', width)}
      />
      <div className="u-clearBoth u-fontSize11 colorError u-paddingTop10">
        {formErrors.expirationDateMonth.message
          ? creditCard.expirationDateMonth != null && formErrors.expirationDateMonth.message
            ? formErrors.expirationDateMonth.message
            : ''
          : formErrors.expirationDateYear.message
            ? creditCard.expirationDateYear != null && formErrors.expirationDateYear.message
              ? formErrors.expirationDateYear.message
              : ''
            : ''}
      </div>
    </div>

    <Cvv
      classes={classes}
      className={classes.cvv}
      value={creditCard.cvv === null ? '' : creditCard.cvv}
      onChange={(evt, cvv) => onChange({ ...creditCard, cvv })}
      validation={creditCard.cvv !== null && formErrors.cvv}
      isMobile={isWidthDown('sm', width)}
    />
  </div>
)

const Name = ({ classes, show, showCheckbox, saveForLater, creditCard, formErrors, onChange }) =>
  !show ? null : (
    <React.Fragment>
      {showCheckbox && (
        <div className={classes.checkbox}>
          <FormControlLabel
            label={
              <TypographyCustom type="164">
                {tr('global.credit_card.form.save_for_later')}
              </TypographyCustom>
            }
            control={
              <Checkbox
                color="primary"
                checked={saveForLater}
                onChange={() => {
                  onChange({ ...creditCard, saveForLater: !saveForLater })
                }}
              />
            }
          />
        </div>
      )}
      {((saveForLater && showCheckbox) || !showCheckbox) && (
        <div className={classes.name}>
          <TextField
            label={tr('global.credit_card.form.card_name', {
              fr: 'Nom de la carte (perso / pro)',
              context: '',
              desc: 'Card name field of credit card form',
            })}
            placeholder={tr('global.credit_card.form.card_name_placeholder')}
            fullWidth
            value={creditCard.name === null ? '' : creditCard.name}
            onChange={evt => onChange({ ...creditCard, name: evt.target.value })}
            helperText={creditCard.name !== null && formErrors.name.message}
            error={creditCard.name !== null && formErrors.name.message}
          />
        </div>
      )}
    </React.Fragment>
  )

class CreditCardForm extends React.Component {
  creditCardYearDatas = {
    currentYear: getCurrentYear(),
    expirationYears: getExpirationYears(getCurrentYear()),
  }

  render() {
    const {
      formData,
      formErrors,
      onChange: _onChange,
      withName,
      withOptionnalRegister = false,
      classes,
      width,
    } = this.props

    const onChange = (...props) => {
      console.log({ props })
      _onChange.apply(null, props)
    }

    return (
      <div>
        <Number
          classes={classes}
          value={
            formData.number === null || formData.number === '' ? '' : parseInt(formData.number, 10)
          }
          onChange={(evt, number) => onChange({ ...formData, number })}
          validation={formData.number !== null && formErrors.number}
          isMobile={isWidthDown('sm', width)}
        />

        <Expiration
          creditCard={formData}
          currentYear={this.creditCardYearDatas.currentYear}
          expirationYears={this.creditCardYearDatas.expirationYears}
          onChange={onChange}
          formErrors={formErrors}
          classes={classes}
          width={width}
        />

        <Name
          classes={classes}
          show={withName}
          saveForLater={formData.saveForLater}
          showCheckbox={withOptionnalRegister}
          creditCard={formData}
          onChange={onChange}
          formErrors={formErrors}
        />
      </div>
    )
  }
}

export default compose(
  withForm(FormType.CREDIT_CARD),
  withStyles(styles),
  withWidth()
)(CreditCardForm)
