import React, { Fragment } from 'react'

import isNil from 'lodash/isNil'
import { withStyles } from 'pmt-ui/styles'
import { EventManager } from 'pmt-modules/event'
import isEmpty from 'lodash/isEmpty'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

import { tr } from 'pmt-modules/i18n'
import {
  CreditCardsContainer,
  getDeleteUserCardIsFetching,
  getIsFetchingPostCreditCardRegistrationType,
  getErrorPostCreditCardRegistrationType,
  getPostPspDatasCard,
  getPostPspDatasIsFetching,
  getPostPspDatasError,
  getSendUserCreditCardToPspIsFetching,
  resetUserCreditCardRegistrationTypeError,
  resetPspDatasError,
  postUserCreditCardRegistrationType,
  getDatasPostCreditCardRegistrationType,
  getStatusSendUserCreditCardToPsp,
  resetUserCreditCardToPspSend,
} from 'pmt-modules/creditCard'
import { PaymentMethodsAllowed } from 'pmt-modules/payment/constants'
import UserType from 'pmt-modules/user/constants/UserType'
import {
  PspRegisterUserCardType,
} from 'pmt-modules/creditCard/constants'

import Divider from '@material-ui/core/Divider'
import RadioGroup from '@material-ui/core/RadioGroup'
import LoadingBlock from 'pmt-ui/LoadingBlock'
import Button from 'app/components/Button'
import { TypographyCustom } from 'pmt-ui/Typography'

import CreditCardPayment from './CreditCardPayment'
import PaymentMethod from './PaymentMethod'

const styles = theme => ({
  container: {
    flex: 1,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  explaination: {
    margin: theme.spacing(2, 2),
  },
  tos: {
    color: theme.pmt.colors.grey91,
  },
  link: {
    textDecoration: 'underline',
  }
})

class PaymentMethods extends React.PureComponent {
  state = {
    // since TRD Payment is not setup yet, this is default
    paymentMethod: PaymentMethodsAllowed.CREDIT_CARD,
    showCreditCardForm: false,
  }

  componentWillReceiveProps(nextProps) {
    // user has no credit card
    if (
      this.props.isFetchingCreditCards &&
      !nextProps.isFetchingCreditCards &&
      isEmpty(nextProps.creditCards)
    ) {
      this.setShowCreditCardForm(true)
    }
  }

  handleSelect = e => {
    const paymentMethod = parseInt(e.target.value, 10)
    EventManager.dispatch(EventManager.Events.ON_PAYMENT_MODE_SELECT, {
      paymentMode: paymentMethod,
    })

    this.setState({ paymentMethod })

    if (paymentMethod !== PaymentMethodsAllowed.CREDIT_CARD) {
      this.props.onResetUserCard()
    }
  }

  setShowCreditCardForm = showCreditCardForm => {
    this.setState({ showCreditCardForm })
  }

  render() {
    const {
      classes,
      user,
      restaurant,
      restaurantsGroup,
      isSubmitDisabled = false,
      submitLabel,
      onSubmit,
      // -- credit cards props
      creditCards,
      isFetchingCreditCards,
      isFetchingDeleteCreditCard,
      onClickDeleteCreditCard,
      onResetUserCard,
      onToggleCard,
      selectedCreditCard,
      resetUserCreditCardRegistrationTypeError,
      resetPspDatasError,
      // --
      postUserCreditCardRegistrationType,
      datasPostCreditCardRegistrationType,
      postPspDatasIsFetching,
      postPspDatasCard,
      isFetchingPostCreditCardRegistrationType,
      statusSendUserCreditCardToPsp,
      resetUserCreditCardToPspSend,
      onSubmitNewCard,
      canRegisterCardWithName,
    } = this.props

    const isEmptyCreditCards = isEmpty(creditCards)
    const isOnlyOneCreditCard = creditCards && creditCards.length === 1

    // TODO: Work in progress
    const isTRDPaymentAllowed = false
    // TODO: handle hasPaymentWithCardPrint without restaurant for top-up
    // https://www.notion.so/paymytable/TopUp-Paygreen-f2f8197d60eb4ea391111a331338cb76
    const hasPaymentWithCardPrint = restaurant ? restaurant.hasPaymentWithCardPrint : true

    let submitEnabled = false
    switch (this.state.paymentMethod) {
      case PaymentMethodsAllowed.CREDIT_CARD:
        submitEnabled = !isNil(selectedCreditCard) || !hasPaymentWithCardPrint
        break
      case PaymentMethodsAllowed.TRD:
        submitEnabled = true
        break
      default:
        break
    }
    submitEnabled = submitEnabled && !isSubmitDisabled

    // we need to know the user type before displaying the form
    // because some user types will load credit cards (like normal users), and some won't (like incognito or userLight users)
    if (isFetchingCreditCards || !user?.type) {
      return <LoadingBlock show />
    }

    return (
      <Fragment>
        <div className={classes.container}>
          <RadioGroup value={this.state.paymentMethod} onChange={this.handleSelect}>
            <PaymentMethod
              isOnlyMethodAllowed={!isTRDPaymentAllowed}
              label={
                restaurant.isPspSaferpay
                  ? tr('web_customer.payment.online.title')
                  : tr('web_customer.payment.credit_cards.title')
              }
              checked={this.state.paymentMethod === PaymentMethodsAllowed.CREDIT_CARD}
              value={PaymentMethodsAllowed.CREDIT_CARD}
            >
              {PaymentMethodsAllowed.CREDIT_CARD === this.state.paymentMethod &&
                (hasPaymentWithCardPrint ? (
                  <CreditCardPayment
                    isFetching={isFetchingCreditCards || isFetchingDeleteCreditCard}
                    isEmptyCreditCards={isEmptyCreditCards}
                    isOnlyOneCreditCard={isOnlyOneCreditCard}
                    postUserCreditCardRegistrationType={postUserCreditCardRegistrationType}
                    datasPostCreditCardRegistrationType={datasPostCreditCardRegistrationType}
                    postPspDatasIsFetching={postPspDatasIsFetching}
                    postPspDatasCard={postPspDatasCard}
                    isFetchingPostCreditCardRegistrationType={
                      isFetchingPostCreditCardRegistrationType
                    }
                    statusSendUserCreditCardToPsp={statusSendUserCreditCardToPsp}
                    creditCards={creditCards}
                    selectedCreditCard={selectedCreditCard}
                    onToggleCard={onToggleCard}
                    onClickDeleteCreditCard={onClickDeleteCreditCard}
                    onResetUserCard={onResetUserCard}
                    resetUserCreditCardToPspSend={resetUserCreditCardToPspSend}
                    resetPspDatasError={resetPspDatasError}
                    resetUserCreditCardRegistrationTypeError={
                      resetUserCreditCardRegistrationTypeError
                    }
                    restaurant={restaurant}
                    restaurantsGroup={restaurantsGroup}
                    user={user}
                    showCreditCardForm={this.state.showCreditCardForm}
                    setShowCreditCardForm={this.setShowCreditCardForm}
                    onSubmitNewCard={onSubmitNewCard}
                    submitLabel={submitLabel}
                    canRegisterCardWithName={canRegisterCardWithName}
                  />
                ) : (
                  <p className={classes.explaination}>
                    {restaurant.isPspSaferpay
                      ? tr('web_customer.payment.online.redirect.explaination')
                      : tr('web_customer.payment.credit_cards.redirect.explaination')}
                  </p>
                ))}
            </PaymentMethod>

            {isTRDPaymentAllowed && <Divider className={classes.divider} />}

            {isTRDPaymentAllowed && (
              <PaymentMethod
                label={tr('global.payment.label.restaurant_ticket')}
                value={PaymentMethodsAllowed.TRD}
                checked={this.state.paymentMethod === PaymentMethodsAllowed.TRD}
              />
            )}

            {isTRDPaymentAllowed && <Divider className={classes.divider} />}
          </RadioGroup>
        </div>

        {(!this.state.showCreditCardForm ||
          !hasPaymentWithCardPrint) /* is paygreen, no form to show */ && (
            <React.Fragment>
              {!isEmpty(restaurantsGroup?.userSettings?.tosUrl) && (
                <TypographyCustom
                  type="144"
                  className={classes.tos}
                  dangerouslySetInnerHTML={{
                    __html: tr('web_customer.payment.tos', {
                      linkBegin: `<a class="${classes.link}" href="${restaurantsGroup.userSettings.tosUrl}" target="_blank" rel="noopener noreferrer">`,
                      linkEnd: '</a>',
                    }),
                  }}
                />
              )}

              <Button
                size="large"
                className="u-marginTop30 u-sizeFullWidth"
                onClick={() => onSubmit(this.state.paymentMethod, { selectedCreditCard })}
                disabled={!submitEnabled}
              >
                {submitLabel}
              </Button>
            </React.Fragment>
          )}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  isFetchingPostCreditCardRegistrationType: getIsFetchingPostCreditCardRegistrationType(state),
  isFetchingDeleteCreditCard: getDeleteUserCardIsFetching(state),
  sendUserCreditCardToPspIsFetching: getSendUserCreditCardToPspIsFetching(state),
  postCardError: getErrorPostCreditCardRegistrationType(state),
  datasPostCreditCardRegistrationType: getDatasPostCreditCardRegistrationType(state),
  postPspDatasCard: getPostPspDatasCard(state),
  postPspDatasIsFetching: getPostPspDatasIsFetching(state),
  postPspDatasError: getPostPspDatasError(state),
  statusSendUserCreditCardToPsp: getStatusSendUserCreditCardToPsp(state),
})

const mapDispatchToProps = {
  resetUserCreditCardRegistrationTypeError,
  resetUserCreditCardToPspSend,
  resetPspDatasError,
  postUserCreditCardRegistrationType,
}

const ComposedPaymentMethods = compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PaymentMethods)

export default props => (
  <CreditCardsContainer
    userId={props.user && props.user.type === UserType.NORMAL && props.user.id
      // we don't load usercards if not necessary (eg. when using an external web payment page, and not registered usercards)
      // and doing so prevents a bug where the Mangopay existing cards are automatically loaded and the first one selected, 
      // so the payment is sent with a cardId, which is not what we want now because we want to use the Web Payin feature (for PCI-DSS compliance)
      && props.restaurant && props.restaurant.pspRegisterUserCardType === PspRegisterUserCardType.WEB_SERVICES}
    storeId={props.restaurant && props.restaurant.id}
  >
    {({
      creditCards,
      isFetchingCreditCards,
      onClickDeleteCreditCard,
      onResetUserCard,
      onToggleCard,
      selectedCreditCard,
    }) => (
      <ComposedPaymentMethods
        creditCards={creditCards}
        isFetchingCreditCards={isFetchingCreditCards}
        onClickDeleteCreditCard={onClickDeleteCreditCard}
        onResetUserCard={onResetUserCard}
        onToggleCard={onToggleCard}
        selectedCreditCard={selectedCreditCard}
        {...props}
      />
    )}
  </CreditCardsContainer>
)
