import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let ArrowTuto = props => (
  <SvgIcon {...props}>
    <g id="ic_tuto_arrow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Group"
        transform="translate(12.000000, 13.000000) rotate(-5.000000) translate(-12.000000, -13.000000) translate(6.000000, 3.000000)"
        fillRule="nonzero"
        stroke="#979797"
        strokeWidth="0.891428571"
      >
        <path
          d="M3.4112216,0.344059247 C7.37486264,4.43914036 9.05750332,8.47397589 8.45914364,12.4485658 C7.86078396,16.4231558 6.18996466,18.8207992 3.44668574,19.6414962"
          id="Path-2"
          transform="translate(5.994044, 9.992778) rotate(20.000000) translate(-5.994044, -9.992778) "
        />
        <path
          d="M6.13200465,1.04683106 L5.47705205,4.97428495 C5.43655994,5.21710769 5.60058132,5.44677975 5.84340405,5.48727186 C5.91025216,5.49841918 5.97876202,5.49417669 6.04372407,5.474867 C7.98474292,4.89790746 9.28578994,4.39933637 9.94686512,3.97915371"
          id="Path-3"
          transform="translate(7.708888, 3.270063) rotate(122.000000) translate(-7.708888, -3.270063) "
        />
      </g>
    </g>
  </SvgIcon>
)
ArrowTuto = pure(ArrowTuto)
ArrowTuto.displayName = 'ArrowTuto'
ArrowTuto.pmtName = 'SvgIcon'

export default ArrowTuto
