import createMiddleware from '../redux/createMiddleware'

import { displayGenerousPaymentDialog } from 'pmt-modules/dialog'
import {
  GetAsyncPaymentAction,
  isAsyncPaymentRedirectToPspResponse,
} from 'pmt-modules/asyncPayment'
import { PostPspDatasAction } from 'pmt-modules/creditCard/actions'
import {
  GetRefreshCheckAction,
  GetCheckWithCodeAction,
  GetCheckWithTableNumberAction,
  GetCheckWithPosCheckIdAction,
  GetCheckAction,
  MergeCheckWithCodeAction,
  PaymentStatus,
  PostPaymentAction,
  PostUserLightPaymentAction,
  processPaymentv2,
  resetPayment,
  resetPostPayment,
} from 'pmt-modules/payment'
import { getPayment } from 'pmt-modules/payment/payment/selectors'
import { PaymentMethodsAllowed } from 'pmt-modules/payment/constants'
import {
  generatePathWithParams,
  redirectTo,
  redirectToExternal,
  getRoute,
} from 'pmt-modules/routing'
import { PostTopUpAction, postTopUpWithCreditCard } from 'pmt-modules/topUp'
import { resetTopUp } from 'pmt-modules/topUp/makeTopUp/actions'
import { fetchUserUserAccounts } from 'pmt-modules/userAccount/actions'
import { GetRestaurantAction } from 'pmt-modules/restaurant'

import { getAbsolutePath } from 'pmt-utils/url'
import { formatPriceWithCurrency } from 'pmt-utils/currency'
import { setCurrency } from 'pmt-utils/currency'

import { WebCustomerAction, processPaymentv2 as webCustomerProcessPayment } from './actions'

const redirectToPostPaymentMiddleware = createMiddleware(
  PostUserLightPaymentAction.SUCCESS,
  ({ next, action, dispatch }) => {
    const restaurantId = action.data.restaurantId
    const checkId = action.data.check.id

    next(action)

    dispatch(resetPayment())
    dispatch(resetPostPayment())

    dispatch(redirectTo(getRoute('PAYMENT_CONFIRM'), { restaurantId, checkId }))
    return createMiddleware.STOP_PROPAGATION
  }
)

const postPaymentSuccessMiddleware = createMiddleware(
  PostPaymentAction.SUCCESS,
  ({ next, action, dispatch }) => {
    const restaurantId = action.data.restaurantId
    const checkId = action.data.check.id

    next(action)

    dispatch(resetPayment())
    dispatch(resetPostPayment())

    dispatch(redirectTo(getRoute('PAYMENT_CONFIRM'), { restaurantId, checkId }))
    return createMiddleware.STOP_PROPAGATION
  }
)

const postTopUpSuccessMiddleware = createMiddleware(
  PostTopUpAction.SUCCESS,
  ({ dispatch, next, action }) => {
    dispatch(fetchUserUserAccounts('me'))

    next(action)

    dispatch(redirectTo(getRoute('USER_TOP_UP_CONFIRM')))
    return createMiddleware.STOP_PROPAGATION
  }
)

const processPaymentMiddleware = createMiddleware(
  WebCustomerAction.PROCESS_PAYMENT,
  ({ action, dispatch }) => {
    const { restaurantId, paymentMethod, selectedCreditCard, check } = action
    const payment = {
      ...action.payment,
      supportAsyncPayment: true,
      asyncPaymentData: {
        redirectUrl: getAbsolutePath(
          generatePathWithParams(getRoute('PAYMENT_VERIFICATION'), {
            checkId: check.id,
            restaurantId: restaurantId,
          })
        ),
      },
    }

    dispatch(
      processPaymentv2(action.restaurantId, paymentMethod, payment, action.check, {
        selectedCreditCard,
      })
    )
  }
)

/**
 *
 */
const postOrderAfterPostCreditCard = createMiddleware(
  PostPspDatasAction.SUCCESS,
  ({ action, dispatch }) => {
    if (action.data.options && action.data.options.dispatchTopUp) {
      dispatch(
        postTopUpWithCreditCard(
          action.data.options.userAccount,
          action.response,
          action.data.options.topUp
        )
      )
    }

    if (action.data.options && action.data.options.dispatchPayment) {
      dispatch(
        webCustomerProcessPayment(
          action.data.options.restaurantId,
          PaymentMethodsAllowed.CREDIT_CARD,
          action.data.options.payment,
          action.data.options.check,
          {
            selectedCreditCard: action.response,
          }
        )
      )
    }
  }
)

/**
 * When in 3DSecure mode, API will return an http code 303 along with the payment information.
 * The payment information contains a property payIn3DSecureUrl indicating where to redirect the
 * user to process the 3DSecure verification.
 */
const postPaymentFailureMiddleware = createMiddleware(
  [PostPaymentAction.FAILURE, PostUserLightPaymentAction.FAILURE],
  ({ action, dispatch, next }) => {
    if (isAsyncPaymentRedirectToPspResponse(action) && action.error.body.asyncPaymentData) {
      next(action)
      return dispatch(
        redirectTo(getRoute('PAYMENT__ASYNC__FORM'), null, { flow: 'paymentAtTable' })
      )
    } else if (
      isAsyncPaymentRedirectToPspResponse(action) &&
      action.error.body.payment.asyncPaymentSecureUrl
    ) {
      dispatch(redirectToExternal(action.error.body.payment.asyncPaymentSecureUrl))
    }
  }
)

/**
 * When in 3DSecure mode, API will return an http code 303 along with the payment information.
 * The payment information contains a property payIn3DSecureUrl indicating where to redirect the
 * user to process the 3DSecure verification.
 */
const postTopUpFailureMiddleware = createMiddleware(
  [PostTopUpAction.FAILURE],
  ({ action, dispatch, next }) => {
    if (isAsyncPaymentRedirectToPspResponse(action) && action.error.body.payment.asyncPaymentData) {
      dispatch(redirectTo(getRoute('PAYMENT__ASYNC__FORM'), null, { flow: 'topUp' }))
      return next(action)
    } else if (
      isAsyncPaymentRedirectToPspResponse(action) &&
      action.error.body.payment.asyncPaymentSecureUrl
    ) {
      dispatch(redirectToExternal(action.error.body.payment.asyncPaymentSecureUrl))
    }
  }
)

/**
 * We get the payment after a 3DSecure transaction in order to verify its status
 * on success, we redirect to payment confirmation page
 */
const finalizeAsyncPaymentSuccess = createMiddleware(
  GetAsyncPaymentAction.SUCCESS,
  ({ action, dispatch, next }) => {
    // on top-up we receive the top-up with the payment inside it.
    // on payment we directly have the payment data
    const isTopUp = action.response.payment?.paymentType === 7 // 7 is for DEPOSIT (top-up)
    const isPayment = !isTopUp

    if (isTopUp) {
      // the API can gives us a success with an error model on it to avoid spamming error logs.
      // But the real success is when we have the payment data
      if (action.response.payment?.status === PaymentStatus.PAID) {
        next(action)

        dispatch(resetTopUp())

        dispatch(redirectTo(getRoute('USER_TOP_UP_CONFIRM')))
        return createMiddleware.STOP_PROPAGATION
      }
    }
    if (isPayment) {
      // the API can gives us a success with an error model on it to avoid spamming error logs.
      // But the real success is when we have the payment data
      if (action.response.payment?.status === PaymentStatus.PAID) {
        next(action)

        dispatch(resetPayment())
        dispatch(resetPostPayment())

        dispatch(
          redirectTo(getRoute('PAYMENT_CONFIRM'), {
            restaurantId: action.data.restaurantId,
            checkId: action.data.checkId,
          })
        )
        return createMiddleware.STOP_PROPAGATION
      }
    }
  }
)

const finalizeAsyncPaymentFailure = createMiddleware(
  GetAsyncPaymentAction.FAILURE,
  ({ action, dispatch }) => {
    if (action.error) {
      const isTopUp = !action.data?.checkId // trick to know if we are in payment or topup flow
      const isPayment = !isTopUp

      if (isTopUp) {
        dispatch(redirectTo(getRoute('USER_TOP_UP')))
      } else if (isPayment) {
        dispatch(
          redirectTo(getRoute('PAYMENT_PAYMENT'), {
            restaurantId: action.data.restaurantId,
            checkId: action.data.checkId,
          })
        )
      }
    }
  }
)

const updateCurrency = createMiddleware(
  [
    GetRefreshCheckAction.SUCCESS,
    GetCheckWithCodeAction.SUCCESS,
    GetCheckWithTableNumberAction.SUCCESS,
    GetCheckWithPosCheckIdAction.SUCCESS,
    GetCheckAction.SUCCESS,
    MergeCheckWithCodeAction.SUCCESS,
    GetRestaurantAction.SUCCESS,
  ],
  ({ action, getState, dispatch }) => {
    setCurrency(action.response.currency)
  }
)

const refreshCheckSuccessFromCheck = createMiddleware(
  GetRefreshCheckAction.SUCCESS,
  ({ action, getState, dispatch }) => {
    setCurrency(action.response.currency)

    if (action.data.isFromCheck) {
      const payment = getPayment(getState())
      const paymentAmount = parseFloat(payment.amount)

      if (action.response.outstandingBalance < paymentAmount) {
        dispatch(
          displayGenerousPaymentDialog({
            restaurantId: action.data.restaurantId,
            checkId: action.data.checkId,
            amount: payment.amountFormatted,
            outstandingBalance: formatPriceWithCurrency(action.response.outstandingBalance),
          })
        )
      } else {
        dispatch(
          redirectTo(getRoute('PAYMENT_PAYMENT'), {
            restaurantId: action.data.restaurantId,
            checkId: action.data.checkId,
          })
        )
      }
    }
  }
)

export const webCustomerMiddlewares = [
  finalizeAsyncPaymentFailure,
  finalizeAsyncPaymentSuccess,
  postOrderAfterPostCreditCard,
  postPaymentSuccessMiddleware,
  postPaymentFailureMiddleware,
  postTopUpSuccessMiddleware,
  postTopUpFailureMiddleware,
  processPaymentMiddleware,
  redirectToPostPaymentMiddleware,
  updateCurrency,
  refreshCheckSuccessFromCheck,
]
