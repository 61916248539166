import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Peanut = props => (
  <SvgIcon {...props}>
    <path d="M 13 3 C 11.558594 3 10.292969 3.757813 9.59375 4.90625 C 9.394531 5.234375 9.265625 5.601563 9.125 5.96875 C 8.578125 7.398438 7.398438 8.578125 5.96875 9.125 C 5.601563 9.265625 5.234375 9.394531 4.90625 9.59375 C 3.964844 10.164063 3.304688 11.109375 3.09375 12.21875 C 3.0625 12.347656 3.019531 12.484375 3.03125 12.625 C 3.042969 12.804688 3.195313 13 3.375 13 L 3.625 13 C 7.09375 13 8.46875 12.535156 9.75 11.90625 C 11.238281 11.175781 12.777344 9.019531 12.96875 6.71875 C 12.988281 6.878906 13 6.828125 13 7 C 13 9.121094 12.25 11.472656 10.28125 12.5625 C 9.679688 12.890625 8.171875 13.789063 4 14 L 3.5 14 C 3.378906 14 3.277344 14.054688 3.1875 14.125 C 3.078125 14.214844 3 14.339844 3 14.5 C 3 14.597656 3.058594 14.667969 3.09375 14.75 C 3.746094 16.195313 5.601563 17 7 17 C 12.519531 17 17 12.519531 17 7 C 17 4.789063 15.210938 3 13 3 Z M 16.9375 12.4375 C 16.273438 13.773438 15.320313 14.960938 14.15625 15.875 C 14.1875 15.890625 14.21875 15.890625 14.25 15.90625 C 15.53125 16.535156 16.90625 17 20.375 17 L 20.625 17 C 20.804688 17 20.957031 16.804688 20.96875 16.625 C 20.980469 16.484375 20.9375 16.347656 20.90625 16.21875 C 20.695313 15.109375 20.035156 14.164063 19.09375 13.59375 C 18.765625 13.394531 18.398438 13.265625 18.03125 13.125 C 17.628906 12.96875 17.277344 12.699219 16.9375 12.4375 Z M 13.4375 16.375 C 12.328125 17.097656 11.074219 17.609375 9.71875 17.84375 C 11.542969 19.785156 14.128906 21 17 21 C 18.398438 21 20.253906 20.195313 20.90625 18.75 C 20.941406 18.667969 21 18.597656 21 18.5 C 21 18.339844 20.921875 18.214844 20.8125 18.125 C 20.722656 18.054688 20.621094 18 20.5 18 L 20 18 C 15.828125 17.789063 14.320313 16.890625 13.71875 16.5625 C 13.621094 16.507813 13.527344 16.433594 13.4375 16.375 Z " />
  </SvgIcon>
)
Peanut = pure(Peanut)
Peanut.displayName = 'Peanut'
Peanut.muiName = 'SvgIcon'

export default Peanut
