import { tr } from 'pmt-modules/i18n'
import React from 'react'
import classNames from 'classnames'

import { withStyles } from 'pmt-ui/styles'
import Grid from 'pmt-ui/Grid'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import Price from 'pmt-ui/Price'
import { TypographyCustom } from 'pmt-ui/Typography'
import Choose from 'pmt-ui/svg-icons/pmt/Choose'
import Share from 'pmt-ui/svg-icons/pmt/Share'
import Refresh from 'pmt-ui/svg-icons/navigation/refresh'

import CheckList from '../../../components/payment/CheckList'
import { Button } from '../../../components/Button'

const styles = theme => ({
  content: {
    padding: theme.spacing(2),
  },
  refreshContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 110,
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(1)}px`,
    margin: '0 auto',
    zIndex: 5,
  },
  refreshIcon: {
    width: 15,
    height: 15,
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  refreshLabel: {
    lineHeight: '16px',
    color: theme.palette.primary.main,
  },
  checkList: {
    padding: theme.spacing(2),
    paddingTop: 0,
    overflowY: 'scroll',
    flex: 1,
  },
  actionsContainer: {
    zIndex: 5,
  },
  actionsSubContainer: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  shadowContainer: {
    height: theme.spacing(2),
    marginTop: -theme.spacing(1) * 2,
    background: 'linear-gradient(to bottom, rgba(239, 239, 244, 0) 0%,rgba(239, 239, 244, 1) 100%)',
  },
  totalContainer: {
    overflow: 'hidden',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.pmt.colors.grey91}`,
  },
  totalLabel: {
    float: 'left',
    lineHeight: '18px',
    color: theme.pmt.colors.grey91,
  },
  totalPrice: {
    color: theme.pmt.colors.grey91,
  },
  outstandingContainer: {
    overflow: 'hidden',
    paddingTop: theme.spacing(2),
  },
  outstandingLabel: {
    float: 'left',
    lineHeight: '24px',
  },
  button: {
    width: '100%',
    marginBottom: theme.spacing(2),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  buttonLabel: {
    lineHeight: '20px',
    color: theme.pmt.colors.white,
  },
  buttonIcon: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    color: theme.pmt.colors.white,
  },
})

/**
 * @mockup N/A
 */
const View = ({
  check,
  isFetchingCheck,
  onRefreshCart,
  onRedirectToTotality,
  onRedirectToShare,
  onRedirectToChoose,
  classes,
}) => {
  return (
    <React.Fragment>
      <div className={classes.refreshContainer} onClick={onRefreshCart}>
        <Refresh className={classes.refreshIcon} />
        <TypographyCustom type="124" component="span" className={classes.refreshLabel}>
          {tr('web_customer.check.refresh')}
        </TypographyCustom>
      </div>
      <LoadingBlockWrapper show={isFetchingCheck} />
      <CheckList
        check={check}
        type={CheckList.Type.PRICE}
        classes={{ root: classes.checkList, entry: classes.entry }}
      />
      <div className={classes.actionsContainer}>
        <div className={classes.shadowContainer} />
        <div className={classes.actionsSubContainer}>
          <div className={classes.totalContainer}>
            <TypographyCustom type="147" className={classes.totalLabel}>
              {tr('web_customer.check.total_table')}
            </TypographyCustom>
            {check && (
              <TypographyCustom type="187" className={classes.totalPrice}>
                <Price value={check.totalWithoutTipsFormatted} classes={{ root: 'u-floatRight' }} />
              </TypographyCustom>
            )}
          </div>
          <div className={classes.outstandingContainer}>
            <TypographyCustom type="187" className={classes.outstandingLabel}>
              {tr('web_customer.check.left_to_pay')}
            </TypographyCustom>
            {check && (
              <TypographyCustom type="247">
                <Price
                  value={check.outstandingBalanceFormatted}
                  classes={{ root: 'u-floatRight' }}
                />
              </TypographyCustom>
            )}
          </div>
          {check &&
            check.outstandingBalance > 0 && (
              <React.Fragment>
                <Button
                  onClick={onRedirectToTotality}
                  className={classNames(classes.button, 'u-marginTop15')}
                  disabled={isFetchingCheck}
                >
                  <TypographyCustom type="167" className={classes.buttonLabel}>
                    {tr('web_customer.check.pay_rest')}
                  </TypographyCustom>
                </Button>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      onClick={onRedirectToShare}
                      className={classes.button}
                      disabled={isFetchingCheck}
                    >
                      <Share className={classes.buttonIcon} />
                      <TypographyCustom type="167" className={classes.buttonLabel}>
                        {tr('web_customer.check.mode.share')}
                      </TypographyCustom>
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      onClick={onRedirectToChoose}
                      className={classes.button}
                      disabled={isFetchingCheck}
                    >
                      <Choose className={classes.buttonIcon} />
                      <TypographyCustom type="167" className={classes.buttonLabel}>
                        {tr('web_customer.check.mode.choose')}
                      </TypographyCustom>
                    </Button>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default withStyles(styles)(View)
