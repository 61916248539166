import { tr } from 'pmt-modules/i18n'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import { UserAccountContainer, UserAccountsContainer } from 'pmt-modules/userAccount'
import { withUserMe } from 'pmt-modules/userMe'

import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import { TypographyCustom } from 'pmt-ui/Typography'

import Page from '../../../components/Page'
import { Button } from '../../../components/Button'

import View from './View'

/**
 * @specs N/A
 */
@withUserMe
class UserQrCodePage extends React.Component {
  render() {
    const { userId, isFetchingMe } = this.props

    return (
      <UserAccountsContainer userId={userId}>
        {({ userAccounts, isFetchingUserAccounts }) =>
          (isFetchingUserAccounts || isFetchingMe || isEmpty(userAccounts) || !userAccounts[0]) ? (
            <LoadingBlockWrapper show />
          ) : (
            <UserAccountContainer
              userId={userId}
              userAccountId={userAccounts[0].id}
              refreshToken={true}
            >
              {({ userAccount, isFetchingUserAccount, onRedirectToTopUp, onLoadUserAccount }) => (
                <Page
                  title={tr('web_customer.qr_code.title', {
                    fr: 'Badge',
                    context: '',
                    desc: 'Header title for qr code page',
                  })}
                >
                  <View
                    // after signin, user account has a null token
                    // and succeeded user accounts request set user account isFetching to false
                    isFetchingUserAccount={isFetchingUserAccount || userAccount.token === null}
                    onLoadUserAccount={onLoadUserAccount}
                    userId={userId}
                    userAccount={userAccount}
                  />
                  <div className="u-paddingBottom20 u-paddingLeft20 u-paddingRight20">
                    <Button
                      size="large"
                      className="u-sizeFull"
                      onClick={onRedirectToTopUp}
                    >
                      <TypographyCustom type="187" skipColor>
                        {tr('web_customer.qr_code.top_up.action')}
                      </TypographyCustom>
                    </Button>
                  </div>
                </Page>
              )}
            </UserAccountContainer>
          )
        }
      </UserAccountsContainer>
    )
  }
}

UserQrCodePage.propTypes = {
  me: PropTypes.object,
  userId: PropTypes.string,
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps, {})(UserQrCodePage)
