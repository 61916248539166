import { AuthUserAction } from 'pmt-modules/auth'
import { ForgotPasswordAction } from 'pmt-modules/forgotPassword'

import { DataCatcherType } from 'pmt-modules/dataCatcher'

export default [
  //
  // Catch user email
  // It allow us to update the forms with the user email (login form / recovery password form)
  //
  {
    actionType: AuthUserAction.REQUEST,
    getObject: action => action.data.username,
    dataCatcherType: DataCatcherType.USER_FORM_EMAIL,
  },
  {
    actionType: ForgotPasswordAction.REQUEST,
    getObject: action => action.data.username,
    dataCatcherType: DataCatcherType.USER_FORM_EMAIL,
  },
]
