import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import isNil from 'lodash/isNil'
import { fetchUserHistory } from '../actions'
import {
  makeIsFetchingHistory,
  makeGetUserHistoryTypeList,
  makeGetUserHistoryTypePagination,
} from '../selectors'

import { getLoadedRestaurantsIds } from '../../restaurant'

/**
 * @specs N/A
 *
 * A container that take a `Function as child component`.
 * It fetch the given HistoryType data for the given user.
 *
 * Requirements:
 * - historyType
 * - userId (can be 'me')
 *
 * see `withHistoryType`
 *
 */
class HistoryTypeContainer extends React.Component {
  get data() {
    return {
      restaurantIds: this.props.loadedRestaurantsIds,
      userAccountId: this.props.userAccountId,
    }
  }

  componentDidMount() {
    this.props.fetchUserHistory(
      this.props.userId,
      this.props.historyType,
      this.data,
      '',
      this.props.limit
    )
  }

  loadMore = () => {
    // This is done to be sure we are not already fetching content
    if (!this.props.isFetchingHistory && !isNil(this.props.pagination.cursors.after)) {
      this.props.fetchUserHistory(
        this.props.userId,
        this.props.historyType,
        this.data,
        this.props.pagination.cursors.after,
        this.props.limit
      )
    }
  }

  render() {
    return this.props.children({
      isFetchingHistory: this.props.isFetchingHistory,
      list: this.props.historyData,
      pagination: this.props.pagination,
      onLoadMore: () => {
        this.loadMore()
      },
    })
  }
}

HistoryTypeContainer.defaultProps = {
  // web customer is using this value.
  // if you change it, don't forget to set limit in web customer container
  limit: 10,
}

HistoryTypeContainer.propTypes = {
  fetchUserHistory: PropTypes.func.isRequired,
  isFetchingHistory: PropTypes.bool,
  me: PropTypes.object,
  limit: PropTypes.number,

  // children MUST be a function
  children: PropTypes.func.isRequired,
}

const makeMapStateToProps = () => {
  const isFetchingHistory = makeIsFetchingHistory()
  const getUserHistoryTypeList = makeGetUserHistoryTypeList()
  const getUserHistoryTypePagination = makeGetUserHistoryTypePagination()

  const mapStateToProps = (state, props) => {
    return {
      // history: getHistory(state, props),
      isFetchingHistory: isFetchingHistory(state, props),
      historyData: getUserHistoryTypeList(state, props),
      pagination: getUserHistoryTypePagination(state, props),
      loadedRestaurantsIds: getLoadedRestaurantsIds(state),
    }
  }
  return mapStateToProps
}

export default connect(
  makeMapStateToProps,
  {
    fetchUserHistory,
  }
)(HistoryTypeContainer)
