const MangoPayErrors = {
  registerCreditCard: errorCode => {
    const errors = {
      '02625': 'global.credit_card.errors.number_invalid',
      '02626': 'global.credit_card.errors.date_invalid',
      '02627': 'global.credit_card.errors.security_code_invalid',
      '02101': 'global.credit_card.errors.internal_error',
      '01902': 'global.credit_card.errors.card_inactive',
      '02624': 'global.credit_card.errors.card_expired',
      '02631': 'global.credit_card.errors.delay_exceeded',
    }

    return errors[errorCode] || "Un problème est survenue lors de l'enregistrement de la carte"
  },
  list: {
    125: 'global.mangopay.error.125',
    126: 'global.mangopay.error.126',
    127: 'global.mangopay.error.127',
    128: 'global.mangopay.error.128',
    129: 'global.mangopay.error.129',
    130: 'global.mangopay.error.130',
    131: 'global.mangopay.error.131',
    132: 'global.mangopay.error.132',
    133: 'global.mangopay.error.133',
    134: 'global.mangopay.error.134',
    135: 'global.mangopay.error.135',
    136: 'global.mangopay.error.136',
    137: 'global.mangopay.error.137',
    138: 'global.mangopay.error.138',

    13100: 'global.mangopay.error.13100',
  },
}

export default MangoPayErrors
