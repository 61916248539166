import React from 'react'
import QrCode from 'pmt-ui/QrCode'

const QrCodeView = ({ value, size, backgroundgColor, foregroundColor, level }) => (
  <QrCode
    value={value}
    size={size}
    backgroundgColor={backgroundgColor}
    foregroundColor={foregroundColor}
    level={level}
  />
)

export default QrCodeView
