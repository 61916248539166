import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import ui from 'pmt-modules/reduxUi'

import ErrorBlock from 'pmt-ui/ErrorBlock'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import MessageBlock from 'pmt-ui/MessageBlock'
import NumberField from 'pmt-ui/NumberField'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'

import { Button } from '../../../components/Button'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
  },
  logo: {
    marginTop: theme.spacing(6),
    '&>img': {
      maxWidth: 72,
      maxHeight: 40,
    },
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
    maxWidth: 260,
    whiteSpace: 'pre-line',
  },
  numberField: {
    textAlign: 'center',
  },
  caption: {
    marginTop: theme.spacing(2),
    color: theme.pmt.colors.grey91,
    maxWidth: 280,
  },
  submit: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    borderRadius: 0,
  },
  checkErrorMessage: {
    color: theme.pmt.status.error,
    marginTop: theme.spacing(3),
  },
  errorBlock: {
    justifyContent: 'center',
    textAlign: 'center',
  },
})

const View = ({
  classes,
  checkError,
  frontAppConfig,
  isFetchingCheck,
  restaurant,
  tableNumber,
  onSubmit,
  ui,
  updateUI,
}) => {
  return (
    <div className={classes.root}>
      <LoadingBlockWrapper show={isFetchingCheck} />
      <form
        className={classes.root}
        onSubmit={e => {
          e.preventDefault()
          onSubmit(ui.tableNumber || tableNumber)
        }}
      >
        <div className={classes.logo}>
          <img src={frontAppConfig.theme.logo.path || (restaurant && restaurant.logo)} alt="logo" />
        </div>

        <TypographyCustom className={classes.title} type="207" align="center">
          {tr('web_customer.table_number.title')}
        </TypographyCustom>

        <NumberField
          autoFocus
          inputProps={{
            className: classes.numberField,
          }}
          value={ui.tableNumber || tableNumber}
          placeholder={tr('web_customer.table_number.placeholder')}
          onChange={(e, tableNumber) => updateUI({ tableNumber })}
        />

        <TypographyCustom className={classes.caption} type="124">
          {tr('web_customer.table_number.caption')}
        </TypographyCustom>

        <TypographyCustom type="124" className={classes.checkErrorMessage}>
          <ErrorBlock
            error={checkError}
            mode={ErrorBlock.Mode.CUSTOM}
            customElement={<MessageBlock classes={{ container: classes.errorBlock }} />}
          />
        </TypographyCustom>

        <Button type="submit" className={classes.submit} disabled={!ui.tableNumber && !tableNumber}>
          <TypographyCustom type="187" skipColor>
            {tr('web_customer.table_number.submit')}
          </TypographyCustom>
        </Button>
      </form>
    </div>
  )
}

export default compose(
  ui({
    state: {
      tableNumber: null,
    },
  }),
  withStyles(styles)
)(View)
