import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Wheat = props => (
  <SvgIcon {...props}>
    <path d="M 11.441406 5.878906 C 11.554688 6.007812 11.722656 6.082031 11.894531 6.082031 C 12.070312 6.082031 12.238281 6.007812 12.351562 5.878906 C 12.859375 5.304688 13.609375 4.25 13.609375 3.039062 C 13.609375 1.832031 12.859375 0.777344 12.351562 0.203125 C 12.238281 0.0742188 12.070312 0 11.898438 0 C 11.722656 0 11.554688 0.0742188 11.441406 0.199219 C 10.933594 0.777344 10.183594 1.832031 10.183594 3.039062 C 10.183594 4.246094 10.933594 5.304688 11.441406 5.878906 Z M 11.441406 5.878906 " />
    <path d="M 11.898438 9.835938 C 11.992188 9.839844 14.226562 9.925781 15.542969 8.664062 C 16.433594 7.808594 16.679688 6.554688 16.746094 5.800781 C 16.761719 5.636719 16.699219 5.46875 16.574219 5.351562 C 16.449219 5.234375 16.277344 5.171875 16.101562 5.1875 C 15.320312 5.25 14.007812 5.488281 13.117188 6.339844 C 12.132812 7.285156 11.933594 8.71875 11.898438 9.421875 C 11.863281 8.71875 11.664062 7.285156 10.679688 6.339844 C 9.789062 5.488281 8.476562 5.25 7.695312 5.1875 C 7.519531 5.171875 7.347656 5.234375 7.222656 5.351562 C 7.101562 5.472656 7.039062 5.636719 7.054688 5.804688 C 7.117188 6.554688 7.367188 7.808594 8.257812 8.664062 C 9.574219 9.925781 11.804688 9.839844 11.898438 9.835938 Z M 11.898438 9.835938 " />
    <path d="M 13.117188 10.867188 C 12.132812 11.8125 11.9375 13.246094 11.898438 13.949219 C 11.863281 13.246094 11.667969 11.8125 10.683594 10.867188 C 9.789062 10.015625 8.480469 9.777344 7.695312 9.714844 C 7.519531 9.703125 7.347656 9.761719 7.226562 9.878906 C 7.101562 10 7.039062 10.164062 7.054688 10.332031 C 7.121094 11.082031 7.367188 12.339844 8.257812 13.191406 C 9.574219 14.453125 11.804688 14.367188 11.898438 14.363281 C 11.992188 14.367188 14.226562 14.453125 15.542969 13.191406 C 16.433594 12.335938 16.679688 11.082031 16.746094 10.328125 C 16.757812 10.164062 16.695312 9.996094 16.574219 9.878906 C 16.449219 9.761719 16.277344 9.699219 16.101562 9.714844 C 15.320312 9.777344 14.007812 10.015625 13.117188 10.867188 Z M 13.117188 10.867188 " />
    <path d="M 13.117188 15.398438 C 12.132812 16.339844 11.9375 17.773438 11.898438 18.476562 C 11.863281 17.773438 11.667969 16.339844 10.683594 15.398438 C 9.789062 14.542969 8.480469 14.304688 7.695312 14.242188 C 7.523438 14.230469 7.351562 14.289062 7.226562 14.410156 C 7.101562 14.527344 7.039062 14.691406 7.054688 14.859375 C 7.121094 15.609375 7.367188 16.867188 8.257812 17.71875 C 9.15625 18.582031 10.488281 18.816406 11.265625 18.875 L 11.265625 22.136719 C 11.265625 22.507812 11.535156 22.808594 11.921875 22.808594 C 12.308594 22.808594 12.574219 22.507812 12.574219 22.136719 L 12.574219 18.871094 C 13.359375 18.808594 14.65625 18.566406 15.542969 17.71875 C 16.433594 16.867188 16.679688 15.609375 16.746094 14.859375 C 16.757812 14.691406 16.695312 14.527344 16.574219 14.410156 C 16.449219 14.289062 16.277344 14.230469 16.101562 14.242188 C 15.320312 14.304688 14.007812 14.542969 13.117188 15.398438 Z M 13.117188 15.398438 " />
  </SvgIcon>
)
Wheat = pure(Wheat)
Wheat.displayName = 'Wheat'
Wheat.muiName = 'SvgIcon'

export default Wheat
