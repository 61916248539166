import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getRoute } from 'pmt-modules/routing'

import { getAbsolutePath } from 'pmt-utils/url'

import { PspRegisterUserCardType } from '../constants'
import { postUserCreditCardRegistrationType } from '../actions'
import {
  getDatasPostCreditCardRegistrationType,
  getIsFetchingPostCreditCardRegistrationType,
  getErrorPostCreditCardRegistrationType,
  getStatusSendUserCreditCardToPsp,
} from '../selectors'

/**
 * Container that fetch the credit cards for the given `user.id` and pass it to the children.
 * The `children` must be a `Function as child component`.
 *
 * Requirements:
 * - user.id, can be 'me'
 *
 */
class CreditCardRegistrationTypeContainer extends React.PureComponent {
  constructor(props) {
    super(props)

    if (
      props.user &&
      props.restaurant &&
      props.restaurant.pspRegisterUserCardType !== PspRegisterUserCardType.WEB_SERVICES
    ) {
      this.loadCreditCardRegistrationType(props)
    }
  }

  componentWillReceiveProps(nextProps) {
    const props = this.props

    if (
      ((props.user && props.user.id !== nextProps.user.id) ||
        (!props.user && nextProps.user) ||
        (props.user && !props.restaurant && nextProps.restaurant)) &&
      nextProps.restaurant &&
      nextProps.restaurant.pspRegisterUserCardType !== PspRegisterUserCardType.WEB_SERVICES &&
      ((props.restaurant && props.restaurant.id !== nextProps.restaurant.id) ||
        (!props.restaurant && nextProps.restaurant) ||
        (props.restaurant && !props.user && nextProps.user))
    ) {
      this.loadCreditCardRegistrationType(nextProps)
    }
  }

  loadCreditCardRegistrationType(props) {
    props.postUserCreditCardRegistrationType(props.user.id, props.restaurant.id, undefined, {
      urlSuccess: getAbsolutePath(getRoute('ORDER__PAYMENT__SUCCESS')),
      urlFailure: getAbsolutePath(getRoute('ORDER__PAYMENT__FAILURE')),
      urlCancel: getAbsolutePath(getRoute('ORDER__PAYMENT__CANCEL')),
    })
  }

  render() {
    const {
      CreditCardRegistrationTypeWrappedComponent,
      isFetchingPostCreditCardRegistrationType,
      datasPostCreditCardRegistrationType,
      errorPostCreditCardRegistrationType,
      statusSendUserCreditCardToPsp,
      user,
      restaurant,
      children,
      ...otherProps
    } = this.props

    return (
      <CreditCardRegistrationTypeWrappedComponent
        isFetchingPostCreditCardRegistrationType={isFetchingPostCreditCardRegistrationType}
        datasPostCreditCardRegistrationType={datasPostCreditCardRegistrationType}
        errorPostCreditCardRegistrationType={errorPostCreditCardRegistrationType}
        statusSendUserCreditCardToPsp={statusSendUserCreditCardToPsp}
        restaurant={restaurant}
        {...otherProps}
      />
    )
  }
}

CreditCardRegistrationTypeContainer.propTypes = {
  datasPostCreditCardRegistrationType: PropTypes.object,
  isFetchingPostCreditCardRegistrationType: PropTypes.bool,
  errorPostCreditCardRegistrationType: PropTypes.object,
}

const mapStateToProps = (state, props) => ({
  datasPostCreditCardRegistrationType: getDatasPostCreditCardRegistrationType(state),
  isFetchingPostCreditCardRegistrationType: getIsFetchingPostCreditCardRegistrationType(state),
  errorPostCreditCardRegistrationType: getErrorPostCreditCardRegistrationType(state),
  statusSendUserCreditCardToPsp: getStatusSendUserCreditCardToPsp(state),
})

export default connect(
  mapStateToProps,
  {
    postUserCreditCardRegistrationType,
  }
)(CreditCardRegistrationTypeContainer)
