import React from 'react'

import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  section: {
    padding: `0 ${theme.spacing(3)}px`,
  },
  title: {
    paddingBottom: theme.spacing(1),
    borderBottom: `3px solid ${theme.palette.primary.main}`,
  },
  content: {
    marginTop: theme.spacing(3),
  },
})

const Section = ({ classes, title, children }) => (
  <div className={classes.section}>
    <TypographyCustom className={classes.title} type="204">
      {title}
    </TypographyCustom>

    <div className={classes.content}>{children}</div>
  </div>
)

export default withStyles(styles)(Section)
