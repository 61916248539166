import { tr } from 'pmt-modules/i18n'
import React from 'react'

import { ForgotPasswordFormView } from 'pmt-modules/forgotPassword/forms/forgotPassword'

import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import ErrorBlock from 'pmt-ui/ErrorBlock'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

import { Button } from '../../components/Button'

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
  },
  caption: {
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
    marginBottom: theme.spacing(2),
  },
})

const View = ({ classes, isFetching, formIsValid, userError, onSubmit }) => (
  <div className={classes.root}>
    <form
      onSubmit={event => {
        event.preventDefault()
        onSubmit()
      }}
    >
      <LoadingBlockWrapper show={isFetching} />

      <ErrorBlock mode={ErrorBlock.Mode.SNACKBAR} error={userError} />

      <TypographyCustom type="144" skipColor align="center" className={classes.caption}>
        {tr('web_customer.forgot_password.caption')}
      </TypographyCustom>

      <ForgotPasswordFormView />

      <Button
        type="submit"
        className="u-marginTop20 u-sizeFull"
        disabled={!formIsValid || isFetching}
      >
        <TypographyCustom type="167" skipColor>
          {tr('global.validate')}
        </TypographyCustom>
      </Button>
    </form>
  </div>
)

export default withStyles(styles)(View)
