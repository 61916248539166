import React from 'react'

import AuthStateContainer from './index'

/**
 * Add the AuthStateContainer as HOC for the given component
 */
const withAuthState = (AuthWrappedComponent) => {
  return (props) => (
    <AuthStateContainer
      AuthWrappedComponent={AuthWrappedComponent}
      {...props}
    />
  )
}

export default withAuthState
