import { createApiCallAction, createApiEnumAction } from '../../api/utils'

import AuthAppApi from '../../api/calls/AuthAppApi'

export const PostAuthorizeAppAction = createApiEnumAction(
  'OAUTH::APP::AUTHORIZE::POST'
)

export const postAuthorizeApp = props =>
  createApiCallAction(PostAuthorizeAppAction, AuthAppApi.postAuthorize(props), {
    props,
  })
