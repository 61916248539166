import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { withStyles } from 'pmt-ui/styles'

import Typography from 'pmt-ui/Typography'

const styles = theme => ({
  root: {
    textAlign: 'center',
    position: 'relative',
    lineHeight: 0,
  },
  label: {
    lineHeight: 1,
    zIndex: 2,
    position: 'relative',
    display: 'inline-block',
    backgroundColor: '#fff',
    padding: '0 1rem 0 .9rem',
  },
  border: {
    width: '100%',
    backgroundColor: 'transparent',
    height: '.1rem',
    position: 'absolute',
    top: '50%',
    marginTop: 0,
    zIndex: 1,
    borderTop: `.1rem solid ${theme.palette.divider}`,
  },
})

/**
 * Display a divider line, with label
 * Default view should render something like this
 * ----------  label  ----------
 */
class LabelDivider extends React.Component {
  render() {
    const { label, classes, className, ...other } = this.props

    return (
      <div className={clsx(className, classes.root)} {...other}>
        <Typography component="span" className={classes.label}>
          {label}
        </Typography>
        <div className={classes.border} />
      </div>
    )
  }
}

LabelDivider.propTypes = {
  label: PropTypes.string,
}

export default withStyles(styles)(LabelDivider)
