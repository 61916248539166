import { SdkRoutingEnum, Route } from 'pmt-modules/routing'

const RoutingEnum = {
  ...SdkRoutingEnum,

  /**
   * TOP UP
   */
  // TODO: change /me/ by /:userId
  USER_HISTORY: new Route('USER::ME::HISTORY', '/users/me/history'),

  USER_HISTORY_TRANSACTION: new Route(
    'USER::ME::HISTORY_TRANSACTION',
    '/users/me/history/transactions/:checkPosId'
  ),

  // TODO: change /me/ by /:userId
  USER_TOP_UP: new Route('USER::ME::TOP_UP', '/users/me/top-up'),

  USER_TOP_UP_CONFIRM: new Route('USER::ME::TOP_UP::CONFIRM', '/users/me/top-up/confirm'),

  TOPUP_VERIFICATION: new Route(
    'TOPUP_VERIFICATION::VERIFICATION',
    '/users/me/top-up/verification'
  ),

  AUTHORIZE_APP: new Route('OAUTH::AUTHORIZE', '/authorize'),

  // TODO: change /me/ by /:userId
  USER_QR_CODE: new Route('USER::ME::QR_CODE', '/users/me/qr-code'),

  /**
   * Check and Payment
   */
  RESTAURANT: new Route('RESTAURANT', '/restaurants/:restaurantId', false),

  PAYMENT_TABLE_NUMBER: new Route(
    'PAYMENT::TABLE_NUMBER',
    '/restaurants/:restaurantId/payment/table-number'
  ),

  PAYMENT_TABLE_NUMBER_VIEW: new Route(
    'PAYMENT::TABLE_NUMBER::VIEW',
    '/restaurants/:restaurantId/payment/table-number/:tableNumberId'
  ),

  PAYMENT_CHECK_VIEW: new Route(
    'PAYMENT::CHECK_VIEW',
    '/restaurants/:restaurantId/payment/check/:checkId'
  ),

  PAYMENT_SHARE: new Route(
    'PAYMENT::PAYMENT_SHARE',
    '/restaurants/:restaurantId/payment/check/:checkId/share'
  ),

  PAYMENT_CHOOSE: new Route(
    'PAYMENT::PAYMENT_CHOOSE',
    '/restaurants/:restaurantId/payment/check/:checkId/choose'
  ),

  PAYMENT_PAYMENT: new Route(
    'PAYMENT::PAYMENT',
    '/restaurants/:restaurantId/payment/check/:checkId/payment'
  ),

  PAYMENT_VERIFICATION: new Route(
    'PAYMENT::VERIFICATION',
    '/restaurants/:restaurantId/payment/check/:checkId/verification'
  ),

  PAYMENT__ASYNC__FORM: new Route('PAYMENT__ASYNC__FORM', '/payment/async/form'),

  ORDER__PAYMENT__SUCCESS: new Route('ORDER::PAYMENT::SUCCESS', '/payment/success', true),

  ORDER__PAYMENT__FAILURE: new Route('ORDER::PAYMENT::FAILURE', '/payment/failure', true),

  ORDER__PAYMENT__CANCEL: new Route('ORDER::PAYMENT::CANCEL', '/payment/cancel', true),

  PAYMENT_CONFIRM: new Route(
    'PAYMENT::PAYMENT_CONFIRM',
    '/restaurants/:restaurantId/payment/check/:checkId/confirm'
  ),
}

export default RoutingEnum
