import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'

import {
  forgotPassword,
  getForgotPasswordError,
  getForgotPasswordIsFetching,
} from 'pmt-modules/forgotPassword'
import { FormType, withForm } from 'pmt-modules/form'

import Page from '../../components/Page'
import View from './View'

class ForgotPasswordPage extends React.Component {
  handleSubmit = () => {
    const credentials = this.props.formData
    this.props.forgotPassword(credentials.username)
  }

  render() {
    const { userError, formIsValid, isFetching } = this.props

    return (
      <Page title={tr('web_customer.forgot_password.title')}>
        <View
          isFetching={isFetching}
          userError={userError}
          formIsValid={formIsValid}
          onSubmit={this.handleSubmit}
        />
      </Page>
    )
  }
}

const mapStateToProps = state => ({
  isFetching: getForgotPasswordIsFetching(state),
  userError: getForgotPasswordError(state),
})

export default withForm(FormType.FORGOT_PASSWORD)(
  connect(
    mapStateToProps,
    {
      forgotPassword,
    }
  )(ForgotPasswordPage)
)
