import React from 'react'

import { tr } from 'pmt-modules/i18n'

import UserType from 'pmt-modules/user/constants/UserType'
import Divider from 'pmt-ui/Divider'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import Price from 'pmt-ui/Price'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import { formatPriceWithCurrency } from 'pmt-utils/currency'
import { TipsValueType } from 'pmt-modules/payment/constants'


import PaymentMethods from 'app/components/payment/PaymentMethods'
import ErrorAlert from './components/ErrorAlert'
import Tips from 'pmt-modules/payment/components/Tips'


const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%',
  },
  outstandingBalance: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  receipt: {
    fontStyle: 'italic',
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tipsTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  scrollContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
  },
  submitLabel: {
    display: 'flex',
  },
})

const View = ({
  classes,
  paymentError,
  isFetching,
  payment,
  onPostPayment,
  onPostNewCardPayment,
  resetPostPayment,
  restaurant,
  restaurantsGroup,
  selectTips,
  user,
  appConfig,
}) => (
  <div className={classes.root}>
    <div className={classes.outstandingBalance}>
      <TypographyCustom type="164">
        {tr('web_customer.payment.payment.outstanding_balance')}
      </TypographyCustom>
      <TypographyCustom type="247">
        <Price value={payment.totalWithTipFormatted} />
      </TypographyCustom>
    </div>

    <TypographyCustom type="124" className={classes.receipt}>
      {tr('web_customer.payment.payment.receipt')}
    </TypographyCustom>

    <Divider className={classes.divider} />

    <div className={classes.scrollContent}>
      {appConfig?.front?.display?.tipsSettings?.activated && restaurant?.allowTips && (
        <React.Fragment>
          <div className={classes.tipsTitle}>
            <TypographyCustom type="164">
              {tr('web_customer.payment.payment.tips')}
            </TypographyCustom>
            {(appConfig?.front?.display?.tipsSettings?.slider
            || (appConfig?.front?.display?.tipsSettings?.tipsFixedValuesSettings?.type === TipsValueType.PERCENTAGE)
            ) && (
              <TypographyCustom type="164">
                {formatPriceWithCurrency(payment.tips)}
              </TypographyCustom>
            )}
          </div>
          <Tips
              tips={payment.tips}
              amountWithoutTips={payment.amount}
              onChoseTip={tips => selectTips(tips)}
              tipsSettings={appConfig?.front?.display?.tipsSettings}
              roundingValue={appConfig?.front?.display?.roundingValue}/>
          <Divider className={classes.divider} />
        </React.Fragment>
      )}

      <ErrorAlert
        paymentError={paymentError}
        resetPostPayment={resetPostPayment}
      />

      <LoadingBlockWrapper show={isFetching} />

      <PaymentMethods
        isFetching={isFetching}
        onSubmit={onPostPayment}
        onSubmitNewCard={onPostNewCardPayment}
        restaurant={restaurant}
        restaurantsGroup={restaurantsGroup}
        user={user}
        submitLabel={
          <TypographyCustom type="207" skipColor className={classes.submitLabel}>
            {tr('web_customer.payment.payment.pay')}
            &nbsp;
            <Price value={payment.totalWithTipFormatted} />
          </TypographyCustom>
        }
        canRegisterCardWithName={user && user.type === UserType.NORMAL}
      />
    </div>
  </div>
)

export default withStyles(styles)(View)
