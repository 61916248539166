import React from 'react'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

import { TypographyCustom } from 'pmt-ui/Typography'

const PaymentMethod = ({ checked, isOnlyMethodAllowed, label, value, children }) => (
  <React.Fragment>
    {!isOnlyMethodAllowed ? (
      <FormControlLabel
        fullWidth
        value={value}
        control={<Radio color="primary" />}
        label={<TypographyCustom type={checked ? '167' : '164'}>{label}</TypographyCustom>}
      />
    ) : (
      <TypographyCustom type={checked ? '167' : '164'}>{label}</TypographyCustom>
    )}
    {children}
  </React.Fragment>
)

export default PaymentMethod