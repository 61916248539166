import React from 'react'
import { createLoadable } from 'pmt-utils/react-loadable'

// -- app
import AuthAppPage from '../pages/authApp'
// -- user
import LoginPage from '../pages/login'
import ForgotPasswordPage from '../pages/forgotpassword'
import RegisterPage from '../pages/register'
// -- restaurant
import RestaurantPage from '../pages/restaurant'
// -- payment
import TableNumberPage from '../pages/payment/tableNumber'
import CheckPage from '../pages/payment/check'
import PaymentSharePage from '../pages/payment/share'
import PaymentChoosePage from '../pages/payment/choose'
import PaymentPaymentPage from '../pages/payment/payment'
import PaymentVerificationPage from '../pages/payment/verification'
import AsyncPaymentFormPage from '../pages/payment/asyncForm'
import PaymentConfirmPage from '../pages/payment/confirm'
import PaymentSuccessPage from '../pages/payment/success'
import PaymentFailurePage from '../pages/payment/failure'
import PaymentCancelPage from '../pages/payment/cancel'
// -- user account
import UserHistoryPage from '../pages/user/history'
import UserQrCodePage from '../pages/user/qrCode'
import TopUpPage from '../pages/topUp/topUp'
import TopUpConfirmPage from '../pages/topUp/confirm'

import { APP_TOKEN_QUERY_PARAM } from 'pmt-modules/appSecurity'
import RoutingEnum from './routingEnum'

// list of the queries that are global to all our urls, and should be kept
const globalQueries = [
  // app token
  APP_TOKEN_QUERY_PARAM,
  'apiVersion',
  'simulate',
]

// merge our globalQueries with the specific queries of the route
const getQueries = (queries = []) => [...globalQueries, ...queries]

const createAsyncPage = loader => createLoadable(loader, () => <div />)

/**
 * We define  the `handler` for each route of our RoutingEnum.
 * The `handler` is the React Component to display for the route.
 * It should be a container in most cases.
 */
const routes = [
  {
    definition: RoutingEnum.HOME,
    handler: () => null,
  },
  //
  // OAuth
  //
  {
    definition: RoutingEnum.AUTHORIZE_APP,
    handler: AuthAppPage,
  },
  //
  // User
  //
  {
    definition: RoutingEnum.LOGIN,
    handler: LoginPage,
  },
  {
    definition: RoutingEnum.REGISTER,
    handler: RegisterPage,
  },
  {
    definition: RoutingEnum.FORGOT_PASSWORD,
    handler: ForgotPasswordPage,
  },
  //
  // restaurant
  //
  {
    definition: RoutingEnum.RESTAURANT,
    handler: RestaurantPage,
  },
  //
  // Payment
  //
  {
    definition: RoutingEnum.PAYMENT_TABLE_NUMBER,
    handler: TableNumberPage,
  },
  {
    definition: RoutingEnum.PAYMENT_TABLE_NUMBER_VIEW,
    handler: TableNumberPage,
  },
  {
    definition: RoutingEnum.PAYMENT_CHECK_VIEW,
    handler: CheckPage,
  },
  {
    definition: RoutingEnum.PAYMENT_SHARE,
    handler: PaymentSharePage,
  },
  {
    definition: RoutingEnum.PAYMENT_CHOOSE,
    handler: PaymentChoosePage,
  },
  {
    definition: RoutingEnum.PAYMENT_PAYMENT,
    handler: PaymentPaymentPage,
  },
  {
    definition: RoutingEnum.PAYMENT_VERIFICATION,
    handler: PaymentVerificationPage,
  },
  {
    definition: RoutingEnum.PAYMENT_CONFIRM,
    handler: PaymentConfirmPage,
  },
  {
    definition: RoutingEnum.PAYMENT__ASYNC__FORM,
    handler: AsyncPaymentFormPage,
    props: {
      authRequired: true,
    },
  },
  {
    definition: RoutingEnum.ORDER__PAYMENT__SUCCESS,
    handler: PaymentSuccessPage,
    props: {
      authRequired: true,
    },
  },
  {
    definition: RoutingEnum.ORDER__PAYMENT__FAILURE,
    handler: PaymentFailurePage,
    props: {
      authRequired: true,
    },
  },
  {
    definition: RoutingEnum.ORDER__PAYMENT__CANCEL,
    handler: PaymentCancelPage,
    props: {
      authRequired: true,
    },
  },
  //
  // user account
  //
  {
    definition: RoutingEnum.USER_HISTORY,
    handler: UserHistoryPage,
  },
  {
    definition: RoutingEnum.USER_HISTORY_TRANSACTION,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "UserHistoryTransactionPage" */ '../pages/user/historyTransactionDetail')
    ),
  },
  {
    definition: RoutingEnum.USER_TOP_UP,
    handler: TopUpPage,
  },
  {
    definition: RoutingEnum.TOPUP_VERIFICATION,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "TopUpVBPage" */ '../pages/topUp/verification')
    ),
  },
  {
    definition: RoutingEnum.USER_TOP_UP_CONFIRM,
    handler: TopUpConfirmPage,
  },
  {
    definition: RoutingEnum.USER_QR_CODE,
    handler: UserQrCodePage,
  },

  //
  // 404
  //

  {
    definition: RoutingEnum.PAGE_NOT_FOUND,
    handler: createAsyncPage(() =>
      import(/*  webpackChunkName: "PageNotFound" */ '../pages/pageNotFound')
    ),
  },
]

export default routes.map(route => {
  if (!route.props) {
    route.props = {}
  }
  // handle props.query to set global queries
  if (!route.props.query) {
    route.props.query = getQueries([])
  }

  return route
})
