import React, { Fragment } from 'react'
import compose from 'recompose/compose'
import isNil from 'lodash/isNil'
import { tr } from 'pmt-modules/i18n'
import { PspRegisterUserCardType } from 'pmt-modules/creditCard'
import { withForm, FormType } from 'pmt-modules/form'
import { withUserMe } from 'pmt-modules/userMe'
import { getRoute } from 'pmt-modules/routing'
import { getAbsolutePath } from 'pmt-utils/url'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import { withStyles } from 'pmt-ui/styles'
import { TypographyCustom } from 'pmt-ui/Typography'
import Button, { ButtonLink } from 'app/components/Button'
import AddCreditCardFormView from './AddCreditCardFormView'
import CreditCardList from './CreditCardList'

const styles = theme => ({
  addCreditCardButton: {
    width: '100%',
  },
  creditCardForm: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  name: {
    marginTop: 0,
  },
  submitCard: {
    width: '100%',
    marginTop: theme.spacing(2),
    minHeight: 52,
  },
  newCard: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
})

@withUserMe
class CreditCardPayment extends React.Component {
  constructor(props) {
    super(props)

    if (!props.creditCards) {
      this.props.setShowCreditCardForm(true)
    }

    if (props.creditCards && props.creditCards.length >= 1) {
      props.onToggleCard(true, props.creditCards[0])
      this.props.setShowCreditCardForm(false)
    }
  }

  componentWillReceiveProps(nextProps) {
    // default when receiving card list
    if (!this.props.creditCards && nextProps.creditCards) {
      if (nextProps.creditCards.length >= 1) {
        if (nextProps.creditCards.length === 1) {
          nextProps.onToggleCard(true, nextProps.creditCards[0])
        }
        nextProps.setShowCreditCardForm(false)
      } else {
        // empty list
        this.props.setShowCreditCardForm(true)
      }
    }

    // but override when...

    // user already had at least one credit card and click on adding a new one
    // or delete his cards, list becomes empty so we want to display form
    if (
      (isNil(this.props.datasPostCreditCardRegistrationType) &&
        !isNil(nextProps.datasPostCreditCardRegistrationType) &&
        !nextProps.showCreditCardForm) ||
      (!this.props.isEmptyCreditCards && nextProps.isEmptyCreditCards && !nextProps.isFetching)
    ) {
      this.openCreditCardForm()
      nextProps.setShowCreditCardForm(true)
    }

    // hide credit card form after a success full POST
    if (
      this.props.postPspDatasIsFetching &&
      !nextProps.postPspDatasIsFetching &&
      !isNil(nextProps.postPspDatasCard) &&
      nextProps.showCreditCardForm
    ) {
      nextProps.setShowCreditCardForm(false)
    }
  }

  openCreditCardForm = (shouldResetCards = false) => {
    const {
      restaurant,
      checkId,
      user,
      setShowCreditCardForm,
      postUserCreditCardRegistrationType,
    } = this.props

    // TODO: handle pspRegisterUserCardType without restaurant for top-up
    // https://www.notion.so/paymytable/TopUp-Paygreen-f2f8197d60eb4ea391111a331338cb76
    const pspRegisterUserCardType = restaurant ? restaurant.pspRegisterUserCardType : 'WEB_SERVICES'

    if (shouldResetCards) {
      this.props.resetUserCreditCardRegistrationTypeError()
      this.props.resetPspDatasError()
      this.props.onResetUserCard()
      this.props.resetUserCreditCardToPspSend()
    }

    if (pspRegisterUserCardType !== PspRegisterUserCardType.WEB_SERVICES) {
      postUserCreditCardRegistrationType(user.id, restaurant.id, {
        urlSuccess: getAbsolutePath(getRoute('ORDER__PAYMENT__SUCCESS'), {
          restaurantId: restaurant.id,
          checkId: checkId,
        }),
        urlFailure: getAbsolutePath(getRoute('ORDER__PAYMENT__FAILURE'), {
          restaurantId: restaurant.id,
          checkId: checkId,
        }),
        urlCancel: getAbsolutePath(getRoute('ORDER__PAYMENT__CANCEL'), {
          restaurantId: restaurant.id,
          checkId: checkId,
        }),
      })
    } else {
      setShowCreditCardForm(true)
    }
  }

  handleSubmitCreditCard = () => {
    const { formData, onSubmitNewCard, user } = this.props

    // we set expiration year with the last 2 digits
    // (yes it's ugly, but you know what ? maybe one day, the API we'll accept 4 digits)
    formData.expirationDateYear = parseInt(formData.expirationDateYear.toString().substr(-2), 10)

    onSubmitNewCard(user.id, formData)
  }

  render() {
    const {
      classes,
      // --
      isEmptyCreditCards,
      isOnlyOneCreditCard,
      creditCards,
      onClickDeleteCreditCard,
      onToggleCard,
      selectedCreditCard,
      // --
      // user,
      // TODO: make without it for deposit
      restaurant,
      restaurantsGroup,
      isFetching,
      isFetchingPostCreditCardRegistrationType,
      postPspDatasIsFetching,
      // statusSendUserCreditCardToPsp,
      sendUserCreditCardToPspIsFetching,
      // datasPostCreditCardRegistrationType,
      showCreditCardForm,
      setShowCreditCardForm,
      submitLabel,
      canRegisterCardWithName,
    } = this.props

    // TODO: handle pspRegisterUserCardType without restaurant for top-up
    // https://www.notion.so/paymytable/TopUp-Paygreen-f2f8197d60eb4ea391111a331338cb76
    const pspRegisterUserCardType = restaurant ? restaurant.pspRegisterUserCardType : 'WEB_SERVICES'

    return (
      <Fragment>
        <LoadingBlockWrapper
          show={
            isFetchingPostCreditCardRegistrationType ||
            sendUserCreditCardToPspIsFetching ||
            postPspDatasIsFetching
          }
        />

        <CreditCardList
          creditCards={creditCards}
          isFetching={isFetching}
          onClickDeleteCreditCard={onClickDeleteCreditCard}
          onToggleCard={onToggleCard}
          selectedCreditCard={selectedCreditCard}
          setShowCreditCardForm={setShowCreditCardForm}
        />

        {!showCreditCardForm &&
          ((pspRegisterUserCardType !== PspRegisterUserCardType.WEB_SERVICES &&
            !isFetchingPostCreditCardRegistrationType) ||
            (!isEmptyCreditCards &&
              pspRegisterUserCardType === PspRegisterUserCardType.WEB_SERVICES)) && (
            <ButtonLink
              className={classes.addCreditCardButton}
              onClick={() => this.openCreditCardForm(true)}
            >
              <TypographyCustom type="144" align="center" skipColor>
                {tr('web_customer.credit_card.add_credit_card_button')}
              </TypographyCustom>
            </ButtonLink>
          )}

        {pspRegisterUserCardType === PspRegisterUserCardType.WEB_SERVICES &&
          (showCreditCardForm && (
            <form className="u-marginTop30">
              {!isEmptyCreditCards && (
                <TypographyCustom type="167" className={classes.newCard}>
                  {tr('web_customer.payment.pay_with_new_card')}
                  <Button
                    size="small"
                    variant="outlined"
                    classes={{ root: 'u-floatRight' }}
                    onClick={() => {
                      if (isOnlyOneCreditCard) {
                        onToggleCard(true, creditCards[0])
                      }
                      setShowCreditCardForm(false)
                    }}
                  >
                    {tr('web_customer.global.cancel')}
                  </Button>
                </TypographyCustom>
              )}

              <AddCreditCardFormView
                setShowCreditCardForm={setShowCreditCardForm}
                formClasses={{ cvv: classes.cvv, name: classes.name }}
                submitLabel={submitLabel}
                onSubmitCreditCard={this.handleSubmitCreditCard}
                withName={canRegisterCardWithName}
                tosUrl={restaurantsGroup?.userSettings?.tosUrl}
              />
            </form>
          ))}
      </Fragment>
    )
  }
}

export default compose(
  withForm(FormType.CREDIT_CARD),
  withStyles(styles)
)(CreditCardPayment)
