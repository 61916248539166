import { ModalType } from 'pmt-modules/dialog'

import ChooseEntryDividerDialog from '../components/payment/CheckList/ChooseEntryDividerDialog'
import GenerousPaymentDialog from '../components/payment/GenerousPaymentDialog'

const dialogs = [
  {
    type: ModalType.PAYMENT_CHOOSE_ENTRY_DIVIDER,
    view: ChooseEntryDividerDialog,
  },
  {
    type: ModalType.PAYMENT_GENEROUS_PAYMENT,
    view: GenerousPaymentDialog,
  },
]

export default dialogs
