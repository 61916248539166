import { tr } from 'pmt-modules/i18n'
import React from 'react'

import LoadingBlock from 'pmt-ui/LoadingBlock'
import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingBlock: {
    background: 'transparent',
  },
  wording: {
    marginBottom: theme.spacing(4),
  },
})

const View = ({ classes }) => (
  <div className={classes.root}>
    <div>
      <TypographyCustom type={167} align="center" className={classes.wording}>
        {tr('web_customer.payment.verification')}
      </TypographyCustom>
      <LoadingBlock show classes={{ loadingBlock: classes.loadingBlock }} />
    </div>
  </div>
)

export default withStyles(styles)(View)
