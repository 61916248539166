import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { fetchUserUserAccount } from '../../actions'

import { makeGetUserUserAccount, makeIsFetchingUserUserAccount } from '../../selectors'

import { redirectTo, getRoute } from '../../../routing'

/**
 * The `children` must be a `Function as child component`.
 *
 * Requirements:
 * - userId, can be 'me'
 *
 */
class UserAccountContainer extends React.Component {
  componentWillMount() {
    if (this.props.userAccount === null || this.props.refreshToken) {
      this.loadUserAccount(this.props)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userAccountId !== this.props.userAccountId) {
      this.loadUserAccount(nextProps)
    }
  }

  loadUserAccount(props) {
    props.fetchUserUserAccount(props.userId, props.userAccountId, props.refreshToken)
  }

  render() {
    const { isFetchingUserAccount, userAccount, userId, children, ...otherProps } = this.props

    return children({
      isFetchingUserAccount,
      userAccount,
      userId,
      onRedirectToTopUp: () => {
        this.props.redirectTo(getRoute('USER_TOP_UP'))
      },
      onLoadUserAccount: () => {
        this.loadUserAccount(this.props)
      },
      ...otherProps,
    })
  }
}

UserAccountContainer.propTypes = {
  fetchUserUserAccount: PropTypes.func.isRequired,
  isFetchingUserAccount: PropTypes.bool,
  userAccount: PropTypes.object,
  refreshToken: PropTypes.bool,

  // children MUST be a function
  children: PropTypes.func.isRequired,
}

UserAccountContainer.defaultProps = {
  refreshToken: false,
}

const makeMapStateToProps = () => {
  const getUserAccount = makeGetUserUserAccount()
  const isFetchingUserAccount = makeIsFetchingUserUserAccount()

  const mapStateToProps = (state, props) => {
    return {
      // Note: For now we handle only one UserAccount, so the userAccount is the default one
      userAccount: getUserAccount(state, props),
      isFetchingUserAccount: isFetchingUserAccount(state, props),
    }
  }
  return mapStateToProps
}

export default connect(
  makeMapStateToProps,
  {
    fetchUserUserAccount,
    redirectTo,
  }
)(UserAccountContainer)
