import { AsyncPaymentMiddlewares } from 'pmt-modules/asyncPayment'
import { appConfigMiddlewares } from 'pmt-modules/appConfig'
import { authMiddlewares } from 'pmt-modules/auth'
import { creditCardMiddlewares } from 'pmt-modules/creditCard'
import { dataCatcherMiddlewares } from 'pmt-modules/dataCatcher'
import { forgotPasswordMiddlewares } from 'pmt-modules/forgotPassword'
import { restaurantThemeMiddleware } from 'pmt-modules/restaurant'
import { restaurantsGroupThemeMiddleware } from 'pmt-modules/restaurantsGroup'
import { registrationMiddlewares } from 'pmt-modules/registration'
import { authorizeAppMiddlewares, getAppMiddlewares } from 'pmt-modules/authApp'
import { userAccountMiddlewares } from 'pmt-modules/userAccount'
import { appSecurityMiddlewares } from 'pmt-modules/appSecurity'
import { webCustomerMiddlewares } from 'pmt-modules/webCustomer'

export default [
  ...AsyncPaymentMiddlewares,
  ...appConfigMiddlewares,
  ...authMiddlewares,
  ...appSecurityMiddlewares,
  ...dataCatcherMiddlewares,
  ...forgotPasswordMiddlewares,
  restaurantThemeMiddleware,
  restaurantsGroupThemeMiddleware,
  ...registrationMiddlewares,
  ...creditCardMiddlewares,
  ...authorizeAppMiddlewares,
  ...getAppMiddlewares,
  ...userAccountMiddlewares,
  ...webCustomerMiddlewares,
]
