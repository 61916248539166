import moment from 'moment'

import { appConfigReducer } from 'pmt-modules/appConfig'
import { appSecurityReducer } from 'pmt-modules/appSecurity'
import { authReducer } from 'pmt-modules/auth'
import { authenticatedUserReducer } from 'pmt-modules/authenticatedUser'
import { authAppReducer } from 'pmt-modules/authApp'
import {
  userCreditCardsReducer,
  userSelectedCreditCardsReducer,
  deleteUserCreditCardReducer,

  // new process credit card v2
  postUserCreditCardRegistrationTypeReducer,
  sendUserCreditCardToPspReducer,
  postPspDatasReducer,
} from 'pmt-modules/creditCard'
import { forgotPasswordReducer } from 'pmt-modules/forgotPassword'
import { restaurantReducer } from 'pmt-modules/restaurant'
import { restaurantsGroupReducer } from 'pmt-modules/restaurantsGroup'
import { registrationReducer } from 'pmt-modules/registration'
import { checkReducer, paymentReducer, postPaymentReducer } from 'pmt-modules/payment'
import { fetchPaymentPdfLinkReducer} from 'pmt-modules/payment/pdf'
import { topUpReducer } from 'pmt-modules/topUp'
import { userUserAccountsReducer } from 'pmt-modules/userAccount'
import { userHistoryReducer } from 'pmt-modules/userHistory'
import { meReducer } from 'pmt-modules/userMe'

const persistExpiresAt = moment().add(60, 'minutes')
const persistOptions = { persist: true, persistExpiresAt }

// for 3DSecure we need to persist the postPayment data
checkReducer.options = { ...persistOptions }
paymentReducer.options = { ...persistOptions }
postPaymentReducer.options = { ...persistOptions }
topUpReducer.options = { ...persistOptions }

export default {
  appConfig: appConfigReducer,
  appSecurity: appSecurityReducer,
  auth: authReducer,
  authenticatedUser: authenticatedUserReducer,
  authApp: authAppReducer,
  check: checkReducer,
  deleteUserCreditCard: deleteUserCreditCardReducer,
  fetchPaymentPdfLink: fetchPaymentPdfLinkReducer,
  forgotPassword: forgotPasswordReducer,
  payment: paymentReducer,
  postPayment: postPaymentReducer,
  postUserCreditCardRegistrationType: postUserCreditCardRegistrationTypeReducer,
  sendUserCreditCardToPsp: sendUserCreditCardToPspReducer,
  postPspDatas: postPspDatasReducer,
  registration: registrationReducer,
  restaurant: restaurantReducer,
  restaurantsGroup: restaurantsGroupReducer,
  topUp: topUpReducer,
  userMe: meReducer,
  userHistory: userHistoryReducer,
  userCreditCards: userCreditCardsReducer,
  userSelectedCreditCards: userSelectedCreditCardsReducer,
  userAccounts: userUserAccountsReducer,
}
