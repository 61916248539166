import React from 'react'
import { connect } from 'react-redux'
import isEmpty from 'lodash/isEmpty'

import { withAppConfig } from 'pmt-modules/appConfig'
import { withRestaurant } from 'pmt-modules/restaurant/components'
import {
  fetchCheckWithTableNumber,
  getCheck,
  isFetchingCheck,
  getCheckError,
} from 'pmt-modules/payment'
import { redirectTo, getRoute } from 'pmt-modules/routing'

import Page from '../../../components/Page'
import View from './View'

/**
 * https://paymytable.atlassian.net/browse/PP-711
 * https://app.zeplin.io/project/5b616247d86fcf41520410cc/screen/5baca120fb8dcf563ffafee1
 */
@withAppConfig
@withRestaurant
class TableNumberPage extends React.PureComponent {
  constructor(props) {
    super(props)

    if (!isEmpty(props.params.tableNumberId)) {
      this.loadCheck(props.params.tableNumberId)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.params.tableNumberId) {
      if (!this.props.check && nextProps.check) {
        this.props.redirectTo(getRoute('PAYMENT_CHECK_VIEW'), {
          restaurantId: nextProps.params.restaurantId,
          checkId: nextProps.check.id,
        })
      }
    } else if (
      (!this.props.check && nextProps.check) ||
      (!this.props.checkError && nextProps.checkError)
    ) {
      this.props.redirectTo(
        getRoute('RESTAURANT'),
        { restaurantId: nextProps.params.restaurantId },
        { tableId: this.tableNumber }
      )
    }
  }

  handleSubmitTableNumber = tableNumber => this.loadCheck(tableNumber)

  loadCheck = tableNumber => {
    this.tableNumber = tableNumber
    this.props.fetchCheckWithTableNumber(this.props.params.restaurantId, tableNumber)
  }

  render() {
    return (
      <Page>
        <View
          frontAppConfig={this.props.frontAppConfig}
          isFetchingCheck={this.props.isFetchingCheck}
          checkError={this.props.checkError}
          restaurant={this.props.restaurant}
          tableNumber={this.props.params.tableNumberId}
          onSubmit={this.handleSubmitTableNumber}
        />
      </Page>
    )
  }
}

const mapStateToProps = state => ({
  check: getCheck(state),
  checkError: getCheckError(state),
  isFetchingCheck: isFetchingCheck(state),
})

export default connect(mapStateToProps, {
  fetchCheckWithTableNumber,
  redirectTo,
})(withRestaurant(TableNumberPage))
