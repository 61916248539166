import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import isUndefined from 'lodash/isUndefined'
import isNil from 'lodash/isNil'

import { addEntryToPayment, removeEntryFromPayment } from 'pmt-modules/payment'
import { displayChooseEntryDividerDialog } from 'pmt-modules/dialog'

import { withStyles } from 'pmt-ui/styles'
import Checkbox from 'pmt-ui/Checkbox'
import Price from 'pmt-ui/Price'
import { TypographyCustom } from 'pmt-ui/Typography'

import { existsOnArray } from 'pmt-utils/array'

const styles = theme => ({
  root: {
    width: '100%',
  },
  entry: {
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    display: 'flex',
  },
  entrySELECTION: {
    display: 'flex',
    position: 'relative',
    height: theme.spacing(6),
    padding: 0,
  },
  quantity: {
    float: 'left',
    // not too large, to keep place for the (sometimes long) item labels
    width: 30,
    lineHeight: '16px',
  },
  checkbox: {
    marginLeft: `-${theme.spacing(1.5)}px`,
  },
  nameContainer: {
    flex: 1,
    lineHeight: '16px',
  },
  name: {
    lineHeight: '18px',
  },
  nameContainerLISTING: {
    width: 'calc(100% - 73px)',
  },
  nameContainerPRICE: {
    width: 'calc(100% - 118px)',
  },
  nameContainerSELECTION: {
    width: 'calc(100% - 105px)',
    height: theme.spacing(4.5),
    marginTop: theme.spacing(2),

    // in SELECTION mode, no need to see the full label of the item
    // we put ellipsis after 2 lines
    // /!\ in SELECTION mode, when one item is selected, a link "Divide the quantity" appears
    // the layout should be ok with this link displayed too
    '& $name': {
      overflow: 'hidden',
      display: '-webkit-box',
      textOverflow: 'ellipsis',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
  },
  dividerLabel: {
    position: 'relative',
    top: -1 * theme.spacing(0.5),
    color: theme.palette.primary.main,
  },
  price: {
    // we should be ok with prices like "CHF 23.50" ("CHF" makes it longer than "€")
    maxWidth: 90,
  },
  priceShared: {
    // when shared, we display the price and base price, we need more space.
    maxWidth: 110,
  },
  priceSELECTION: {
    height: theme.spacing(4.5),
    marginTop: theme.spacing(1.5),
  },
  grey: {
    color: theme.pmt.colors.grey91,
  },
  modifyShareContainer: {
    marginTop: theme.spacing(0.25),
  },
  modifyShareLabel: {
    float: 'left',
    marginLeft: theme.spacing(1.5),
    color: theme.palette.primary.main,
  },
})

const Entry = ({ entry, item, type, selected, addAction, removeAction, divideAction, classes }) => (
  <div
    className={classNames(classes.entry, classes[`entry${type}`])}
    onClick={() => type === Type.SELECTION && (!selected ? addAction(entry) : removeAction(entry))}
  >
    {type !== Type.SELECTION && (
      <TypographyCustom type="144" className={classes.quantity}>
        {entry.quantity}
        &nbsp;
        <span className={classes.grey}>x</span>
      </TypographyCustom>
    )}
    {type === Type.SELECTION && (
      <Checkbox className={classes.checkbox} color="primary" checked={selected} />
    )}
    <div className={classNames(classes.nameContainer, classes[`nameContainer${type}`])}>
      <TypographyCustom type="144" className={classes.name}>
        {entry.name}
      </TypographyCustom>
      {selected && item && !item.share.enabled ? (
        <TypographyCustom
          type="124"
          component="span"
          className={classes.dividerLabel}
          onClick={e => {
            e.stopPropagation()
            divideAction(entry)
          }}
        >
          {tr('web_customer.components.checklist.divide')}
        </TypographyCustom>
      ) : (
        selected &&
        item &&
        item.share.enabled && (
          <div
            className={classes.modifyShareContainer}
            onClick={e => {
              e.stopPropagation()
              divideAction(entry)
            }}
          >
            <TypographyCustom type="127" component="span" className="u-floatLeft">
              x{item.share.multiplier}/{item.share.divider}
            </TypographyCustom>
            <TypographyCustom type="124" component="span" className={classes.modifyShareLabel}>
              {tr('web_customer.components.checklist.modify_quantity')}
            </TypographyCustom>
          </div>
        )
      )}
    </div>
    {type !== Type.LISTING && (
      <React.Fragment>
        {selected && item && item.share.enabled ? (
          <TypographyCustom
            type="184"
            className={classNames(classes.price, classes[`price${type}`], classes.priceShared)}
            align="right"
          >
            <Price
              hasCartModifier
              columnReverse
              baseValue={item.absoluteTotalFormattedWithCurrency}
              value={item.totalFormattedWithCurrency}
              classes={{
                root: 'u-floatRight',
                baseValue: classes.baseValue,
              }}
            />
          </TypographyCustom>
        ) : (
          <TypographyCustom
            type="184"
            className={classNames(classes.price, classes[`price${type}`])}
            align="right"
          >
            <Price value={entry.priceWithCurrencyFormatted} classes={{ root: 'u-floatRight' }} />
          </TypographyCustom>
        )}
      </React.Fragment>
    )}
  </div>
)

/**
 * Generate a check list for different payment layouts
 */

class CheckList extends React.PureComponent {
  addEntry = entry => {
    this.props.addEntryToPayment(entry)
  }

  removeEntry = entry => {
    this.props.removeEntryFromPayment(entry)
  }

  displayChooseEntryDividerDialog = entry => {
    if (!isUndefined(this.props.payment.items[entry.id])) {
      this.props.displayChooseEntryDividerDialog(entry, this.props.payment.items[entry.id])
    }
  }

  render() {
    const { check, payment, type, classes, ...otherProps } = this.props

    return (
      <div className={classes.root} {...otherProps}>
        {check && (
          <React.Fragment>
            {check.entries.map((entry, index) => (
              <Entry
                key={index}
                entry={entry}
                selected={
                  !isNil(payment) && existsOnArray(payment.items, item => item.id === entry.id)
                }
                item={(payment && payment.items[entry.id]) || null}
                type={type}
                addAction={this.addEntry}
                removeAction={this.removeEntry}
                divideAction={this.displayChooseEntryDividerDialog}
                classes={classes}
              />
            ))}
          </React.Fragment>
        )}
      </div>
    )
  }
}

CheckList.propTypes = {
  payment: PropTypes.object,
}

CheckList.defaultProps = {
  payment: null,
}

const Type = {
  LISTING: 'LISTING',
  PRICE: 'PRICE',
  SELECTION: 'SELECTION',
}

const mapStateToProps = () => ({})

const CheckListComponent = compose(
  connect(
    mapStateToProps,
    {
      addEntryToPayment,
      removeEntryFromPayment,
      displayChooseEntryDividerDialog,
    }
  ),
  withStyles(styles)
)(CheckList)

CheckListComponent.Type = Type

export default CheckListComponent
