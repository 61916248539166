import React from 'react'
import { connect } from 'react-redux'
import { tr } from 'pmt-modules/i18n'
import { getQueryParam } from 'pmt-utils/url'
import { TopUpContainer } from 'pmt-modules/topUp'
import { UserAccountsContainer } from 'pmt-modules/userAccount'
import { withAppConfig } from 'pmt-modules/appConfig'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import { redirectTo, redirectToExternal, getHistory, goBack } from 'pmt-modules/routing'
import { withUserMe } from 'pmt-modules/userMe'
import { resetPayment } from 'pmt-modules/payment/payment/actions'
import { getPostPaymentAtTableData } from 'pmt-modules/payment/payment/selectors'
import withScrollToTop from 'pmt-ui/Layout/withScrollToTop'
import PaymentAsyncRedirectTutorialDialog from 'pmt-ui/PaymentAsyncRedirectTutorialDialog'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'

/**
 * @specs N/A
 */
// only here, we want color for payment at table, but not for top-up, which is not linked to a restaurant
@withRestaurant
class ForPaymentAtTable extends React.Component {
  constructor(props) {
    super(props)

    let history = getHistory()
    if (history?.location?.state?.popupAlreadyDisplayed) {
      // we are here a second time because the user has been redirected from here to the Psp payment page, and hit "back"
      // in order to prevent him to go to the Psp payment page again (problematic with Saferpay)
      // we put him back on the Order plugin payment page
      // so he can try to resend the order&payment if needed (but the payment won't be considered the same as the first try)
      //props.redirectTo(getRoute('ORDER__PAYMENT'))
      props.goBack()
    }

    this.form = React.createRef()
  }

  render() {
    const { paymentAtTableData, redirectToExternal } = this.props

    if (!paymentAtTableData) {
      return null
    }

    return (
      <div style={{ minHeight: '100%', height: '100%' }}>
        {paymentAtTableData.asyncPaymentData.httpMethod === 'POST' && (
          <form
            method="POST"
            action={paymentAtTableData.asyncPaymentData.pspRedirectUrl}
            ref={this.form}
          >
            {Object.keys(paymentAtTableData.asyncPaymentData.fields || []).map(key => (
              <input
                key={key}
                type="hidden"
                name={key}
                value={paymentAtTableData.asyncPaymentData.fields[key]}
              />
            ))}
          </form>
        )}

        <PaymentAsyncRedirectTutorialDialog
          onSubmit={() => {
            switch (paymentAtTableData.asyncPaymentData.httpMethod) {
              case 'GET':
                redirectToExternal(paymentAtTableData.asyncPaymentData.pspRedirectUrl)
                break

              case 'POST':
                this.form.current.submit()
                break

              default:
            }
          }}
          label1={tr('PaymentAsyncRedirectTutorialDialog.explain.redirection.1')}
          label2={tr('PaymentAsyncRedirectTutorialDialog.explain.redirection.2')}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  paymentAtTableData: getPostPaymentAtTableData(state),
})

const ForPaymentAtTableImpl = connect(
  mapStateToProps,
  {
    resetPayment,
    redirectTo,
    redirectToExternal,
    getHistory,
    goBack,
  }
)(ForPaymentAtTable)

class ForTopUp extends React.Component {
  constructor(props) {
    super(props)

    let history = getHistory()
    if (history?.location?.state?.popupAlreadyDisplayed) {
      // we are here a second time because the user has been redirected from here to the Psp payment page, and hit "back"
      // in order to prevent him to go to the Psp payment page again (problematic with Saferpay)
      // we put him back on the Order plugin payment page
      // so he can try to resend the order&payment if needed (but the payment won't be considered the same as the first try)
      //props.redirectTo(getRoute('ORDER__PAYMENT'))
      props.goBack()
    }

    this.form = React.createRef()
  }

  render() {
    const { userId, redirectToExternal } = this.props

    return (
      <UserAccountsContainer userId={userId}>
        {({ userAccount, isFetchingUserAccounts }) =>
          isFetchingUserAccounts || !userAccount ? (
            <LoadingBlockWrapper show />
          ) : (
            <TopUpContainer userId={userId} userAccount={userAccount}>
              {({ topUp }) =>
                !topUp.payment ? null : ( // topUp has not been updated yet on our store
                  <div style={{ minHeight: '100%', height: '100%' }}>
                    {topUp.payment.asyncPaymentData.httpMethod === 'POST' && (
                      <form
                        method="POST"
                        action={topUp.payment.asyncPaymentData.pspRedirectUrl}
                        ref={this.form}
                      >
                        {Object.keys(topUp.payment.asyncPaymentData.fields || []).map(key => (
                          <input
                            key={key}
                            type="hidden"
                            name={key}
                            value={topUp.payment.asyncPaymentData.fields[key]}
                          />
                        ))}
                      </form>
                    )}

                    <PaymentAsyncRedirectTutorialDialog
                      onSubmit={() => {
                        switch (topUp.payment.asyncPaymentData.httpMethod) {
                          case 'GET':
                            redirectToExternal(topUp.payment.asyncPaymentData.pspRedirectUrl)
                            break

                          case 'POST':
                            this.form.current.submit()
                            break

                          default:
                        }
                      }}
                      label1={tr('PaymentAsyncRedirectTutorialDialog.explain.3ds.1')}
                      label2={tr('PaymentAsyncRedirectTutorialDialog.explain.3ds.2')}
                    />
                  </div>
                )
              }
            </TopUpContainer>
          )
        }
      </UserAccountsContainer>
    )
  }
}

const ForTopUpImpl = connect(
  state => ({}),
  {
    resetPayment,
    redirectTo,
    redirectToExternal,
    getHistory,
    goBack,
  }
)(ForTopUp)

@withAppConfig
@withUserMe
@withScrollToTop()
class AsyncPaymentFormPage extends React.Component {
  render() {
    const flow = getQueryParam('flow')

    switch (flow) {
      case 'paymentAtTable':
        return <ForPaymentAtTableImpl {...this.props} />

      case 'topUp':
        return <ForTopUpImpl {...this.props} />

      default:
        throw new Error(`Missing flow`)
    }
  }
}

export default AsyncPaymentFormPage
