export default {
  // for key we use the `ENV` from our config
  DEV: {
    identifiers: [
      // UA (old)
      /*{
        trackingId: 'UA-105853425-8',
        gaOptions: {
          cookieDomain: 'auto',
          name: 'pmt_tracker_dev',
        },
      },
       */
      // GA4
      {
        tracingId: 'G-BVPC0W2VQX',
        gaOptions: {
          cookieDomain: 'auto',
          name: 'pmt_tracker_dev',
        },
      },
    ],
    /*
     options.nonce	string Optional Used for Content Security Policy (CSP) more
     options.testMode	boolean Default false
     options.gaOptions	object Optional Reference
     options.gtagOptions	object Optional
     options.legacyDimensionMetric	boolean Default true
     */
    options: {
      gtagOptions: { debug_mode: true },
    },
  },
  PREPROD: {
    identifiers: [
      // UA (old)
      /*{
        trackingId: 'UA-105853425-9',
        gaOptions: {
          cookieDomain: 'auto',
          name: 'pmt_tracker_preprod',
        },
      },
       */
      // GA4
      {
        trackingId: 'G-C1BP72DLT3',
        gaOptions: {
          cookieDomain: 'auto',
          name: 'pmt_tracker_preprod',
        },
      },
    ],
    options: {},
  },
  PROD: {
    identifiers: [
      // UA (old)
      /*{
        trackingId: 'UA-105853425-10',
        gaOptions: {
          cookieDomain: 'auto',
          name: 'pmt_tracker_prod',
        },
      },
       */
      // GA4
      {
        trackingId: 'G-5RJXJ7RMZS',
        gaOptions: {
          cookieDomain: 'auto',
          name: 'pmt_tracker_prod',
        },
      },
    ],
    options: {},
  },
}
