import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Fish = props => (
  <SvgIcon {...props}>
    <path d="M 10 4 L 9.46875 8.03125 C 7.8125 8.796875 6.367188 9.855469 5.25 10.8125 C 4.539063 9.859375 3.386719 9.179688 2 9 L 2 17 C 3.464844 16.808594 4.679688 16.070313 5.375 15.03125 C 6.753906 16.023438 8.644531 17.082031 11 17.625 L 11 20 C 11 20 12.761719 19.625 14.21875 18 C 20.046875 17.878906 22 13 22 13 C 22 13 20.378906 8.164063 15.46875 7.1875 C 13.492188 4.769531 10 4 10 4 Z M 13.6875 9 C 17.3125 9 19.113281 11.601563 19.78125 12.90625 C 19.09375 13.992188 17.363281 16 14 16 C 10.46875 16 7.628906 14.269531 6 13 C 6 12.933594 5.972656 12.878906 5.96875 12.8125 C 7.175781 11.714844 8.867188 10.457031 10.6875 9.6875 L 12 9.25 C 12.5625 9.105469 13.125 9 13.6875 9 Z M 16.5 11 C 15.671875 11 15 11.671875 15 12.5 C 15 13.328125 15.671875 14 16.5 14 C 17.328125 14 18 13.328125 18 12.5 C 18 11.671875 17.328125 11 16.5 11 Z " />
  </SvgIcon>
)
Fish = pure(Fish)
Fish.displayName = 'Fish'
Fish.muiName = 'SvgIcon'

export default Fish
