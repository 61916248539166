import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Choose = props => (
  <SvgIcon {...props}>
    <path
      d="M0.396825397,0 L17.3809524,5.55111512e-17 C17.600113,1.52519994e-17 17.7777778,0.177664782 17.7777778,0.396825397 L17.7777778,2.4156746 C17.7777778,2.63483522 17.600113,2.8125 17.3809524,2.8125 L0.396825397,2.8125 C0.177664782,2.8125 3.59906342e-16,2.63483522 3.33066907e-16,2.4156746 L-5.55111512e-17,0.396825397 C-8.23505858e-17,0.177664782 0.177664782,1.51281454e-16 0.396825397,1.11022302e-16 Z"
      id="Rectangle-2"
      opacity="0.501618304"
    />
    <path
      d="M2.61904762,5.0625 L19.6031746,5.0625 C19.8223352,5.0625 20,5.24016478 20,5.4593254 L20,7.4781746 C20,7.69733522 19.8223352,7.875 19.6031746,7.875 L2.61904762,7.875 C2.399887,7.875 2.22222222,7.69733522 2.22222222,7.4781746 L2.22222222,5.4593254 C2.22222222,5.24016478 2.399887,5.0625 2.61904762,5.0625 Z"
      id="Rectangle-2"
    />
    <path
      d="M0.396825397,10.125 L17.3809524,10.125 C17.600113,10.125 17.7777778,10.3026648 17.7777778,10.5218254 L17.7777778,12.5406746 C17.7777778,12.7598352 17.600113,12.9375 17.3809524,12.9375 L0.396825397,12.9375 C0.177664782,12.9375 3.59906342e-16,12.7598352 3.33066907e-16,12.5406746 L0,10.5218254 C-2.68394346e-17,10.3026648 0.177664782,10.125 0.396825397,10.125 Z"
      id="Rectangle-2"
      opacity="0.501618304"
    />
    <path
      d="M0.396825397,15.1875 L17.3809524,15.1875 C17.600113,15.1875 17.7777778,15.3651648 17.7777778,15.5843254 L17.7777778,17.6031746 C17.7777778,17.8223352 17.600113,18 17.3809524,18 L0.396825397,18 C0.177664782,18 3.59906342e-16,17.8223352 3.33066907e-16,17.6031746 L0,15.5843254 C-2.68394346e-17,15.3651648 0.177664782,15.1875 0.396825397,15.1875 Z"
      id="Rectangle-2"
      opacity="0.501618304"
    />
  </SvgIcon>
)
Choose = pure(Choose)
Choose.displayName = 'Choose'
Choose.muiName = 'SvgIcon'

export default Choose
