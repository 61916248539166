import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import ErrorBlock from 'pmt-ui/ErrorBlock'
import { postUserCreditCardRegistrationType } from 'pmt-modules/creditCard'
import { TopUpContainer, postTopUpWithCreditCard } from 'pmt-modules/topUp'
import { UserAccountsContainer } from 'pmt-modules/userAccount'
import { withUserMe } from 'pmt-modules/userMe'
import { getPaymentError, resetPostPayment } from 'pmt-modules/payment'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'

import Page from 'app/components/Page'
import View from './View'
import { getAuthenticatedUser } from 'pmt-modules/authenticatedUser/selectors'

/**
 * @specs N/A
 */
@withUserMe
class TopUpPage extends React.PureComponent {
  handleSubmit = (userAccount, selectedCreditCard, topUp) => {
    this.props.postTopUpWithCreditCard(userAccount, selectedCreditCard, topUp)
  }

  handleSubmitNewCard = (userId, formData, userAccount, topUp) => {
    this.props.postUserCreditCardRegistrationType(
      userId,
      null,
      { creditCard: formData },
      {
        dispatchTopUp: true,
        userAccount,
        topUp,
      }
    )
  }

  render() {
    const { authenticatedUser, userId, paymentError, resetPostPayment } = this.props
    //
    // Our page requires many containers:
    // - UserAccountsContainer: we top up a specific user account
    // - TopUpContainer: to retrieve the data of the topUp
    // - CreditCardsContainer: the user have to chose a credit card to use
    //
    return (
      <UserAccountsContainer userId={userId}>
        {({ userAccount, isFetchingUserAccounts }) =>
          isFetchingUserAccounts ? (
            <LoadingBlockWrapper show />
          ) : (
            <TopUpContainer userId={userId} userAccount={userAccount}>
              {({ topUp, topUpIsInit, isPostingTopUp, topUpError }) => (
                <Page title={tr('web_customer.top_up.title')}>
                  <View
                    userAccount={userAccount}
                    isFetching={isPostingTopUp}
                    isSubmitDisabled={!topUpIsInit || isPostingTopUp || !topUp.amount}
                    onSubmit={(paymentMethod, { selectedCreditCard }) =>
                      this.handleSubmit(userAccount, selectedCreditCard, topUp)
                    }
                    onSubmitNewCard={(userId, formData) =>
                      this.handleSubmitNewCard(userId, formData, userAccount, topUp)
                    }
                    topUp={topUp}
                    topUpError={topUpError}
                    user={authenticatedUser}
                    userId={userId}
                  />

                  <ErrorBlock
                    mode={ErrorBlock.Mode.SNACKBAR}
                    error={paymentError}
                    options={{
                      onClose: () => resetPostPayment()
                    }}
                  />

                </Page>
              )}
            </TopUpContainer>
          )
        }
      </UserAccountsContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  paymentError: getPaymentError(state),
  authenticatedUser: getAuthenticatedUser(state),
})

const mapDispatchToProps = {
  postTopUpWithCreditCard,
  postUserCreditCardRegistrationType,
  resetPostPayment,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopUpPage)
