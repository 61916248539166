import React from 'react'

import { tr } from 'pmt-modules/i18n'

import { getQueryParam } from 'pmt-utils/url'

import { TypographyCustom } from 'pmt-ui/Typography'
import { withStyles } from 'pmt-ui/styles'

const styles = theme => ({
  root: {
    color: theme.pmt.colors.white,
    textTransform: 'uppercase',
  },
})

const TNumber = ({ classes, tableNumber }) => (
  <div className={classes.root}>
    <TypographyCustom type={117} skipColor>
      {tr('web_customer.restaurant.table', {
        number: tableNumber,
      })}
    </TypographyCustom>
  </div>
)

const TableNumber = ({ classes, check, canPay }) => {
  if (canPay && check?.tableNumber) {
    return <TNumber classes={classes} tableNumber={check.tableNumber} />
  }

  const tableId = getQueryParam('tableId')

  return tableId && <TNumber classes={classes} tableNumber={tableId} />
}

export default withStyles(styles)(TableNumber)
