import React from 'react'

import { CircularProgress } from '../Progress'
import { withStyles, withTheme } from '../styles'

const styles = theme => ({
  progress: {
    color: theme.pmt.loading.color || theme.palette.primary.main,
  },
})

const LoadingPage = ({ classes, size = 50, message = '', show }) => {
  if (!show) {
    return null
  }

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        position: 'absolute',
        textAlign: 'center',
        left: 0,
        top: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        zIndex: 10,
      }}
    >
      <div
        style={{
          position: 'relative',
          height: '100%',
          top: '50%',
          marginTop: '-25px',
        }}
      >
        <CircularProgress size={size} className={classes.progress} />
      </div>
    </div>
  )
}

export default withTheme(withStyles(styles)(LoadingPage))
