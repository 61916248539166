import { tr } from 'pmt-modules/i18n'
import React from 'react'
import isNil from 'lodash/isNil'
import {compose} from 'redux'

import CustomTextsContainer
  from 'pmt-modules/customTexts/components/CustomTextsContainer'
import {FormType, withForm} from 'pmt-modules/form'
import {RegisterFormView} from 'pmt-modules/registration/forms/register'

import ErrorBlock from 'pmt-ui/ErrorBlock'
import LabelDivider from 'pmt-ui/LabelDivider'
import {LoadingBlockWrapper} from 'pmt-ui/LoadingBlock'
import {withStyles} from 'pmt-ui/styles'
import {TypographyCustom} from 'pmt-ui/Typography'
import {CguLabel} from 'pmt-ui/Cgu'

import {Button, ButtonLink} from '../../../components/Button'

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    overflow: 'auto',
  },
  subheader: {
    color: theme.pmt.colors.grey500,
  },
  allowIncognito: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  link: {
    display: 'inline',
    fontSize: 14,
    lineHeight: '14px',
    marginTop: -2,
  },
})

const RegisterForm = ({
  classes,
  isFetchingRegister,
  formIsValid,
  onSubmitRegistration,
  registerError,
  onRedirectToLogin,
  allowIncognito,
  onRedirectToIncognito,
  formData,
  restaurantsGroup,
}) => (
    <div className={classes.root}>
      <form
          onSubmit={e => {
            e.preventDefault()
            onSubmitRegistration(formData)
          }}
      >
        <CustomTextsContainer>
          {({texts}) =>
              !isNil(texts) &&
              !isNil(texts.REGISTER__SUBHEADER) && (
                  <TypographyCustom type="144" className={classes.subheader}>
                    {texts.REGISTER__SUBHEADER}
                  </TypographyCustom>
              )
          }
        </CustomTextsContainer>

        <LoadingBlockWrapper show={isFetchingRegister}/>

        <ErrorBlock mode={ErrorBlock.Mode.SNACKBAR} error={registerError}/>

        <RegisterFormView
            cguLabel={<CguLabel restaurantsGroup={restaurantsGroup}
                                linkClass={classes.link}/>}/>

        <Button type="submit" className="u-marginTop10 u-sizeFull"
                disabled={!formIsValid}>
          <TypographyCustom type="167" skipColor>
            {tr('web_customer.register.signin')}
          </TypographyCustom>
        </Button>

        {allowIncognito && (
            <div className={classes.allowIncognito}>
              <CustomTextsContainer>
                {({texts}) => (
                    <ButtonLink
                        component="a"
                        onClick={e => {
                          e.stopPropagation()
                          onRedirectToIncognito()
                        }}
                        label={
                          !isNil(texts) && !isNil(texts.ORDER__INCOGNITO__LINK)
                              ? texts.ORDER__INCOGNITO__LINK
                              : tr(
                                  'web_customer.login.continue_as_incognito.from_NORMAL.link')
                        }
                    />
                )}
              </CustomTextsContainer>
            </div>
        )}

        <LabelDivider className="u-marginTop30 u-marginBottom30"
                      label={tr('global.divider.or')}/>

        <div className="u-marginTop10 u-sizeFullWidth">
          <Button
              className="u-sizeFull"
              variant="outlined"
              onClick={e => {
                e.preventDefault()
                onRedirectToLogin()
              }}
          >
            <TypographyCustom type="167" skipColor>
              {tr('web_customer.signin.connect')}
            </TypographyCustom>
          </Button>
        </div>
      </form>
    </div>
)

export default compose(
    withStyles(styles),
    withForm(FormType.REGISTER)
)(RegisterForm)
