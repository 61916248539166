const UserCardErrors = {
  POST_REGISTRATION_TYPE: {
    default: 'global.post_registration_type.default',
  },
  SEND_TO_PSP: {
    default: 'global.send_to_psp.default',
  },
  POST_PSP: {
    103: 'global.post_user_card.103',
    default: 'global.post_psp_datas.default',
  },
}

export default UserCardErrors
