import { tr } from 'pmt-modules/i18n'
import isNil from 'lodash/isNil'
import isUndefined from 'lodash/isUndefined'
import { isBo } from 'pmt-modules/environment'

import StatusChip from 'pmt-ui/StatusChip'

import { getAsyncPaymentBrowserInfo } from 'pmt-utils/browser'
import Logger from 'pmt-utils/logger'
import { createFormatter, createListFormatter } from 'pmt-utils/format'
import { formatDate } from 'pmt-utils/date'
import { formatPriceWithCurrency } from 'pmt-utils/currency'
import { getRoute, generatePathWithParams } from 'pmt-modules/routing'

import { getAbsolutePath } from 'pmt-utils/url'
import { TOP_UP_TYPE, TOP_UP_STATUS } from '../constants'

const formatCreationDate = topUp => {
  topUp.formattedCreationDate = formatDate(topUp.creationDate, 'DD/MM/YY HH:mm')

  return topUp
}

const formatSimpleCreationDate = topUp => {
  topUp.formattedSimpleCreationDate = formatDate(topUp.creationDate, 'DD/MM/YY')

  return topUp
}

const formatPrices = topUp => {
  topUp.amountPriceFormatted = formatPriceWithCurrency(topUp.amount)

  return topUp
}

const formatStatus = topUp => {
  const label = {
    [TOP_UP_STATUS.CREATED]: 'Créé',
    [TOP_UP_STATUS.FAILED]: 'Échoué',
    [TOP_UP_STATUS.SUCCEEDED]: 'Réussi',
    [TOP_UP_STATUS.REFUNDED]: 'Remboursé',
  }

  const type = {
    [TOP_UP_STATUS.CREATED]: StatusChip.Status.WARNING,
    [TOP_UP_STATUS.FAILED]: StatusChip.Status.ERROR,
    [TOP_UP_STATUS.SUCCEEDED]: StatusChip.Status.VALID,
    [TOP_UP_STATUS.REFUNDED]: StatusChip.Status.INFO,
  }

  topUp.statusLabel = label[topUp.status] || tr('global.not_available')
  topUp.statusType = type[topUp.status]

  topUp.isStatusCreated = topUp.status === TOP_UP_STATUS.CREATED
  topUp.isStatusFailed = topUp.status === TOP_UP_STATUS.FAILED
  topUp.isStatusSucceeded = topUp.status === TOP_UP_STATUS.SUCCEEDED
  topUp.isStatusRefunded = topUp.status === TOP_UP_STATUS.REFUNDED

  return topUp
}

const formatType = topUp => {
  const label = {
    [TOP_UP_TYPE.CASH]: tr('global.top_up.type.cash', {
      fr: 'Espèces',
      context: '',
      desc: 'Top up type : cash',
    }),
    [TOP_UP_TYPE.CREDIT_CARD]: tr('global.top_up.type.credit_card', {
      fr: 'Carte bancaire',
      context: '',
      desc: 'Top up type : credit card',
    }),
    [TOP_UP_TYPE.LUNCHEON_VOUCHER]: tr('global.top_up.type.luncheon_voucher', {
      fr: 'Titres restaurant',
      context: '',
      desc: 'Top up type : luncheon voucher',
    }),
    [TOP_UP_TYPE.IMPORTED_INITIAL_BALANCE]: tr('global.top_up.type.initial_balance', {
      fr: "Reprise d'historique",
      context: '',
      desc: 'Top up type : initial balance',
    }),
  }

  topUp.typeLabel =
    label[topUp.type] ||
    tr('global.not_available', {
      fr: 'N/D',
      context: '',
      desc: 'Not available label',
    })

  return topUp
}

const formatSource = (topUp, props) => {
  const defaultApiConsumer = { id: 0, name: 'Autre' }

  if (!isUndefined(props.apiConsumers) && !isNil(topUp.source)) {
    const apiConsumer = props.apiConsumers
      .filter(apiConsumer => {
        return apiConsumer.id === topUp.source.apiConsumerId
      })
      .shift()

    if (!isUndefined(apiConsumer)) {
      topUp.apiConsumer = apiConsumer
    } else {
      Logger.warn(
        'formatTopUpSource',
        `apiConsumer ${topUp.source.apiConsumerId} not found for topUp ${topUp.id}`
      )
      topUp.apiConsumer = defaultApiConsumer
    }
  } else {
    topUp.apiConsumer = defaultApiConsumer
  }

  return topUp
}

/**
 * Data neeeded for 3DS v2
 *
 * https://www.notion.so/paymytable/Mangopay-3DS2-543d705dd4534a908b3dc1a64882e7a6.
 */
const formatBrowserInfo = data => {
  if (isBo()) {
    // on bo we do not have the TOPUP_VERIFICATION route, and no need of 3DS data.
    return data
  }

  return {
    ...data,
    asyncPaymentData: {
      redirectUrl: getAbsolutePath(generatePathWithParams(getRoute('TOPUP_VERIFICATION'))),
    },
    browserInfo: getAsyncPaymentBrowserInfo(),
  }
}

export const formatTopUp = createFormatter(
  formatCreationDate,
  formatSimpleCreationDate,
  formatPrices,
  formatStatus,
  formatType,
  formatSource,
  formatBrowserInfo
)

export const formatTopUps = createListFormatter(formatTopUp)
