import React from 'react'

const LockerIcon = props => (
  <svg viewBox="0 0 810 810" {...props}>
    <defs>
      <clipPath id="a">
        <path d="M83 69.008h623V741H83Zm0 0" />
      </clipPath>
    </defs>
    <path fill="#FFF" d="M-81-81h972v972H-81z" />
    <path fill="#FFF" d="M-81-81h972v972H-81z" />
    <path fill="#FFF" d="M-81-81h972v972H-81z" />
    <path fill="#FFF" d="M-81-81h972v972H-81z" />
    <g clipPath="url(#a)">
      <path
        fill="#18212B"
        d="M702.574 131.258c-2.785-3.051-8.797-2.457-8.797-2.457h-146.53l-2.345-.059c-108.757-2.55-87.308-59.597-145.465-59.691-64.347-.102-55.46 61.172-116.222 62.62l-42.102 1.9c-5.687.296-11.465.296-17.449 0h-120.52s-11.433-1.305-16.44 4.62c-4.802 5.66-3.29 11.528-3.29 16.918V488h.176c-.086 2.52-.176 5.066-.176 7.645 0 89.867 251.379 245.304 311.047 245.304 55.61 0 311.02-155.437 311.02-245.304 0-2.579-.063-5.125-.18-7.645h.18V142.727s.234-8-2.907-11.47"
      />
    </g>
    <path
      fill="#ECEAF7"
      d="M636.094 190.313c-2.192-2.403-6.903-1.926-6.903-1.926H514.242l-1.808-.063c-85.293-1.984-68.47-46.722-114.09-46.812-50.485-.09-43.492 48-91.16 49.125l-33.036 1.48c-4.414.238-8.976.238-13.656 0h-94.539s-8.95-1.008-12.887 3.645c-3.761 4.445-2.578 9.039-2.578 13.246v261.094h.149c-.09 1.988-.149 3.972-.149 5.988 0 70.488 197.164 192.414 243.942 192.414 43.613 0 243.945-121.926 243.945-192.414 0-2.016-.059-4-.148-5.988h.148V199.316s.18-6.308-2.281-9.004"
    />
    <path
      fill="#18212B"
      d="m477.18 328.563-.032-.563c-.68-14.46-3.109-27.32-7.257-38.191-4.329-11.29-10.52-20.801-18.489-28.297-14.101-13.246-33.3-19.973-57.058-19.973-23.29 0-43.317 7.23-57.89 20.918-8.177 7.645-14.458 17.188-18.638 28.297-4.175 11.023-6.308 23.676-6.308 37.601v.118c0 .18.117 17.66 0 26.343-.149 11.524 9.094 21.067 20.648 21.215a20.97 20.97 0 0 0 14.961-6.015c3.942-3.914 6.133-9.098 6.223-14.637.117-9.04 0-26.074 0-27.113.031-17.094 4.473-29.422 13.242-36.621 6.668-5.48 16-8.266 27.762-8.266 12.914 0 22.219 2.812 28.379 8.621 7.468 6.992 11.703 19.586 12.62 37.422-.027 3.32-.116 17.808 0 25.957.063 5.539 2.282 10.723 6.223 14.637 3.942 3.851 9.157 6.015 14.696 6.015h.265c11.528-.148 20.797-9.691 20.653-21.215-.121-8.18-.032-23.882 0-25.66v-.594"
    />
    <path
      fill="#EBA116"
      d="M499.781 493.246c0 15.582-12.351 28.356-27.433 28.356H316.336c-15.078 0-27.434-12.774-27.434-28.356V386.457c0-15.582 12.356-28.355 27.434-28.355h156.012c15.082 0 27.433 12.773 27.433 28.355v106.79"
    />
    <path
      fill="#18212B"
      d="M412.621 485.453c10.133 0 14.281-7.172 9.215-15.941l-3.762-6.551c-5.097-8.797-3.406-21.863 3.73-29.066 0 0 .891-.918.891-11.29 0-15.671-12.68-28.382-28.351-28.382-15.672 0-28.383 12.71-28.383 28.382 0 10.372.918 11.29.918 11.29 7.11 7.203 8.8 20.27 3.734 29.066l-3.793 6.55c-5.039 8.77-.918 15.942 9.243 15.942h36.558"
    />
  </svg>
)

export default LockerIcon
