import { tr } from 'pmt-modules/i18n'
import React from 'react'
// import PropTypes from 'prop-types'

import { HistoryType, HistoryTypeContainer } from 'pmt-modules/userHistory'

import { withStyles } from 'pmt-ui/styles'
import { TableRow, TableCell } from 'pmt-ui/Table'
import { TypographyCustom } from 'pmt-ui/Typography'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'

import HistoryList from '../components/HistoryList'

import { formatDate } from 'pmt-utils/date'

const styles = theme => ({
  row: {
    height: 48,
  },
  cell: {
    padding: '0 5px !important',
  },
})

const OrderHistoryContent = ({ isFetchingHistory, list, pagination, onLoadMore, classes }) => (
  <HistoryList
    key={'order-history-list'}
    list={list}
    pagination={pagination}
    onLoadMore={onLoadMore}
    emptyList={
      !isFetchingHistory &&
      tr('web_customer.history.order.no_transaction', {
        fr: 'Aucune transaction',
        context: '',
        desc: 'Label when no transaction has been returned on order history',
      })
    }
    rowRenderer={order => (
      <TableRow key={`order-history-${order.id}`} className={classes.row}>
        <TableCell className={classes.cell} width={70}>
          <TypographyCustom type="144">{formatDate(order.dueDate, 'DD/MM/YY')}</TypographyCustom>
        </TableCell>
        <TableCell className={classes.cell}>
          <TypographyCustom type="144">{order.restaurant.name}</TypographyCustom>
        </TableCell>
        <TableCell className={classes.cell}>
          <TypographyCustom type="144" align="right">
            {order.totalPaidPrice > 0
              ? `-${order.totalPaidPriceFormatted}`
              : order.totalPaidPriceFormatted}
          </TypographyCustom>
        </TableCell>
      </TableRow>
    )}
  />
)

const OrderHistory = ({ className, classes, userId }) => (
  <HistoryTypeContainer userId={userId} historyType={HistoryType.ORDER}>
    {props => (
      <div className={className}>
        <LoadingBlockWrapper show={props.isFetchingHistory} />
        <OrderHistoryContent classes={classes} {...props} />
      </div>
    )}
  </HistoryTypeContainer>
)

export default withStyles(styles)(OrderHistory)
