import React, { Fragment } from 'react'
import isEmpty from 'lodash/isEmpty'

import { EPaymentMode } from 'pmt-modules/creditCard'

import Checkbox from 'pmt-ui/Checkbox'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import { withStyles } from 'pmt-ui/styles'
import Delete from 'pmt-ui/svg-icons/action/delete'
import CreditCardVisa from 'pmt-ui/svg-icons/pmt/CreditCardVisa'
import CreditCardMasterCard from 'pmt-ui/svg-icons/pmt/CreditCardMasterCard'
import { TypographyCustom } from 'pmt-ui/Typography'

const styles = theme => ({
  root: {
    marginTop: theme.spacing(2),
  },
  creditCardContainer: {
    overflow: 'hidden',
    marginBottom: theme.spacing(1),
    background: theme.pmt.colors.greyBackground,
    padding: theme.spacing(0.5) * 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  nameContainer: {
    display: 'flex',
  },
  name: {
    marginBottom: theme.spacing(0.5),
    color: theme.pmt.colors.grey500,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  iconCheck: {
    padding: 0,
  },
  iconDelete: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  iconCreditCard: {
    marginRight: theme.spacing(1),
  },
})

const CreditCardList = ({
  classes,
  creditCards,
  selectedCreditCard,
  isFetching,
  onToggleCard,
  onClickDeleteCreditCard,
  setShowCreditCardForm,
}) => (
  <Fragment>
    <LoadingBlockWrapper show={isFetching} />

    {!isEmpty(creditCards) && (
      <div className={classes.root}>
        {(creditCards || []).map((creditCard, i) => (
          <div
            key={i}
            className={classes.creditCardContainer}
            onClick={
              creditCards.length > 1
                ? () => {
                    const toBeSelected =
                      !selectedCreditCard ||
                      (selectedCreditCard && creditCard.id !== selectedCreditCard.id)
                    onToggleCard(toBeSelected, creditCard)
                    setShowCreditCardForm(false)
                  }
                : null
            }
          >
            <div className={classes.nameContainer}>
              {creditCard.payMode === EPaymentMode.VISA && (
                <CreditCardVisa className={classes.iconCreditCard} />
              )}
              {creditCard.payMode === EPaymentMode.MASTERCARD && (
                <CreditCardMasterCard className={classes.iconCreditCard} />
              )}
              <div>
                <TypographyCustom type="127" className={classes.name}>
                  {creditCard.name}
                </TypographyCustom>
                <TypographyCustom type="147">{creditCard.number}</TypographyCustom>
              </div>
            </div>
            <div className={classes.actions}>
              <Checkbox
                className={classes.iconCheck}
                color="primary"
                checked={selectedCreditCard && selectedCreditCard.id === creditCard.id}
                disabled={creditCards.length === 1}
              />
              <Delete
                className={classes.iconDelete}
                onClick={e => {
                  e.stopPropagation()
                  onClickDeleteCreditCard(creditCard)
                }}
              />
            </div>
          </div>
        ))}
      </div>
    )}
  </Fragment>
)

export default withStyles(styles)(CreditCardList)
