import React, { Fragment } from 'react'

import get from 'lodash/get'
import set from 'lodash/set'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { getUserSettings } from 'pmt-modules/appConfig/selectors'
import { withForm, FormType, updateFormOptions } from 'pmt-modules/form'
import { getRegisterError } from 'pmt-modules/registration'

import withWidth from 'pmt-ui/utils/withWidth'

import Cgu from 'pmt-ui/Cgu'

import { getField } from './constants'


class UserLightFormView extends React.PureComponent {
  constructor(props) {
    super(props)

    this.props.updateFormOptions(FormType.IDENTIFICATION, {
      fields: this.props.userSettings.identification.form.fields,
    })
  }

  render() {
    const { style, formData, formErrors, onChange, cguLabel } = this.props
    const formFields = this.props.userSettings.identification.form.fields

    return (
      <div style={style}>
        <Fragment>
          {formFields.map(fieldInfo => {
            const field = getField(fieldInfo.type)

            return React.cloneElement(field.component, {
              key: field.key,
              value: get(formData, field.key),
              required: fieldInfo.required,
              onChange: value => onChange(set(formData, field.key, value)),
              validation: get(formErrors, field.key),
            })
          })}
          <Cgu
            label={cguLabel}
            value={formData.acceptCgu}
            onChange={acceptCgu => onChange({ ...formData, acceptCgu })}
          />
        </Fragment>
      </div>
    )
  }
}

UserLightFormView.defaultProps = {
  cguLabel: null,
}
const mapStateToProps = state => ({
  userSettings: getUserSettings(state),
  registerError: getRegisterError(state),
})

export default compose(
  withWidth(),
  connect(
    mapStateToProps,
    {
      updateFormOptions,
    }
  ),
  withForm(FormType.USER_LIGHT)
)(UserLightFormView)
