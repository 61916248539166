import { tr } from 'pmt-modules/i18n'
import { notEmpty, isGreaterThan, isValidEmail } from 'pmt-modules/form/validation'

import createForm from 'pmt-modules/form/createForm'

import FormType from 'pmt-modules/form/FormType'

export LoginFormView from './LoginFormView'

// in DEV environment, prefill form with the following data
const devData = {
  username: '',
  password: 'bonjour1',
}

const defaultData = {
  username: null,
  password: null,
}

const validationRules = () => ({
  username: [
    [notEmpty, tr('global.login.form.validation.required.email')],
    [isValidEmail, tr('global.login.form.validation.email_invalid')],
  ],
  password: [[isGreaterThan(5), tr('global.login.form.validation.password_length')]],
})

export default createForm(FormType.LOGIN, validationRules, defaultData, devData)
