import { tr } from 'pmt-modules/i18n'
import React, { Fragment } from 'react'
import ReactDOMServer from 'react-dom/server'
import classNames from 'classnames'

import Divider from 'pmt-ui/Divider'
import { withTheme, ThemeProvider } from 'pmt-ui/styles'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import Price from 'pmt-ui/Price'
import { withStyles } from 'pmt-ui/styles'
import AddCircleOutline from 'pmt-ui/svg-icons/content/add-circle-outline'
import RemoveCircleOutline from 'pmt-ui/svg-icons/content/remove-circle-outline'
import ArrowTuto from 'pmt-ui/svg-icons/navigation/arrow-tuto'
import { TypographyCustom } from 'pmt-ui/Typography'

import { fade } from 'pmt-utils/theme/colorManipulator'

import { Button } from '../../../components/Button'

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  content: {
    flex: 1,
    marginTop: theme.spacing(2),
  },
  total: {
    position: 'relative',
    height: 150,
    width: 150,
    borderRadius: '50%',
    background: `${fade(theme.palette.primary.main, 0.3)}`,
    margin: 'auto',

    '& *, & *:before, & *:after': {
      boxSizing: 'content-box',
    },
    '&:after': {
      position: 'absolute',
      top: 25,
      left: 25,
      width: 100,
      height: 100,
      display: 'block',
      content: '" "',
      borderRadius: '50%',
      backgroundColor: theme.pmt.colors.white,
    },
  },
  totalLabel: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    width: 150,
    height: 150,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  slice: {
    position: 'absolute',
    width: 150,
    height: 150,
    clip: 'rect(0, 150px, 150px, 75px)',
  },
  sliceUp: {
    clip: 'rect(auto, auto, auto, auto)',
  },
  sliceCut: {
    height: '50%',
    background: theme.pmt.colors.white,
    position: 'absolute',
    left: '50%',
    transformOrigin: 'bottom',
    // we could do `width: 4` but this cleans border around each slice
    width: 2,
    marginLeft: -1,
  },
  bar: {
    clip: 'rect(0, 75px, 150px, 0)',
    borderRadius: '50%',
    border: `25px solid ${theme.palette.primary.main}`,
    width: 100,
    height: 100,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  fill: {},
  fillUp: {
    borderRadius: '50%',
    border: `25px solid ${theme.palette.primary.main}`,
    width: 100,
    height: 100,
    position: 'absolute',
    top: 0,
    left: 0,
    transform: 'rotate(180deg)',
    clip: 'rect(0, 75px, 150px, 0)',
  },
  split: {
    width: '100%',
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(5),
  },
  shareBetween: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  shareBetweenLabel: {
    margin: `0 ${theme.spacing(3)}px`,
  },
  shareIcons: {
    fontSize: 34,
  },
  disabled: {
    color: theme.pmt.colors.grey91,
  },
  caption: {
    position: 'relative',
    color: theme.pmt.colors.grey91,
    marginTop: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    whiteSpace: 'pre-wrap',
    fontStyle: 'italic',
    width: 185,
    height: 50,
  },
  captionLabel: {
    position: 'absolute',
    top: 0,
    left: -20,
  },
  arrowTuto: {
    position: 'absolute',
    right: 26,
    top: -22,
    fontSize: 34,
  },
  shareTotal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(4),
  },
  payFor: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div:first-child': {
      marginRight: theme.spacing(1),
    },
  },
  payForLabel: {
    margin: `0 ${theme.spacing(2)}px`,
  },
  payForIcons: {
    fontSize: 24,
  },
  submit: {
    width: '100%',
  },
  submitLabel: {
    display: 'flex',
  },
})

const getRatio = ({ multiplier, divider }) => (multiplier / divider) * 100

const View = ({
  classes,
  payment,
  isFetchingCheck,
  check,
  onDecreaseMultiplier,
  onIncreaseMultiplier,
  onDecreaseDivider,
  onIncreaseDivider,
  onSubmit,
  theme,
}) => (
  <div className={classes.root}>
    <LoadingBlockWrapper show={isFetchingCheck || !check || !payment} />
    <div className={classes.content}>
      <div className={classes.total}>
        <div className={classes.totalLabel}>
          <TypographyCustom type="124">{tr('web_customer.payment.share.total')}</TypographyCustom>
          <TypographyCustom type="167">
            {check && <Price value={check.totalWithoutTipsFormatted} />}
          </TypographyCustom>
        </div>

        <div
          className={classNames(classes.slice, {
            [classes.sliceUp]: getRatio(payment.share) >= 50,
          })}
        >
          <div
            className={classNames(classes.bar, {
              [classes.barUp]: getRatio(payment.share) >= 50,
            })}
            style={{
              transform: `rotate(${Math.ceil((getRatio(payment.share) * 360) / 100)}deg)`,
            }}
          />
          <div
            className={classNames(classes.fill, {
              [classes.fillUp]: getRatio(payment.share) >= 50,
            })}
          />
        </div>
        {payment.share.divider > 1 &&
          Array.from(Array(payment.share.divider).keys()).map(i => (
            <div
              className={classes.sliceCut}
              style={{
                transform: `rotate(${Math.ceil((360 / payment.share.divider) * i)}deg)`,
              }}
            />
          ))}
      </div>

      <div className={classes.split}>
        <TypographyCustom type="164" align="center">
          {tr('web_customer.payment.share.split')}
        </TypographyCustom>
        <div className={classes.shareBetween}>
          <RemoveCircleOutline
            color="primary"
            className={classNames(classes.shareIcons, {
              [classes.disabled]: payment.share.dividerIsMinimum,
            })}
            onClick={onDecreaseDivider}
          />
          <TypographyCustom type="267" className={classes.shareBetweenLabel}>
            {payment.share.divider}
          </TypographyCustom>
          <AddCircleOutline
            color="primary"
            className={classes.shareIcons}
            onClick={onIncreaseDivider}
          />
        </div>
        {payment.share.dividerIsMinimum ? (
          <div className={classes.caption}>
            <ArrowTuto className={classes.arrowTuto} />
            <TypographyCustom type="144" skipColor className={classes.captionLabel}>
              {tr('web_customer.payment.share.share.caption')}
            </TypographyCustom>
          </div>
        ) : (
          <Fragment>
            <TypographyCustom
              className={classes.shareTotal}
              align="center"
              type="144"
              dangerouslySetInnerHTML={{
                __html: tr('web_customer.payment.share.share_total', {
                  amount: ReactDOMServer.renderToStaticMarkup(
                    <ThemeProvider theme={theme}>
                      &nbsp;
                      <TypographyCustom type="167">
                        {payment && <Price value={payment.share.amountPerPersonFormatted} />}
                      </TypographyCustom>
                      &nbsp;
                    </ThemeProvider>
                  ),
                }),
              }}
            />
            <Divider />
            <div className={classes.payFor}>
              <TypographyCustom type="164">
                {tr('web_customer.payment.share.pay_for')}
              </TypographyCustom>
              <RemoveCircleOutline
                color="primary"
                className={classNames(classes.payForIcons, {
                  [classes.disabled]: payment.share.payForIsMinimum,
                })}
                onClick={onDecreaseMultiplier}
              />
              <TypographyCustom type="207" className={classes.payForLabel}>
                {payment.share.multiplier}
              </TypographyCustom>
              <AddCircleOutline
                color="primary"
                className={classNames(classes.payForIcons, {
                  [classes.disabled]: payment.share.payForIsMaximum,
                })}
                onClick={onIncreaseMultiplier}
              />
            </div>
          </Fragment>
        )}
      </div>
    </div>
    <Button
      size="large"
      onClick={onSubmit}
      className={classes.submit}
      disabled={!payment || isFetchingCheck}
    >
      <TypographyCustom type="207" skipColor className={classes.submitLabel}>
        {tr('web_customer.payment.share.submit')}
        &nbsp;
        <Price value={payment.amountFormatted} />
      </TypographyCustom>
    </Button>
  </div>
)

export default withStyles(styles)(withTheme(View))
