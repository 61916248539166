import React from 'react'

import HistoryTransactionsModeView from './HistoryTransactionsModeView'
import HistoryNormalModeView from './HistoryNormalModeView'

const HistoryView = ({ userId, restaurantsGroup }) => {
  if (restaurantsGroup.frontSettings.catering.isHistoryTypeThirdParty) {
    return <HistoryTransactionsModeView userId={userId} />
  }

  return <HistoryNormalModeView userId={userId} />
}

export default HistoryView
