import { tr } from 'pmt-modules/i18n'
import StatusChip from 'pmt-ui/StatusChip'

import PaymentStatus from './PaymentStatus'

export { default as PaymentStatus } from './PaymentStatus'
export { default as PayZenCallbackStatus } from './PayZenCallbackStatus'
export { default as TipsValueType } from './TipsValueType'
export { default as WebRedirectCallbackStatus } from './WebRedirectCallbackStatus'

export const getPaymentStatusLabel = status => {
  switch (status) {
    case PaymentStatus.CREATED:
      return tr('global.PaymentStatus.CREATED')
    case PaymentStatus.PAID:
      return tr('global.PaymentStatus.PAID')
    case PaymentStatus.REFUNDED:
      return tr('global.PaymentStatus.REFUNDED')
    case PaymentStatus.FAILED:
      return tr('global.PaymentStatus.FAILED')
    default:
      return status
  }
}
/**
 * IrlPayment, typocally used as order param
 */
export const IrlPaymentMethod = {
  CASH: 'CASH',
  CB: 'CB',
  LUNCHEON_VOUCHER: 'LUNCHEON_VOUCHER',
  COUPON: 'COUPON',
  CHEQUE: 'CHEQUE',
  OTHER: 'OTHER',
}

export const getPaymentStatusType = status => {
  switch (status) {
    case PaymentStatus.CREATED:
      return StatusChip.Status.DEFAULT
    case PaymentStatus.PAID:
      return StatusChip.Status.VALID
    case PaymentStatus.REFUNDED:
      return StatusChip.Status.WARNING
    case PaymentStatus.FAILED:
      return StatusChip.Status.ERROR
    default:
      return StatusChip.Status.DEFAULT
  }
}

// TODO: move me on restaurant, please.
export const PaymentMethodsAllowed = {
  IRL: 1,
  CREDIT_CARD: 2,
  USER_ACCOUNT: 4,
  TRD: 8,
}
