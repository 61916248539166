import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let Mustard = props => (
  <SvgIcon {...props}>
    <path d="M 10.851562 5.101562 L 11.507812 0.898438 L 12.492188 0.898438 L 13.148438 5.101562 Z M 10.851562 5.101562 " />
    <path
      fill="none"
      d="M 12.238281 1.199219 L 12.800781 4.800781 L 11.203125 4.800781 L 11.765625 1.199219 L 12.238281 1.199219 M 12.75 0.601562 L 11.25 0.601562 L 10.5 5.398438 L 13.5 5.398438 Z M 12.75 0.601562 "
    />
    <path
      fill="none"
      d="M 9.300781 7.5 L 9.300781 6 C 9.300781 5.503906 9.703125 5.101562 10.199219 5.101562 L 13.800781 5.101562 C 14.296875 5.101562 14.699219 5.503906 14.699219 6 L 14.699219 7.5 Z M 9.300781 7.5 "
    />
    <path d="M 13.800781 5.398438 C 14.128906 5.398438 14.398438 5.667969 14.398438 6 L 14.398438 7.199219 L 9.601562 7.199219 L 9.601562 6 C 9.601562 5.667969 9.871094 5.398438 10.199219 5.398438 L 13.800781 5.398438 M 13.800781 4.800781 L 10.199219 4.800781 C 9.535156 4.800781 9 5.335938 9 6 L 9 7.800781 L 15 7.800781 L 15 6 C 15 5.335938 14.464844 4.800781 13.800781 4.800781 Z M 13.800781 4.800781 " />
    <path
      fill="none"
      d="M 8.398438 23.101562 C 7.902344 23.101562 7.5 22.695312 7.5 22.199219 L 7.5 8.398438 C 7.5 7.902344 7.902344 7.5 8.398438 7.5 L 15.601562 7.5 C 16.097656 7.5 16.5 7.902344 16.5 8.398438 L 16.5 22.199219 C 16.5 22.695312 16.097656 23.101562 15.601562 23.101562 Z M 8.398438 23.101562 "
    />
    <path d="M 15.601562 7.800781 C 15.929688 7.800781 16.199219 8.070312 16.199219 8.398438 L 16.199219 22.199219 C 16.199219 22.53125 15.929688 22.800781 15.601562 22.800781 L 8.398438 22.800781 C 8.070312 22.800781 7.800781 22.53125 7.800781 22.199219 L 7.800781 8.398438 C 7.800781 8.070312 8.070312 7.800781 8.398438 7.800781 L 15.601562 7.800781 M 15.601562 7.199219 L 8.398438 7.199219 C 7.738281 7.199219 7.199219 7.738281 7.199219 8.398438 L 7.199219 22.199219 C 7.199219 22.863281 7.738281 23.398438 8.398438 23.398438 L 15.601562 23.398438 C 16.261719 23.398438 16.800781 22.863281 16.800781 22.199219 L 16.800781 8.398438 C 16.800781 7.738281 16.261719 7.199219 15.601562 7.199219 Z M 15.601562 7.199219 " />
  </SvgIcon>
)
Mustard = pure(Mustard)
Mustard.displayName = 'Mustard'
Mustard.muiName = 'SvgIcon'

export default Mustard
