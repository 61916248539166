import clsx from 'clsx'
import { tr } from 'pmt-modules/i18n'
import React from 'react'

import InputAdornment from '@material-ui/core/InputAdornment'
import { makeStyles } from '@material-ui/core/styles'
import { isProd } from 'pmt-modules/environment'

import NumberField from 'pmt-ui/NumberField'
import Price from 'pmt-ui/Price'
import { TypographyCustom } from 'pmt-ui/Typography'

import { formatPriceWithCurrency, getCurrencySymbol } from 'pmt-utils/currency'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
     // using fixed values and not theme.spacing because used by Order Plugin and Web customer, which don't have the same theme.spacing
     marginTop: '16px',
  },
  tip: {
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRight: 0,
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tipSelected: {
    background: theme.palette.primary.main,
    color: theme.pmt.colors.white,
    fontWeight: 'bold',

    '& .MuiInputBase-root': {
      color: theme.pmt.colors.white,
    },
    '& .MuiInputAdornment-root p': {
      color: theme.pmt.colors.white,
    },
  },
  customTip: {
    border: `1px solid ${theme.palette.primary.main}`,

    '& .MuiInputBase-root::before, & .MuiInputBase-root::after': {
      border: 'none!important',
    },
    '& .MuiInput-input': {
      padding: 0,
      textAlign: 'center',
      minWidth: 40,
    },
    '& .MuiInputAdornment-root': {
      margin: 0,
    },
  },
}))

const CustomTip = ({ customValueSelected, onChange, classes, isPercentage, placeholder }) => (
  <div className={clsx(classes.tip, classes.customTip, { [classes.tipSelected]: (customValueSelected) })}>
    <NumberField
      placeholder={placeholder}
      min={0}
      max={isPercentage ? 100 : isProd() ? 99 : 1000}
      value={customValueSelected > 0 ? customValueSelected : ''} //  '' to reset field value
      onChange={(e, value) => onChange(value)}
      InputProps={{
        endAdornment: <InputAdornment position="end">{isPercentage ? '%' : getCurrencySymbol()}</InputAdornment>,
      }}
    />
  </div>
)

const Tip = ({ classes, label, selected, onClick }) => (
  <TypographyCustom
    type="164"
    onClick={onClick}
    className={clsx(classes.tip, { [classes.tipSelected]: selected })}
  >
    {label}
  </TypographyCustom>
)


const round = (exactValue, roundingValue) => {
  return Math.round(exactValue / roundingValue) * roundingValue
}

const TipBar = ({ tips, onChoseTip, tipsOptions, isPercentage, amountWithoutTips, roundValue }) => {
  const classes = useStyles()
  const [customValueSelected, setCustomValueSelected] = React.useState(0)
  const [initialized, setInitialized] = React.useState(false)

  const onTipSelection = React.useCallback((tipAmount, customValue) => {
    onChoseTip(tipAmount)
    setCustomValueSelected(customValue)
  }, [onChoseTip])

  const calculateAmountFromPercentage = React.useCallback((percentage, roundValue) => {
    const exactAmount = amountWithoutTips * percentage / 100;
    return round(exactAmount, roundValue)
  }, [amountWithoutTips])

  React.useEffect(
    () => {
      if (!initialized) {
        // custom value (percentage or amount). 0 if no custom value
        // initialized if there is no fixed value corresponding to the existing tips
        let customValue = null
        let matchingPossibleValue = tipsOptions.find(possibleValue => tips === (isPercentage ? calculateAmountFromPercentage(possibleValue.value, roundValue) : possibleValue.value))
        if (tips > 0 && !matchingPossibleValue) {
          customValue = { value: isPercentage ? Number((tips * 100 / amountWithoutTips).toFixed(2)) : tips }
        }
        const defaultValue = tipsOptions.find(possibleValue => possibleValue.selectedByDefault)
        const currentValue = matchingPossibleValue ?? customValue ?? defaultValue ?? { value: 0 }
        const tipAmount = isPercentage ? calculateAmountFromPercentage(currentValue.value, roundValue) : currentValue.value
        setInitialized(true)
        onTipSelection(tipAmount, customValue?.value)
      }
    },
    [tipsOptions, amountWithoutTips, isPercentage, tips, calculateAmountFromPercentage, roundValue, onTipSelection, initialized]
  )

  return (
    <div className={classes.root}>
      {tipsOptions && tipsOptions.map(({ value }) => {

        let selected = false
        if (!customValueSelected) {
          selected = isPercentage ? (tips === calculateAmountFromPercentage(value, roundValue)) : (value === tips)
        }

        return (
          <Tip
            classes={classes}
            key={`tip-${value}`}
            label={isPercentage ? `${value} %` : <Price value={formatPriceWithCurrency(value)} />}
            selected={selected}
            // on click, toggle the tips
            onClick={() => {
              const tipAmount = selected ? 0 : isPercentage ? calculateAmountFromPercentage(value, roundValue) : value
              const customValue = 0
              onTipSelection(tipAmount, customValue)
            }}
          />
        )
      })}
      <CustomTip
        customValueSelected={customValueSelected}
        onChange={value => {
          const tipAmount = isPercentage ? calculateAmountFromPercentage(value, roundValue) : value
          const customValue = value
          onTipSelection(tipAmount, customValue)
        }}
        classes={classes}
        isPercentage={isPercentage}
        placeholder={tipsOptions && tipsOptions.length > 0 ? tr('global.payment.custom_tip') : ''}
      />
    </div>
  )
}

export default TipBar
