// @flow
import { blackList } from './blackList'

const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const isValidEmail = email => {
  if (!email) {
    return false
  }
  if (!EMAIL_REGEXP.test(email)) {
    return false
  }

  const matches = email.match(/[^@]+$/)
  if (!matches || !matches[0]) {
    return false
  }
  const domain = matches[0] || null
  if (!domain) {
    return false
  }

  if (blackList.indexOf(domain) !== -1) {
    return false
  }

  return true
}
