import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

import { tr } from 'pmt-modules/i18n'

import {
  addEntryToPayment,
  shareEntryToPaymentUpdate,
  getEntryWithId,
  getItemWithId,
  getDefaultChoose,
} from 'pmt-modules/payment'
import { hideDialogAction, ModalType } from 'pmt-modules/dialog'

import { withStyles } from 'pmt-ui/styles'
import { DialogActions, DialogContent, DialogTitle } from 'pmt-ui/Dialog'
import Grid from 'pmt-ui/Grid'
import NumberField from 'pmt-ui/NumberField'
import { TypographyCustom } from 'pmt-ui/Typography'

import { Button } from '../../Button'

const styles = theme => ({
  root: {
    width: '100%',
  },
  title: {
    color: theme.palette.primary.main,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    float: 'left',
    width: 50,
  },
  inputTheme: {
    textAlign: 'center',
  },
  divider: {
    float: 'left',
    height: 32,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    lineHeight: '32px',
  },
  multiplier: {
    float: 'left',
    height: 32,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    lineHeight: '32px',
  },
  entryName: {
    float: 'left',
    width: 'calc(100% - 147px)',
    minHeight: 18,
    maxHeight: 29,
    overflow: 'hidden',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    lineHeight: '14px',
  },
  actionsContainer: {
    padding: `0 ${theme.spacing(2)}px`,
  },
  button: {
    width: '100%',
  },
  buttonSubmitLabel: {
    color: theme.pmt.colors.white,
  },
  buttonCancelLabel: {
    color: theme.palette.primary.main,
  },
})

class ChooseEntryDividerDialog extends React.Component {
  state = {
    share: this.props.share,
  }

  constructor(props) {
    super(props)

    this.state.share = {
      ...this.state.share,
      // if already a share: use existing value. Otherwise, use the quantity as divider (except if quantity = 1, then the default divider is 2)
      divider: props.item.share.enabled
        ? props.item.share.divider
        : Math.max(props.entry.quantity, 2),
      multiplier: props.item.share.multiplier,
    }
  }

  onCancel = () => this.props.hideDialogAction(ModalType.PAYMENT_CHOOSE_ENTRY_DIVIDER)

  onSubmit = () => {
    const {
      entry,
      item,
      addEntryToPayment,
      shareEntryToPaymentUpdate,
      hideDialogAction,
    } = this.props

    if (this.state.share.multiplier >= this.state.share.divider) {
      addEntryToPayment(entry)
    } else {
      shareEntryToPaymentUpdate(entry, item, this.state.share)
    }
    hideDialogAction(ModalType.PAYMENT_CHOOSE_ENTRY_DIVIDER)
  }

  isDisabled = () =>
    this.state.share.divider <= 0 ||
    this.state.share.multiplier <= 0 ||
    this.state.share.multiplier > this.state.share.divider

  render() {
    const { entry, classes } = this.props

    return (
      <div className={classes.root}>
        <DialogTitle>
          <TypographyCustom type="167" className={classes.title}>
            {tr('web_customer.payment.popin.choose_divider.title')}
          </TypographyCustom>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <NumberField
            id="multiplier"
            className={classes.input}
            classes={{ textField: classes.inputTheme }}
            value={this.state.share.multiplier ?? ''}
            min={0}
            max={100}
            onChange={(e, value) => {
              this.setState({
                share: {
                  ...this.state.share,
                  multiplier: value,
                },
              })
            }}
          />
          <TypographyCustom type="144" className={classes.divider}>
            /
          </TypographyCustom>
          <NumberField
            id="divider"
            className={classes.input}
            classes={{ textField: classes.inputTheme }}
            value={this.state.share.divider ?? ''}
            min={0}
            max={100}
            onChange={(e, value) => {
              this.setState({
                share: {
                  ...this.state.share,
                  divider: value,
                },
              })
            }}
          />
          <TypographyCustom type="144" className={classes.multiplier}>
            x
          </TypographyCustom>
          <TypographyCustom type="144" className={classes.entryName}>
            {entry.name}
          </TypographyCustom>
        </DialogContent>
        <DialogActions>
          <Grid container className={classes.actionsContainer}>
            <Grid item xs={12}>
              <Button
                className={classes.button}
                onClick={this.onSubmit}
                disabled={this.isDisabled()}
              >
                <TypographyCustom type="167" className={classes.buttonSubmitLabel}>
                  {tr('web_customer.payment.popin.choose_divider.take')}{' '}
                  {this.state.share.multiplier}
                  &nbsp;/&nbsp;
                  {this.state.share.divider}
                </TypographyCustom>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button variant="text" className={classes.button} onClick={this.onCancel}>
                <TypographyCustom type="167" className={classes.buttonCancelLabel}>
                  {tr('global.cancel')}
                </TypographyCustom>
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </div>
    )
  }
}

ChooseEntryDividerDialog.DialogRootProps = {
  disableBackdropClick: true,
}

ChooseEntryDividerDialog.defaultProps = {
  share: getDefaultChoose(),
}

ChooseEntryDividerDialog.DialogStyles = theme => ({
  paper: {
    maxWidth: 'none',
    margin: theme.spacing(3),
  },
})

const mapStateToProps = (state, ownProps) => {
  return {
    entry: getEntryWithId(state, ownProps),
    item: getItemWithId(state, ownProps),
  }
}

export default compose(
  connect(
    mapStateToProps,
    {
      hideDialogAction,
      addEntryToPayment,
      shareEntryToPaymentUpdate,
    }
  ),
  withStyles(styles)
)(ChooseEntryDividerDialog)
