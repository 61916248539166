import React from 'react'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'

import { isRefreshingOauthToken } from 'pmt-modules/auth'
import CheckContainer from 'pmt-modules/payment/components/CheckContainer'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import { redirectTo, getRoute } from 'pmt-modules/routing'
import {
  setPaymentTypeAll,
  setPaymentTypeShare,
  setPaymentTypeEntries,
  fetchRefreshCheck,
} from 'pmt-modules/payment'

import Page from '../../../components/Page'

import View from './View'

/**
 * https://paymytable.atlassian.net/browse/PP-896
 * https://app.zeplin.io/project/5b616247d86fcf41520410cc/screen/5b61d7fb1b881f434d00e545
 */
@withRestaurant
class CheckPage extends React.Component {
  handleRedirectToTotality = () => {
    this.props.setPaymentTypeAll()
    this.props.redirectTo(getRoute('PAYMENT_PAYMENT'), {
      restaurantId: this.props.restaurant.id,
      checkId: this.props.routeParams.checkId,
    })
  }

  handleRedirectToShare = () => {
    this.props.setPaymentTypeShare()
    this.props.redirectTo(getRoute('PAYMENT_SHARE'), {
      restaurantId: this.props.restaurant.id,
      checkId: this.props.routeParams.checkId,
    })
  }

  handleRedirectToChoose = () => {
    this.props.setPaymentTypeEntries()
    this.props.redirectTo(getRoute('PAYMENT_CHOOSE'), {
      restaurantId: this.props.restaurant.id,
      checkId: this.props.routeParams.checkId,
    })
  }

  handleRefreshCart = () => {
    this.props.fetchRefreshCheck(this.props.restaurantId, this.props.routeParams.checkId)
  }

  render() {
    const { restaurant, isRefreshingOauthToken, routeParams, restaurantId, redirectTo } = this.props

    return (
      <CheckContainer
        restaurantId={routeParams.restaurantId}
        checkId={routeParams.checkId}
        forceReload
      >
        {({ check, isFetchingCheck }) => (
          <Page
            title={
              !isNil(restaurant)
                ? !isNil(check)
                  ? `${restaurant.name} - ${check.tableNumberLabel}`
                  : restaurant.name
                : null
            }
            goBackRoute={() => redirectTo(getRoute('RESTAURANT'), { restaurantId })}
          >
            <View
              check={check}
              isFetchingCheck={isFetchingCheck || isRefreshingOauthToken}
              onRefreshCart={this.handleRefreshCart}
              onRedirectToTotality={this.handleRedirectToTotality}
              onRedirectToShare={this.handleRedirectToShare}
              onRedirectToChoose={this.handleRedirectToChoose}
            />
          </Page>
        )}
      </CheckContainer>
    )
  }
}

CheckPage.propTypes = {}

const mapStateToProps = (state, props) => ({
  isRefreshingOauthToken: isRefreshingOauthToken(state, props),
})

export default connect(
  mapStateToProps,
  {
    setPaymentTypeAll,
    setPaymentTypeShare,
    setPaymentTypeEntries,
    fetchRefreshCheck,
    redirectTo,
  }
)(CheckPage)
