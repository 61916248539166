import { createSimpleReducer } from 'pmt-modules/redux'
import { FetchPaymentPdfLinkAction, ResetPaymentPdfLinkAction } from './actions'
import Immutable from 'immutable'

export * from './actions'
export * from './selectors'

const resetData = (state, action) => {
  return Immutable.fromJS({})
}

export const fetchPaymentPdfLinkReducer = createSimpleReducer(FetchPaymentPdfLinkAction, {
  [ResetPaymentPdfLinkAction]: resetData,
})
