import { createAction } from '../redux'

export const WebCustomerAction = {
  PROCESS_PAYMENT: 'WEB_CUSTOMER::PROCESS_PAYMENT',
}

export const processPaymentv2 = (
  restaurantId,
  paymentMethod,
  payment,
  check,
  { selectedCreditCard }
) =>
  createAction(WebCustomerAction.PROCESS_PAYMENT, {
    restaurantId,
    paymentMethod,
    selectedCreditCard,
    payment,
    check,
  })
