/*
* This module handles OAuth2 Application login.
*
* Summary:
* - The client (mobile app, etc) display our /authorize page
* - we ask the user if it authorize the app to use its account
* - if yes, we generate a `code` (authorization code)
* - we redirect to the client's `redirect_uri` with the code as query param
*
* Grant types support by draft 20
*
* The Authorization Code grant type is used when the client wants to request access to protected
* resources on behalf of another user (i.e. a 3rd party). This is the grant type most often
* associated with OAuth.
*
* The authorization code grant should be very familiar if you’ve ever signed into a web app using
* your Facebook or Google account.
*
* The authorization code grant type is used to obtain both access tokens and refresh tokens and
* is optimized for confidential clients. Since this is a redirection-based flow, the client must
* be capable of interacting with the resource owner’s user-agent (typically a web browser) and
* capable of receiving incoming requests (via redirection) from the authorization server.
*
* The reddit documentation can help to understand:
* https://github.com/reddit/reddit/wiki/OAuth2
*
* <pre>
* +----------+
* | Resource |
* |   Owner  |
* |          |
* +----------+
*      ^
*      |
*     (B)
* +----|-----+          Client Identifier      +---------------+
* |         -+----(A)-- & Redirection URI ---.|               |
* |  User-   |                                 | Authorization |
* |  Agent  -+----(B)-- User authenticates --.|     Server    |
* |          |                                 |               |
* |         -+----(C)-- Authorization Code ---<|               |
* +-|----|---+                                 +---------------+
*   |    |                                         ^      v
*   (A)  (C)                                       |      |
*   |    |                                         |      |
*   ^    v                                         |      |
* +---------+                                      |      |
* |         |>---(D)-- Authorization Code ---------'      |
* |  Client |          & Redirection URI                  |
* |         |                                             |
* |         |<---(E)----- Access Token -------------------'
* +---------+       (w/ Optional Refresh Token)
* </pre>
*
* Note: The lines illustrating steps (A), (B), and (C) are broken into two parts as they pass
* through the user-agent.
*
* A - The client initiates the flow by directing the resource owner’s user-agent to the
* authorization endpoint. The client includes its client identifier, requested scope, local
* state, and a redirection URI to which the authorization server will send the user-agent back
* once access is granted (or denied).
*
* B - The authorization server authenticates the resource owner (via the user-agent) and
* establishes whether the resource owner grants or denies the client’s access request.
*
* C - Assuming the resource owner grants access, the authorization server redirects the
* user-agent back to the client using the redirection URI provided earlier (in the request or
* during client registration). The redirection URI includes an authorization code and any local
* state provided by the client earlier.
*
* D  - The client requests an access token from the authorization server’s token endpoint by
* including the authorization code received in the previous step. When making the request, the
* client authenticates with the authorization server. The client includes the redirection URI
* used to obtain the authorization code for verification.
*
* E - The authorization server authenticates the client, validates the authorization code, and
* ensures that the redirection URI received matches the URI used to redirect the client in step
* (C). If valid, the authorization server responds back with an access token and, optionally, a
* refresh token.
*
* Flow:
*
* <p> ---- Part1:
*
* The client will redirect the user to the authorization server with the following parameters in
* the query string:
*
* - response_type with the value 'code'
*
* - client_id with the client identifier
*
* - redirect_uri with the client redirect URI. This parameter is optional, but if not send the
* user will be redirected to a pre-registered redirect URI.
*
* - scope a space delimited list of scopes
*
* - state with a CSRF token. This parameter is optional but highly recommended. You should store
* the value of the CSRF token in the user’s session to be validated when they return.
*
* - All of these parameters will be validated by the authorization server. </p>
*
* <p> The user will then be asked to login to the authorization server and approve the client. If
* the user approves the client they will be redirected from the authorization server to the
* client’s redirect URI with the following parameters in the query string:
*
* - code with the authorization code
*
* - state with the state parameter sent in the original request. You should compare this value
* with the value stored in the user’s session to ensure the authorization code obtained is in
* response to requests made by this client rather than another client application. </p>
*
* <p> ---- Part2:
*
* The client will now send a POST request to the authorization server with the following
* parameters:
*
* - grant_type with the value of authorization_code - client_id with the client identifier -
* client_secret with the client secret - redirect_uri with the same redirect URI the user was
* redirect back to - code with the authorization code from the query string
*
* The authorization server will respond with a JSON object containing the following properties:
*
* - token_type with the value Bearer - expires_in with an integer representing the TTL of the
* access token - access_token a JWT signed with the authorization server’s private key -
* refresh_token an encrypted payload that can be used to refresh the access token when it
* expires. </p>
*/
import { appDataReducer } from './getApp'

import { authorizeAppReducer } from './authorizeApp'

import { combineReducers } from 'redux'

export * from './actions'
export * from './authorizeApp'
export * from './getApp'

export const authAppReducer = combineReducers({
  appData: appDataReducer,
  authorize: authorizeAppReducer,
})
