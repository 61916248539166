import { tr } from 'pmt-modules/i18n'
import keys from 'lodash/keys'

import { generateIdempotencyKey } from 'pmt-utils/idempotency'

export const TOP_UP_TYPE = {
  NONE: 'NONE',
  CASH: 'CASH',
  CREDIT_CARD: 'CREDIT_CARD',
  LUNCHEON_VOUCHER: 'LUNCHEON_VOUCHER',
  // Deposit is made by an import
  IMPORTED_INITIAL_BALANCE: 'IMPORTED_INITIAL_BALANCE',
}

export const DEFAULT_TOP_UP = () => ({
  amount: 0,
  otherAmountClicked: false,
  idempotencyKey: generateIdempotencyKey(),
  type: TOP_UP_TYPE.CREDIT_CARD,
})

export const TOP_UP_STATUS = {
  CREATED: 0,
  FAILED: 1,
  SUCCEEDED: 2,
  REFUNDED: 3,
}

export const getTopUpTypeLabel = () => ({
  [TOP_UP_TYPE.NONE]: tr('global.top_up.type.none'),
  [TOP_UP_TYPE.CASH]: tr('global.top_up.type.cash'),
  [TOP_UP_TYPE.CREDIT_CARD]: tr('global.top_up.type.credit_card'),
  [TOP_UP_TYPE.LUNCHEON_VOUCHER]: tr('global.top_up.type.luncheon_voucher'),
  [TOP_UP_TYPE.IMPORTED_INITIAL_BALANCE]: tr('global.top_up.type.initial_balance'),
})

export const getTopUpTypeLabelAsArray = () =>
  keys(TOP_UP_TYPE).map(value => ({
    label: getTopUpTypeLabel()[value],
    value,
  }))
