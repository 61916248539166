import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'

import withCheck from 'pmt-modules/payment/components/withCheck'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import { redirectTo, getRoute } from 'pmt-modules/routing'

import {
  setPaymentTypeShare,
  increaseDivider,
  decreaseDivider,
  increaseMultiplier,
  decreaseMultiplier,
  fetchRefreshCheck,
} from 'pmt-modules/payment'

import Page from '../../../components/Page'
import View from './View'

/**
 * https://app.zeplin.io/project/5b616247d86fcf41520410cc/screen/5b684e7f1e7df31acf9ad185
 * https://app.zeplin.io/project/5b616247d86fcf41520410cc/screen/5b684e7f1e7df31acf9ad1a0
 * https://paymytable.atlassian.net/browse/PP-899
 */
@withCheck
@withRestaurant
class Share extends React.Component {
  componentDidMount() {
    this.props.setPaymentTypeShare()
  }

  handleSubmit = () => {
    const { restaurantId, checkId } = this.props
    this.props.fetchRefreshCheck(restaurantId, checkId, true)
  }

  handleIncreaseDivider = () => {
    this.props.increaseDivider()
  }

  handleDecreaseDivider = () => {
    this.props.decreaseDivider()
  }

  handleIncreaseMultiplier = () => {
    this.props.increaseMultiplier()
  }
  handleDecreaseMultiplier = () => {
    this.props.decreaseMultiplier()
  }

  render() {
    const { check, payment, isFetchingCheck, restaurantId, checkId, redirectTo } = this.props

    return (
      <Page
        title={tr('web_customer.payment.share.title')}
        goBackRoute={() => redirectTo(getRoute('PAYMENT_CHECK_VIEW'), { restaurantId, checkId })}
      >
        <View
          isFetchingCheck={isFetchingCheck}
          check={check}
          payment={payment}
          onSubmit={this.handleSubmit}
          onIncreaseDivider={this.handleIncreaseDivider}
          onDecreaseDivider={this.handleDecreaseDivider}
          onIncreaseMultiplier={this.handleIncreaseMultiplier}
          onDecreaseMultiplier={this.handleDecreaseMultiplier}
        />
      </Page>
    )
  }
}

Share.propTypes = {}

const mapStateToProps = () => ({})

export default connect(
  mapStateToProps,
  {
    setPaymentTypeShare,
    increaseDivider,
    decreaseDivider,
    increaseMultiplier,
    decreaseMultiplier,
    fetchRefreshCheck,
    redirectTo,
  }
)(Share)
