import { tr } from 'pmt-modules/i18n'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import isEmpty from 'lodash/isEmpty'
import {
  getIsFetchingPostCreditCardRegistrationType,
  getErrorPostCreditCardRegistrationType,
  getSendUserCreditCardToPspError,
  getPostPspDatasError,
  postUserCreditCardRegistrationType,
  resetUserCreditCardRegistrationTypeError,
  resetPspDatasError,
} from 'pmt-modules/creditCard'
import CreditCardFormView from 'pmt-modules/creditCard/components/CreditCardFormView'
import { FormType, resetFormWithDefaultData, withForm } from 'pmt-modules/form'
import ErrorBlock from 'pmt-ui/ErrorBlock'
import { LoadingBlockWrapper } from 'pmt-ui/LoadingBlock'
import { withStyles } from 'pmt-ui/styles'
import CreditCardVisa from 'pmt-ui/svg-icons/pmt/CreditCardVisa'
import CreditCardMasterCard from 'pmt-ui/svg-icons/pmt/CreditCardMasterCard'
import Button from 'app/components/Button'
import { TypographyCustom } from 'pmt-ui/Typography'


const styles = theme => ({
  creditCardTypes: {
    textAlign: 'right',

    '&>svg': {
      width: 40,
      height: 24,
      marginLeft: theme.spacing(1),
    },
  },
  errorBlock: {
    marginTop: 0,
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  tos: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: theme.pmt.colors.grey91,
  },
  link: {
    textDecoration: 'underline',
  }
})

class AddCreditCardFormView extends React.PureComponent {
  constructor(props) {
    super(props)

    props.resetFormWithDefaultData(FormType.CREDIT_CARD)
  }

  componentWillUnmount() {
    this.props.setShowCreditCardForm(false)
    this.props.resetUserCreditCardRegistrationTypeError()
    this.props.resetPspDatasError()
  }

  render() {
    const {
      classes,
      formClasses,
      formIsValid,
      isFetchingPostCreditCardRegistrationType,
      errorPostCreditCardRegistrationType,
      errorPostPspDatas,
      submitLabel,
      onSubmitCreditCard,
      withName,
      tosUrl,
    } = this.props

    return (
      <Fragment>
        <LoadingBlockWrapper show={isFetchingPostCreditCardRegistrationType} />

        <div className={classes.creditCardTypes}>
          <CreditCardVisa />
          <CreditCardMasterCard />
        </div>

        <ErrorBlock
          error={errorPostCreditCardRegistrationType || errorPostPspDatas}
          mode={ErrorBlock.Mode.SNACKBAR}
        />

        <CreditCardFormView withName={withName} withOptionnalRegister classes={formClasses} />

        {!isEmpty(tosUrl) && (
          <TypographyCustom
            type="144"
            className={classes.tos}
            dangerouslySetInnerHTML={{
              __html: tr('web_customer.payment.tos', {
                linkBegin: `<a class="${classes.link}" href="${tosUrl}" target="_blank" rel="noopener noreferrer">`,
                linkEnd: '</a>',
              }),
            }}
          />
        )}

        <Button
          type="submit"
          size="large"
          disabled={!formIsValid || isFetchingPostCreditCardRegistrationType}
          className={classes.submitButton}
          label={submitLabel}
          onClick={onSubmitCreditCard}
        >
          {submitLabel}
        </Button>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  isFetchingPostCreditCardRegistrationType: getIsFetchingPostCreditCardRegistrationType(state),
  errorPostCreditCardRegistrationType: getErrorPostCreditCardRegistrationType(state),
  errorSendUserCreditCardToPsp: getSendUserCreditCardToPspError(state),
  errorPostPspDatas: getPostPspDatasError(state),
})

export default compose(
  withForm(FormType.CREDIT_CARD),
  withStyles(styles),
  connect(
    mapStateToProps,
    {
      postUserCreditCardRegistrationType,
      resetFormWithDefaultData,
      resetPspDatasError,
      resetUserCreditCardRegistrationTypeError,
    }
  )
)(AddCreditCardFormView)
