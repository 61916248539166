import { tr } from 'pmt-modules/i18n'
import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { hideDialogAction, ModalType } from 'pmt-modules/dialog'
import { redirectTo, getRoute } from 'pmt-modules/routing'
import { setPaymentTypeAll } from 'pmt-modules/payment'

import { withStyles } from 'pmt-ui/styles'
import { DialogActions, DialogContent, DialogTitle } from 'pmt-ui/Dialog'
import Grid from 'pmt-ui/Grid'
import { TypographyCustom } from 'pmt-ui/Typography'

import { Button } from '../../Button'

const styles = theme => ({
  root: {
    width: '100%',
  },
  title: {
    color: theme.palette.primary.main,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  actions: {
    margin: 0,
    padding: `0 ${theme.spacing(2.5)}px ${theme.spacing(1)}px`,
  },
  button: {
    width: '100%',
  },
  buttonSubmitLabel: {
    color: theme.pmt.colors.white,
  },
  buttonCancelLabel: {
    color: theme.palette.primary.main,
  },
})

class GenerousPaymentDialog extends React.Component {
  onCancel = () => this.props.hideDialogAction(ModalType.PAYMENT_GENEROUS_PAYMENT)

  onSubmit = () => {
    const { restaurantId, checkId, setPaymentTypeAll, redirectTo, hideDialogAction } = this.props

    setPaymentTypeAll()
    redirectTo(getRoute('PAYMENT_PAYMENT'), {
      restaurantId,
      checkId,
    })
    hideDialogAction(ModalType.PAYMENT_GENEROUS_PAYMENT)
  }

  render() {
    const { amount, outstandingBalance, classes } = this.props

    return (
      <div className={classes.root}>
        <DialogTitle>
          <TypographyCustom type="167" className={classes.title}>
            {tr('web_customer.payment.popin.generous_payment.title')}
          </TypographyCustom>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <TypographyCustom type="144">
            {tr('web_customer.payment.popin.generous_payment.content', {
              amount,
              outstandingBalance,
            })}
          </TypographyCustom>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Grid container>
            <Grid item xs={12}>
              <Button className={classes.button} onClick={this.onSubmit}>
                <TypographyCustom type="167" className={classes.buttonSubmitLabel}>
                  {tr('web_customer.payment.popin.generous_payment.pay', {
                    outstandingBalance,
                  })}
                </TypographyCustom>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="text"
                className={classNames(classes.button, 'u-marginTop5')}
                onClick={this.onCancel}
              >
                <TypographyCustom type="167" className={classes.buttonCancelLabel}>
                  {tr('web_customer.payment.popin.generous_payment.no_cancel')}
                </TypographyCustom>
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </div>
    )
  }
}

GenerousPaymentDialog.DialogRootProps = {
  disableBackdropClick: true,
}

GenerousPaymentDialog.DialogStyles = theme => ({
  paper: {
    maxWidth: 'none',
    margin: theme.spacing(3),
  },
})

const mapStateToProps = (state, ownProps) => {
  return {}
}

export default compose(
  connect(
    mapStateToProps,
    {
      setPaymentTypeAll,
      redirectTo,
      hideDialogAction,
    }
  ),
  withStyles(styles)
)(GenerousPaymentDialog)
