import React from 'react'
import pure from 'recompose/pure'
import isEmpty from 'lodash/isEmpty'

import { getPublicUrl } from 'pmt-modules/environment'

let Flag = ({ className, country, alt = '', ...otherProps }) =>
  !isEmpty(country) && (
    <img
      className={className}
      src={getPublicUrl() + require(`./flags/${country.toLowerCase()}.svg`)}
      alt={alt}
      {...otherProps}
    />
  )

Flag = pure(Flag)
Flag.displayName = 'Flag'

export default Flag
