import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { tr } from 'pmt-modules/i18n'
import isNil from 'lodash/isNil'

import { getOauthClient, withAppConfig } from 'pmt-modules/appConfig'
import { withRestaurantsGroupMine } from 'pmt-modules/restaurantsGroup/components'
import { authenticateUser, getAuthError, isFetchingAuth, logout } from 'pmt-modules/auth'
import { AuthMode } from 'pmt-modules/auth/constants'
import { clearAttemptRefresh } from 'pmt-modules/auth/refreshToken/attemptRefresh'
import {
  getRegisterError,
  isFetchingRegister,
  registerAsIncognito,
  registerUserLight,
  registerUser,
} from 'pmt-modules/registration'

import LoadingPage from 'pmt-ui/LoadingPage'
import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'

import PaymentPage from '../../components/Page'
import AuthenticationChoiceForm from './components/AuthenticationChoiceForm'
import LoginForm from './components/LoginForm'
import RegisterForm from './components/RegisterForm'
import UserLightForm from './components/UserLightForm'
import IncognitoForm from './components/IncognitoForm'
import { LoginViews } from './constants'
import { getQueryParam } from 'pmt-utils/url'

@withAppConfig
@withRestaurantsGroupMine()
class LoginPage extends React.Component {
  constructor(props) {
    super(props)

    // clear refresh token retrying
    clearAttemptRefresh()
    // logout
    this.props.logout()

    let view
    if (this.props.initialView) {
      view = this.props.initialView
    } else if (this.props.appConfig.authentication.mode === AuthMode.LIGHT) {
      view = LoginViews.USER_LIGHT
    } else if (this.props.appConfig.authentication.mode === AuthMode.INCOGNITO_ONLY) {
      // this param is automatically added when the user is redirected to the login page because he got a 401 due to TEST_MODE_UNAUTHORIZED
      if (getQueryParam('forTestMode') === 'true') {
        //  we allow test user to login even on incognito_only apiConsumer
        // so a user can use the login page to authenticate as TEST user, and access test restaurants
        view = LoginViews.LOGIN
      } else {
        view = LoginViews.INCOGNITO
      }
    } else {
      // NORMAL authentication
      view = LoginViews.CHOICE_TO_DO
    }

    this.state = {
      view: view,
    }
  }

  handleSubmitUserLightLogin = formData => {
    this.props.registerUserLight(formData)
  }

  handleSubmitLogin = formData => {
    const credentials = formData
    const { clientId, clientSecret } = this.props.oauthClient

    this.props.authenticateUser(credentials.username, credentials.password, {
      clientId,
      clientSecret,
    })
  }

  handleKeepIncognito = formData => {
    this.props.registerAsIncognito(formData)
  }

  handleSubmitRegistration = formData => {
    const { clientId, clientSecret } = this.props.oauthClient

    this.props.registerUser(formData, {
      clientId,
      clientSecret,
    })
  }

  handleToggleView = newView => {
    this.setState({
      view: newView,
    })
    window.scrollTo(0, 0)
  }

  onRedirectToUserLight = () => this.handleToggleView(LoginViews.USER_LIGHT)
  onRedirectToLogin = () => this.handleToggleView(LoginViews.LOGIN)
  onRedirectToRegister = () => this.handleToggleView(LoginViews.REGISTER)
  onRedirectToIncognito = () => this.handleToggleView(LoginViews.INCOGNITO)

  render() {
    const {
      authError,
      isFetchingAuth,
      registerError,
      isFetchingRegister,
      isFetchingRestaurantsGroup,
      restaurantsGroup,
      appConfig,
    } = this.props

    if (isFetchingRestaurantsGroup) {
      return <LoadingPage show />
    }

    return (
      <CustomTextsContainer>
        {({ texts }) => (
          <React.Fragment>
            {this.state.view === LoginViews.CHOICE_TO_DO && (
              <PaymentPage title={tr('web_customer.connection.title')}>
                <AuthenticationChoiceForm
                  isFetchingAuth={isFetchingAuth}
                  isFetchingRegister={isFetchingRegister}
                  authError={authError}
                  onRedirectToLogin={this.onRedirectToLogin}
                  onRedirectToRegister={this.onRedirectToRegister}
                  onRedirectToIncognito={this.onRedirectToIncognito}
                  allowIncognito={appConfig.authentication.allowIncognito}
                />
              </PaymentPage>
            )}

            {this.state.view === LoginViews.USER_LIGHT && (
              <PaymentPage title={tr('web_customer.connection.title')}>
                <UserLightForm
                  isFetchingRegister={isFetchingRegister}
                  registerError={registerError}
                  onSubmitLogin={this.handleSubmitUserLightLogin}
                  onRedirectToIncognito={this.onRedirectToIncognito}
                  allowIncognito={appConfig.authentication.allowIncognito}
                  restaurantsGroup={restaurantsGroup}
                  customTexts={texts}
                />
              </PaymentPage>
            )}

            {this.state.view === LoginViews.LOGIN && (
              <PaymentPage title={tr('web_customer.connection.title')}>
                <LoginForm
                  isFetchingAuth={isFetchingAuth}
                  isFetchingRegister={isFetchingRegister}
                  authError={authError}
                  onSubmitLogin={this.handleSubmitLogin}
                  onRedirectToRegister={this.onRedirectToRegister}
                  onRedirectToIncognito={this.onRedirectToIncognito}
                  allowIncognito={appConfig.authentication.allowIncognito}
                />
              </PaymentPage>
            )}

            {this.state.view === LoginViews.INCOGNITO && (
              <PaymentPage
                title={
                  appConfig.authentication.mode === AuthMode.INCOGNITO_ONLY
                    ? !isNil(texts) && !isNil(texts.ORDER__INCOGNITO__TITLE)
                      ? texts.ORDER__INCOGNITO__TITLE
                      : tr('web_customer.login.continue_as_incognito.from_INCOGNITO_ONLY.title')
                    : tr('web_customer.connection.title')
                }
              >
                <IncognitoForm
                  isFetchingAuth={isFetchingAuth}
                  isFetchingRegister={isFetchingRegister}
                  authError={authError}
                  onRedirectToLogin={this.onRedirectToLogin}
                  onRedirectToRegister={this.onRedirectToRegister}
                  onRedirectToUserLight={this.onRedirectToUserLight}
                  handleKeepIncognito={this.handleKeepIncognito}
                  restaurantsGroup={restaurantsGroup}
                  authMode={appConfig.authentication.mode}
                  customTexts={texts}
                />
              </PaymentPage>
            )}

            {this.state.view === LoginViews.REGISTER && (
              <PaymentPage title={tr('web_customer.register.title')}>
                <RegisterForm
                  isFetchingRegister={isFetchingRegister}
                  registerError={registerError}
                  onSubmitRegistration={this.handleSubmitRegistration}
                  onRedirectToLogin={this.onRedirectToLogin}
                  onRedirectToIncognito={this.onRedirectToIncognito}
                  allowIncognito={appConfig.authentication.allowIncognito}
                  restaurantsGroup={restaurantsGroup}
                />
              </PaymentPage>
            )}
          </React.Fragment>
        )}
      </CustomTextsContainer>
    )
  }
}

const mapStateToProps = state => ({
  isFetchingAuth: isFetchingAuth(state),
  authError: getAuthError(state),
  isFetchingRegister: isFetchingRegister(state),
  registerError: getRegisterError(state),
  oauthClient: getOauthClient(state),
})

export default compose(
  connect(
    mapStateToProps,
    {
      authenticateUser,
      registerUserLight,
      registerAsIncognito,
      registerUser,
      logout,
    }
  )
)(LoginPage)
