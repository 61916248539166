import { tr } from 'pmt-modules/i18n'
import each from 'lodash/each'
import isEmpty from 'lodash/isEmpty'

import {
  notEmpty,
  isValidEmail,
  isValidPhone,  
  isTrue,
} from 'pmt-modules/form/validation'

import createForm from 'pmt-modules/form/createForm'

import FormType from 'pmt-modules/form/FormType'

import { getField } from './constants'

export UserLightFormView from './UserLightFormView'

// in DEV environment, prefill form with the following data
const devData = {
  email: ``,
  firstName: 'Jane',
  lastName: 'Doe',
  phone: null,
  acceptCgu: true,
  marketing: {
    allowContact: true,
  },
  badgeId: null,
}

const defaultData = {
  email: null,
  firstName: null,
  lastName: null,
  phone: null,
  acceptCgu: false,
  nationality: 'FR', // FIXME: find a way to get the user's nationality
  marketing: {
    allowContact: false,
  },
  badgeId: null,
}

/**
 * Validation rules for user light form
 * options parameters should contains fields retrieved from api consumer settings
 * it is structured as is : {
 *   type: FIELD_TYPE,
 *   required: boolean
 * }
 * if field is required, we automatically add a notEmpty rule
 */
 const validationRules = options => {
  const rules = {
    email: [[isValidEmail, tr('global.register.form.validation.email_invalid')]],
    phone: [[isValidPhone, tr('global.register.form.validation.phone_invalid')]],
    acceptCgu: [[isTrue, tr('global.register.form.validation.accept_legal_terms')]],
  }

  if (!isEmpty(options.fields)) {
    each(options.fields, optionField => {
      if (optionField.required) {
        const field = getField(optionField.type)
        rules[field.key] = [
          ...(rules[field.key] || []),
          [notEmpty, tr(`global.register.form.validation.required.${field.key}`)],
        ]
      }
    })
  }

  return rules
}

export default createForm(
  FormType.USER_LIGHT,
  validationRules,
  defaultData,
  devData,
)
