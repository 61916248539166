import isNil from 'lodash/isNil'
import { createSelector } from 'reselect'

const getAuthorizeState = state => state.entities.authApp.authorize

export const getAuthorizeAppData = createSelector([getAuthorizeState], authorizeState => {
  const data = authorizeState.get('data', null)
  if (isNil(data)) {
    return null
  }
  return data.toJS()
})

export const fetchAuthorizeAppError = createSelector([getAuthorizeState], authorizeState => {
  const error = authorizeState.get('error', null)
  if (isNil(error)) {
    return null
  }
  return error.toJS()
})

export const isFetchingAuthorizeApp = createSelector([getAuthorizeState], authorizeState =>
  authorizeState.get('isFetching', false)
)
