import ApiEndpoints from '../config/ApiEndpoints'
import UserAccountErrors from '../errors/UserAccountErrors'

const UserAccountApi = {
  getUserAccounts: userId => {
    return {
      endpoint: ApiEndpoints.USER_ACCOUNTS,
      type: 'GET',
      params: {
        userId,
      },
    }
  },

  getUserAccount: (userId, userAccountId, refreshToken = false) => {
    return {
      endpoint: ApiEndpoints.USER_ACCOUNT,
      type: 'GET',
      params: {
        userId,
        userAccountId,
      },
      query: {
        refreshToken,
      },
    }
  },

  putUserAccount: (userId, userAccount) => ({
    endpoint: ApiEndpoints.USER_ACCOUNT,
    type: 'PUT',
    params: {
      userId,
      userAccountId: userAccount.id,
    },
    body: userAccount,
    errorHandler: UserAccountErrors.EDIT,
  }),
}

export default UserAccountApi
