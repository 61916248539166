const Diet = {
  /**
   * Default value, the user does not have specific diet preferences.
   */
  NONE: 'NONE',

  /**
   * Kosher foods are those that conform to the regulations of kashrut (Jewish dietary law).
   * Food that may be consumed according to halakha (Jewish law) is termed kosher in english.
   *
   * The Torah permits only animal species which both chew the cud and have cloven hooves.
   * Four animals are specifically identified as being forbidden for this reason; the hare,
   * hyrax, camel, and pig – although the camel has two toes, and the hare and hyrax are hindgut
   * fermenters rather than ruminants.
   *
   * The Torah lists winged creatures which may not be consumed, mainly birds of prey, fish-eating
   * water-birds, and bats. The Torah permits any fish residing in "the waters" (seas and rivers)
   * that has both fins and scales
   *
   * @link https://www.wikiwand.com/en/Kosher_foods
   */
  KOSHER: 'KOSHER',

  /**
   * is any object or action which is permissible to use or engage in, according to Islamic law.
   * The term covers and designates food and drink as well as matters of daily life.
   * It is one of five Ahkam—fard (compulsory), mustahabb (recommended), halal (allowed),
   * makruh (disliked), haram (forbidden)—that define the morality of human action in Islam.
   * The Dubai Chamber of Commerce estimated the global industry value of halal food consumer
   * purchases to be US$1.1 trillion in 2013, accounting for 16.6 percent of the global food and
   * beverage market, with an annual growth of 6.9 percent.
   *
   * @link https://www.wikiwand.com/en/Halal
   */
  HALAL: 'HALAL',

  /**
   * The practice of abstaining from the consumption of meat (red meat, poultry, seafood, and the
   * flesh of any other animal), and may also include abstention from by-products of animal
   * slaughter.
   */
  VEGETARIAN: 'VEGETARIAN',

  /**
   * Veganism is both the practice of abstaining from the use of animal products, particularly in
   * diet, and an associated philosophy that rejects the commodity status of animals.
   *
   * Note: You can spot those guys if they tell you "Hi, i'm vegan !"
   *
   * Note: In this case, you can ask Hand to bring the flamethrower in order to deal with this
   * infidel.
   * Long life to the Meat King !
   *
   * (no dairy produce / eggs)
   */
  VEGAN: 'VEGAN',
}

export default Diet
