import React from 'react'
import pure from 'recompose/pure'
import SvgIcon from '../../SvgIcon'

let CreditCardVisa = props => (
  <SvgIcon viewBox="0 0 50 30" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-84.000000, -146.000000)">
        <g id="Group-3" transform="translate(10.000000, -262.000000)">
          <g id="MasterCard-light" transform="translate(74.000000, 408.000000)">
            <rect id="Rectangle" fill="#EFEFF4" x="0" y="0" width="50" height="30" rx="2" />
            <path
              d="M28.0643105,15.2427011 C28.0643105,20.6318289 23.6959851,25 18.3070115,25 C12.9178837,25 8.54925,20.6318289 8.54925,15.2427011 C8.54925,9.85372747 12.9178837,5.48509375 18.3070115,5.48509375 C23.6959851,5.48509375 28.0643105,9.85372747 28.0643105,15.2427011"
              id="Fill-333"
              fill="#CC0000"
            />
            <path
              d="M31.4070531,5.48509375 C28.88527,5.48509375 26.5883102,6.44159493 24.8563384,8.0107318 C24.5039919,8.33054213 24.1750838,8.67594962 23.8714645,9.04371607 L25.8429085,9.04371607 C26.1121413,9.37154478 26.3602488,9.71618127 26.5867682,10.0771629 L23.1274506,10.0771629 C22.9205145,10.4088466 22.7323908,10.7534831 22.5652382,11.1106098 L27.1486722,11.1106098 C27.3053392,11.4450691 27.4431938,11.7895514 27.5619276,12.1435941 L22.1519828,12.1435941 C22.039417,12.480058 21.9444299,12.8248487 21.8685636,13.1764241 L27.8450384,13.1764241 C27.9881358,13.8425672 28.0643105,14.5336905 28.0643105,15.2427011 C28.0643105,16.3259545 27.8877517,17.3686533 27.5619276,18.3424249 L22.1519828,18.3424249 C22.2707166,18.6961592 22.4085712,19.041104 22.5649298,19.3760259 L27.1486722,19.3760259 C26.9812113,19.7322274 26.7940127,20.0771723 26.585843,20.4093186 L23.1274506,20.4093186 C23.3530448,20.7692209 23.6016149,21.11432 23.8714645,21.4416861 L25.8422917,21.4416861 C25.5392892,21.8097609 25.2100727,22.1551684 24.8566468,22.4749787 C26.5884644,24.0441156 28.88527,25 31.4070531,25 C36.7958725,25 41.164352,20.6318289 41.164352,15.2427011 C41.164352,9.85419007 36.7958725,5.48509375 31.4070531,5.48509375"
              id="Fill-334"
              fill="#FF9900"
            />
            <path
              d="M16.0284015,16.3109971 C15.9172235,16.2983527 15.8681879,16.2943435 15.7918591,16.2943435 C15.1914051,16.2943435 14.8877858,16.5003544 14.8877858,16.9072876 C14.8877858,17.1578622 15.0359718,17.3171506 15.2672714,17.3171506 C15.6988766,17.3171506 16.0098975,16.9063624 16.0284015,16.3109971 L16.0284015,16.3109971 Z M16.7989378,18.1044946 L15.9209243,18.1044946 L15.9411244,17.6873842 C15.6734336,18.016755 15.3161528,18.1740387 14.8308861,18.1740387 C14.2569545,18.1740387 13.8632825,17.7259342 13.8632825,17.0747486 C13.8632825,16.0945006 14.5477753,15.5228821 15.7229318,15.5228821 C15.8433618,15.5228821 15.9972532,15.5339844 16.1548453,15.5538762 C16.1875357,15.420956 16.1961709,15.3645189 16.1961709,15.292816 C16.1961709,15.0263588 16.0117479,14.9264373 15.5169209,14.9264373 C14.9986554,14.9211946 14.5712137,15.0502598 14.3955801,15.1079305 C14.4066825,15.0410078 14.5425325,14.2026236 14.5425325,14.2026236 C15.0702041,14.0474986 15.4183872,13.9895195 15.8102088,13.9895195 C16.7199875,13.9895195 17.2018618,14.3978405 17.2007824,15.1697646 C17.2026328,15.3768549 17.1682462,15.6314387 17.1150473,15.9672858 C17.0231442,16.5501609 16.8257685,17.8002585 16.7989378,18.1044946 L16.7989378,18.1044946 Z"
              id="Fill-336"
              fill="#000066"
            />
            <polyline
              id="Fill-337"
              fill="#000066"
              points="13.4199582 18.1044946 12.3604515 18.1044946 12.9676902 14.3000779 11.6124285 18.1044946 10.8906193 18.1044946 10.8013377 14.3213574 10.1635674 18.1044946 9.17206288 18.1044946 10.0002698 13.1551446 11.5236094 13.1551446 11.6156667 15.9254977 12.5450287 13.1551446 14.2392216 13.1551446 13.4199582 18.1044946"
            />
            <path
              d="M32.7159008,16.3109971 C32.6047227,16.2983527 32.556304,16.2943435 32.4801293,16.2943435 C31.8792128,16.2943435 31.5759018,16.5003544 31.5759018,16.9072876 C31.5759018,17.1578622 31.723471,17.3171506 31.9553875,17.3171506 C32.3866842,17.3171506 32.6978594,16.9063624 32.7159008,16.3109971 L32.7159008,16.3109971 Z M33.4868996,18.1044946 L32.6091945,18.1044946 L32.6290863,17.6873842 C32.3613955,18.016755 32.0042688,18.1740387 31.5193105,18.1740387 C30.944608,18.1740387 30.5513986,17.7259342 30.5513986,17.0747486 C30.5513986,16.0945006 31.2361997,15.5228821 32.4110478,15.5228821 C32.5313236,15.5228821 32.6849066,15.5339844 32.842653,15.5538762 C32.8754975,15.420956 32.8839785,15.3645189 32.8839785,15.292816 C32.8839785,15.0263588 32.6995556,14.9264373 32.2047285,14.9264373 C31.6864631,14.9211946 31.2591755,15.0502598 31.0829252,15.1079305 C31.0946444,15.0410078 31.2308028,14.2026236 31.2308028,14.2026236 C31.758166,14.0474986 32.1066575,13.9895195 32.4978623,13.9895195 C33.4082577,13.9895195 33.8895152,14.3978405 33.88859,15.1697646 C33.8904404,15.3768549 33.8563623,15.6314387 33.8028549,15.9672858 C33.7112603,16.5501609 33.513422,17.8002585 33.4868996,18.1044946 L33.4868996,18.1044946 Z"
              id="Fill-338"
              fill="#000066"
            />
            <path
              d="M21.5066568,18.0432773 C21.216607,18.1345636 20.9907043,18.1740387 20.7455267,18.1740387 C20.2041313,18.1740387 19.9085303,17.8628636 19.9085303,17.289703 C19.9009746,17.1117565 19.9864012,16.6437602 20.0536323,16.2169352 C20.1150038,15.840842 20.5131477,13.4701747 20.5131477,13.4701747 L21.5658695,13.4701747 L21.4429723,14.079418 L22.0790463,14.079418 L21.9354863,15.0468674 L21.2972535,15.0468674 C21.1749731,15.8121608 21.0005731,16.7657322 20.9985685,16.892176 C20.9985685,17.0997289 21.109284,17.1903984 21.3614006,17.1903984 C21.482139,17.1903984 21.5755841,17.1780624 21.6469786,17.1524652 L21.5066568,18.0432773"
              id="Fill-339"
              fill="#000066"
            />
            <path
              d="M24.7349832,18.0107412 C24.3732305,18.1213024 24.0245848,18.1752723 23.6546596,18.1740387 C22.4758023,18.1724967 21.8613163,17.5572396 21.8613163,16.3783824 C21.8613163,15.002612 22.6429549,13.9895195 23.7043119,13.9895195 C24.5723024,13.9895195 25.126959,14.5566663 25.126959,15.4457822 C25.126959,15.7413831 25.0890259,16.0294283 24.9969686,16.4358989 L22.9001601,16.4358989 C22.8293824,17.0198534 23.2028543,17.2630265 23.8157984,17.2630265 C24.1922,17.2630265 24.5323647,17.1853098 24.9103083,17.0099847 L24.7349832,18.0107412 L24.7349832,18.0107412 Z M24.1431645,15.6246539 C24.1491783,15.540615 24.2546509,14.906083 23.6528092,14.906083 C23.3178873,14.906083 23.0777982,15.1617462 22.9804982,15.6246539 L24.1431645,15.6246539 L24.1431645,15.6246539 Z"
              id="Fill-340"
              fill="#000066"
            />
            <path
              d="M17.4342408,15.3520287 C17.4342408,15.8607338 17.6809605,16.2118466 18.2407056,16.4756824 C18.6695352,16.6775299 18.7363037,16.7372053 18.7363037,16.919932 C18.7363037,17.1705066 18.5469464,17.284306 18.1279856,17.284306 C17.8117219,17.284306 17.5178171,17.2346537 17.1787318,17.1250177 C17.1787318,17.1250177 17.0397978,18.0125916 17.0331672,18.0549965 C17.2738731,18.107116 17.4885191,18.155689 18.1355414,18.1740387 C19.2531813,18.1740387 19.7694422,17.7484474 19.7694422,16.8286457 C19.7694422,16.2756853 19.5535625,15.9510948 19.0228069,15.7068424 C18.5788657,15.5031445 18.5275172,15.4575014 18.5275172,15.269686 C18.5275172,15.0521102 18.7037675,14.9409321 19.0459368,14.9409321 C19.2539523,14.9409321 19.5378341,14.9631369 19.8072211,15.0012242 L19.9578743,14.0676988 C19.6837071,14.0240603 19.2676761,13.9895195 19.0255825,13.9895195 C17.8407114,13.9895195 17.4303858,14.6083232 17.4342408,15.3520287"
              id="Fill-341"
              fill="#000066"
            />
            <path
              d="M29.8869517,14.0957632 C30.1811649,14.0957632 30.4559489,14.1722463 30.8335841,14.3629914 L31.0070589,13.2888358 C30.8513171,13.2277727 30.3052957,12.869875 29.8426964,12.869875 C29.1339943,12.869875 28.5341572,13.2220673 28.1130376,13.8020126 C27.4980889,13.5984689 27.2450471,14.0098739 26.9354139,14.4200453 L26.6601673,14.4841924 C26.6809843,14.3492676 26.6999509,14.215268 26.6939371,14.079418 L25.7206281,14.079418 C25.5880163,15.3255064 25.3525533,16.5871689 25.1682845,17.8337198 L25.12002,18.1044946 L26.179835,18.1044946 C26.3567022,16.9550895 26.4533854,16.2194024 26.5122898,15.7210288 L26.9113588,15.4999063 C26.9713425,15.2777044 27.1574616,15.2030717 27.5318586,15.2121695 C27.4829773,15.4746175 27.456455,15.7510977 27.456455,16.0371383 C27.456455,17.3541585 28.1679327,18.1740387 29.3077774,18.1740387 C29.601528,18.1740387 29.8537988,18.1354888 30.2443868,18.0290909 L30.4300433,16.9012738 C30.0787763,17.0738234 29.7911937,17.1550866 29.5304419,17.1550866 C28.914568,17.1550866 28.5418671,16.7003515 28.5418671,15.9492444 C28.5418671,14.8592062 29.0957527,14.0957632 29.8869517,14.0957632"
              id="Fill-342"
              fill="#000066"
            />
            <path
              d="M38.8555188,13.1551446 L38.6200558,14.585039 C38.3309312,14.2040114 38.0197561,13.9279938 37.6089679,13.9279938 C37.0745115,13.9279938 36.5880112,14.3332308 36.2694345,14.9299839 C35.8258017,14.8379267 35.3673658,14.6814139 35.3673658,14.6814139 L35.3670574,14.6852689 C35.4023692,14.3517348 35.4173265,14.1488079 35.4137799,14.079418 L34.4406252,14.079418 C34.308476,15.3255064 34.0727045,16.5871689 33.88859,17.8337198 L33.8398629,18.1044946 L34.8999863,18.1044946 C35.0430837,17.1755952 35.1524113,16.4033628 35.2330578,15.7918065 C35.5954273,15.4642861 35.7767662,15.1794792 36.1420655,15.1979831 C35.9800015,15.589959 35.885477,16.0411475 35.885477,16.5040552 C35.885477,17.5099003 36.3940279,18.1740387 37.16441,18.1740387 C37.552685,18.1740387 37.8505989,18.0400391 38.1411113,17.7297892 L38.0911506,18.1041862 L39.0934491,18.1041862 L39.9003765,13.1551446 L38.8555188,13.1551446 L38.8555188,13.1551446 Z M37.5307886,17.17467 C37.1702695,17.17467 36.987697,16.9072876 36.987697,16.381158 C36.987697,15.589959 37.3289411,15.0291344 37.8092734,15.0291344 C38.1733391,15.0291344 38.3707148,15.3060772 38.3707148,15.8174036 C38.3707148,16.6153874 38.0237653,17.17467 37.5307886,17.17467 L37.5307886,17.17467 Z"
              id="Fill-343"
              fill="#000066"
            />
            <polyline
              id="Fill-344"
              fill="#FFFFFF"
              points="13.7246569 17.8107441 12.6654587 17.8107441 13.2725432 14.0063273 11.9175898 17.8107441 11.1954723 17.8107441 11.1063448 14.0283779 10.4684204 17.8107441 9.47691582 17.8107441 10.3051228 12.8618566 11.8284623 12.8618566 11.8710215 15.9254977 12.8989171 12.8618566 14.5440745 12.8618566 13.7246569 17.8107441"
            />
            <path
              d="M16.3332545,16.0178633 C16.2222306,16.0049105 16.1731951,16.0013639 16.0968662,16.0013639 C15.4964123,16.0013639 15.1926387,16.2070664 15.1926387,16.6136912 C15.1926387,16.8639575 15.3409789,17.024171 15.5724328,17.024171 C16.0038837,17.024171 16.3149047,16.6133828 16.3332545,16.0178633 L16.3332545,16.0178633 Z M17.1036365,17.8107441 L16.2260856,17.8107441 L16.2461316,17.3939421 C15.9784408,17.7231586 15.6211599,17.8805966 15.1358932,17.8805966 C14.5618075,17.8805966 14.1682897,17.4318752 14.1682897,16.7808438 C14.1682897,15.8004416 14.8529366,15.2295941 16.0280931,15.2295941 C16.1482147,15.2295941 16.3021061,15.2405423 16.4598525,15.2608966 C16.4925428,15.127668 16.501178,15.0710767 16.501178,14.9990654 C16.501178,14.7327624 16.3167551,14.633612 15.821928,14.633612 C15.3035084,14.627444 14.8762208,14.7565092 14.7004331,14.8140257 C14.7116897,14.7474114 14.8473855,13.9090273 14.8473855,13.9090273 C15.3752113,13.7542107 15.7233944,13.695769 16.115216,13.695769 C17.0248404,13.695769 17.5065605,14.1045526 17.5057895,14.8761682 C17.5074857,15.0829501 17.4734076,15.3387675 17.4200544,15.6736894 C17.3279972,16.256102 17.1306215,17.5069705 17.1036365,17.8107441 L17.1036365,17.8107441 Z"
              id="Fill-345"
              fill="#FFFFFF"
            />
            <path
              d="M31.3117576,12.9950852 L31.1382829,14.0690866 C30.7600309,13.8789583 30.4860179,13.8020126 30.1921131,13.8020126 C29.4012225,13.8020126 28.8464117,14.5663809 28.8464117,15.6565733 C28.8464117,16.4076803 29.2192667,16.8614903 29.8351406,16.8614903 C30.0960466,16.8614903 30.384246,16.7808438 30.7347421,16.6076774 L30.548623,17.7354946 C30.1586517,17.8417382 29.9062267,17.8805966 29.6126304,17.8805966 C28.4720146,17.8805966 27.7616163,17.0605622 27.7616163,15.7438503 C27.7616163,13.9745622 28.7437146,12.7380342 30.1475494,12.7380342 C30.6101487,12.7380342 31.1564785,12.9340221 31.3117576,12.9950852"
              id="Fill-346"
              fill="#FFFFFF"
            />
            <path
              d="M33.0205995,16.0178633 C32.9101925,16.0049105 32.8610027,16.0013639 32.784828,16.0013639 C32.1846825,16.0013639 31.8807548,16.2070664 31.8807548,16.6136912 C31.8807548,16.8639575 32.0289408,17.024171 32.2602404,17.024171 C32.6915372,17.024171 33.0028665,16.6133828 33.0205995,16.0178633 L33.0205995,16.0178633 Z M33.7915984,17.8107441 L32.9140475,17.8107441 L32.9339392,17.3939421 C32.6662484,17.7231586 32.3089676,17.8805966 31.8241635,17.8805966 C31.2494609,17.8805966 30.8564057,17.4318752 30.8564057,16.7808438 C30.8564057,15.8004416 31.5404359,15.2295941 32.7159008,15.2295941 C32.8360224,15.2295941 32.9896053,15.2405423 33.1471975,15.2608966 C33.1800421,15.127668 33.1886772,15.0710767 33.1886772,14.9990654 C33.1886772,14.7327624 33.0047169,14.633612 32.5095815,14.633612 C31.9916244,14.627444 31.5644911,14.7565092 31.3883949,14.8140257 C31.3994973,14.7474114 31.5353473,13.9090273 31.5353473,13.9090273 C32.0631731,13.7542107 32.4110478,13.695769 32.8028694,13.695769 C33.7124939,13.695769 34.1946766,14.1045526 34.193443,14.8761682 C34.1956018,15.0829501 34.1612152,15.3387675 34.1078621,15.6736894 C34.0161132,16.256102 33.8187375,17.5069705 33.7915984,17.8107441 L33.7915984,17.8107441 Z"
              id="Fill-347"
              fill="#FFFFFF"
            />
            <path
              d="M21.8113555,17.749681 C21.5216142,17.8409672 21.2954031,17.8805966 21.0505339,17.8805966 C20.5089843,17.8805966 20.2132291,17.5692672 20.2132291,16.9967235 C20.2058275,16.8181601 20.2914084,16.3509348 20.3587937,15.9236473 C20.4197026,15.5472456 20.8180006,13.1768867 20.8180006,13.1768867 L21.8710308,13.1768867 L21.7478252,13.7859758 L22.2884496,13.7859758 L22.144427,14.7531168 L21.6021064,14.7531168 C21.479826,15.5191813 21.3054261,16.4721359 21.3032673,16.5985797 C21.3032673,16.8067494 21.4144453,16.896802 21.6662535,16.896802 C21.786992,16.896802 21.8802828,16.8849286 21.9519857,16.8587147 L21.8113555,17.749681"
              id="Fill-348"
              fill="#FFFFFF"
            />
            <path
              d="M25.0402987,17.7177616 C24.6783919,17.8286312 24.3289752,17.880905 23.9595125,17.8805966 C22.7805011,17.8798256 22.1664776,17.2637975 22.1664776,16.0850944 C22.1664776,14.7088615 22.947962,13.695769 24.0085481,13.695769 C24.8774638,13.695769 25.431812,14.2629157 25.431812,15.153111 C25.431812,15.4480952 25.393262,15.7359861 25.3022841,16.1426109 L23.2051673,16.1426109 C23.1340812,16.7265655 23.5080156,16.9702011 24.1203429,16.9702011 C24.4970529,16.9702011 24.8370634,16.892176 25.2154697,16.7163883 L25.0402987,17.7177616 L25.0402987,17.7177616 Z M24.4483258,15.3309033 C24.4540312,15.2467103 24.5599664,14.6124866 23.9582789,14.6124866 C23.6230486,14.6124866 23.3829596,14.8686124 23.2853511,15.3309033 L24.4483258,15.3309033 L24.4483258,15.3309033 Z"
              id="Fill-349"
              fill="#FFFFFF"
            />
            <path
              d="M17.7389396,15.0582781 C17.7389396,15.5679084 17.9859676,15.9182503 18.545867,16.1823945 C18.9746965,16.3840878 19.0411566,16.4437631 19.0411566,16.626644 C19.0411566,16.877527 18.8519535,16.9907097 18.4326843,16.9907097 C18.116729,16.9907097 17.82267,16.9416742 17.4834305,16.8323465 C17.4834305,16.8323465 17.3446508,17.7193036 17.3381744,17.7610917 C17.578726,17.8138281 17.7935263,17.8619384 18.4402401,17.8805966 C19.5581885,17.8805966 20.0744493,17.4551594 20.0744493,16.5353578 C20.0744493,15.982089 19.8582612,15.6576527 19.3276598,15.414017 C18.8835645,15.2097023 18.8325243,15.1645218 18.8325243,14.9763981 C18.8325243,14.7588222 19.0086205,14.6468732 19.3510982,14.6468732 C19.5586511,14.6468732 19.8428412,14.6700031 20.112074,14.7079363 L20.2628814,13.7745651 C19.98856,13.7312349 19.5728374,13.695769 19.3305896,13.695769 C18.1454102,13.695769 17.735393,14.3147269 17.7389396,15.0582781"
              id="Fill-350"
              fill="#FFFFFF"
            />
            <path
              d="M39.398302,17.8107441 L38.3963119,17.8107441 L38.4459643,17.4361928 C38.1552977,17.746597 37.8575379,17.8805966 37.4692629,17.8805966 C36.6994976,17.8805966 36.1901758,17.2172291 36.1901758,16.2103046 C36.1901758,14.8715422 36.9798328,13.7431083 37.9138208,13.7431083 C38.324609,13.7431083 38.63563,13.9108776 38.9253713,14.2920595 L39.1603718,12.8618566 L40.2050752,12.8618566 L39.398302,17.8107441 L39.398302,17.8107441 Z M37.8354874,16.8807652 C38.3290808,16.8807652 38.6754135,16.3217911 38.6754135,15.5245783 C38.6754135,15.0130976 38.478192,14.7352296 38.1141263,14.7352296 C37.6336399,14.7352296 37.29255,15.2962084 37.29255,16.0877158 C37.29255,16.6144622 37.4752767,16.8807652 37.8354874,16.8807652 L37.8354874,16.8807652 Z"
              id="Fill-351"
              fill="#FFFFFF"
            />
            <path
              d="M34.7457865,13.7859758 C34.6133289,15.0317558 34.3777117,16.2934183 34.193443,17.5401235 L34.1451784,17.8107441 L35.2046851,17.8107441 C35.5837081,15.3494073 35.6748402,14.869075 36.2694345,14.9290587 C36.3639589,14.4252881 36.5402093,13.9841225 36.6714333,13.7617665 C36.2278005,13.6692466 35.9800015,13.9199754 35.6552568,14.3964527 C35.6811624,14.1902876 35.7278849,13.9902905 35.7190955,13.7859758 L34.7457865,13.7859758"
              id="Fill-352"
              fill="#FFFFFF"
            />
            <path
              d="M26.0259437,13.7859758 C25.8928693,15.0317558 25.6569436,16.2934183 25.4729833,17.5401235 L25.424873,17.8107441 L26.484688,17.8107441 C26.8638652,15.3494073 26.9546889,14.869075 27.548358,14.9290587 C27.6434993,14.4252881 27.820058,13.9841225 27.9509736,13.7617665 C27.5078035,13.6692466 27.2595418,13.9199754 26.9354139,14.3964527 C26.9610111,14.1902876 27.008042,13.9902905 26.99879,13.7859758 L26.0259437,13.7859758"
              id="Fill-353"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </g>
    </g>
  </SvgIcon>
)
CreditCardVisa = pure(CreditCardVisa)
CreditCardVisa.displayName = 'CreditCardVisa'
CreditCardVisa.muiName = 'SvgIcon'

export default CreditCardVisa
