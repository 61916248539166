import { tr } from 'pmt-modules/i18n'
import React from 'react'

import OrderHistory from '../OrderHistory'
import TopUpHistory from '../TopUpHistory'
import Tabs, { Tab } from 'pmt-ui/Tabs'
import AppBar from 'pmt-ui/AppBar'

import { withStyles } from 'pmt-ui/styles'

const styles = () => ({
  tabsAppBar: {
    boxShadow: 'none',
  },
})

const TabContainer = ({ children, selected, style }) => {
  const tabTemplateStyles = {
    width: '100%',
    position: 'relative',
    textAlign: 'initial',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'auto',
  }

  const templateStyle = Object.assign({}, tabTemplateStyles, style)
  if (!selected) {
    templateStyle.display = 'none'
    templateStyle.height = 0
    templateStyle.overflow = 'hidden'
  }

  return <div style={templateStyle}>{children}</div>
}

/**
 * TODO: booking.
 */
class HistoryNormalModeView extends React.Component {
  static Tabs = {
    ORDER: 'order',
    TOP_UP: 'top-up',
  }

  state = {
    tabValue: HistoryNormalModeView.Tabs.TOP_UP,
  }

  handleTabChange = (event, value) => {
    this.setState({
      tabValue: value,
    })
  }

  render() {
    const { classes } = this.props

    return (
      <React.Fragment>
        <AppBar
          position="static"
          color="inherit"
          classes={{
            root: classes.tabsAppBar,
          }}
        >
          <Tabs
            value={this.state.tabValue}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab
              label={tr('web_customer.history.top_up', {
                fr: 'Rechargements',
                context: '',
                desc: 'History top up tab label',
              })}
              value={HistoryNormalModeView.Tabs.TOP_UP}
            />

            <Tab
              label={tr('web_customer.history.orders', {
                fr: 'Paiements',
                context: '',
                desc: 'History orders tab label',
              })}
              value={HistoryNormalModeView.Tabs.ORDER}
            />
          </Tabs>
        </AppBar>

        <TabContainer selected={this.state.tabValue === HistoryNormalModeView.Tabs.TOP_UP}>
          <TopUpHistory
            className="u-flexColumnTop u-flexGrow1 u-padding20 u-paddingTop7 u-overflowAuto"
            userId={this.props.userId}
          />
        </TabContainer>

        <TabContainer selected={this.state.tabValue === HistoryNormalModeView.Tabs.ORDER}>
          <OrderHistory
            className="u-flexColumnTop u-flexGrow1 u-padding20 u-paddingTop7 u-overflowAuto"
            userId={this.props.userId}
          />
        </TabContainer>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(HistoryNormalModeView)
