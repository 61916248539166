import { tr } from 'pmt-modules/i18n'
import React from 'react'

import Button from 'pmt-ui/Button'
import Dialog, { DialogActions, DialogContent, DialogContentText, DialogTitle } from 'pmt-ui/Dialog'
import ErrorBlock from 'pmt-ui/ErrorBlock'

const ErrorWithRefundDialog = ({ paymentError, open, onClose }) => (
  <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle>{paymentError.localizedMessage}</DialogTitle>
    <DialogContent>
      <DialogContentText>{tr('web_customer.payment.payment.refunded')}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} autoFocus color="primary">
        OK
      </Button>
    </DialogActions>
  </Dialog>
)

const ErrorSnackBar = ({ paymentError, resetPostPayment }) => (
  <ErrorBlock
    mode={ErrorBlock.Mode.SNACKBAR}
    error={paymentError}
    options={{
      onClose: () => resetPostPayment(),
    }}
  />
)

const ErrorAlert = ({ paymentError, resetPostPayment }) => {
  const [open, setOpen] = React.useState(true)

  const resetAndClose = () => {
    resetPostPayment()
    setOpen(false)
  }

  if (!paymentError) {
    return null
  }
  if (paymentError.refunded) {
    return (
      <ErrorWithRefundDialog
        paymentError={paymentError}
        resetPostPayment={resetPostPayment}
        open={open}
        onClose={resetAndClose}
      />
    )
  } else {
    return <ErrorSnackBar paymentError={paymentError} resetPostPayment={resetPostPayment} />
  }
}

export default ErrorAlert
