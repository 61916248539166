import { tr } from 'pmt-modules/i18n'
import React from 'react'
import map from 'lodash/map'

import Diet from './Diet'
import Allergen from './Allergen'
import Gender from './Gender'

import Celery from 'pmt-ui/svg-icons/food/celery'
import Cheese from 'pmt-ui/svg-icons/food/cheese'
import Crab from 'pmt-ui/svg-icons/food/crab'
import Egg from 'pmt-ui/svg-icons/food/egg'
import Fish from 'pmt-ui/svg-icons/food/fish'
import Lupine from 'pmt-ui/svg-icons/food/lupine'
import Milk from 'pmt-ui/svg-icons/food/milk'
import Mustard from 'pmt-ui/svg-icons/food/mustard'
import Nut from 'pmt-ui/svg-icons/food/nut'
import Peanut from 'pmt-ui/svg-icons/food/peanuts'
import Shellfish from 'pmt-ui/svg-icons/food/shellfish'
import Sesame from 'pmt-ui/svg-icons/food/sesame'
import Soy from 'pmt-ui/svg-icons/food/soy'
import Sulphites from 'pmt-ui/svg-icons/food/sulphites'
import Wheat from 'pmt-ui/svg-icons/food/wheat'

export Allergen from './Allergen'
export Diet from './Diet'
export Gender from './Gender'

export const DEFAULT_BIRTHDATE = -2208988800000

//
//
//

export const getGenderLabel = gender => {
  switch (gender) {
    case 0:
      return tr('global.gender.NONE')
    case 1:
      return tr('global.gender.MALE')
    case 2:
      return tr('global.gender.FEMALE')

    default:
      return gender
  }
}

export const getGenderOptions = () => map(Gender, gender => ({
  value: gender,
  label: getGenderLabel(gender),
}))

//
//
//

export const getDietLabel = diet => {
  switch (diet) {
    case Diet.NONE:
      return tr('global.diet.NONE')
    case Diet.KOSHER:
      return tr('global.diet.KOSHER')
    case Diet.HALAL:
      return tr('global.diet.HALAL')
    case Diet.VEGETARIAN:
      return tr('global.diet.VEGETARIAN')
    case Diet.VEGAN:
      return tr('global.diet.VEGAN')
    default:
      return diet
  }
}

export const getDietsOptions = () => map(Diet, diet => ({
  value: diet,
  label: getDietLabel(diet)
}))

//
//
//

export const getAllergenLabel = allergen => {
  switch (allergen) {
    case Allergen.CELERY:
      return tr('global.allergen.CELERY')
    case Allergen.CHEESE:
      return tr('global.allergen.CHEESE')
    case Allergen.CRUSTACEAN:
      return tr('global.allergen.CRUSTACEAN')
    case Allergen.EGG:
      return tr('global.allergen.EGG')
    case Allergen.FISH:
      return tr('global.allergen.FISH')
    case Allergen.GLUTEN:
      return tr('global.allergen.GLUTEN')
    case Allergen.LACTOSE:
      return tr('global.allergen.LACTOSE')
    case Allergen.LUPINE:
      return tr('global.allergen.LUPINE')
    case Allergen.MUSTARD:
      return tr('global.allergen.MUSTARD')
    case Allergen.NUTS:
      return tr('global.allergen.NUTS')
    case Allergen.PEANUT:
      return tr('global.allergen.PEANUT')
    case Allergen.SESAME:
      return tr('global.allergen.SESAME')
    case Allergen.SHELLFISH:
      return tr('global.allergen.SHELLFISH')
    case Allergen.SOYA:
      return tr('global.allergen.SOYA')
    case Allergen.SULPHITES:
      return tr('global.allergen.SULPHITES')
    default:
      return allergen
  }
}

export const getAllergenIcon = allergen => {
  switch (allergen) {
    case Allergen.CELERY:
      return <Celery />
    case Allergen.CHEESE:
      return <Cheese />
    case Allergen.CRUSTACEAN:
      return <Crab />
    case Allergen.EGG:
      return <Egg />
    case Allergen.FISH:
      return <Fish />
    case Allergen.GLUTEN:
      return <Wheat />
    case Allergen.LACTOSE:
      return <Milk />
    case Allergen.LUPINE:
      return <Lupine />
    case Allergen.MUSTARD:
      return <Mustard />
    case Allergen.NUTS:
      return <Nut />
    case Allergen.PEANUT:
      return <Peanut />
    case Allergen.SESAME:
      return <Sesame />
    case Allergen.SHELLFISH:
      return <Shellfish />
    case Allergen.SOYA:
      return <Soy />
    case Allergen.SULPHITES:
      return <Sulphites />
    default:
      return null
  }
}

export const getAllergensOptions = () => map(Allergen, allergen => ({
  value: allergen,
  label: getAllergenLabel(allergen),
  icon: getAllergenIcon(allergen),
}))


//
//
//

export const DEFAULT_DIETARY_PREFERENCES = {
  diet: Diet.NONE,
  allergens: [],
}
