//
// Define predicates that can be used to validate forms
//

import isString from 'lodash/isString'
import isEmpty from 'lodash/isEmpty'
import startsWith from 'lodash/startsWith'

import moment from 'moment'

import { isValidEmail as isValidEmailUtils } from 'pmt-utils/email'
import { getCountryDialCodeFromNumber, isValidPhoneNumber } from 'pmt-utils/phone'

export * from './card'
export * from './locale'

export const notEmpty = obj => {
  // working to check an empty string or a number (0 return true)
  // for empty string, send default value to null to not have an error from your form init
  return obj !== null && typeof obj !== 'undefined' && obj !== ''
}

// check an empty array
export const arrayNotEmpty = obj => obj !== null && typeof obj !== 'undefined' && obj.length > 0

export const isTrue = obj => obj === true

/**
 * Example:
 * ```
 * [isGreaterThan(5)]
 * ```
 */
export const isGreaterThan = (length, orEqual) => obj =>
  isString(obj) ? notEmpty(obj) && obj.length > length : obj > length

export const isGreaterThanInclude = (length, orEqual) => obj =>
  isString(obj) ? notEmpty(obj) && obj.length >= length : obj >= length

export const isLessThan = length => obj =>
  isString(obj) ? notEmpty(obj) && obj.length > length : obj < length

export const isLessThanInclude = length => obj =>
  isString(obj) ? notEmpty(obj) && obj.length <= length : obj <= length

export const isBetween = (min, max) => obj =>
  isString(obj) ? notEmpty(obj) && obj.length < max && obj.length > min : obj < max && obj > min

export const isBetweenInclude = (min, max) => obj =>
  isString(obj) ? notEmpty(obj) && obj.length <= max && obj.length >= min : obj <= max && obj >= min

export const isExpirationDateMonthInFuture = () => (obj, data) =>
  // moment().month() are zero indexed, so January is month 0, that's why
  // it's a strict greater than comparison
  data.expirationDateYear > moment().year() ||
  (data.expirationDateYear === moment().year() && obj > moment().month())

export const isValidEmail = email => isValidEmailUtils(email)

export const isValidEmailOrEmpty = email => isEmpty(email) || isValidEmail(email)

// if phone === getCountryCodeFromNumber(phone), then phone is empty so we can return true
export const isValidPhone = phone =>
  phone === null ||
  (startsWith(phone, getCountryDialCodeFromNumber(phone)) && isValidPhoneNumber(phone))
